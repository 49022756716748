import React from "react";
import { Fragment } from "react";

import SettlementLog from "./SettlementLog";
import Sidebar from "../../../sidebar/Sidebar";

const Logs = ({ match }) => {
  // Function for Log Page render according to :name
  function Log() {
    switch (match.params.name) {
      case "settlement-log":
        return <SettlementLog />;

      default:
        break;
    }
  }

  //   Function for Render the name of the page
  function LogName() {
    switch (match.params.name) {
      case "settlement-log":
        return <div>Settlement Log</div>;

      default:
        break;
    }
  }

  return (
    <Fragment>
      <main>
        <div
          className="card w-100 sticky-top mb-3 p-3 detail-sticky"
          style={{
            maxHeight: "91px",
            zIndex: "1030",
            paddingTop: "1em",
          }}
        >
          <h5
            style={{
              color: "#333333",
              fontWeight: "bold",
              fontFamily: "Montserrat",
            }}
          >
            {LogName()}
          </h5>
        </div>
        <div className="container-fluid">{Log()}</div>
      </main>
    </Fragment>
  );
};

export default Logs;

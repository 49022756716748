import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import '../src/styles/App.css';
import '../src/styles/Dashboard.css';
import '../src/styles/Card.css';
import '../src/styles/RequestMoreInfo.css';
import '../src/styles/Page404.css';
import '../src/styles/AutoSuggest.css';
import '../src/styles/progress.css';

ReactDOM.render(<App />, document.getElementById('root'));

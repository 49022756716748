import React from "react";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";
import moment from "moment";

const GridCheckbox = ({ headers, data, setState, initialState }) => {
    function EnhancedTableHead(props) {
        const { onSelectAllClick, numSelected, rowCount } = props;

        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ "aria-label": "select all desserts" }}
                        />
                    </StyledTableCell>

                    {headers.map((headCell) => (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "default"}
                        >
                            <b>{headCell.label}</b>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const useToolbarStyles = makeStyles((theme) => ({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
        },
        highlight:
            theme.palette.type === "light"
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.secondary.dark,
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            flex: "1 1 100%",
        },
    }));

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles();

        return (
            <Toolbar>
                <Typography
                    className={classes.title}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    IOU
                </Typography>
            </Toolbar>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };

    // Defualt Material UI Package functions
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#dadee7",
            color: theme.palette.common.black,
        },
        body: {
            fontSize: 15,
        },
    }))(TableCell);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
            backgroundColor: "#F8F9FB",
        },

        table: {
            minWidth: 750,
        },

        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
    }));

    const classes = useStyles();
    const [selectedId, setSelectedId] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const iouSum = (obj) =>
        Object.values(obj).reduce(
            (accumulator, current) => accumulator + current.amount,
            0
        );

    const handleSelectAllClick = (event) => {
        if (data !== "") {
            if (event.target.checked) {
                const newSelectedIds = data.map((i) => ({ id: i.id }));
                setSelectedId(newSelectedIds);
                setState({
                    idList: newSelectedIds,
                    totalAmount: iouSum(
                        data.map((i) => ({
                            amount: i.amount,
                        }))
                    ),
                });
                return;
            }
        }
        setSelectedId([]);

        // Set defualt callback state to initial state
        setState({
            idList: [],
            totalAmount: 0,
        });
    };
    const handleClick = (event, id, index) => {
        const selectedIdIndex = selectedId.findIndex((i) => i.id == id);
        let newSelected = [];

        if (selectedIdIndex === -1) {
            newSelected = newSelected.concat(selectedId, { id: id });
            setState({
                idList: newSelected,
                totalAmount: JSON.parse(initialState.totalAmount) + data[index].amount,
            });
        } else if (selectedIdIndex === 0) {
            setState({
                idList: newSelected,
                totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
            });
            newSelected = newSelected.concat(selectedId.slice(1));
        } else if (selectedIdIndex === selectedId.length - 1) {
            setState({
                idList: newSelected,
                totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
            });
            newSelected = newSelected.concat(selectedId.slice(0, -1));
        } else if (selectedIdIndex > 0) {
            setState({
                idList: newSelected,
                totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
            });
            newSelected = newSelected.concat(
                selectedId.slice(0, selectedIdIndex),
                selectedId.slice(selectedIdIndex + 1)
            );
        }

        setSelectedId(newSelected);
    };

    const isSelected = (id) => selectedId.findIndex((i) => i.id == id) !== -1;

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedId.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={data.length}
                        />
                        <TableBody>
                            {data !== "" &&
                                data
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, row.id, index)}
                                                role="checkbox"
                                                color="primary"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.formNumber}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                    />
                                                </TableCell>
                                                {headers.map((property) => (
                                                    <TableCell
                                                        align={property.numeric ? "right" : "left"}
                                                        id={labelId}
                                                        key={property.id}
                                                        scope="row"
                                                        padding={property.disablePadding && "none"}
                                                    >
                                                        {property.type === "date"
                                                            ? moment(
                                                                new Date(row[property.propertyValue])
                                                            ).format("YYYY-MM-DD")
                                                            : row[`${property.propertyValue}`]}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
};

GridCheckbox.propTypes = {
    headers: PropTypes.array.isRequired,
    setState: PropTypes.func.isRequired,
    initialState: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
};

export default GridCheckbox;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const UserInfo = ({ firstName, lastName, email, department }) => {
   
    const text = {
        fontFamily: 'Montserrat',
        marginLeft: '20px',
        marginRight: '20px',
        marginBottom: '10px',
        fontSize: '17px',
        color: 'black',
    };
    const subText = {
        fontFamily: 'Montserrat',
        marginTop: '20px',
        marginBottom: '10px',
        fontWeight: '200',
        color: 'black',
    };
    return (
        <div>
            <div className="card card-userinfo card-body mb-4">
                <div style={text}>
                    <b>REQUESTER</b>
                </div>
                <div className="divider" />
                <div className="row pl-3 pr-3">
                    <div className="col-lg-4" style={subText}>
                        <b>
                            {firstName} {lastName}
                        </b>
                    </div>
                    <div className="col-lg-4" style={subText}>
                        <b>{email}</b>
                    </div>
                    <div className="col-lg-3" style={subText}>
                        <b>{department.name}</b>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserInfo.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    department: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(UserInfo);

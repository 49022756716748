import {
  GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT,
  GET_RELATIONSHIP_MANAGER_FOR_USER,
  GET_TEMP_CREDIT_REQUEST_BY_ID,
  GET_CURRENCY_VALUES,
    GET_ALL_CREDIT_TERMS,
    CLEAR_REQUEST_ITEM
} from "../actions/types";

const initialState = {
  relationship_managers: null,
  relationship_managers_loading: true,

  relationship_manager: null,
  relationship_manager_loading: true,

  request_item: null,
  request_item_loading: true,

  currencies: null,
  currencies_loading: true,

  credit_terms: null,
  credit_terms_loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT:
      return {
        ...state,
        relationship_managers_loading: false,
        relationship_managers: action.payload,
      };
    case GET_RELATIONSHIP_MANAGER_FOR_USER:
      return {
        ...state,
        relationship_manager_loading: false,
        relationship_manager: action.payload,
      };
    case GET_TEMP_CREDIT_REQUEST_BY_ID:
      return {
        ...state,
        request_item_loading: false,
        request_item: action.payload,
      };
    case GET_CURRENCY_VALUES:
      return {
        ...state,
        currencies_loading: false,
        currencies: action.payload,
      };
    case GET_ALL_CREDIT_TERMS:
      return {
        ...state,
        credit_terms_loading: false,
        credit_terms: action.payload,
          };
      case CLEAR_REQUEST_ITEM:
          return {
              ...state,
              request_item: null,
              request_item_loading: true,
          }
    default:
      return state;
  }
};

export default reducer;

import axios from 'axios';

import {
    CLEAR_FORM,
    CLEAR_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGIN_SUCCESS_ADMIN,
    LOGOUT,
    LOAD_USER,
    USER_LOADING,
    CHANGE_PASSWORD,

} from './types';
import { setAlert } from './alert';

// Login User
export const login =
    ({ Email, password }) =>
        async dispatch => {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            const body = JSON.stringify({ Email, password });

            try {
                const res = await axios.post('/api/user/logIn', body, config);

                if (res.data.roles.length > 0) {
                    if (
                        res.data.roles.some(e => e.name === 'Head of Department') ||
                        res.data.roles.some(e => e.name === 'Approver') ||
                        res.data.roles.some(e => e.name === 'IT Manager')
                    ) {
                        dispatch({
                            type: LOGIN_SUCCESS_ADMIN,
                            payload: res.data,
                        });
                    } else {
                        dispatch({
                            type: LOGIN_SUCCESS,
                            payload: res.data,
                        });
                    }
                } else {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: res.data,
                    });
                }
                if (res.data.roles.some(e => e.name === 'Security Supervisor'))
                    localStorage.setItem('role', 'Admin');
                if (
                    res.data.roles.some(e => e.name === 'Security Supervisor') ||
                    res.data.roles.some(e => e.name === 'Director of Security')
                )
                    localStorage.setItem('role', 'Security Supervisor/Manager');
                else if (res.data.roles.some(e => e.name === 'Cashier'))
                    localStorage.setItem('role', 'Cashier');
            } catch (err) {
                console.log(err);
                // If Error occures
                dispatch(
                    setAlert('Access Denied, Check your User Name and Password', 'error')
                );

                dispatch({
                    type: LOGIN_FAIL,
                });
            }
        };

// Get user info from localstorage
export const loadUser = () => dispatch => {

    // Loading Spinner
    dispatch({ type: USER_LOADING });

    // NOTE: Check the localstorage for loggedin user details
    const user = {
        firstName: localStorage.getItem('firstName'),
        lastName: localStorage.getItem('lastName'),
        access: localStorage.getItem('access'),
        id: parseInt(localStorage.getItem('id')),
    };

    dispatch({
        type: LOAD_USER,
        payload: user,
    });
};

// Change password of a user
export const changePassword = (userId, newPassword) => async (dispatch) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const body = {
        UserId: userId,

        NewPassword: newPassword,
    };

    try {
        const res = await axios.post("/api/User/reset-password", body, config);

        if (res.status === 200) {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: true,
            });
            dispatch(setAlert("Password has been Changed successfully", "success"));
        } else {
            dispatch({
                type: CHANGE_PASSWORD,
                payload: false,
            });
        }
    } catch (err) {
        console.log(err.error);
    }
};

// Logout /clear everthing
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT,
    });
    dispatch({
        type: CLEAR_FORM,
    });
    dispatch({
        type: CLEAR_REQUEST,
    });

    dispatch(setAlert('You Have Successfully Signed Out', 'success'));

};

// Set loading state
export const setUserLoading = () => {
    return {
        type: USER_LOADING,
    };
};

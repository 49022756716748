import { GET_REPORTS, SET_REPORT_LOADING } from '../actions/types';

const intialState = {
  reports: [],
  reports_loading: true,
};

export default function (state = intialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REPORTS:
      return {
        ...state,
        reports: payload,
        reports_loading: false,
          };
      case SET_REPORT_LOADING:

      return {
        ...state,
        reports_loading: true,
      };

    default:
      return state;
  }
}

import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

import {
    GET_CASHBOX_DATA,
    GET_CASHBOX_HISTORY_DATA,
    REMAIN_CASH_LOADING,
    REQUEST_HISTORY_LOADING,
} from './types';

export const getCashboxData = dateRange => async dispatch => {
    dispatch({ type: REMAIN_CASH_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    const config = {
        'Content-Type': 'application/json',
    };
    try {
        const res = await axios.get(`/api/cashbox/get-cashbox`, config);

      

        if (res.status == 200) {
            dispatch(getCashboxHistory(dateRange));
        }

        dispatch({
            type: GET_CASHBOX_DATA,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

export const submitCashbox = (formData, dateRange) => async dispatch => {
    console.log(formData);
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(
            `/api/cashbox/insert-cashbox`,
            formData,
            config
        );

       

        dispatch(getCashboxData(dateRange));
        //dispatch(getCashboxHistory(dateRange));
        console.log(dateRange);
    } catch (err) {
        console.log(err);
    }
};

// Get Cashbox history Data
export const getCashboxHistory = dateRange => async dispatch => {
    console.log(dateRange);

    dispatch({ type: REQUEST_HISTORY_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(
            `/api/cashbox/get-by-period?dateFrom=${dateRange.from}&dateTo=${dateRange.to}`,
            config
        );

        

        dispatch({
            type: GET_CASHBOX_HISTORY_DATA,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Set loading state
export const setRemainCashLoading = () => {
    return {
        type: REMAIN_CASH_LOADING,
    };
};

export const historyRequestLoading = () => {
    return {
        type: REQUEST_HISTORY_LOADING,
    };
};

import axios from "axios";
import store from "../store";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";
import { clearRequests, setRequestLoading } from "./requests";

import {
    CLEAR_REQUEST,
    CLEAR_TILES,
    DASHBOARD_TILE,
    GET_REQUESTS,
    GET_APPROVED_REQUESTS_COUNT,
    GET_PENDING_REQUESTS_COUNT,
    GET_REJECTED_REQUESTS_COUNT,
    GET_ALL_REQUESTS,
} from "./types";

// get all requests
export const getDashboardTileData = (userId) => async (dispatch) => {
    dispatch(clearTiles());

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        
        const res = await axios.get(
            `/api/dashboard/get-alldata-by-userid?userid=${userId}`,
            config
        );
       

        //   pendingCount: 11,
        //   rejectCount: 0,
        // };

        //   pendingCount: 11,
        //   rejectCount: 0,
        // };

        dispatch({
            type: DASHBOARD_TILE,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get all requests
export const getAllRequests = (userId) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-alldata-by-userid?userid=${userId}`,
            config
        );

        dispatch({
            type: GET_REQUESTS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get approved request
export const getApprovedRequests = (userId) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-approved?userid=${userId}`,
            config
        );
       
        dispatch({
            type: GET_REQUESTS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get approved request count
export const getApprovedRequestsCount = (userId) => async (dispatch) => {
    dispatch(clearTiles());
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-approved?userid=${userId}`,
            config
        );
        
        dispatch({
            type: GET_APPROVED_REQUESTS_COUNT,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get rejected requests
export const getRejectedRequests = (userId) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-rejected?userid=${userId}`,
            config
        );
        
        dispatch({
            type: GET_REQUESTS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get rejected requests count
export const getRejectedRequestsCount = (userId) => async (dispatch) => {
    dispatch(clearTiles());
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-rejected?userid=${userId}`,
            config
        );
        
        dispatch({
            type: GET_REJECTED_REQUESTS_COUNT,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get pending requests
export const getPendingRequests = (userId) => async (dispatch) => {
    store.dispatch({ type: CLEAR_REQUEST });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-pending?userid=${userId}`,
            config
        );

        dispatch({
            type: GET_REQUESTS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// get pending requests count
export const getPendingRequestsCount = (userId) => async (dispatch) => {
    dispatch(clearTiles());
    store.dispatch({ type: CLEAR_REQUEST });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/dashboard/get-pending?userid=${userId}`,
            config
        );

        dispatch({
            type: GET_PENDING_REQUESTS_COUNT,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert("Something Went Wrong", "error"));
    }
};

// Clear tiles
export const clearTiles = () => {
    return {
        type: CLEAR_TILES,
    };
};

import {
  GET_ALL_ROLES,
  GET_ROLE_BY_ID,
    GET_ALL_ACCESS_LEVELS,
    ROLE_LOADING,
} from "../actions/types";

const initialState = {
  roles: [],
  roles_loading: true,

  role: null,
  role_loading: true,

  access_levels: [],
  access_levels_loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ROLES:
      return {
        ...state,
        roles_loading: false,
        roles: action.payload,
      };
    case GET_ROLE_BY_ID:
      return {
        ...state,
        role_loading: false,
        role: action.payload,
      };
    case GET_ALL_ACCESS_LEVELS:
      return {
        ...state,
        access_levels_loading: false,
        access_levels: action.payload,
          };
      case ROLE_LOADING:
          return {
              ...state,
              role_loading: true,
          };

    default:
      return state;
  }
};

export default reducer;

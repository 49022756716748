import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Fragment } from 'react';

import IouGrid from './IouGrid';
import {
    getPendingSettlementIOUs,
    getPendingSettlmentPettyCash,
    getRemainingCashboxBalance,
    pettyCashSettle,
    iouSettle,
    createSettlementAndReconsilationLog,
} from '../../../../actions/settlement';
import './index.css';
import PettyCashGrid from './PettyCashGrid';
import Skeleton from './Skeleton';
import CardSpinner from '../../../layout/CardSpinner';

const Settlement = ({
    getPendingSettlementIOUs,
    getRemainingCashboxBalance,
    getPendingSettlmentPettyCash,
    pettyCashSettle,
    iouSettle,
    createSettlementAndReconsilationLog,
    settlement: {
        iou,
        pettyCash,
        loading_iou,
        loading_pettyCash,
        loading_cashBox,
        remainingCashBox,
        loading,
    },
    alert,
    history,
}) => {
    useEffect(() => {
        getPendingSettlementIOUs();
        getPendingSettlmentPettyCash();
        getRemainingCashboxBalance();
    }, []);

    // Submit Loading state
    const [submitLoading, setSubmitLoading] = useState(true);

    // Set submit loading to  false after all loadings are false
    useEffect(() => {
        if (!loading && !loading_cashBox && !loading_iou && !loading_pettyCash) {
            setSubmitLoading(false);
        }
    }, [loading_pettyCash, loading_cashBox, remainingCashBox, loading]);

    const [iouCost, setIouCost] = useState({
        idList: [],
        totalAmount: 0,
    });

    const [pettyCashCost, setPettyCashCost] = useState({
        idList: [],
        totalAmount: 0,
    });

    useEffect(() => {
        if (alert.length > 0) {
            setSubmitLoading(false);
        }
    }, [alert]);

    //   Calculate total balance: pettycash - iou
    const totalBalance = pettyCashCost.totalAmount - iouCost.totalAmount;

    //   Calculate Initial PettyCash cost
    let reaminingBalance;
    if (remainingCashBox != null) {
        reaminingBalance =
            remainingCashBox.remainingCash -
            pettyCashCost.totalAmount +
            iouCost.totalAmount;
    }
    //   Call Back funcitons from the IOU and Petty Cash grid
    const IouCostCallback = callbackCost => setIouCost(callbackCost);
    const PettyCashCostCallback = callbackCost => setPettyCashCost(callbackCost);

    const onSettle = e => {
        setSubmitLoading(true);
        e.preventDefault();

        // Settlement
        //pettyCashSettle(pettyCashCost.idList);
        //iouSettle(iouCost.idList);

        // Settlement Log
        createSettlementAndReconsilationLog(
            pettyCashCost.totalAmount,
            iouCost.totalAmount,
            pettyCashCost.idList,
            iouCost.idList
        );
    };

    useEffect(() => {
        setIouCost({ idList: [], totalAmount: 0 });
        setPettyCashCost({ idList: [], totalAmount: 0 });
    }, [remainingCashBox]);

    return (
        <div className="pl-3 pr-3">
            {!loading && !loading_cashBox && !loading_iou && !loading_pettyCash ? (
                <Fragment>
                    <div className="card card-body p-3">
                        <div className="cost-container">
                            <div className="p-2">
                                <div className="small-heading">Remaining Balance</div>
                                <h2 className="amount-heading">
                                    <span>LKR </span>

                                    {remainingCashBox != null &&
                                        (remainingCashBox.remainingCash === 0
                                            ? '0.00'
                                            : remainingCashBox.remainingCash)}
                                </h2>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-3 p-4">
                                    <div className="small-heading">Total Petty Cash Cost</div>
                                    <h2 className="amount-heading-normal">
                                        <span>LKR </span>
                                        {pettyCashCost.totalAmount === 0
                                            ? '0.00'
                                            : pettyCashCost.totalAmount}
                                    </h2>
                                </div>
                                <div className="col-md-3 p-4">
                                    <div className="small-heading">Total IOU Cost</div>
                                    <h2 className="amount-heading-normal">
                                        <span>LKR </span>

                                        {iouCost.totalAmount === 0 ? '0.00' : iouCost.totalAmount}
                                    </h2>
                                </div>
                                <div className="col-md-3 p-4">
                                    <div className="small-heading">Total Cost</div>
                                    <h2 className="amount-heading-normal">
                                        <span>LKR </span>
                                        {totalBalance === 0 ? '0.00' : totalBalance}
                                    </h2>
                                </div>
                                {(totalBalance > 0 ||
                                    totalBalance < 0) && (
                                        <div className="col-md-3 p-4">
                                            <div className="small-heading">
                                                Remaining Total Balance
                      </div>
                                            <h2 className="amount-heading">
                                                <span>LKR </span>
                                                {reaminingBalance === 0
                                                    ? '0.00'
                                                    : reaminingBalance}{' '}
                                            </h2>
                                        </div>
                                    )}
                            </div>
                            <div className="divider"></div>
                            <div className="table-divider">
                                <h5>IOU</h5>
                            </div>
                            {!loading_iou ? (
                                <div className="mb-5">
                                    <IouGrid
                                        setState={IouCostCallback}
                                        initialState={iouCost}
                                        data={iou}
                                    />
                                </div>
                            ) : (
                                    <CardSpinner />
                                )}

                            <div className="table-divider">
                                <h5>Petty Cash</h5>
                            </div>
                            {!loading_pettyCash ? (
                                <PettyCashGrid
                                    setState={PettyCashCostCallback}
                                    initialState={pettyCashCost}
                                    data={pettyCash}
                                />
                            ) : (
                                    <CardSpinner />
                                )}
                        </div>
                    </div>
                    <button
                        disabled={pettyCash == '' && iou == ''}
                        onClick={onSettle}
                        style={{ float: 'right', width: '200px' }}
                        className="btn btn-primary mt-4 mb-3"
                        type="button"
                    >
                        Settle
          </button>
                </Fragment>
            ) : (
                    <Skeleton />
                )}
        </div>
    );
};

Settlement.propTypes = {
    getPendingSettlementIOUs: PropTypes.func.isRequired,
    getRemainingCashboxBalance: PropTypes.func.isRequired,
    getPendingSettlmentPettyCash: PropTypes.func.isRequired,
    settlement: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    pettyCashSettle: PropTypes.func.isRequired,
    iouSettle: PropTypes.func.isRequired,
    createSettlementAndReconsilationLog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    settlement: state.settlement,
    alert: state.alert,
});

export default connect(mapStateToProps, {
    getPendingSettlementIOUs,
    getPendingSettlmentPettyCash,
    getRemainingCashboxBalance,
    pettyCashSettle,
    iouSettle,
    createSettlementAndReconsilationLog,
})(Settlement);

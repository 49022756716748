import { GET_REIMBURSE_BY_ID, GET_REIMBURSE_DATA, SET_REIMBURSE_LOADING } from '../actions/types';

const intialState = {
    reimburse: [],
    reimburseItem: null,
    reimburse_loading: true,
    reimburse_data_loading: true,
};

export default function (state = intialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_REIMBURSE_DATA:
            return {
                ...state,
                reimburse: payload,
                reimburse_data_loading: false,
            };

        case GET_REIMBURSE_BY_ID:
            return {
                ...state,
                reimburseItem: payload,
                reimburse_loading: false,
            };
        case SET_REIMBURSE_LOADING:
            return {
                ...state,
                reimburse_loading: true,
            }

        default:
            return state;
    }
}

import {
  GET_ALL_VALIDITY_PERIODS,
  GET_ALL_PAYMENT_TYPES,
  GET_GIFT_VOUCHER_BY_ID,
  GET_GIFT_VOUCHERS_BY_DATE_RANGE,
  LOADING_GIFT_VOUCHER,
  GET_GIFT_VOUCHER_BY_NUMBER,
  LOADING_GIFT_VOUCHERS,
  LOADING_GIFT_VOUCHER_BY_NUMBER,
  GET_GIFT_VOUCHER_TO_UTILIZE,
  LOADING_GIFT_VOUCHER_TO_UTILIZE,
  GET_GIFT_VOUCHER_REQUEST_BY_ID,
  LOADING_GIFT_VOUCHER_REQUEST,
  GET_VOUCHER_TO_EXTEND,
  GET_ALL_PENDING_VOUCHER_REQUESTS,
  GET_NEXT_VOUCHER_NUMBER,
  GET_ALL_CATEGORIES,
  GET_ALL_CATEGORIES_SUCCESS,
  CANCEL_GIFT_VOUCHERS,
  CANCEL_GIFT_SUCCESS,
  CANCEL_GIFT_RESET,
  GET_GIFT_VOUCHER_TO_UTILIZE_RESET,
  ISSUE_GIFT_VOUCHER,
  ISSUE_GIFT_VOUCHER_SUCCESS,
  ISSUE_GIFT_VOUCHER_ERROR,
} from "../actions/types";

const initialState = {
  validity_periods: [],
  validity_periods_loading: true,

  payment_types: [],
  payment_types_loading: true,

  gift_voucher: null,
  gift_voucher_loading: true,

  gift_vouchers: [],
  gift_vouchers_loading: true,

  gift_voucher_by_number: [],
  gift_voucher_by_number_loading: true,

  gift_voucher_to_utilize: [],
  gift_voucher_to_utilize_loading: true,
  gift_voucher_to_utilize_search_loading: false,

  gift_voucher_request: null,
  gift_voucher_request_loading: true,

  voucher_to_extend: null,
  voucher_to_extend_loading: true,

  pending_voucher_requests: [],
  pending_voucher_requests_loading: true,

  next_voucher_number: null,

  get_all_categories_loading: false,
  get_all_categories_data: [],

  cancel_gift_vouchers_loading: false,
  cancel_gift_vouchers_data: null,

  issue_gift_voucher_loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VALIDITY_PERIODS:
      return {
        ...state,
        validity_periods_loading: false,
        validity_periods: action.payload,
      };
    case GET_ALL_PAYMENT_TYPES:
      return {
        ...state,
        payment_types_loading: false,
        payment_types: action.payload,
      };
    case GET_GIFT_VOUCHER_BY_ID:
      return {
        ...state,
        gift_voucher_loading: false,
        gift_voucher: action.payload,
      };
    case GET_GIFT_VOUCHERS_BY_DATE_RANGE:
      return {
        ...state,
        gift_vouchers_loading: false,
        gift_vouchers: action.payload,
      };
    case LOADING_GIFT_VOUCHER:
      return {
        ...state,
        gift_voucher_loading: true,
      };
    case GET_GIFT_VOUCHER_BY_NUMBER:
      return {
        ...state,
        gift_voucher_by_number_loading: false,
        gift_voucher_by_number: action.payload,
      };
    case GET_GIFT_VOUCHER_TO_UTILIZE:
      return {
        ...state,
        gift_voucher_to_utilize_loading: false,
        gift_voucher_to_utilize_search_loading: false,
        gift_voucher_to_utilize: action.payload,
      };
    case GET_GIFT_VOUCHER_TO_UTILIZE_RESET:
      return {
        ...state,
        gift_voucher_to_utilize_loading: true,
        gift_voucher_to_utilize_search_loading: false,
        gift_voucher_to_utilize: [],
      };
    case LOADING_GIFT_VOUCHERS:
      return {
        ...state,
        gift_vouchers_loading: true,
      };
    case LOADING_GIFT_VOUCHER_BY_NUMBER:
      return {
        ...state,
        gift_voucher_by_number_loading: true,
      };
    case LOADING_GIFT_VOUCHER_TO_UTILIZE:
      return {
        ...state,
        gift_voucher_to_utilize_loading: true,
        gift_voucher_to_utilize_search_loading: true,
      };
    case GET_GIFT_VOUCHER_REQUEST_BY_ID:
      return {
        ...state,
        gift_voucher_request_loading: false,
        gift_voucher_request: action.payload,
      };
    case LOADING_GIFT_VOUCHER_REQUEST:
      return {
        ...state,
        gift_voucher_request_loading: true,
      };
    case GET_VOUCHER_TO_EXTEND:
      return {
        ...state,
        voucher_to_extend: action.payload,
        voucher_to_extend_loading: false,
      };
    case GET_ALL_PENDING_VOUCHER_REQUESTS:
      return {
        ...state,
        pending_voucher_requests: action.payload,
        pending_voucher_requests_loading: false,
      };
    case GET_NEXT_VOUCHER_NUMBER:
      return {
        ...state,
        next_voucher_number: action.payload,
      };
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        get_all_categories_loading: true,
      };
    case GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        get_all_categories_loading: false,
        get_all_categories_data: action.payload,
      };
    case CANCEL_GIFT_VOUCHERS: {
      return {
        ...state,
        cancel_gift_vouchers_loading: true,
      }
    };
    case CANCEL_GIFT_SUCCESS: {
      return {
        ...state,
        cancel_gift_vouchers_loading: false,
        cancel_gift_vouchers_data: action.payload,
      }
    }
    case CANCEL_GIFT_RESET: {
      return {
        ...state,
        cancel_gift_vouchers_loading: initialState.cancel_gift_vouchers_loading,
        cancel_gift_vouchers_data: initialState.cancel_gift_vouchers_data,
      }
    }
    case ISSUE_GIFT_VOUCHER: {
      return {
        ...state,
        issue_gift_voucher_loading: true,
      }
    }
    case ISSUE_GIFT_VOUCHER_SUCCESS: {
      return {
        ...state,
        issue_gift_voucher_loading: false,
      }
    }
    case ISSUE_GIFT_VOUCHER_ERROR: {
      return {
        ...state,
        issue_gift_voucher_loading: false,
      }
    }
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonScreen = () => {
  return (
    <div className="pl-3 pr-3">
      <div className="card card-body p-3">
        <div className="cost-container">
          <div className="p-2">
            <div className="small-heading" style={{ width: '15vw' }}>
              <Skeleton variant="caption" />
            </div>
            <h2 className="amount-heading mt-2" style={{ width: '20vw' }}>
              <Skeleton variant="h3" />
            </h2>
          </div>
          <div className="row mb-4">
            <div className="col-md-3 p-4">
              <div className="small-heading mb-3 pr-5">
                <Skeleton variant="caption" />
              </div>
              <h2 className="amount-heading">
                <Skeleton variant="h3" />
              </h2>
            </div>
            <div className="col-md-3 p-4">
              <div className="small-heading mb-3 pr-5">
                <Skeleton variant="caption" />
              </div>
              <h2 className="amount-heading">
                <Skeleton variant="h3" />
              </h2>
            </div>
            <div className="col-md-3 p-4">
              <div className="small-heading mb-3 pr-5">
                <Skeleton variant="caption" />
              </div>
              <h2 className="amount-heading">
                <Skeleton variant="h3" />
              </h2>
            </div>
            <div className="col-md-3 p-4">
              <div className="small-heading mb-3 pr-5">
                <Skeleton variant="caption" />
              </div>
              <h2 className="amount-heading">
                <Skeleton variant="h3" />
              </h2>
            </div>
          </div>
          <div className="divider mb-5"></div>
          <div className="mb-5 pl-2">
            <Skeleton
              variant="h3"
              style={{ width: '25%', marginBottom: '20px' }}
            />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" />
          </div>

          <div className="mt-5 pl-2">
            <Skeleton
              variant="h3"
              style={{ width: '25%', marginBottom: '20px' }}
            />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" style={{ marginBottom: '10px' }} />
            <Skeleton variant="caption" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonScreen;

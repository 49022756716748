import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Signin from "../auth/Signin";
import PropTypes from "prop-types";

const Landing = ({ auth: { isAuthenticated, access } }) => {
    // If Authenticated Redirect to dashboard
    if (isAuthenticated) {
        if (localStorage.destinationPath && localStorage.destinationPath !== null) {
            return <Redirect to={localStorage.destinationPath} />;
        } else {
            return <Redirect to="/user-dashboard" />;
        }
    }

  return (
    <div>
      {/* <div className="outer" style={{ paddingTop: '56px' }}>
        <div className="inner">
          <div className="prod-left">
            <h1 className="prod-head">
              <span style={{ color: '#0f457f' }}>BUSINESS</span> E-APPROVAL
            </h1>
            <span>
              <Signin />
            </span>
          </div>
          <div className="prod-right">
            <img
              src="https://github.com/abhinanduN/codepen/blob/master/human-image.png?raw=true"
              className="prod-human-img"
              alt="prod"
            />
          </div>
        </div>
      </div> */}
      <header className="masthead">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 text-center" style={{ color: "white" }}>
              <Signin />
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Landing);

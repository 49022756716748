import React from "react";
import PropTypes from "prop-types";
import IOUWithdrawal from "./IOUWithdrawal";
import Sidebar from "../../sidebar/Sidebar";
import Settlement from "./Settlement";

const CashierView = ({ match }) => {
  const renderCashierView = () => {
    switch (match.params.name) {
      case "iou-withdrawal":
        return <IOUWithdrawal />;

      case "settle":
        return <Settlement />;

      default:
        break;
    }
  };

  const renderFormName = () => {
    switch (match.params.name) {
      case "iou-withdrawal":
        return <div>IOU Withdrawal</div>;

      case "settle":
        return <div>Settlement</div>;

      default:
        break;
    }
  };

  return (
    <main>
      <div
        className="card w-100 sticky-top mb-3 p-3 detail-sticky"
        style={{
          maxHeight: "91px",
          zIndex: "1030",
          paddingTop: "1em",
        }}
      >
        <h5
          style={{
            color: "#333333",
            fontWeight: "bold",
            fontFamily: "Montserrat",
          }}
        >
          {renderFormName()}
        </h5>
      </div>
      <div className="container-fluid">{renderCashierView()}</div>
    </main>
  );
};

CashierView.propTypes = {};

export default CashierView;

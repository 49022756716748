import React from 'react';
var moment = require('moment');

var pervious_prograss_status = '';
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export default class IOUPrint extends React.PureComponent {
    render() {
        const { formNumber } = this.props.requestData;

        return (
            <div style={{ margin: 40 }}>
                <div className="row">
                    <div style={{ marginTop: 36, marginLeft: 20 }}>
                        <img
                            style={{ width: 300 }}
                            src={require("../../../../img/navLogo.png")}
                        />
                    </div>
                    <div
                        className="box-header"
                        style={{
                            display: "grid",
                        }}
                    >
                        <h2
                            style={{ marginLeft: "260px", marginTop: "150px" }}
                            className="box-title "
                        >
                            Shangri-La Hotels Lanka Pvt Ltd
                        </h2>
                    </div>
                </div>
                <div className="box">
                    <div
                        className="box-header"
                        style={{
                            margin: 20,
                            display: 'grid',
                        }}
                    >
                        <h2 style={{ marginLeft: 'auto' }} className="box-title ">
                            Shangri-La Hotels Lanka Pvt Ltd
            </h2>
                    </div>
                    <div className="box-body">
                        <h4 style={{ marginBottom: 25, marginTop: 45 }}>
                            {' '}
                            <b>Request IOU - Form Number</b> :{' '}
                            {formNumber !== null && formNumber}{' '}
                        </h4>

                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Request By</th>
                                    <th>Department Name</th>
                                    <th>Location</th>
                                    <th>Created Date</th>
                                </tr>
                                <tr>
                                    <td>
                                        {this.props.requestData.createdUser.firstName +
                                            ' ' +
                                            this.props.requestData.createdUser.lastName}
                                    </td>
                                    <td>
                                        {this.props.requestData.createdUser.department !== null &&
                                            this.props.requestData.createdUser.department.name}{' '}
                                    </td>
                                    <td>Shangri-La Hotels Lanka Pvt Ltd</td>
                                    <td> {this.props.requestData.createdOn.split('T')[0]} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="box">
                    <div
                        className="box-header"
                        style={{ marginTop: 40, marginBottom: 20 }}
                    ></div>
                    <h5 style={{ marginTop: 30 }}>Details</h5>
                    <div className="box-body" style={{ marginTop: 30 }}>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: 340 }}>Attribute Name</th>
                                    <th> Values</th>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Form Number</td>
                                    <td> {this.props.requestData.formNumber}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Payment Title</td>
                                    <td> {this.props.requestData.paymentTitle}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Currency</td>
                                    <td> LKR</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Amount</td>
                                    <td> {this.props.requestData.amount}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Requesting Date </td>
                                    <td>
                                        {moment(
                                            new Date(this.props.requestData.requestingDate)
                                        ).format('YYYY-MM-DD')}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Description</td>
                                    <td> {this.props.requestData.description}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Remarks</td>
                                    <td> {this.props.requestData.remarks} </td>
                                </tr>
                            </tbody>
                        </table>

                        {this.props.requestData.requestProgress.length > 0 && (
                            <div style={{ pageBreakBefore: 'none' }}>
                                <div className="box">
                                    <div
                                        className="box-header"
                                        style={{ marginTop: 40, marginBottom: 20 }}
                                    >
                                        <h6>Document Approval History :</h6>
                                    </div>
                                    <div className="box-body">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Stage</th>
                                                    <th>Action</th>
                                                </tr>
                                                {this.props.requestData.requestProgress.length > 0 &&
                                                    this.props.requestData.requestProgress.map(
                                                        (progress, index) => {
                                                            if (index == 0) {
                                                                pervious_prograss_status = '';
                                                            }

                                                            if (pervious_prograss_status != 'Pending') {
                                                                pervious_prograss_status = progress.tookAction;
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{progress.date}</td>
                                                                        <td>{progress.userName}</td>
                                                                        <td>{progress.levelName}</td>
                                                                        <td>{progress.tookAction}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {JSON.parse(this.props.requestData.comments).length > 0 && (
                            <div>
                                <h5 style={{ marginTop: 50 }}>Comments</h5>

                                <table
                                    className="table table-bordered"
                                    style={{ marginTop: 30 }}
                                >
                                    <tbody>
                                        <tr>
                                            <th style={{ width: 340 }}>Level Name</th>
                                            <th> Comment </th>
                                        </tr>
                                        {JSON.parse(this.props.requestData.comments).length > 0 &&
                                            JSON.parse(this.props.requestData.comments).map(
                                                comment => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: 340 }}>{comment.name}</td>
                                                            <td> {comment.comment}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

                <br />
                <span className="mb-3 mt-3">
                    ..............................................................
        </span>
                <h5 className="mb-4">Cash Received Signature,</h5>
                <h5>Date: .............................</h5>
                <h3 style={{ color: '#e2e2e2' }}>
                    {'  '}
          ---------------------------------------------------------------------------------{' '}
                </h3>
                <span className="">
                    Printed On : {moment(new Date()).format('YYYY-MM-DD')}
                    <span className=" pull-right">
                        Generated By : {this.props.firstName + ' ' + this.props.lastName}
                    </span>
                </span>
            </div>
        );
    }
}

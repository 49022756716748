import { IOU_DATA_GET_BY_ID } from '../actions/types';

const initialState = {
    IOU: null,
    approvedIOU: [],
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case IOU_DATA_GET_BY_ID:
            return {
                ...state,
                loading: false,
                IOU: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

import Axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

import {
    GET_SETTLEMENT_LOG,
    GET_PENDING_SETTLEMENT_IOU,
    GET_PENDING_SETTLEMENT_PETTYCASH,
    GET_REMAINING_CASHBOX,
    SETTLEMENT_LOADING,
    SETTLEMENT_IOU_LOADING,
    SETTLEMENT_PETTYCASH_LOADING,
    SETTLEMENT_CASHBOX_LOADING,
} from './types';

export const createSettlementAndReconsilationLog =
    (pettyCash, Iou, pettyCashIdList, IouIdList) => async dispatch => {
        dispatch({ type: SETTLEMENT_PETTYCASH_LOADING });
        dispatch({ type: SETTLEMENT_IOU_LOADING });
        dispatch({ type: SETTLEMENT_CASHBOX_LOADING });

        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            const bodySettlementLog = {
                IOU: Iou,
                PettyCash: pettyCash,
                Balance: pettyCash - Iou,
                SettlementIOUs: IouIdList.map(item => ({
                    IOUId: item.id,
                })),
                SettlementPettyCashes: pettyCashIdList.map(item => ({
                    PettyCashId: item.id,
                })),
            };

            const bodyReconsilation = {
                totalPettyCash: pettyCash,
                totalSettledIOUs: Iou,
                pettyCashes: pettyCashIdList,
                ioUs: IouIdList,
            };

            // Settlement Log
            const resSettlementLog = await Axios.post(
                `/api/Settlement/create`,
                bodySettlementLog,
                config
            );

            //  Reconsilation
            // NOTE: Only if settlement log success
            if (resSettlementLog.status == 200) {
                const res = await Axios.post(
                    `/api/reconciliation/create-reconciliation-onsettlement`,
                    bodyReconsilation,
                    config
                );

                if (res.status == 200) {
                    dispatch(getPendingSettlmentPettyCash());
                    dispatch(getRemainingCashboxBalance());
                    dispatch(getPendingSettlementIOUs());
                }
            } else {
                dispatch(setAlert('Request Is Not Settled', 'error'));
                dispatch(getPendingSettlmentPettyCash());
                dispatch(getRemainingCashboxBalance());
                dispatch(getPendingSettlementIOUs());
            }
        } catch (err) {
            console.log(err);
        }
    };

export const getSettlementLog = formData => async dispatch => {
    dispatch({ type: SETTLEMENT_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await Axios.get(
            `/api/Settlement/settlement-log?fromDate=${formData.from}&toDate=${formData.to}`,
            config
        );

        dispatch({
            type: GET_SETTLEMENT_LOG,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const getPendingSettlmentPettyCash = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await Axios.get(
            `/api/PettyCash/all-pendingSettlement-pettycash`,
            config
        );

        dispatch({
            type: GET_PENDING_SETTLEMENT_PETTYCASH,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const getPendingSettlementIOUs = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const config = {
            'Content-Type': 'application/json',
        };
        const res = await Axios.get(`/api/IOU/all-pendingSettlemnt-ious`, config);

        dispatch({
            type: GET_PENDING_SETTLEMENT_IOU,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const getRemainingCashboxBalance = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await Axios.get(`/api/cashbox/get-cashbox`, config);

        dispatch({
            type: GET_REMAINING_CASHBOX,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const iouSettle = formData => async dispatch => {
    dispatch({ type: SETTLEMENT_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const config = {
            'Content-Type': 'application/json',
        };

        await Axios.post('/api/IOU/settle', formData, config);

        dispatch(getRemainingCashboxBalance());
        dispatch(getPendingSettlementIOUs());
    } catch (err) {
        console.log(err);
    }
};

export const pettyCashSettle = formData => async dispatch => {
    dispatch({ type: SETTLEMENT_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const config = {
            'Content-Type': 'application/json',
        };

       
        await Axios.post('/api/PettyCash/settle', formData, config);

        dispatch(getPendingSettlmentPettyCash());
        dispatch(getRemainingCashboxBalance());
    } catch (err) {
        console.log(err);
    }
};

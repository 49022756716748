import {
  GET_ALL_RECOVERY_TYPES,
  GET_ALL_REASONS_FOR_REFUND,
  GET_CURRENCY_VALUES_FOR_REQUEST_FOR_REFUND,
  GET_REQUEST_FOR_REFUND_BY_ID,
} from "../actions/types";

const initialState = {
  recovery_types: null,
  recovery_types_loading: true,

  reasons_for_refund: null,
  reasons_for_refund_loading: true,

  currency: null,
  currency_loading: true,

  request_item: null,
  request_item_loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_RECOVERY_TYPES:
      return {
        ...state,
        recovery_types_loading: false,
        recovery_types: action.payload,
      };
    case GET_ALL_REASONS_FOR_REFUND:
      return {
        ...state,
        reasons_for_refund_loading: false,
        reasons_for_refund: action.payload,
      };
    case GET_CURRENCY_VALUES_FOR_REQUEST_FOR_REFUND:
      return {
        ...state,
        currency_loading: false,
        currency: action.payload,
      };
    case GET_REQUEST_FOR_REFUND_BY_ID:
      return {
        ...state,
        request_item_loading: false,
        request_item: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

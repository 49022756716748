import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
var moment = require('moment');

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const column_data = [
    { title: 'Form No', width: { wpx: 80 }, style: { font: { bold: true } } },
    {
        title: 'Transaction date',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Payment Title',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    { title: 'Description', width: { wch: 25 }, style: { font: { bold: true } } },
    {
        title: 'Requested By',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Amount (LKR)',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Cost Center Department',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
];
var excel_data;
const font_style = { font: { sz: '11' } };
class ReimburseTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiDataSet: [
                {
                    columns: [],
                    data: [],
                },
            ],
        };

        excel_data = this.state.multiDataSet;
    }

    componentDidMount() {
        var data = [];
        excel_data[0].columns = column_data;

        var data_set = [];
        this.props.data.map(item => {
            data_set.push([
                {
                    value:
                        item.formNumber != '' && item.formNumber != null
                            ? item.formNumber
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.settledOn != '' && item.settledOn != null
                            ? moment(new Date(item.settledOn)).format('YYYY-MM-DD')
                            : '--',
                    style: font_style,
                },

                {
                    value:
                        item.pyamentTitle != '' && item.pyamentTitle != null
                            ? item.pyamentTitle
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.description != '' && item.description != null
                            ? item.description
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.createdUser != '' && item.createdUser != null
                            ? item.createdUser.firstName + ' ' + item.createdUser.lastName
                            : '--',
                    style: font_style,
                },
                {
                    value: item.amount != '' && item.amount != null ? item.amount : '--',
                    style: font_style,
                },
                {
                    value:
                        item.costCenterDepartment != '' && item.costCenterDepartment != null
                            ? item.costCenterDepartment.name
                            : '--',
                    style: font_style,
                },
            ]);
        });
        excel_data[0].data = data_set;
    }

    render() {
        return (
            <div>
                <ExcelFile
                    filename="Reimburse Petty Cashes"
                    element={
                        <button className="btn btn-info mb-3 mt-3" type="button">
                            <DescriptionOutlinedIcon />
                            <a style={{ margin: 10, cursor: 'pointer' }}>Export</a>
                        </button>
                    }
                >
                    <ExcelSheet
                        dataSet={this.state.multiDataSet}
                        name="Reimburse Petty Cashes"
                    />
                </ExcelFile>
            </div>
        );
    }
}

export default ReimburseTemplate;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getReportsForHouseUse } from "../../actions/reports";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import HouseUseRequestTemplate from "./Export/HouseUseRequestTemplate";
import Grid from "../PettyCash/Shared/Grid";
import DateRangeCalender from "../PettyCash/Shared/DateRangeCalender";
var moment = require("moment");

const HouseUseRequest = ({
  getReportsForHouseUse,
  reports: { reports, reports_loading },
}) => {
    
    const dateToday = new Date();
    const week_erlier = moment(dateToday).add(-7, 'd').format("YYYY-MM-DD");

  // State for Date submission
    const [formData, setFormData] = useState({
        from: week_erlier,
        to: moment(dateToday).format('YYYY-MM-DD'),
  });

  const { from, to } = formData;

  useEffect(() => {
    if (from !== "" && to !== "") {
      getReportsForHouseUse(from, to);
    }
  }, [formData]);

    const headerCells = [
        {
            id: "formNo",
            numeric: false,
            disablePadding: false,
            name: "Form No",
            propertyValue: "formNo",
            nested: false,
            minWidth: 120,
            type: "number",
        },
        {
            id: "formStatus",
            numeric: false,
            disablePadding: false,
            name: "Form Status",
            propertyValue: "formStatus",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "docName",
            numeric: false,
            disablePadding: false,
            name: "Docoument Name",
            propertyValue: "documentName",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "requestedBy",
            numeric: false,
            disablePadding: false,
            name: "Requested By",
            propertyValue: "requestedBy",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "department",
            numeric: false,
            disablePadding: false,
            name: "Department",
            propertyValue: "department",
            minWidth: 190,
            nested: false,
            type: "text",
        },
       
        {
            id: "company",
            numeric: false,
            disablePadding: false,
            name: "Company",
            propertyValue: "company",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "guestName",
            numeric: false,
            disablePadding: false,
            name: "Guest Name",
            propertyValue: "guestName",
            nested: false,
            minWidth: 190,
            type: "text",
        },
        {
            id: "roomType",
            numeric: false,
            disablePadding: false,
            name: "Room Type",
            propertyValue: "roomType",
            nested: false,
            minWidth: 190,
            type: "text",
        },
        {
            id: "arrivalDate",
            numeric: false,
            disablePadding: false,
            name: "Arrival Date",
            propertyValue: "arrivalDate",
            nested: false,
            minWidth: 190,
            type: "date",
        },
        {
            id: "departureDate",
            numeric: false,
            disablePadding: false,
            name: "Depature Date",
            propertyValue: "departureDate",
            nested: false,
            minWidth: 190,
            type: "date",
        },
      
    ];

  return (
      <div className="mt-3 mb-3">
          <div>
              <DateRangeCalender setDate={setFormData} initialState={formData} />
          </div>

          {!reports_loading ? (
              <div>
                  <div>
                      {reports.length > 0 && (
                          <HouseUseRequestTemplate data={reports} />
                      )}
                  </div>

                  <div>
                      <Grid
                          headers={headerCells}
                          data={reports !== null ? reports : []}
                      />
                  </div>
              </div>
          ) : (
              <Spinner />
          )}
      </div>
  );
};

HouseUseRequest.propTypes = {
  getReportsForHouseUse: PropTypes.func.isRequired,
  reports: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reports,
});

export default connect(mapStateToProps, { getReportsForHouseUse })(
  HouseUseRequest
);

import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";
import { GET_NAV_LINKS, ACCESS_LEVEL_SET, ACCESS_PEETYCASH_LEVEL_SET, ACCESS_PEETYCASH_CLEAR } from "./types";
import { setAccessLevels, setAccessLevelsPettyCash } from "../actions/shared/AccessLevelsHashMap";

const accesslevelList = {};
const accessPettycashLevelList = {};

export const getNavLinks = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };
    try {
     
    const res = await axios.get(`/api/navigationmenu/get-left-menu`, config);
        setAccessLevels(res.data[0].items);
        setAccessLevelsPettyCash(res.data[3].items);

        dispatch({
      type: GET_NAV_LINKS,
      payload: res.data,
    });

        res.data[0].items.forEach((i) => {
            accesslevelList[i.name] = i.accessLevelId;
        });

    dispatch({
        type: ACCESS_LEVEL_SET,
        payload: accesslevelList,
    });
        for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].menuName == "Petty Cash Management") {
                if (res.data[i].items.length > 0) {

                    res.data[i].items.forEach((i) => {
                        accessPettycashLevelList[i.name] = i.accessLevelId;
                    });

                    dispatch({
                        type: ACCESS_PEETYCASH_LEVEL_SET,
                        payload: accessPettycashLevelList,
                    });
                } else {
                    dispatch({
                        type: ACCESS_PEETYCASH_CLEAR,
                    });
                }
            } 
        }
       
       
  } catch (err) {
      
         //dispatch(setAlert(`Access is Denied due to Invalid Credentials`, "error"));
  }
};

//Find the pettycash menu item for logged in user
const findPettyCashMenu = (data) => {
    for (var i = 0; i < data.length; i++) {
            console.log(data.length)
            console.log(i,data[i].menuName)
        if (data[i].menuName == "Petty Cash Management") {
            return i;
        } else return -1;
    }
}
import React from 'react';
import PropTypes from 'prop-types';

import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import EditIcon from '@material-ui/icons/Edit';

const Grid = ({
  data,
  menuOptions,
  categories,
  priceUpdates,
  onRemove,
  onEdit,
}) => {
  // Defualt Material UI Package functions
  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#dadee7',
      color: theme.palette.common.black,
      padding: '5px',
      paddingLeft: '15px',
    },

    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const useStyles1 = makeStyles(theme => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  // Defualt Material UI Package functions
  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  // @Style General
  const useStyles = makeStyles(theme => ({
    table: {
      width: '100%',
    },

    tr: {
      background: '#fff',
      '&:hover': {
        background: 'rgba(51,51,51,0.2)',
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  // @Function for hadle pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  //@State for pagination
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  // @Method HandleChangePage
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // @Method Hadle RowsperPage
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-label="sticky table"
            aria-label="customized pagination table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  style={{ minWidth: 70 }}
                  rowSpan="2"
                  align="center"
                ></StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Menu Options
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  PLU Number
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Category
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  New Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Old Price
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Unit Cost
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Cost %
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="1"
                  colSpan="5"
                  align="center"
                >
                  Printers
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 170 }}
                  rowSpan="2"
                  align="left"
                >
                  Prices Updates
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell
                  style={{ minWidth: 100 }}
                  rowSpan="1"
                  colSpan="1"
                  align="center"
                >
                  1
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 100 }}
                  rowSpan="1"
                  colSpan="1"
                  align="center"
                >
                  2
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 100 }}
                  rowSpan="1"
                  colSpan="1"
                  align="center"
                >
                  3
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 100 }}
                  rowSpan="1"
                  colSpan="1"
                  align="center"
                >
                  4
                </StyledTableCell>
                <StyledTableCell
                  style={{ minWidth: 100 }}
                  rowSpan="1"
                  colSpan="1"
                  align="center"
                >
                  5
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 &&
                data.map((item, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="center">
                      <div className="row">
                        <RemoveCircleOutlineIcon
                          className="ml-2"
                          style={{ color: 'red', cursor: 'pointer' }}
                          onClick={e => onRemove(e, index)}
                        />
                        <EditIcon
                          className="ml-2"
                          style={{ color: 'green', cursor: 'pointer' }}
                          onClick={e => onEdit(e, index)}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {menuOptions[item.menuOptionId - 1].code}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {item.description}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.pluNumber}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {categories[item.categoryId - 1].name}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.newPrice}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.oldPrice}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.unitCost}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.cost}%</StyledTableCell>

                    {item.POSPrinters.map((printer, index) => (
                      <StyledTableCell key={index} align="center">
                        {printer.Name}
                      </StyledTableCell>
                    ))}
                    {Array.from(
                      { length: 5 - data[index].POSPrinters.length },
                      (_, i) => i + 1
                    ).map(i => (
                      <StyledTableCell align="center" key={i}></StyledTableCell>
                    ))}

                    <StyledTableCell align="left">
                      {priceUpdates[item.priceUpdateId - 1].name}
                    </StyledTableCell>
                  </TableRow>
                ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          colSpan={headers.length}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        /> */}
      </Paper>
    </div>
  );
};

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  menuOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  priceUpdates: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Grid;

import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import Spinner from "../../../layout/SubmitSpinner";
import {
  fetchPettyCashData,
  pettyCashRequestButtonClick,
} from "../../../../actions/pettyCash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Sidebar from "../../../sidebar/Sidebar";
import CardSpinner from "../../../layout/CardSpinner";
import Status from "../../../Shared/Status";
import CommentStaticForms from "../../../Card/CommentStaticForms";
import UserInfo from "../../../user/UserInfo";
import AdditionalComment from "../../../Card/AdditionalComment";
import PettyCashPrint from "./PettyCashPrint";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
var moment = require("moment");

const PettyCashDetailView = ({
  fetchPettyCashData,
  pettyCash: { pettyCash, loading },
  match,
  auth: { access, id, firstName, lastName },
  history,
  pettyCashRequestButtonClick,
}) => {

    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.formAccessLevels);

    useEffect(() => {
        if (formAccessLevels !== null) {
            fetchPettyCashData(match.params.id, formAccessLevels["Petty Cash Request"]);
        }
    }, [match.params.id, formAccessLevels]);


  // Comment parse to json
  // const [jsonParseComment, setJsonParseComment] = useState([])
  let jsonParseComment;

  // Check if comment available or not
  if (!loading) {
    if (pettyCash.comments !== null) {
      jsonParseComment = JSON.parse(pettyCash.comments);
    }
  }

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
  });

  const { comment } = addtionalComment;

    // Form Data for submit additonal comments
    const [formData, setFormData] = useState({
        formNumber: '',
        requestProcessLevelActionId: '',
        requestId: '',
        requestLevelId: '',
        actionId: '',
        Attachments: [],
    });

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: firstName,
      [e.target.name]: e.target.value,
    });
  };

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      formNumber: pettyCash.formNumber,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
    // await setAccessLevel(access)
  };

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setActionButtonLoading(true);
    setOpen(!open);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await pettyCashRequestButtonClick(
      formData,
      jsonParseComment,
      access,
      id,
      history
    );
  };

  // Additional comment popup prop
  const onAdditionalCommentPopup = () => {
    setOpen(!open);
  };

  // Print componet ref
  const componentRef = useRef(null);

  // Action Button onclick Loading
  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  return (
    <div>
      <div
        className="card w-100 sticky-top mb-3 detail-sticky"
        style={{
          maxHeight: "138px",
          zIndex: "1030",
        }}
      >
        {!loading && !actionButtonLoading ? (
          <>
            <h5
              style={{
                color: "#333333",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginLeft: "15px",
              }}
            >
              {pettyCash.formNumber} Petty Cash Request
            </h5>
            <div className="horizontal-container mt-1">
              <Status progress={pettyCash.requestProgress} />
            </div>
          </>
        ) : (
          <CardSpinner />
        )}
      </div>
      <main>
        <div className="container-fluid">
          {!loading && !actionButtonLoading && (
            <div>
              <form onSubmit={(e) => onButtonClick(e)}>
                              <AdditionalComment
                                  openState={open}
                                  onClick={onAdditionalCommentPopup}
                                  button={buttonName}
                                  value={comment}
                                  name="comment"
                                  onChange={onChange}
                                  attachmnetDisplay
                                  setState={setFormData}
                                  state={formData}
                                  currentAttachments={{
                                      data:
                                          pettyCash.attachments !== null &&
                                          pettyCash.attachments,
                                      property: "fileName",
                                  }}
                              />
              </form>
              <UserInfo
                firstName={pettyCash.createdUser.firstName}
                lastName={pettyCash.createdUser.lastName}
                email={pettyCash.createdUser.email}
                department={
                  pettyCash.createdUser.department !== null &&
                  pettyCash.createdUser.department
                }
              />
              <div
                className="card-body-form p-4 mb-4"
                style={{ backgroundColor: "white" }}
              >
                <div
                  className="card"
                  style={{
                    borderRadius: 0,
                    border: "2px solid rgba(233, 236, 239, 1)",
                    borderTop: 0,
                  }}
                >
                  <div
                    className="card-img-top"
                    style={{
                      backgroundColor: "#ffd100",
                      height: "3px",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Form Number
                        </div>

                        <input
                          type="text"
                          name="PaymentTitle"
                          value={pettyCash.formNumber}
                          className="form-control"
                          disabled
                        />
                      </div>
                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Payment Title
                        </div>

                        <input
                          type="text"
                          name="PaymentTitle"
                          value={pettyCash.pyamentTitle}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Invoice Number
                        </div>
                        <input
                          type="text"
                          value={pettyCash.invoiceNumber}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Cost Center Division
                        </div>
                        <input
                          type="text"
                          value={pettyCash.costCenterDivision.name}
                          name="costCenterDivision"
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Cost Center Department
                        </div>
                        <input
                          type="text"
                          name="costCenterDepartment"
                          value={pettyCash.costCenterDepartment.name}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          P&L Expense Category (Main)
                        </div>
                        <input
                          type="text"
                          name="mainPLExpenseCategory"
                          value={pettyCash.mainPLExpenseCategory.name}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          P&L Expense Category (Sub)
                        </div>
                        <input
                          type="text"
                          name="subPLExpenseCategory"
                          value={pettyCash.subPLExpenseCategory.name}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Amount (LKR)
                        </div>
                        <input
                          type="text"
                          name="Amount"
                          value={pettyCash.amount}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Requesting Date
                        </div>
                        <input
                          type="date"
                          name="createOn"
                          value={moment(new Date(pettyCash.createOn)).format(
                            "YYYY-MM-DD"
                          )}
                          className="form-control"
                          disabled
                        />
                      </div>

                      <div className="col-lg-6 pl-4 pr-4 pb-4 pt-2">
                        <div
                          className="row ml-1 mt-3"
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          Description
                        </div>
                        <textarea
                          name="Description"
                          value={pettyCash.description}
                          className="form-control"
                          rows="6"
                          disabled
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-body-form p-4 mb-4"
                style={{ backgroundColor: "white" }}
              >
                <div
                  className="card-img-top"
                  style={{
                    backgroundColor: "#ffd100",
                    height: "3px",
                  }}
                />
                <div className="card card-body">
                  <div
                    className="col-md-12"
                    style={{
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        color: "#333333",
                        fontWeight: "bold",
                        fontSize: "15px",
                        marginBottom: "10px",
                        fontFamily: "Montserrat",
                      }}
                    >
                      Remarks
                    </div>

                    <textarea
                      className="form-control"
                      disabled
                      placeholder={pettyCash.remarks}
                    />
                  </div>
                  <div
                    className="row"
                    style={{
                      padding: "20px",
                    }}
                  >
                    <div className="col-sm-6">
                      <Link
                        className="attachment-link"
                        to="!#"
                        data-toggle="collapse"
                        data-target="#collapseAttachments"
                        aria-expanded="false"
                        aria-controls="collapseAttachments"
                      >
                        <div className="row">
                          <div
                            className="col-sm-6"
                            style={{
                              color: "#333333",
                              fontWeight: "bold",
                              fontSize: "15px",

                              fontFamily: "Montserrat",
                            }}
                          >
                            {pettyCash.attachments !== null
                              ? pettyCash.attachments.length
                              : 0}{" "}
                            Attachments
                          </div>
                        </div>
                      </Link>
                    </div>
                    {pettyCash.attachments !== null ? (
                      <a
                        href={`/api/PettyCash/download-attachments?requestId=${pettyCash.id}`}
                        target="_blank"
                        download
                        className="text-right col-sm-6"
                      >
                        <i
                          className="fas fa-download"
                          style={{
                            color: "black",
                          }}
                        ></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="collapse"
                    id="collapseAttachments"
                    aria-labelledby="headingOne"
                    data-parent="#sidenavAccordion"
                  >
                    <div
                      style={{
                        height: 100,
                        overflowY: "scroll",
                      }}
                    >
                      <ul className="list-group list-group-flush">
                        {pettyCash.attachments !== null &&
                          pettyCash.attachments.map((file, index) => (
                            <li className="list-group-item" key={index}>
                              <div className="col-sm-11">
                                <i className="fas fa-paperclip mr-3" />
                                {file.fileName}
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <CommentStaticForms
                accessLevel={access}
                comments={pettyCash.comments}
                userId={id}
                requestId={pettyCash.id}
                userName={firstName + " " + lastName}
              />

              <div style={{ display: "none" }}>
                <PettyCashPrint
                  ref={componentRef}
                  requestData={pettyCash}
                  firstName={firstName}
                  lastName={lastName}
                />
              </div>
              <div>
                <div style={{ float: "left" }}>
                  <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <button className="btn btn-outline-info ml-3 mb-3 w-100">
                          <PrintIcon /> <b> Print This Out</b>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                  />
                </div>

                <div style={{ textAlign: "right", marginRight: "15px" }}>
                  {pettyCash.actions !== null
                    ? pettyCash.actions.map((action, index) => (
                        <button
                          key={index}
                          style={{ width: "200px" }}
                          className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                          onClick={() => {
                            onAdditionalCommentPopup();
                            setButtonName(action.name);
                            onClick(
                              action.requestProcessLevelActionId,
                              action.requestLevelId,
                              action.requestId,
                              action.actionId
                            );
                          }}
                        >
                          <b>{action.name}</b>
                        </button>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

PettyCashDetailView.propTypes = {
  pettyCash: PropTypes.object.isRequired,
  fetchPettyCashData: PropTypes.func.isRequired,
  pettyCashRequestButtonClick: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pettyCash: state.pettyCash,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchPettyCashData,
  pettyCashRequestButtonClick,
})(PettyCashDetailView);

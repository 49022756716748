import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import jQuery from "jquery";
import { getNavLinks } from "../../actions/app";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import "../../styles/Dashboard.css";

const Sidebar = ({
  getNavLinks,
  app: { nav, loading },
  auth: { username, access },
  match,
}) => {
  useEffect(() => {
    getNavLinks();
  }, [getNavLinks]);

  // Handle Sidebar jquery
  useEffect(() => {
    (function ($) {
      const pathname = window.location.pathname;

      $("#sidenavAccordion .sb-sidenav-menu-nested nav .nav-link").on(
        "click",
        function (e) {
          if (pathname === "/user-dashboard/pettycash/cashier/reconcilation") {
            $("body").toggleClass("sb-sidenav-toggled");
          }
        }
      );

      // Toggle the side navigation
      $("#sidebarToggle").on("click", function (e) {
        e.preventDefault();
        $("body").toggleClass("sb-sidenav-toggled");
      });
    })(jQuery);
  
    return () => {
      (function ($) {
        // Toggle the side navigation
        $("#sidebarToggle").on("click", function (e) {
          e.preventDefault();
          $("body").toggleClass("sb-sidenav-toggled");
        });
      })(jQuery);
    };
  }, [window.location]);

  (function ($) {
    // Add active state to sidbar nav links
    var path = window.location.href;

    // because the 'href' property of the DOM element is the absolute path
    $("#layoutSidenav_nav .sb-sidenav a.nav-link").each(function () {
      if (this.href === path) {
        $(this).addClass("active");
   
     
      } else $(this).removeClass("active");
    });
  })(jQuery);

  // User Sidebar Links

  const [navLink, setNavLink] = useState(null);

  const NavLinks = (
    <div>
      {/* <div className="sb-sidenav-menu-heading">HOME</div> */}
      <Link
        className="nav-link mt-4"
        onClick={() => setNavLink(0)}
        to="/user-dashboard"
      >
        <div className="sb-nav-link-icon" style={{ marginRight: "1rem" }}>
          <HomeOutlinedIcon />
        </div>
        Home
      </Link>

      <a
        className="nav-link collapsed"
        href="!#"
        data-toggle="collapse"
        data-target="#collapseLayouts"
        aria-expanded="false"
        aria-controls="collapseLayouts"
      >
        <div className="sb-nav-link-icon">
          <DescriptionOutlinedIcon />
        </div>
        Request Forms
        <div className="sb-sidenav-collapse-arrow">
          <i className="fas fa-angle-down" />
        </div>
      </a>
      <div
        className="collapse"
        id="collapseLayouts"
        aria-labelledby="headingOne"
        data-parent="#sidenavAccordion"
      >
        <nav className="sb-sidenav-menu-nested nav">
          {!loading ? (
            nav.length > 0 && nav[0].menuName == "Request Forms" ? (
              nav[0].items.map((form) => (
                <Link
                  to={`/user-dashboard/forms/${form.requestTypeId}`}
                  key={form.requestTypeId}
                  className="nav-link"
                  onClick={() => setNavLink(form.requestTypeId)}
                  data-toggle="tooltip"
                  data-placement="right"
                  title={form.requestTypeName}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "16em",
                  }}
                >
                  {form.requestTypeName}
                </Link>
              ))
            ) : (
              <nav className="sb-sidenav-menu-nested nav">No Forms</nav>
            )
          ) : (
            <nav className="sb-sidenav-menu-nested nav">Loading...</nav>
          )}
        </nav>
      </div>

      <div>
        {/* <div className="sb-sidenav-menu-heading">REPORTS</div> */}
        <a
          className="nav-link collapsed"
          href="#!"
          data-toggle="collapse"
          data-target="#collapseReports"
          aria-expanded="false"
          aria-controls="collapseReports"
        >
          <div className="sb-nav-link-icon">
            <AssessmentIcon />
          </div>
          Reports
          <div className="sb-sidenav-collapse-arrow">
            <i className="fas fa-angle-down"></i>
          </div>
        </a>
        <div
          className="collapse"
          id="collapseReports"
          aria-labelledby="headingOne"
          data-parent="#sidenavAccordion"
        >
          <nav className="sb-sidenav-menu-nested nav">
            {!loading ? (
              nav.length > 0 && nav[1].menuName == "Reports" ? (
                nav[1].items.map((form) => (
                  <Link
                    to={`/user-dashboard/admin/reports/${form.reportTypeId}`}
                    key={form.reportTypeId}
                    className="nav-link"
                    data-toggle="tooltip"
                    data-placement="right"
                    title={form.reportTypeName}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "block",
                      width: "16em",
                    }}
                  >
                    {form.reportTypeName}
                  </Link>
                ))
              ) : (
                <nav className="sb-sidenav-menu-nested nav">No Forms</nav>
              )
            ) : (
              <nav className="sb-sidenav-menu-nested nav">Loading...</nav>
            )}
          </nav>
        </div>
      </div>
    </div>
  );

  return (
    <div className="sb-nav-fixed">
      <div id="layoutSidenav">
        <div id="layoutSidenav_nav">
          <nav
            className="sb-sidenav accordion sb-sidenav-dark"
            id="sidenavAccordion"
          >
            <div className="sb-sidenav-menu">
              <div className="nav">{NavLinks}</div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  auth: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  getNavLinks: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

export default connect(mapStateToProps, { getNavLinks })(Sidebar);

import React, { useEffect, useState } from "react";
import { getAllRequests, setRequestLoading } from "../../actions/requests";
import {
  getApprovedRequests,
  getRejectedRequests,
  getPendingRequests,
} from "../../actions/dashboard";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Sidebar from "../sidebar/Sidebar";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../layout/Spinner";
import { setAlert } from "../../actions/alert";
import store from "../../store";
import DashboardCard from "../Card/DashboardCard";

const AdminDashboard = ({
  getApprovedRequests,
  getRejectedRequests,
  getPendingRequests,
  setRequestLoading,
  auth: { isAuthenticated, loading, access, id },
  getAllRequests,
  requests: { requests, requests_loading },
  history,
}) => {
  useEffect(() => {
    getAllRequests();

    if (access === 0) {
      store.dispatch(setAlert("Something went wrong", "error"));
    }
  }, [getAllRequests]);

  const [requestData, setRequestData] = useState([]);

  useEffect(() => {
    if (requests !== null) {
      setRequestData(requests);
    }
  }, [requests]);

  const [searchText, setSearchText] = useState("");

  const [dropdownState, setDropdownState] = useState("All");

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (searchValue !== "" && requests !== null) {
      let filtered = requests.filter((item) => {
        return (
          item.formNumber.includes(searchValue) ||
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        );
      });
      setRequestData(filtered);
    } else {
      setRequestData(requests);
    }
  };

  const onChange = (e) => {
    if (e.target.value === "Approved") {
      setRequestLoading();
      getApprovedRequests(id);
    }
    if (e.target.value === "Rejected") {
      setRequestLoading();
      getRejectedRequests(id);
    }
    if (e.target.value === "Pending") {
      setRequestLoading();
      getPendingRequests(id);
    }
    if (e.target.value === "All") {
      getAllRequests();
    }
  };

  // Defualt Material UI Package functions
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#dadee7",
      color: theme.palette.common.black,
      paddingTop: "13px",
      paddingBottom: "13px",
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  // Defualt Material UI Package functions
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  // @Style General
  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: "100%",
    },

    tr: {
      background: "#fff",
      "&:hover": {
        background: "rgba(51,51,51,0.2)",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "30%",
      maxWidth: "100%",
    },
    select: { fontSize: "20px" },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    search: {
      position: "relative",
      borderRadius: 28,
      boxShadow: "0 5px 10px rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0.23)",
      backgroundColor: "#fafafa",
      "&:hover": {
        backgroundColor: "#fafafa",
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      marginTop: 0,
      width: "100%",
      height: "55px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      marginTop: 10,
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  }));

  // @Function for hadle pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      !loading && (
        <div className={classes.root}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      )
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  // @Method onClick for redirect to form id
  const onClick = (id, status, requestStatus) => {
    if (status === "Request for Payment" && requestStatus === "Draft") {
      console.log(requestStatus);
      history.push(
        `/user-dashboard/request/static/request-for-payment/edit/${id}`
      );
    } else if (status === "Request for Payment") {
      history.push(
        `/user-dashboard/request/static/request-for-payment/view/${id}`
      );
    } else if (status === "IOU") {
      history.push(`/user-dashboard/request/pettycash/request-iou/${id}`);
    } else if (status === "Petty Cash") {
      history.push(`/user-dashboard/request/pettycash/request-pettycash/${id}`);
    } else if (status === "System Access Request") {
      history.push(`/user-dashboard/system-access-request-detail/${id}`);
    } else if (status === "POS Item Request") {
      history.push(`/user-dashboard/pos-request-detail/${id}`);
    } else {
      history.push(`/user-dashboard/request/${id}`);
    }
  };

  //@State for pagination
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, requests.length - page * rowsPerPage);

  // @Method HandleChangePage
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // @Method Hadle RowsperPage
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className="sb-nav-fixed">
      <div id="layoutSidenav">
        <Sidebar />

        <div id="layoutSidenav_content" style={{ backgroundColor: "#F5F1F2" }}>
          <main>
            <div className="container-fluid">
              <div className="mt-3 mb-3">
                <DashboardCard setState={(e) => setDropdownState(e)} />
                <div className="card card-body px-3">
                  <div className="row mb-4">
                    <div className="col-md-7 w-100 mt-3">
                      <FormControl className={classes.formControl}>
                        <Select
                          className={classes.select}
                          value={dropdownState}
                          id="progressStatus"
                          onChange={(e) => onChange(e)}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {/* {access === 1 && (
                            <MenuItem
                              value="All"
                              onClick={() => setDropdownState("All")}
                            >
                              All User Requests
                            </MenuItem>
                          )} */}
                          <MenuItem
                            value="All"
                            onClick={() => setDropdownState("All")}
                          >
                            My Request
                          </MenuItem>
                          <MenuItem
                            value="Approved"
                            onClick={() => setDropdownState("Approved")}
                          >
                            Approved
                          </MenuItem>
                          <MenuItem
                            value="Pending"
                            onClick={() => setDropdownState("Pending")}
                          >
                            Pending
                          </MenuItem>
                          <MenuItem
                            value="Rejected"
                            onClick={() => setDropdownState("Rejected")}
                          >
                            Rejected
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className=" col-md-5 mt-3 mb-3"
                      style={{ float: "right" }}
                    >
                      <div>
                        <div className={classes.grow}>
                          <div className={classes.search}>
                            <div className={classes.searchIcon}>
                              <SearchIcon />
                            </div>
                            <InputBase
                              placeholder="Search�"
                              classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                              }}
                              inputProps={{ "aria-label": "search" }}
                              value={searchText}
                              onChange={(e) => requestSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!requests_loading && requestData !== null ? (
                    <div>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized pagination table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Form Number
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Created Date
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                Created User
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <div className="input-group">
                                  <select
                                    style={{
                                      backgroundColor: "#dadee7",
                                      borderRadius: 0,
                                      borderColor: "#dadee7",
                                      color: "black",
                                      height: "100%",
                                      lineHeight: 2,
                                      fontSize: "15px",
                                      fontWeight: 490,
                                      padding: 0,
                                    }}
                                    className="custom-select"
                                    id="inputGroupSelect01"
                                    // required={true}
                                    // name="priority"
                                    // value={priority}
                                    // onChange={e => onChange(e)}
                                  >
                                    <option
                                      style={{ lineHeight: 3 }}
                                      defaultChecked
                                    >
                                      Request Type
                                    </option>
                                    <option value="Returnable Gatepass">
                                      Returnable Gatepass
                                    </option>
                                    <option value="Non Returnable Gatepass">
                                      Non Returnable Gatepass
                                    </option>
                                  </select>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <div className="input-group">
                                  <select
                                    style={{
                                      backgroundColor: "#dadee7",
                                      borderRadius: 0,
                                      borderColor: "#dadee7",
                                      color: "black",
                                      height: "100%",
                                      lineHeight: 2,
                                      fontSize: "15px",
                                      fontWeight: 490,
                                      padding: 0,
                                    }}
                                    className="custom-select"
                                    id="inputGroupSelect01"
                                    // required={true}
                                    // name="priority"
                                    // value={priority}
                                    // onChange={e => onChange(e)}
                                  >
                                    <option
                                      style={{ lineHeight: 3 }}
                                      defaultChecked
                                    >
                                      Status
                                    </option>
                                    <option value="Pending">Pending</option>
                                    <option value="HOD">HOD</option>
                                    <option value="FC">FC</option>
                                    <option value="RM/GM">RM/GM</option>
                                    <option value="SM">SM</option>
                                  </select>
                                </div>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {requestData.length > 0
                              ? (rowsPerPage > 0
                                  ? requestData.slice(
                                      page * rowsPerPage,
                                      page * rowsPerPage + rowsPerPage
                                    )
                                  : requestData
                                ).map((request, id) => (
                                  <StyledTableRow
                                    key={id}
                                    tabIndex={-1}
                                    className={classes.tr}
                                    onClick={() =>
                                      onClick(
                                        request.id,
                                        request.name,
                                        request.status.name
                                      )
                                    }
                                  >
                                    <StyledTableCell component="th" scope="row">
                                      {request.formNumber !== null &&
                                        request.formNumber}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {request.createdOn}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {request.createdUser.fullname}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {request.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                      {request.status.name}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))
                              : ""}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                          <TableFooter>
                            <TableRow>
                              <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                colSpan={5}
                                count={requests.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: {
                                    "aria-label": "rows per page",
                                  },
                                  native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                              />
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </div>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

AdminDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  getAllRequests: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
  getApprovedRequests: PropTypes.func.isRequired,
  getRejectedRequests: PropTypes.func.isRequired,
  getPendingRequests: PropTypes.func.isRequired,
  setRequestLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getAllRequests,
  setRequestLoading,
  getApprovedRequests,
  getRejectedRequests,
  getPendingRequests,
})(AdminDashboard);

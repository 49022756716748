import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

import {
    GET_FORM,
    CLEAR_FORM,
    GET_FORMS,
    FORM_LOADING,
    GET_COST_DIVISION,
} from './types';

// Get all the names of the forms
export const getFormNames = () => async dispatch => {
    try {
        const res = await axios.get('/api/Forms/GetFormName');

        dispatch({
            type: GET_FORMS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        // If Error occures
        dispatch(setAlert('Error Loading Forms', 'error'));
    }
};

// Get form fields for a one form
export const getFormById = id => async dispatch => {
    // Clear previous form
    dispatch({ type: CLEAR_FORM });

    // Loading Spinner
    dispatch({ type: FORM_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(`/api/Forms`, {
            params: {
                id: id,
            },
        });

        dispatch({
            type: GET_FORM,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert('Loading Requested Form Failed', 'error'));
    }
};

// Form: Request for payment
// Get suggest dropdown value
// Dropdown: Cost center division
export const getCostCenterDivision = value => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const res = await axios.get(
            `/api/CostCenterDivision/get-costcenter?userText=${value}`
        );

        dispatch({
            type: GET_COST_DIVISION,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
        // If Error occures
        dispatch(setAlert('Error Loading Forms', 'error'));
    }
};

// Set loading state
export const setFormLoading = () => {
    return {
        type: FORM_LOADING,
    };
};

import {
    USER_PROFILE_LOADING,
    USER_MANAGEMENT_GET_USER_BY_ID,
    GET_ALL_USERS,
    GET_ALL_USER_ROLES,
    GET_ALL_EMP_LEVELS,
    GET_BRANCH_DATA,
    CLEAR_USER_DETAIL,
    CHANGE_MY_PASSWORD,
} from "../actions/types";

const initialState = {
    userRoles: [],
    userRoles_loading: true,

    users: [],
    loading: true,

    empLevels: [],
    empLevel_loading: true,

    user: [],
    user_loading: true,

    branches: [],
    branches_loading: true,

    roles: [],
    roles_loading: true,

    role: [],
    role_loading: true,

    change_password: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            return {
                ...state,
                loading: false,
                users: action.payload,
            };
        case GET_ALL_USER_ROLES:
            return {
                ...state,
                userRoles_loading: false,
                userRoles: action.payload,
            };
        case GET_ALL_EMP_LEVELS:
            return {
                ...state,
                empLevel_loading: false,
                empLevels: action.payload,
            };
        case GET_BRANCH_DATA:
            return {
                ...state,
                branches_loading: false,
                branches: action.payload,
            };

        case USER_MANAGEMENT_GET_USER_BY_ID:
            return {
                ...state,
                user_loading: false,
                user: action.payload,
            };

        case USER_PROFILE_LOADING:
            return {
                ...state,
                user_loading: true,
            };
        case CLEAR_USER_DETAIL:
            return {
                user: [],
            };
        case CHANGE_MY_PASSWORD:
            return {
                ...state,
                change_password: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

import { PETTYCASH_DATA_GET_BY_ID } from '../actions/types';

const initialState = {
    pettyCash: [],
    loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PETTYCASH_DATA_GET_BY_ID:
            return {
                ...state,
                loading: false,
                pettyCash: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

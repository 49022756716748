import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../PosRequest/Menu/Grid/index.css";
import { getReports } from "../../actions/reports";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ReturnablegatePassTemplate from "./Export/ReturnablegatePassTemplate";
import Grid from "../PettyCash/Shared/Grid";
import DateRangeCalender from "../PettyCash/Shared/DateRangeCalender";
var moment = require("moment");

const ReturnableGatePass = ({
  getReports,
  reports: { reports, reports_loading },
}) => {
  // Calculate three months prior from today
  var threeMonthsAgo = moment().subtract(3, "months");

  // State for Date submission
  const [formData, setFormData] = useState({
    from: threeMonthsAgo.format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  const { from, to } = formData;

  useEffect(() => {
    if (from !== "" && to !== "") {
      getReports(from, to);
    }
  }, [formData]);

  const headerCells = [
    {
      id: "formNo",
      numeric: false,
      disablePadding: false,
      name: "Form No",
      propertyValue: "formNo",
      nested: false,
      minWidth: 120,
      type: "number",
    },
    {
      id: "formStatus",
      numeric: false,
      disablePadding: false,
      name: "Form Status",
      propertyValue: "formStatus",
      nested: false,
      minWidth: 170,
      type: "text",
    },
    {
      id: "docName",
      numeric: false,
      disablePadding: false,
      name: "Docoument Name",
      propertyValue: "documentName",
      nested: false,
      minWidth: 170,
      type: "text",
    },
    {
      id: "createdUser",
      numeric: false,
      disablePadding: false,
      name: "Created By",
      propertyValue: "createdBy",
      nested: false,
      minWidth: 170,
      type: "text",
    },
    {
      id: "raisedDepartment",
      numeric: false,
      disablePadding: false,
      name: "Raised by Department",
      propertyValue: "raisedbyDepartment",
      minWidth: 190,
      nested: false,
      type: "text",
    },
    {
      id: "equipment",
      numeric: false,
      disablePadding: false,
      name: "Equipment",
      propertyValue: "equipment",
      nested: false,
      minWidth: 170,
      type: "text",
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      name: "Company",
      propertyValue: "company",
      nested: false,
      minWidth: 170,
      type: "text",
    },
    {
      id: "checkOutBy",
      numeric: false,
      disablePadding: false,
      name: "Check Out By",
      propertyValue: "checkOutby",
      nested: false,
      minWidth: 190,
      type: "text",
    },
    {
      id: "checkOutDate",
      numeric: false,
      disablePadding: false,
      name: "Check Out Date",
      propertyValue: "checkOutDate",
      nested: false,
      minWidth: 190,
      type: "date",
    },
    {
      id: "checkInBy",
      numeric: false,
      disablePadding: false,
      name: "Check In By",
      propertyValue: "checkInBy",
      nested: false,
      minWidth: 190,
      type: "text",
    },
    {
      id: "checkInDate",
      numeric: false,
      disablePadding: false,
      name: "Check In Date",
      propertyValue: "checkInDate",
      nested: false,
      minWidth: 190,
      type: "date",
    },
    {
      id: "expectedReturnDate",
      numeric: false,
      disablePadding: false,
      name: "Expected Return Date",
      propertyValue: "expectedReturnDate",
      nested: false,
      minWidth: 190,
      type: "text",
    },
    {
      id: "eqipmentReturnStatus",
      numeric: false,
      disablePadding: false,
      name: "Equipment Return Status",
      propertyValue: "equipmentReturnStatus",
      nested: false,
      minWidth: 200,
      type: "text",
    },
  ];

  return (
    <div className="mt-3 mb-3">
      <div>
        <DateRangeCalender setDate={setFormData} initialState={formData} />
      </div>

      {!reports_loading ? (
        <div>
          <div>
            {reports.length > 0 && (
              <ReturnablegatePassTemplate data={reports} />
            )}
          </div>

          <div>
            <Grid
              headers={headerCells}
              data={reports !== null ? reports : []}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

ReturnableGatePass.propTypes = {
  getReports: PropTypes.func.isRequired,
  reports: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reports,
});

export default connect(mapStateToProps, { getReports })(ReturnableGatePass);

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { logout } from '../../actions/auth';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Collapse from '@material-ui/core/Collapse';

const IdleTimerContainer = ({ logout, auth: { isAuthenticated } }) => {
    const idleTimerRef = useRef(null);
    const sessionTimeOutRef = useRef(null);

    // Snackbar open state
    const [open, setOpen] = React.useState(false);

    const signOutAlert = () => {
        setOpen(true);
        sessionTimeOutRef.current = setTimeout(localLogout, 10000);
    };

    const localLogout = () => {
        clearTimeout(sessionTimeOutRef.current);
        logout();
        setOpen(false);
    };

    function SnackBar() {
        const handleClose = (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
            clearTimeout(sessionTimeOutRef.current);
        };

        return (
            <div>
                <Snackbar
                    TransitionComponent={Collapse}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={open}
                    onClose={handleClose}
                    message="You're About to Get Signed Out"
                    action={
                        <React.Fragment>
                            <Button color="secondary" size="small" onClick={handleClose}>
                                KEEP ME SIGNED IN
              </Button>
                        </React.Fragment>
                    }
                />
            </div>
        );
    }

    return (
        <div>
            <SnackBar />
            {isAuthenticated && (
                <IdleTimer
                    ref={idleTimerRef}
                    timeout={1000 * 60 * 20}
                    onIdle={signOutAlert}
                />
            )}
        </div>
    );
};

IdleTimerContainer.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(IdleTimerContainer);

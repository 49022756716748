import React from "react";
import Sidebar from "../sidebar/Sidebar";
import "./report.css";
import HouseUseRequest from "./HouseUseRequest";
import NonReturnableGatePass from "./NonReturnableGatePass";
import ReturnableGatePass from "./ReturnableGatePass";
import RoomRequest from "./RoomRequest";
import SystemAccessrequest from "./SystemAccessrequest";

const Report = ({ match }) => {
  const renderReport = () => {
    switch (match.params.id) {
      case "1":
        return <ReturnableGatePass />;

      case "2":
        return <NonReturnableGatePass />;

      case "3":
        return <HouseUseRequest />;

      case "4":
            return <RoomRequest />;

      case "5":
            return <SystemAccessrequest />;

      default:
        return (
          <div>
            <h4>No Forms</h4>
          </div>
        );
    }
  };

  return (
    <main>
      <div
        className="card w-100 sticky-top mb-3 p-3 report-container"
        style={{
          maxHeight: "91px",
          zIndex: "1035",
        }}
      >
        <h5
          style={{
            color: "#333333",
            fontWeight: "bold",
            fontFamily: "Montserrat",
          }}
        >
          {match.params.id === "1"
            ? "Returnable Gatepass Reports"
            : match.params.id === "2"
            ? " Non Returnable Gatepass Reports"
            : match.params.id === "3"
            ? " House Use Request Reports"
            : match.params.id === "4"
            ? "Complimentary Room Request"
            : match.params.id === "5"
            ? "System Access Request Report"
            : ""}
        </h5>
      </div>
      <div className="container-fluid">{renderReport()}</div>
    </main>
  );
};

Report.propTypes = {};

export default Report;

import React from 'react';
var moment = require('moment');


var pervious_prograss_status = "";
export default class FormPrint extends React.PureComponent {

    renderItemView(field , id) {
         switch (field.type) {
            case 'checkbox':
                return <tr key={id}>
                        <td style={{ width: 340 }}>
                            {field.displayName}
                        </td>
                    <td>{field.isChecked ? "Yes" : "No"}{field.textValue != null && field.textValue.inputText != null ? " / " + field.textValue.inputText : "" } </td>
                    </tr>;
             default:
                 return (
                     <tr key={id}>
                         <td style={{ width: 340 }}>{field.displayName}</td>
                         <td>{field.inputText !== null ? field.inputText : "--"} </td>
                     </tr>
                 );
        }

    }

  render() {
      const { requestData, name } = this.props;
       

    return (
      <div style={{ margin: 40 }}>
            <div className="row">
                <div style={{ marginTop: 36, marginLeft: 20 }}>
                    <img
                        style={{ width: 300 }}
                        src={require("../../img/navLogo.png")}
                    />
                </div>
                <div
                    className="box-header"
                    style={{
                        display: "grid",
                    }}
                >
                    <h2
                        style={{ marginLeft: "260px", marginTop: "150px" }}
                        className="box-title "
                    >
                        Shangri-La Hotels Lanka Pvt Ltd
                    </h2>
                </div>
            </div>
        <div className="box">
                <div className="box-body">
                    <h4 style={{ marginBottom: 35, marginTop: 55 }}> <b>{requestData.requestType.name} - Form Number</b> : {requestData.formNumber !== null && requestData.formNumber} </h4> 

            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Request By</th>
                  <th>Department Name</th>
                  <th>Location</th>
                  <th>Created Date</th>
                </tr>
                <tr>
                  <td>{requestData.createdUser.firstName + " " + requestData.createdUser.lastName}</td>
                  <td>{requestData.createdUser.department.name} </td>
                  <td>Shangri-La Hotels Lanka Pvt Ltd</td>
                  <td>
                    {requestData.createdOn.split('.')[0].replace('T', ' ')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
            </div>
 
     

        {requestData !== null && requestData.tabs.length > 0
          ? requestData.tabs.map(
              (tab, tabIndex) =>
                tab.sections.length > 0 &&
                  tab.sections.map((section, id) => {
                     return <div className="box" key={id} >
                          <div
                              className="box-header"
                              style={{ marginTop: 40, marginBottom: 20 }}
                          >
                              <h6>{section.title} :</h6>
                          </div>
                          <div className="box-body">
                              <table className="table table-bordered">
                                  <tbody>
                                      <tr>
                                          <th style={{ width: 340 }}>Attribute Name</th>
                                          <th>Attribute Values</th>
                                      </tr>
                                      {section.formFields.map((field, id) => (
                                          this.renderItemView(field, id)

                                      ))}
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  })
            )
          : ''}

        {requestData.requestProgress != null && (
          <div className="box">
            <div
              className="box-header"
              style={{ marginTop: 40, marginBottom: 20 }}
            >
              <h6>Document Approval History :</h6>
            </div>
            <div className="box-body">
              <table className="table table-bordered">
                <tbody> 
                  <tr>
                    <th>Date</th>
                    <th>User</th>
                    <th>Stage</th>
                    <th>Action</th>
                  </tr>
                    {requestData.requestProgress.length > 0 &&
                            requestData.requestProgress.map((progress, index) => {
                                if (index == 0) {
                                    pervious_prograss_status = "";
                                } 
                               
                                if (pervious_prograss_status != "Pending") { 
                                pervious_prograss_status = progress.tookAction;
                                return <tr key={index} >
                                    <td>{progress.date}</td>
                                    <td>{progress.userName}</td>
                                    <td>{progress.levelName}</td>
                                    <td>{progress.tookAction}</td>
                                </tr>
                            }
                            
                        }
                       
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
            {JSON.parse(requestData.comments).length > 0 && JSON.parse(requestData.comments) != null && (
          <div className="box">
            <div
              className="box-header"
              style={{ marginTop: 40, marginBottom: 20 }}
            >
              <h6>Comments :</h6>
            </div>
            <div className="box-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Comment</th>
                  </tr>
                  {JSON.parse(requestData.comments).length > 0 &&
                    JSON.parse(requestData.comments).map((progress, index) => (
                      <tr key={index}>
                        <td>{progress.name}</td>
                        <td>{progress.comment}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <br />
        <h3 style={{ color: '#e2e2e2' }}>
          {' '}
          ------------------------------------------------------------------------------------{' '}
        </h3>
        <span className="">
          Printed On : {moment(new Date()).format('YYYY-MM-DD')}
                <span className=" pull-right">Generated By : {name }</span>
        </span>
      </div>
    );
  }
}

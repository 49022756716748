import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { requesterRequestMore } from '../../actions/requests';
import { connect } from 'react-redux';

const CommentStaticForms = ({ comments }) => {
    // Comment parse to json
    let jsonParseComment;

    if (comments !== null) {
        jsonParseComment = JSON.parse(comments);
    }

    // Display comment section after submit
    // NOTE: after submitting a request comment section should disapear
    return (
        <div className="mb-4">
            <div>
                <div
                    className="card-img-top"
                    style={{
                        backgroundColor: '#ffd100',
                        height: '3px',
                    }}
                />

                <div className="card card-body">
                    <b className="mb-3">Comments</b>

                    {comments !== null
                        ? jsonParseComment.map((comment, id) => (
                            <div className="Area" key={id}>
                                <div className="Receiver">
                                    <h6
                                        className="p-0 mb-2 L"
                                        style={{
                                            fontWeight: 'bolder',
                                            color: '#a4a4a4',
                                        }}
                                    >
                                        {comment.name}
                                    </h6>
                                    <div className="text L textL">{comment.comment} </div>
                                </div>
                            </div>
                        ))
                        : ''}
                </div>
            </div>
        </div>
    );
};

CommentStaticForms.propTypes = {
    comments: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    requestId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    requesterRequestMore: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { requesterRequestMore })(
    withRouter(CommentStaticForms)
);

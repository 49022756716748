import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

const PrinterGrid = ({ state, setState }) => {
    // Printer table row mapping
    const printerData = [
        {
            id: 1,
            name: 'Name',
            value: state[0].Name,
        },
        {
            id: 2,
            name: 'printer2',
            value: state[1].Name,
        },
        {
            id: 3,
            name: 'printer3',
            value: state[2].Name,
        },
        {
            id: 4,
            name: 'printer4',
            value: state[3].Name,
        },
        {
            id: 5,
            name: 'printer5',
            value: state[4].Name,
        },
    ];

    // change values on change
    const onChange = (e, id) => {
        var index = state.findIndex(x => x.Id === id + 1);
        let printer = state[index];
        printer['Name'] = e.target.value;
        setState([...state.slice(0, index), printer, ...state.slice(index + 1)]);
    };

    // Add new row on blur
    const onBlur = (e, id) => {
        var index = state.findIndex(x => x.Id === id + 1);
        let printer = state[index];

        // Set id+1 row visible
        if (printer['Name'].length > 0 && index != 4) {
            setState(state => [
                ...state.slice(0, index + 1),
                {
                    ...state[index + 1],
                    visible: true,
                },
                ...state.slice(index + 2),
            ]);
        }
    };

    return (
        <>
            <table className="printer-grid">
                <thead>
                    <tr>
                        <th>Printer</th>
                        <th>Name</th>
                    </tr>
                </thead>
                <tbody>
                    {printerData.map((printer, index) => (
                        <tr
                            key={printer.id}
                            style={{ display: !state[index].visible && 'none' }}
                        >
                            <td>{printer.id}</td>
                            <td className="p-2">
                                <input
                                    type="text"
                                    name={printer.name}
                                    value={printer.value}
                                    onChange={e => onChange(e, index)}
                                    onBlur={e => onBlur(e, index)}
                                    className="form-control"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

PrinterGrid.prototype = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.object.isRequired,
};

export default PrinterGrid;

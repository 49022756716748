import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HouseUseRequest from '../HouseUseRequest';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const column_data = [
    { title: 'Form No', width: { wpx: 80 }, style: { font: { bold: true } } },
    { title: 'Form Status', width: { wch: 25 }, style: { font: { bold: true } } },
    {
        title: 'Dcoument Name',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    { title: 'Requested By', width: { wch: 25 }, style: { font: { bold: true } } },
    {
        title: 'Department',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
  
    { title: 'Company', width: { wch: 25 }, style: { font: { bold: true } } },
    {
        title: 'Guest Name',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Room Type',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Arrival Date',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: 'Departure Date',
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
];
var excel_data;
const font_style = { font: { sz: '11' } };
class HouseUseRequestTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiDataSet: [
                {
                    columns: [],
                    data: [],
                },
            ],
        };

        excel_data = this.state.multiDataSet;
    }

    componentDidMount() {
        var data = [];

        excel_data[0].columns = column_data;

        var data_set = [];
        this.props.data.map(item => {
            data_set.push([
                {
                    value:
                        item.formNo !== '' && item.formNo !== null ? item.formNo : '--',
                    style: font_style,
                },
                {
                    value:
                        item.formStatus !== '' && item.formStatus !== null
                            ? item.formStatus
                            : '--',
                    style: {
                        font: { sz: '11' },
                        fill:
                            item.formStatus == 'Completed'
                                ? { patternType: 'solid', fgColor: { rgb: 'FFFFFF00' } }
                                : null,
                    },
                },
                {
                    value:
                        item.dcoumentName !== '' && item.dcoumentName !== null
                            ? item.dcoumentName
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.requestedBy !== '' && item.requestedBy !== null
                            ? item.requestedBy
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.department !== '' && item.department !== null
                            ? item.department
                            : '--',
                    style: font_style,
                },
              
                {
                    value:
                        item.company !== '' && item.company !== null ? item.company : '--',
                    style: font_style,
                },
                {
                    value:
                        item.guestName !== '' && item.guestName !== null
                            ? item.guestName
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.roomType !== '' && item.roomType !== null
                            ? item.roomType
                            : '--',
                    style: font_style,
                },
              
                {
                    value:
                        item.arrivalDate !== '' && item.arrivalDate !== null
                            ? item.arrivalDate
                            : '--',
                    style: font_style,
                },
                {
                    value:
                        item.departureDate !== '' && item.departureDate !== null
                            ? item.departureDate
                            : '--',
                    style: font_style,
                },
            ]);
        });
        excel_data[0].data = data_set;
    }

    render() {
        return (
            <div>
                <ExcelFile
                    filename="House Use Request"
                    element={

                        <button
                            className="btn btn-info mb-3 mt-3"
                            style={{ left: '100px' }}
                        >
                            <DescriptionOutlinedIcon />
                            <a style={{ margin: 10, cursor: 'pointer' }}>Export</a>
                        </button>

                    }
                >
                    <ExcelSheet
                        dataSet={this.state.multiDataSet}
                        name="Non Returnable Gate Pass"
                    />
                </ExcelFile>
            </div>
        );
    }
}

export default HouseUseRequestTemplate;

import React from "react";
import "./grid.css";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const Grid = ({ headers, data }) => {
  // Defualt Material UI Package functions
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#dadee7",
      color: theme.palette.common.black,
    },

    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  // Defualt Material UI Package functions
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  // @Style General
  const useStyles = makeStyles((theme) => ({
    table: {
      width: "100%",
    },

    tr: {
      background: "#fff",
      "&:hover": {
        background: "rgba(51,51,51,0.2)",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  // @Function for hadle pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  //@State for pagination
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  // @Method HandleChangePage
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // @Method Hadle RowsperPage
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container-grid">
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-label="sticky table"
            aria-label="customized pagination table"
          >
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <StyledTableCell
                    key={header.id}
                    align={header.numeric ? "right" : "left"}
                    padding={header.disablePadding ? "none" : "default"}
                    style={{ minWidth: header.minWidth }}
                  >
                    <b>{header.name}</b>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0
                ? (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((item, id) => (
                    <TableRow key={id}>
                      {headers.map((property) => (
                        <StyledTableCell
                          align={property.numeric ? "right" : "left"}
                          key={property.id}
                          scope="row"
                        >
                          {property.type === "date" ? (
                            moment(
                              new Date(item[`${property.propertyValue}`])
                            ).format("YYYY-MM-DD")
                          ) : property.type === "numeric" ? (
                            <NumberFormat
                              value={item[`${property.propertyValue}`]}
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          ) : property.nested ? (
                            item[property.propertyValue] !== null &&
                            property.nestedValue.map(
                              (nestedItem) =>
                                item[property.propertyValue][nestedItem] + " "
                            )
                          ) : (
                            item[property.propertyValue]
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  ))
                : ""}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          colSpan={headers.length}
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </div>
  );
};

Grid.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default Grid;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getRequestById } from "../../actions/requests";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import Sidebar from "../sidebar/Sidebar";

const Requests = ({
  getRequestById,
  requests: { request, request_loading },
  auth: { isAuthenticated },
  match,
}) => {
  useEffect(() => {
    getRequestById(match.params.id);
  }, [match.params.id, getRequestById]);

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    !request_loading && (
      <div className="sb-nav-fixed">
        <div id="layoutSidenav">
          <Sidebar />

          <div
            id="layoutSidenav_content"
            style={{ backgroundColor: "rgba(238,244,244,1)" }}
          >
            <main>
              <div className="container-fluid">
                <h4>{request.name}</h4>

                <div>
                  {request.tab.length > 0
                    ? request.tab.map((tab, tabIndex) => (
                        <div
                          key={tab.id}
                          style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            paddingTop: "20px",
                          }}
                        >
                          <h2>TAB {tabIndex + 1}</h2>

                          {tab.sections.length > 0 ? (
                            //   *******************
                            //   Section Divider
                            //   *******************
                            <div style={{ padding: "10px" }}>
                              {tab.sections.map((section) => (
                                <div
                                  key={section.id}
                                  className="card card-3"
                                  style={{
                                    padding: "10px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <div
                                    className="card-body"
                                    style={{
                                      backgroundColor: "lightblue",
                                    }}
                                  >
                                    {/* Coloumn Divider for Sections */}
                                    {section.columns === 1 ? (
                                      section.formFields.map((field) => (
                                        <div
                                          key={field.id}
                                          style={{
                                            padding: "10px",
                                          }}
                                        >
                                          {/* Madtory Validation Field Name display */}

                                          <div
                                            style={{
                                              color: "#236968",
                                              fontWeight: "bold",
                                              marginBottom: "10px",
                                              fontFamily: "Montserrat",
                                            }}
                                          >
                                            {field.modatory === true
                                              ? field.displayName + "*"
                                              : field.displayName}
                                          </div>

                                          <div>
                                            <div
                                              style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                marginBottom: "10px",
                                                fontFamily: "Montserrat",
                                              }}
                                            >
                                              {field.inputText}
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                    ) : // SECTION COLUMN = 2
                                    section.columns === 2 ? (
                                      <div className="row">
                                        {section.formFields.map(
                                          (field, index) => (
                                            <div
                                              key={field.id}
                                              className="col-sm-6"
                                              style={{
                                                padding: "20px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color: "#236968",
                                                  fontWeight: "bold",
                                                  marginBottom: "10px",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {field.modatory === true
                                                  ? field.displayName + "*"
                                                  : field.displayName}
                                              </div>

                                              <div>
                                                <div
                                                  style={{
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    marginBottom: "10px",
                                                    fontFamily: "Montserrat",
                                                  }}
                                                >
                                                  {field.inputText}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      // SECTION COLUMN = 3
                                      <div className="row">
                                        {section.formFields.map(
                                          (field, index) => (
                                            <div
                                              key={field.id}
                                              className="col-sm-4"
                                              style={{
                                                padding: "20px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  color: "#236968",
                                                  fontWeight: "bold",
                                                  marginBottom: "10px",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {field.modatory === true
                                                  ? field.displayName + "*"
                                                  : field.displayName}
                                              </div>

                                              <div>
                                                <div
                                                  style={{
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    marginBottom: "10px",
                                                    fontFamily: "Montserrat",
                                                  }}
                                                >
                                                  {field.inputText}
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            "NO SECTIONS PRESENT"
                          )}
                        </div>
                      ))
                    : "No Tabs"}
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    )
  );
};

Requests.propTypes = {
  auth: PropTypes.object.isRequired,
  requests: PropTypes.object.isRequired,
  getRequestById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, { getRequestById })(
  withRouter(Requests)
);

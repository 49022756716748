import React from 'react';

const SubmitSpinner = () => {
  return (
    <div className="container">
      <div
        style={{
          width: '100px',
          margin: 'auto',
          display: 'block',
          alignContent: 'center',
        }}
      >
        <div className="news-spinner">
          <span className="ball-1"></span>
          <span className="ball-2"></span>
          <span className="ball-3"></span>
          <span className="ball-4"></span>
          <span className="ball-5"></span>
          <span className="ball-6"></span>
          <span className="ball-7"></span>
          <span className="ball-8"></span>
        </div>
      </div>
    </div>
  );
};

export default SubmitSpinner;

import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setAlert } from "../../../../actions/alert";
import "../userManagement.css";
import AutoSuggest from "react-autosuggest";
import AutosuggestSpinner from "../../../layout/AutosuggestSpinner";
import Axios from "axios";
import { withRouter } from "react-router";
import { Multiselect } from "multiselect-react-dropdown";
import { usePasswordValidation } from "../../../../hooks/usePassword";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {
    getAllUserRoles,
    getEmployeeLevelData,
    submitNewUser,
} from "../../../../actions/userManagement";
import Spinner from "../../../layout/Spinner";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import "../../UserManagement/userManagement.css";

const Register = ({
    getAllUserRoles,
    getEmployeeLevelData,
    submitNewUser,
    user: { userRoles, empLevels },
    history,
}) => {
    useEffect(() => {
        getAllUserRoles();
        getEmployeeLevelData();
        setSubmitLoading(false);
    }, []);

    // @State register form
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        emplyoeeNumber: "",
        designation: "",
        password: "",
        employmentLevelId: "",
        branchId: 1,
        userRolesDTO: [],
        divisionId: null,
        departmentId: null,
        reportingManager: null,
        isActive: "",
    });

    const {
        email,
        password,
        firstName,
        lastName,
        emplyoeeNumber,
        designation,
        userName,
        employmentLevelId,
    } = formData;

    //state for loading when submit form
    const [submitLoading, setSubmitLoading] = useState(false);

    //state for re-enter password
    const [reEnterPw, setReEnterPw] = useState("");

    // state for active status
    const [isActiveUser, setIsActiveUser] = useState(false);

    // @Function handle re enter password
    const onChangeReEnterPw = (e) => {
        setReEnterPw(e.target.value);
        if (formData.password === e.target.value) {
            setIsMatch(true);
        }
    };

    //user roles error check state
    const [error, setError] = useState({
        userRolesError: false,
    });

    const { userRolesError } = error;

    //state to set selected user roles
    const [userRoleList, setUserRoleList] = useState([]);

    //division error check state
    const [isValidDivision, setIsValidDivision] = useState(true);

    //department error check state
    const [isValidDepartment, setIsValidDepartment] = useState(true);

    //department suggesstions null check
    const [isDepartmentsNull, setIsDepartmentsNull] = useState(false);

    //reporting manager error check state
    const [isValidReportingManager, setIsValidReportingManager] = useState(true);
    //active user check
    const [checkedSwitch, setCheckedSwitch] = useState(true);

    //method for change user active status
    const onChangeActive = () => {
        setIsActiveUser(!isActiveUser);
        setCheckedSwitch(isActiveUser);
    };

    useEffect(() => {
        formData.isActive = isActiveUser;
    }, [isActiveUser]);

    // @Function handle
    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // @Function for create username automatically using firstname & lastname
    useEffect(() => {
        if (firstName !== "" || lastName !== "") {
            setFormData({
                ...formData,
                userName: firstName + "." + lastName,
            });
            handleUsername(firstName + "." + lastName);
        }
    }, [firstName, lastName]);

    //user roles option
    const rolesData = userRoles;

    //handle user role select
    function onSelect(selectedList, selectedItem) {
        setUserRoleList(selectedList);
        if (userRoleList.length) setError({ ...error, userRolesError: false });
    }
    //handle user role remove
    function onRemove(selectedList, removedItem) {
        setUserRoleList(selectedList);
    }

    // Custom validation Hook
    const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
        usePasswordValidation({
            password: formData.password,
        });

    // Password validation
    const [isValid, setIsValid] = useState(true);

    // pasword does not match error
    const [isMatch, setIsMatch] = useState(true);

    // @Function form submit handler
    const onSubmit = async (e) => {
        if (!isAlreadyExists && !isAlreadyExistsEmployeeNo) {
            if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
                if (password === reEnterPw) {
                    if (isValidDivision && isValidDepartment && isValidReportingManager) {
                        if (
                            formData.divisionId !== null &&
                            formData.departmentId !== null &&
                            formData.reportingManager !== null
                        ) {
                            if (userRoleList.length !== 0) {
                                setIsMatch(true);
                                setIsValid(true);
                                e.preventDefault();
                                userRoleList.forEach((i) => {
                                    formData.userRolesDTO.push({ RoleId: i.id });
                                });
                                setSubmitLoading(true);

                                submitNewUser(formData, history);
                            } else {
                                e.preventDefault();
                                setError({ ...error, userRolesError: true });
                               
                            }
                        } else {
                            e.preventDefault();
                        }
                    } else {
                        e.preventDefault();
                    }
                } else {
                    e.preventDefault();
                    setIsMatch(false);
                    
                }
            } else {
                setIsValid(false);
                e.preventDefault();
              
            }
        }
    };

    // Check if all validations are correct for render error message box
    const isMatchValidation =
        lowerCase && upperCase && hasNumber && validLength && specialChar;

    // Close password validation tooltip after 1 sec when password enterd correct
    useEffect(() => {
        setTimeout(() => {
            if (isMatchValidation) {
                setIsValid(true);
            }
        }, 1000);
    }, [isMatchValidation]);

    const passwordValidationDiv = (
        <div className="col-lg-12 ">
            <div
                className="card card-body mb-4 mt-3 w-100"
                style={{
                    borderColor: !isMatchValidation ? "red" : "green",
                }}
            >
                <div className="horizontal-container">
                    <div style={{ display: "flex" }}>
                        <div>
                            {!lowerCase || !upperCase ? (
                                <HighlightOffIcon style={{ fill: "red" }} />
                            ) : (
                                <CheckCircleOutlineIcon style={{ fill: "green" }} />
                            )}
                        </div>
                        <div className="ml-3">
                            <small
                                style={{
                                    color: !lowerCase || !upperCase ? "red" : "green",
                                    textAlign: "left",
                                }}
                                className="form-text"
                            >
                                <b
                                    style={{
                                        color: !lowerCase || !upperCase ? "red" : "green",
                                    }}
                                >
                                    Include both lower and uppercase character.
                                </b>
                            </small>
                        </div>
                    </div>
                    <div className="mt-1" style={{ display: "flex" }}>
                        <div>
                            {!hasNumber || !specialChar ? (
                                <HighlightOffIcon style={{ fill: "red" }} />
                            ) : (
                                <CheckCircleOutlineIcon style={{ fill: "green" }} />
                            )}
                        </div>
                        <div className="ml-3">
                            <small
                                style={{
                                    color: !hasNumber || !specialChar ? "red" : "green",
                                    textAlign: "left",
                                }}
                                className="form-text"
                            >
                                <b
                                    style={{
                                        color: !hasNumber || !specialChar ? "red" : "green",
                                    }}
                                >
                                    Include at least one number and symbol.
                                </b>
                            </small>
                        </div>
                    </div>
                    <div className="mt-1" style={{ display: "flex" }}>
                        <div>
                            {!validLength ? (
                                <HighlightOffIcon style={{ fill: "red" }} />
                            ) : (
                                <CheckCircleOutlineIcon style={{ fill: "green" }} />
                            )}
                        </div>
                        <div className="ml-3">
                            <small
                                style={{
                                    color: !validLength ? "red" : "green",
                                    textAlign: "left",
                                }}
                                className="form-text"
                            >
                                <b
                                    style={{
                                        color: !validLength ? "red" : "green",
                                    }}
                                >
                                    Be at least 8 characters long.
                                </b>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Escape from the un necessecry values
    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    //  ----------Division autosuggest-------------

    const [divisionDataArray, setDivisionDataArray] = useState([]);
    const [division, setDivision] = useState("");
    const [divisionSuggestions, setDivisionSuggestions] = useState([]);

    useEffect(() => {
        const getDivision = async (value) => {
            const res = await Axios.get(
                `/api/divisions/search-divisions?searchkey=${value}`
            );
            setDivisionDataArray(res.data);
        };
        getDivision(division);
    }, []);

    // Clear Data According to the sugessted dropdown change
    useEffect(() => {
        setDepartment("");
        setDepartmentDataArray([]);
    }, [division]);

    // Match the division suggestions
    function getDivisionSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp("^" + escapedValue, "i");
        return divisionDataArray.filter((division) => regex.test(division.name));
    }

    //  ----------Department autosuggest-------------

    const [departmentDisable, setDepartmentDisable] = useState(false);
    const [departmentDataArray, setDepartmentDataArray] = useState([]);
    const [department, setDepartment] = useState("");
    const [departmentSuggestions, setDepartmentSuggestions] = useState([]);
    const [isDepartmentLoading, setIsDepartmentLoading] = useState(false);

    // Set const center department according to cost center division
    // Trigger: Onclick of suggestion
    const getDepartments = async (divisionDataId) => {
        const departments = await Axios.get(
            `/api/departments/get-by-division-id?divisionId=${divisionDataId}`
        );
        if (departments.data.length > 0) {
            setDepartmentDisable(false);
            setDepartmentDataArray(departments.data);
            setIsDepartmentLoading(false);
            setIsDepartmentsNull(false);
        } else {
            setDepartmentDisable(true);
            setIsDepartmentsNull(true);
            setIsDepartmentLoading(false);
        }
    };

    // Match the department suggestions
    function getDepartmentSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp("^" + escapedValue, "i");

        return departmentDataArray.filter((department) =>
            regex.test(department.name)
        );
    }

    //  ----------Reporting Manager autosuggest-------------

    const [reportingManager, setReportingmanager] = useState("");
    const [reportingManagerSuggestions, setReportingManagerSuggestions] =
        useState([]);

    useEffect(() => {
        if (reportingManager !== null && reportingManager !== "") {
            const getReportingManager = async (value) => {
                const reportingManagerData = await Axios.get(
                    `/api/User/search-users?searchkey=${value}`
                );
                setReportingManagerSuggestions(reportingManagerData.data);
            };
            getReportingManager(reportingManager);
        }
    }, [reportingManager]);

    // Match the department suggestions
    function getReportingManagerSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp("^" + escapedValue, "i");

        return reportingManagerSuggestions.filter((manager) =>
            regex.test(manager.username)
        );
    }

    // Invoice Number Validation
    const [isAlreadyExists, setIsAlreadyExists] = useState(false);
    const [isAlreadyExistsEmployeeNo, setIsAlreadyExistsEmployeeNo] =
        useState(false);

    // Mouse Onblur function for invoice number validation
    const onFocusBlur = (e) => {
        const config = {
            "Content-Type": "application/json",
        };

        (async () => {
            const res = await Axios.get(
                `/api/Verification/verify-user-name?userName=${e.target.value}`,
                config
            );

            // Set Already exists state to response
            setIsAlreadyExists(res.data);
        })();
    };

    // Mouse Onblur function for invoice number validation
    const handleUsername = (username) => {
        const config = {
            "Content-Type": "application/json",
        };

        (async () => {
            const res = await Axios.get(
                `/api/Verification/verify-user-name?userName=${username}`,
                config
            );

            // Set Already exists state to response
            setIsAlreadyExists(res.data);
        })();
    };

    //Verify Employee Number
    useEffect(() => {
        (async () => {
            if (formData.emplyoeeNumber.length > 0) {
                const res = await Axios.get(
                    `/api/verification/verify-employee-no?employeeNo=${formData.emplyoeeNumber}`
                );

                // Set Already exists state to response
                setIsAlreadyExistsEmployeeNo(res.data);
            }
        })();
    }, [formData.emplyoeeNumber]);

    //===========================POP UP BOX BEGIN=============
    //new pop Button
    const styles = (theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

    const DialogTitle = withStyles(styles)((props) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const DialogActions = withStyles((theme) => ({
        root: {
            margin: 0,
            padding: theme.spacing(1),
        },
    }))(MuiDialogActions);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    //===========================POP UP BOX END=============

    return (
        <main>
            {!submitLoading ? (
                <div className="container-fluid">
                    <form onSubmit={(e) => onSubmit(e)}>
                        <div
                            className="card mt-4 mb-4 py-4 px-4"
                            style={{ borderRadius: "10px" }}
                        >
                            <div className="row">
                                <div
                                    className="form-title ml-3 mb-3"
                                    style={{
                                        fontSize: "22px",
                                        fontWeight: "600",
                                        fontFamily: "Segoe UI",
                                    }}
                                >
                                    Create New User
                                </div>
                            </div>

                            <div className="register-card-body px-5 py-4 mb-2">
                                {/* rowstart1 */}
                                <div className="row mb-4">
                                    {/* col1 */}
                                    <div className="col-lg-4 pr-4 ">
                                        <div className="row ml-1 mb-2">
                                            <b>First Name</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            name="firstName"
                                            value={firstName}
                                            onChange={(e) => onChange(e)}
                                            required
                                            autoFocus
                                            className="form-control"
                                        />
                                    </div>
                                    {/* col2 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Last Name</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="lastName"
                                            value={lastName}
                                            onChange={(e) => onChange(e)}
                                            required
                                        />
                                    </div>
                                    {/* col3 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>User Name</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>

                                        <input
                                            style={{ borderColor: isAlreadyExists && "red" }}
                                            className="form-control"
                                            type="text"
                                            name="userName"
                                            value={userName}
                                            onChange={(e) => onChange(e)}
                                            onInputCapture={(e) => onFocusBlur(e)}
                                            lowerCase
                                            required
                                        />
                                        {isAlreadyExists && (
                                            <small
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                <b>This User Already Exists</b>
                                            </small>
                                        )}
                                    </div>
                                </div>
                                {/* rowstart2 */}
                                <div className="row mb-4">
                                    {/* col1 */}
                                    <div className="col-lg-4 pr-4 ">
                                        <div className="row ml-1 mb-2">
                                            <b>Email</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => onChange(e)}
                                            required
                                        />
                                    </div>
                                    {/* col2 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Employee No</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="emplyoeeNumber"
                                            value={emplyoeeNumber}
                                            onChange={(e) => onChange(e)}
                                            required
                                            style={{
                                                borderColor: isAlreadyExistsEmployeeNo && "red",
                                            }}
                                        />
                                        {isAlreadyExistsEmployeeNo && (
                                            <small
                                                style={{
                                                    color: "red",
                                                }}
                                            >
                                                <b>Employee Number is Already Exists</b>
                                            </small>
                                        )}
                                    </div>
                                    {/* col3 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Designation</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="text"
                                            name="designation"
                                            value={designation}
                                            onChange={(e) => onChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                                {/* rowstart3 */}
                                <div className="row mb-4">
                                    {/* col1 */}
                                    <div className="col-lg-4 pr-4 ">
                                        <div className="row ml-1 mb-2">
                                            <b>Employement Level</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <select
                                            className="form-control"
                                            name="employmentLevelId"
                                            value={employmentLevelId}
                                            onChange={(e) => onChange(e)}
                                            required
                                        >
                                            <option value="">Select</option>
                                            {empLevels.length > 0 &&
                                                empLevels.map((option) => (
                                                    <option value={option.id}>{option.name}</option>
                                                ))}
                                        </select>
                                    </div>
                                    {/* col2 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Password</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={(e) => onChange(e)}
                                            required
                                            autoComplete="new-password"
                                        />
                                        {!isMatch ? (
                                            <small
                                                style={{ color: "red", textAlign: "left" }}
                                                className="form-text"
                                            >
                                                <b>Password does not match</b>
                                            </small>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* col3 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Re Enter Password</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        <input
                                            className="form-control"
                                            type="password"
                                            name="reEnterPw"
                                            value={reEnterPw}
                                            onChange={(e) => onChangeReEnterPw(e)}
                                            required
                                        />
                                    </div>
                                    {!isValid ? passwordValidationDiv : ""}
                                </div>
                                {/* row4 */}
                                <div className="row mb-4">
                                    {/* col1 */}
                                    <div className="col-lg-4 pr-4 ">
                                        <div className="row ml-1 mb-2">
                                            <b>User Roles</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>

                                        <Multiselect
                                            className="form-control m-0 mt-1 mb-2"
                                            options={rolesData !== null ? rolesData : []}
                                            displayValue="name"
                                            onSelect={onSelect}
                                            onRemove={onRemove}
                                        />
                                        {userRolesError && (
                                            <small>
                                                <b>This Field is Required</b>
                                            </small>
                                        )}
                                    </div>
                                    {/* col2 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Division</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>

                                        <AutoSuggest
                                            suggestions={divisionSuggestions}
                                            onSuggestionsClearRequested={() =>
                                                setDivisionSuggestions([])
                                            }
                                            onSuggestionsFetchRequested={({ value }) => {
                                                setDivisionSuggestions(getDivisionSuggestions(value));
                                            }}
                                            onSuggestionSelected={(
                                                _,
                                                { suggestionValue, suggestionIndex }
                                            ) => {
                                                getDepartments(divisionSuggestions[suggestionIndex].id);
                                                setIsDepartmentLoading(true);
                                                setFormData({
                                                    ...formData,
                                                    divisionId: divisionSuggestions[suggestionIndex].id,
                                                });
                                                setIsValidDivision(true);
                                                setIsDepartmentsNull(false);
                                            }}
                                            getSuggestionValue={(suggestion) => suggestion.name}
                                            shouldRenderSuggestions={() => true}
                                            renderSuggestion={(suggestion) => (
                                                <span>{suggestion.name}</span>
                                            )}
                                            inputProps={{
                                                className: "form-control",
                                                value: division,
                                                required: true,
                                                placeholder: "Select Division",

                                                onChange: (_, { newValue, method }) => {
                                                    setDivision(newValue);
                                                    setFormData({
                                                        ...formData,
                                                        divisionId: null,
                                                        departmentId: null,
                                                    });

                                                    setIsValidDivision(false);
                                                    setIsValidDepartment(true);
                                                },
                                            }}
                                            highlightFirstSuggestion={true}
                                        />

                                        {!isValidDivision && (
                                            <small style={{ color: "red" }}>
                                                <b>Select a Valid Division from Suggestions </b>
                                            </small>
                                        )}
                                    </div>
                                    {/* col3 */}
                                    <div className="col-lg-4 pr-4 pl-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Department</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>
                                        {!isDepartmentLoading ? (
                                            <AutoSuggest
                                                suggestions={departmentSuggestions}
                                                onSuggestionsClearRequested={() =>
                                                    setDepartmentSuggestions([])
                                                }
                                                onSuggestionsFetchRequested={({ value }) => {
                                                    setDepartmentSuggestions(
                                                        getDepartmentSuggestions(value)
                                                    );
                                                }}
                                                onSuggestionSelected={(
                                                    _,
                                                    { suggestionValue, suggestionIndex }
                                                ) => {
                                                    setFormData({
                                                        ...formData,
                                                        departmentId:
                                                            departmentSuggestions[suggestionIndex].id,
                                                    });

                                                    setIsValidDepartment(true);
                                                }}
                                                getSuggestionValue={(suggestion) => suggestion.name}
                                                shouldRenderSuggestions={() => true}
                                                renderSuggestion={(suggestion) => (
                                                    <span>{suggestion.name}</span>
                                                )}
                                                inputProps={{
                                                    className: "form-control",
                                                    value: department,
                                                    required: true,
                                                    disabled: departmentDisable,
                                                    placeholder: "Select Department",
                                                    onChange: (_, { newValue, method }) => {
                                                        setDepartment(newValue);
                                                        setFormData({
                                                            ...formData,
                                                        });
                                                        setIsValidDepartment(false);
                                                    },
                                                }}
                                                highlightFirstSuggestion={true}
                                            />
                                        ) : (
                                            <AutosuggestSpinner />
                                        )}

                                        {!isValidDepartment && (
                                            <small style={{ color: "red" }}>
                                                <b>Select a Valid Department from Suggestions </b>
                                            </small>
                                        )}
                                        {isDepartmentsNull && (
                                            <small style={{ color: "red" }}>
                                                <b>
                                                    No Department Suggestions for the {division} division{" "}
                                                </b>
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="row mb-4  pr-4 ">
                                    <div className="col-lg-4">
                                        <div className="row ml-1 mb-2">
                                            <b>Reporting Manager</b>
                                            <div
                                                className="ml-2"
                                                style={{
                                                    color: "#ef233c",
                                                }}
                                            >
                                                *
                                            </div>
                                        </div>

                                        <AutoSuggest
                                            suggestions={reportingManagerSuggestions}
                                            onSuggestionsClearRequested={() =>
                                                setReportingManagerSuggestions([])
                                            }
                                            onSuggestionsFetchRequested={({ value }) => {
                                                setReportingManagerSuggestions(
                                                    getReportingManagerSuggestions(value)
                                                );
                                            }}
                                            onSuggestionSelected={(
                                                _,
                                                { suggestionValue, suggestionIndex }
                                            ) => {
                                                setFormData({
                                                    ...formData,
                                                    reportingManager:
                                                        reportingManagerSuggestions[suggestionIndex].id,
                                                });
                                                setIsValidReportingManager(true);
                                            }}
                                            getSuggestionValue={(suggestion) =>
                                                suggestion.firstName + " " + suggestion.lastName
                                            }
                                            shouldRenderSuggestions={() => true}
                                            renderSuggestion={(suggestion) => (
                                                <span>
                                                    {suggestion.firstName + " " + suggestion.lastName}
                                                </span>
                                            )}
                                            inputProps={{
                                                className: "form-control",
                                                value: reportingManager,
                                                required: true,
                                                placeholder: "Select Reporting Manager",
                                                onChange: (_, { newValue, method }) => {
                                                    setReportingmanager(newValue);
                                                    setFormData({
                                                        ...formData,
                                                    });
                                                    setIsValidReportingManager(false);
                                                },
                                            }}
                                            highlightFirstSuggestion={true}
                                        />

                                        {!isValidReportingManager && (
                                            <small style={{ color: "red" }}>
                                                <b>
                                                    Select a Valid Reporting Manager from Suggestions{" "}
                                                </b>
                                            </small>
                                        )}
                                    </div>
                                    <div className="col-lg-4  pr-4 pl-4">
                                        <div className="pb-2 pl-2">
                                            <b>Active user</b>
                                        </div>
                                        <div className="col-lg-1 ml-1 mt-4 p-0">
                                            <div class="button r" id="button-4">
                                                <input
                                                    type="checkbox"
                                                    class="checkbox"
                                                    checked={checkedSwitch}
                                                    onClick={onChangeActive}
                                                />
                                                <div class="knobs"></div>
                                                <div class="layer"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4"></div>
                                </div>
                            </div>

                            
                        </div>

                        <button
                            style={{
                                float: "right",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "right",
                            }}
                            type="submit"
                            className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                        >
                            Create
                        </button>
                    </form>
                </div>
            ) : (
                <Spinner />
            )}
        </main>
    );
};

Register.propTypes = {
    user: PropTypes.object.isRequired,
    getAllUserRoles: PropTypes.func.isRequired,
    getEmployeeLevelData: PropTypes.func.isRequired,
    submitNewUser: PropTypes.func.isRequired,

    setAlert: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.user,
});

export default connect(mapStateToProps, {
    setAlert,
    getAllUserRoles,
    getEmployeeLevelData,
    submitNewUser,
})(withRouter(Register));

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./formItem.css";
import { connect } from "react-redux";
import { getFormById } from "../../actions/forms";
import { formRequest } from "../../actions/requests";
import { withRouter } from "react-router";
import Spinner from "../layout/Spinner";
import store from "../../store";
import { setAlert } from "../../actions/alert";
import $ from "jquery";

const FormItem = ({
  getFormById,
  formRequest,
  match,
  history,
  auth: { id },
  forms: { form, form_loading },
}) => {
  useEffect(() => {
    getFormById(match.params.id);
  }, [match.params.id, getFormById]);
  const options = [];
  // @State FormSubmission, Alert
  const [formData, setformData] = useState([]);

  // @State pass the user id to request controller
  const [userId, setUserId] = useState(null);
  const [optionId, setOptionId] = useState("");

  // @State Popup
  const [popup, setPopup] = useState(false);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  // File Upload States
  const [uploadFile, setUploadFile] = useState("");
  const [fileName, setFileName] = useState("Choose a File");
  const [fileListArray, setFileListArray] = useState([]);
  const [base64List, setBase64List] = useState([]);

  const onChange = (index, tabIndex, sectionIndex, e) => {
    const values = form;
    values.statusId = 1;
    values.tabs[tabIndex].sections[sectionIndex].formFields[index].inputText =
      e.target.value;

    // If the selected value is date in or dapature date ? set min date value
    if (
      values.tabs[tabIndex].sections[sectionIndex].formFields[index]
        .displayName === "Date In" ||
      values.tabs[tabIndex].sections[sectionIndex].formFields[index]
        .displayName === "Arrival Date"
    ) {
      setCheckedOutDate(new Date(e.target.value).toISOString().split("T")[0]);
    } else {
      setformData({
        ...formData,
        values,
      });
    }

    setUserId(id);
  };

  const onCheckTextChange = (index, tabIndex, sectionIndex, e) => {
    const values = form;
    values.statusId = 1;
    values.tabs[tabIndex].sections[sectionIndex].formFields[
      index
    ].textValue.inputText = e.target.value;

    setformData({
      ...formData,
      values,
    });
  };

  const base64arraylist = [];
  // Handle File upload change
  const onFileUploadChange = async (e) => {
    if (fileName === "Choose a File") {
      setUploadFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      const fileNameOfchoosenUpload = e.target.files[0].name;

      //Convert file to base 64
      const base64 = await convertBase64(e.target.files[0]);
      const splitBase64 = base64.split(",")[1];

      const formatedList = {
        name: fileNameOfchoosenUpload,
        value: splitBase64,
      };

      base64arraylist.push(formatedList);

      setBase64List([...base64List, formatedList]);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, rejects) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  const addButtonHandler = async (index, tabIndex, sectionIndex, e) => {
    if (fileName === "Choose a File") {
      store.dispatch(setAlert("Choose a file to upload", "warning"));
    } else {
      const values = form;

      setFileListArray([...fileListArray, { name: fileName }]);
      setFileName("Choose a File");

      values.tabs[tabIndex].sections[sectionIndex].formFields[
        index
      ].attachmentData = base64List;

      setformData({
        ...formData,
        values,
      });
    }
  };

  const generateCheckTextBox = (id) => {
    options.push(id);
    return id;
  };

  const generateCheckboxArray = (item) => {
    setCheckBoxArray((checkBoxArray) => [...checkBoxArray, item]);
  };

  // Handle onCheckbox handle
  const onCheckboxHandle = (index, tabIndex, sectionIndex, e, field) => {
    const values = form;

    var checkbox_index = {
      index: index.toString(),
      tabIndex: tabIndex.toString(),
      sectionIndex: sectionIndex.toString(),
    };

    var exist_item_index = checkBoxArray.findIndex(
      (x) => x.sectionIndex === sectionIndex.toString()
    );
    if (exist_item_index === -1) {
      generateCheckboxArray(checkbox_index);
    } else {
      values.tabs[checkBoxArray[exist_item_index].tabIndex].sections[
        checkBoxArray[exist_item_index].sectionIndex
      ].formFields[checkBoxArray[exist_item_index].index].isChecked = false;
      setCheckBoxArray(
        checkBoxArray.filter(
          (item) => item.sectionIndex !== sectionIndex.toString()
        )
      );
      if (e.target.checked) {
        setTimeout(
          function () {
            //Start the timer
            generateCheckboxArray(checkbox_index); //After 1 second, set render to true
          }.bind(this),
          50
        );
      }
      // generateCheckboxArray(checkbox_index)
    }

    for (var i = 0; i < 40; i++) {
      $(`#check_text_${i + "_" + sectionIndex}`).hide();
    }

    if (e.target.checked) {
      setOptionId((optionId) => `#check_text_${field.id + "_" + sectionIndex}`);

      $(`#check_text_${field.id + "_" + sectionIndex}`).show();
    }

    values.tabs[tabIndex].sections[sectionIndex].formFields[index].isChecked =
      e.target.checked;

    setformData({
      ...formData,
      values,
    });
  };

  // @Function on submit
  const onSubmit = async (e) => {
    setPopup(!popup);
    e.preventDefault();
    await formRequest(formData, userId, history);
  };

  // Get current data from system
  const cutrrent_date = new Date().toISOString().split("T")[0];

  // @State date check in and check out
  // NOTE: This will only change in Check in, check out, Arival date, Departure date
  const [checkedOutDate, setCheckedOutDate] = useState(cutrrent_date);

  return (
    <>
      <main>
        {!form_loading && form !== null ? (
          <div
            className="card w-100 sticky-top mb-3 p-3 form-item-container"
            style={{
              maxHeight: "91px",
              zIndex: "1030",
            }}
          >
            <h5
              style={{
                color: "#333333",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              {form.name}
            </h5>
          </div>
        ) : (
          ""
        )}
        <div className="container-fluid">
          {!form_loading && form !== null ? (
            <div className="mt-3 mb-3">
              {!popup ? (
                <form className="form" onSubmit={(e) => onSubmit(e)}>
                  {form.tabs.length > 0
                    ? form.tabs.map((tab, tabIndex) => (
                        <div
                          key={tabIndex}
                          className="card card-3"
                          style={{
                            marginBottom: "30px",
                          }}
                        >
                          <div
                            className="card-body-form"
                            // style={{ backgroundColor: '#F5F1F2' }}
                          >
                            {tab.title !== "" ? (
                              <h6
                                style={{
                                  color: "#333333",
                                  fontWeight: "bold",
                                  marginBottom: "20px",
                                  marginTop: "20px",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {tab.title}
                              </h6>
                            ) : (
                              ""
                            )}
                            {tab.sections.length > 0 ? (
                              <div>
                                {tab.sections.map((section, sectionIndex) => (
                                  <div
                                    key={sectionIndex}
                                    className="card"
                                    style={{
                                      marginBottom: "20px",
                                      marginTop: "20px",
                                      borderRadius: 0,
                                      border:
                                        "2px solid rgba(233, 236, 239, 1)",
                                      borderTop: 0,
                                    }}
                                  >
                                    <div
                                      className="card-img-top"
                                      style={{
                                        backgroundColor: "#ffd100",
                                        height: "3px",
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                      }}
                                    />
                                    <div className="card-body">
                                      <div
                                        className="attachment-link"
                                        data-toggle="collapse"
                                        data-target={`#${tabIndex}${sectionIndex}`}
                                        aria-expanded="false"
                                        aria-controls="collapseComments"
                                      >
                                        {section.title !== "" ? (
                                          <b>{section.title} </b>
                                        ) : (
                                          <b>Section {sectionIndex + 1}</b>
                                        )}
                                        <i
                                          className="fas fa-angle-down"
                                          style={{
                                            float: "right",
                                            marginTop: "2px",
                                          }}
                                        />
                                      </div>
                                      <div
                                        className="collapse"
                                        id={`${tabIndex}${sectionIndex}`}
                                        aria-labelledby="headingOne"
                                        data-parent="#sidenavAccordion"
                                      >
                                        {section.columns === 1 ? (
                                          section.formFields.map(
                                            (field, index) => (
                                              <div
                                                key={index}
                                                style={{
                                                  padding: "10px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    color: "#333333",
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                    marginBottom: "10px",
                                                    fontFamily: "Montserrat",
                                                  }}
                                                >
                                                  {field.type === "checkbox" ? (
                                                    ""
                                                  ) : field.modatory ===
                                                    true ? (
                                                    <div className="row ml-1">
                                                      {field.displayName}
                                                      <div
                                                        className="ml-2"
                                                        style={{
                                                          color: "#ef233c",
                                                        }}
                                                      >
                                                        *
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    field.displayName
                                                  )}
                                                </div>

                                                <div>
                                                  {field.type === "TextArea" ? (
                                                    <textarea
                                                      style={{
                                                        width: "100%",
                                                        padding: "10px",
                                                      }}
                                                      rows="5"
                                                      required={
                                                        field.modatory === true
                                                          ? true
                                                          : false
                                                      }
                                                      // placeholder={
                                                      //   field.displayName
                                                      // }
                                                      value={field.value}
                                                      onChange={(e) =>
                                                        onChange(
                                                          index,
                                                          tabIndex,
                                                          sectionIndex,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  ) : field.type ===
                                                    "checkbox" ? (
                                                    <div>
                                                      <input
                                                        type="checkbox"
                                                        className="mr-3"
                                                        onChange={(e) =>
                                                          onCheckboxHandle(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e,
                                                            field
                                                          )
                                                        }
                                                        name="name"
                                                        value={true}
                                                        checked={
                                                          checkBoxArray.findIndex(
                                                            (x) =>
                                                              x.sectionIndex ===
                                                                sectionIndex.toString() &&
                                                              x.index ===
                                                                index.toString() &&
                                                              x.tabIndex ===
                                                                tabIndex.toString()
                                                          ) === -1
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                      <b>{field.displayNam}</b>
                                                      {field.isTextAvailable ? (
                                                        <input
                                                          style={{
                                                            display: "none",
                                                            marginTop: 10,
                                                          }}
                                                          id={generateCheckTextBox(
                                                            `check_text_${
                                                              field.id +
                                                              "_" +
                                                              sectionIndex
                                                            }`
                                                          )}
                                                          type="text"
                                                          required={
                                                            field.modatory ===
                                                            false
                                                          }
                                                          value={field.value}
                                                          onChange={(e) =>
                                                            onCheckTextChange(
                                                              index,
                                                              tabIndex,
                                                              sectionIndex,
                                                              e
                                                            )
                                                          }
                                                          className="form-control"
                                                        />
                                                      ) : null}
                                                    </div>
                                                  ) : field.type ===
                                                    "dropdown" ? (
                                                    <div>
                                                      <select
                                                        className="form-control"
                                                        value={field.value}
                                                        name="inputText"
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                      >
                                                        <option value="">
                                                          Select
                                                        </option>
                                                        {field.options.map(
                                                          (
                                                            option,
                                                            dropDownIndex
                                                          ) => (
                                                            <option
                                                              key={
                                                                dropDownIndex
                                                              }
                                                              value={
                                                                option.value
                                                              }
                                                            >
                                                              {option.text}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    </div>
                                                  ) : field.type ===
                                                    "Attachments" ? (
                                                    <div className="card card-body">
                                                      <div className="row">
                                                        <div className="custom-file col-sm-11">
                                                          <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            onChange={(e) =>
                                                              onFileUploadChange(
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <div
                                                            className="custom-file-label"
                                                            htmlFor="customFile"
                                                          >
                                                            {fileName}
                                                          </div>
                                                        </div>
                                                        <div className="col-sm-1">
                                                          <button
                                                            className="btn btn-success ml-3"
                                                            type="button"
                                                            onClick={(e) =>
                                                              addButtonHandler(
                                                                index,
                                                                tabIndex,
                                                                sectionIndex,
                                                                e
                                                              )
                                                            }
                                                          >
                                                            ADD
                                                          </button>
                                                        </div>

                                                        <ul className="list-group list-group-flush">
                                                          {fileListArray &&
                                                            fileListArray.map(
                                                              (file, index) => (
                                                                <li
                                                                  className="list-group-item"
                                                                  key={index}
                                                                >
                                                                  <i className="fas fa-paperclip mr-3" />
                                                                  {file.name}
                                                                </li>
                                                              )
                                                            )}
                                                        </ul>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <input
                                                      type={
                                                        field.type === "Mobile"
                                                          ? "number"
                                                          : field.type
                                                      }
                                                      required={
                                                        field.modatory === true
                                                          ? true
                                                          : false
                                                      }
                                                      name="inputText"
                                                      value={field.value}
                                                      onChange={(e) =>
                                                        onChange(
                                                          index,
                                                          tabIndex,
                                                          sectionIndex,
                                                          e
                                                        )
                                                      }
                                                      className="form-control"
                                                      // placeholder={
                                                      //   field.displayName
                                                      // }
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          )
                                        ) : // SECTION COLUMN = 2
                                        section.columns === 2 ? (
                                          <div className="row">
                                            {section.formFields.map(
                                              (field, index) => (
                                                <div
                                                  key={index}
                                                  className="col-sm-6"
                                                  style={{
                                                    padding: "20px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      color: "#333333",
                                                      fontWeight: "bold",
                                                      fontSize: "15px",
                                                      marginBottom: "10px",
                                                      fontFamily: "Montserrat",
                                                    }}
                                                  >
                                                    {field.type ===
                                                    "checkbox" ? (
                                                      ""
                                                    ) : field.modatory ===
                                                      true ? (
                                                      <div className="row ml-1">
                                                        {field.displayName}
                                                        <div
                                                          className="ml-2"
                                                          style={{
                                                            color: "#ef233c",
                                                          }}
                                                        >
                                                          *
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      field.displayName
                                                    )}
                                                  </div>

                                                  <div>
                                                    {field.type ===
                                                    "TextArea" ? (
                                                      <textarea
                                                        style={{
                                                          width: "100%",
                                                          padding: "10px",
                                                        }}
                                                        rows="5"
                                                        required={
                                                          field.modatory ===
                                                          true
                                                            ? true
                                                            : false
                                                        }
                                                        // placeholder={
                                                        //   field.displayName
                                                        // }
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                    ) : field.type ===
                                                      "checkbox" ? (
                                                      <div>
                                                        <input
                                                          type="checkbox"
                                                          className="mr-3"
                                                          onChange={(e) =>
                                                            onCheckboxHandle(
                                                              index,
                                                              tabIndex,
                                                              sectionIndex,
                                                              e,
                                                              field
                                                            )
                                                          }
                                                          name="name"
                                                          value={true}
                                                          checked={
                                                            checkBoxArray.findIndex(
                                                              (x) =>
                                                                x.sectionIndex ===
                                                                  sectionIndex.toString() &&
                                                                x.index ===
                                                                  index.toString() &&
                                                                x.tabIndex ===
                                                                  tabIndex.toString()
                                                            ) === -1
                                                              ? false
                                                              : true
                                                          }
                                                        />
                                                        <b>
                                                          {field.displayName}
                                                        </b>
                                                        {field.isTextAvailable ? (
                                                          <input
                                                            style={{
                                                              display: "none",
                                                              marginTop: 10,
                                                            }}
                                                            id={generateCheckTextBox(
                                                              `check_text_${
                                                                field.id +
                                                                "_" +
                                                                sectionIndex
                                                              }`
                                                            )}
                                                            type="text"
                                                            required={
                                                              field.modatory ===
                                                              false
                                                            }
                                                            value={field.value}
                                                            onChange={(e) =>
                                                              onCheckTextChange(
                                                                index,
                                                                tabIndex,
                                                                sectionIndex,
                                                                e
                                                              )
                                                            }
                                                            className="form-control"
                                                          />
                                                        ) : null}
                                                      </div>
                                                    ) : field.type ===
                                                      "dropdown" ? (
                                                      <div>
                                                        <select
                                                          className="form-control"
                                                          value={field.value}
                                                          name="inputText"
                                                          onChange={(e) =>
                                                            onChange(
                                                              index,
                                                              tabIndex,
                                                              sectionIndex,
                                                              e
                                                            )
                                                          }
                                                        >
                                                          <option value="">
                                                            Select
                                                          </option>
                                                          {field.options.map(
                                                            (
                                                              option,
                                                              dropDownIndex
                                                            ) => (
                                                              <option
                                                                key={
                                                                  dropDownIndex
                                                                }
                                                                value={
                                                                  option.value
                                                                }
                                                              >
                                                                {option.text}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      </div>
                                                    ) : field.type ===
                                                      "Attachments" ? (
                                                      <div className="card card-body">
                                                        <div className="row">
                                                          <div className="custom-file col-sm-9 mb-3">
                                                            <input
                                                              type="file"
                                                              className="custom-file-input"
                                                              id="customFile"
                                                              onChange={(e) =>
                                                                onFileUploadChange(
                                                                  e
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              className="custom-file-label"
                                                              htmlFor="customFile"
                                                            >
                                                              {fileName}
                                                            </div>
                                                          </div>
                                                          <div className="col-sm-3">
                                                            <button
                                                              className="btn btn-success text-center w-100"
                                                              type="button"
                                                              onClick={(e) =>
                                                                addButtonHandler(
                                                                  index,
                                                                  tabIndex,
                                                                  sectionIndex,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              ADD
                                                            </button>
                                                          </div>

                                                          <ul className="list-group list-group-flush">
                                                            {fileListArray &&
                                                              fileListArray.map(
                                                                (
                                                                  file,
                                                                  index
                                                                ) => (
                                                                  <li
                                                                    className="list-group-item"
                                                                    key={index}
                                                                  >
                                                                    <i className="fas fa-paperclip mr-3" />
                                                                    {file.name}
                                                                  </li>
                                                                )
                                                              )}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <input
                                                        type={
                                                          field.type ===
                                                          "Mobile"
                                                            ? "number"
                                                            : field.type
                                                        }
                                                        required={
                                                          field.modatory ===
                                                          true
                                                            ? true
                                                            : false
                                                        }
                                                        name="inputText"
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                        className="form-control"
                                                        // placeholder={
                                                        //   field.displayName
                                                        // }
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        ) : (
                                          // SECTION COLUMN = 3
                                          <div className="row">
                                            {section.formFields.map(
                                              (field, index) => (
                                                <div
                                                  key={index}
                                                  className="col-sm-4"
                                                  style={{
                                                    padding: "20px",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      color: "#333333",
                                                      fontSize: "15px",
                                                      fontWeight: "bold",
                                                      marginBottom: "10px",
                                                      fontFamily: "Montserrat",
                                                    }}
                                                  >
                                                    {field.type ===
                                                    "checkbox" ? (
                                                      ""
                                                    ) : field.modatory ===
                                                      true ? (
                                                      <div className="row ml-1">
                                                        {field.displayName}
                                                        <div
                                                          className="ml-2"
                                                          style={{
                                                            color: "#ef233c",
                                                          }}
                                                        >
                                                          *
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      field.displayName
                                                    )}
                                                  </div>

                                                  <div>
                                                    {field.type ===
                                                    "TextArea" ? (
                                                      <textarea
                                                        style={{
                                                          width: "100%",
                                                          padding: "10px",
                                                        }}
                                                        rows="5"
                                                        required={
                                                          field.modatory ===
                                                          true
                                                            ? true
                                                            : false
                                                        }
                                                        // placeholder={
                                                        //   field.displayName
                                                        // }
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                    ) : field.type ===
                                                      "checkbox" ? (
                                                      <div>
                                                        <input
                                                          type="checkbox"
                                                          className="mr-3"
                                                          onChange={(e) =>
                                                            onCheckboxHandle(
                                                              index,
                                                              tabIndex,
                                                              sectionIndex,
                                                              e,
                                                              field
                                                            )
                                                          }
                                                          name="name"
                                                          value={true}
                                                          checked={
                                                            checkBoxArray.findIndex(
                                                              (x) =>
                                                                x.sectionIndex ===
                                                                  sectionIndex.toString() &&
                                                                x.index ===
                                                                  index.toString() &&
                                                                x.tabIndex ===
                                                                  tabIndex.toString()
                                                            ) === -1
                                                              ? false
                                                              : true
                                                          }
                                                        />
                                                        <b>
                                                          <b>
                                                            {field.displayName}
                                                          </b>{" "}
                                                          {field.isTextAvailable ? (
                                                            <input
                                                              style={{
                                                                display: "none",
                                                                marginTop: 10,
                                                              }}
                                                              id={generateCheckTextBox(
                                                                `check_text_${
                                                                  field.id +
                                                                  "_" +
                                                                  sectionIndex
                                                                }`
                                                              )}
                                                              type="text"
                                                              required={
                                                                field.modatory ===
                                                                false
                                                              }
                                                              value={
                                                                field.value
                                                              }
                                                              onChange={(e) =>
                                                                onCheckTextChange(
                                                                  index,
                                                                  tabIndex,
                                                                  sectionIndex,
                                                                  e
                                                                )
                                                              }
                                                              className="form-control"
                                                            />
                                                          ) : null}
                                                        </b>
                                                      </div>
                                                    ) : field.type ===
                                                      "dropdown" ? (
                                                      <div>
                                                        <select
                                                          required
                                                          className="form-control"
                                                          value={field.value}
                                                          name="inputText"
                                                          onChange={(e) =>
                                                            onChange(
                                                              index,
                                                              tabIndex,
                                                              sectionIndex,
                                                              e
                                                            )
                                                          }
                                                        >
                                                          <option value="">
                                                            Select
                                                          </option>
                                                          {field.options.map(
                                                            (
                                                              option,
                                                              dropDownIndex
                                                            ) => (
                                                              <option
                                                                key={
                                                                  dropDownIndex
                                                                }
                                                                value={
                                                                  option.value
                                                                }
                                                              >
                                                                {option.text}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      </div>
                                                    ) : field.type ===
                                                      "Attachments" ? (
                                                      <div className="card card-body">
                                                        <div className="row">
                                                          <div className="custom-file col-md-8">
                                                            <input
                                                              type="file"
                                                              className="custom-file-input"
                                                              id="customFile"
                                                              onChange={(e) =>
                                                                onFileUploadChange(
                                                                  e
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              className="custom-file-label"
                                                              htmlFor="customFile"
                                                            >
                                                              {fileName}
                                                            </div>
                                                          </div>
                                                          <div>
                                                            <button
                                                              className="btn btn-success ml-3"
                                                              type="button"
                                                              onClick={(e) =>
                                                                addButtonHandler(
                                                                  index,
                                                                  tabIndex,
                                                                  sectionIndex,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              ADD
                                                            </button>
                                                          </div>

                                                          <ul className="list-group list-group-flush">
                                                            {fileListArray &&
                                                              fileListArray.map(
                                                                (
                                                                  file,
                                                                  index
                                                                ) => (
                                                                  <li
                                                                    className="list-group-item"
                                                                    key={index}
                                                                  >
                                                                    <i className="fas fa-paperclip mr-3" />
                                                                    {file.name}
                                                                  </li>
                                                                )
                                                              )}
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    ) : field.type ===
                                                      "date" ? (
                                                      <input
                                                        type="date"
                                                        min={
                                                          field.displayName ===
                                                          "Estimated return date"
                                                            ? cutrrent_date
                                                            : field.displayName ===
                                                                "Departure Date" ||
                                                              field.displayName ===
                                                                "Date Out"
                                                            ? checkedOutDate
                                                            : field.displayName ===
                                                            "Arrival Date" ? cutrrent_date : ""
                                                        }
                                                        required={
                                                          field.modatory ===
                                                          true
                                                            ? true
                                                            : false
                                                        }
                                                        name="inputText"
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                        className="form-control"
                                                      />
                                                    ) : (
                                                      <input
                                                        type={
                                                          field.type ===
                                                          "Mobile"
                                                            ? "number"
                                                            : field.type
                                                        }
                                                        required={
                                                          field.modatory ===
                                                          true
                                                            ? true
                                                            : false
                                                        }
                                                        name="inputText"
                                                        value={field.value}
                                                        onChange={(e) =>
                                                          onChange(
                                                            index,
                                                            tabIndex,
                                                            sectionIndex,
                                                            e
                                                          )
                                                        }
                                                        className="form-control"
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              "NO SECTIONS PRESENT"
                            )}
                          </div>
                        </div>
                      ))
                    : "No Tabs"}
                  <div className="text-right mr-2">
                    <button className="btn btn-primary pl-4 pr-4 mb-3">
                      SUBMIT
                    </button>
                  </div>
                </form>
              ) : (
                <Spinner />
              )}

              {/* {popup ? (
                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="btn btn-info w-25"
                        onClick={() => setPopup(!popup)}
                      >
                        Submit Another
                      </button>
                    </div>
                  ) : (
                    ''
                  )} */}
            </div>
          ) : (
            <Spinner />
          )}
        </div>
      </main>
    </>
  );
};

FormItem.propTypes = {
  forms: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getFormById: PropTypes.func.isRequired,
  formRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  forms: state.forms,
});

export default connect(mapStateToProps, { getFormById, formRequest })(
  withRouter(FormItem)
);

import React, { useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "../../sidebar/Sidebar";
import RequestForPayment from "./RequestForPayment";
import RequestForPaymentDraft from "../Static_Forms/Drafts/RequestForPayment";
import Spinner from "../../layout/Spinner";
import Status from "../../Shared/Status";
import CardSpinner from "../../layout/CardSpinner";

const RequestItem = ({ match }) => {
  const renderRequestItem = () => {
    switch (match.params.name && match.params.status) {
      case "request-for-payment" && "view":
        return (
          <RequestForPayment
            statusData={handleCallBack}
            loading={hadleLoading}
          />
        );

      case "request-for-payment" && "edit":
        return <RequestForPaymentDraft />;

      default:
        break;
    }
  };

  const renderFormName = () => {
    switch (match.params.name) {
      case "request-for-payment":
        return (
          <div style={{ height: "30px" }}>{formNumber} Request For Payment</div>
        );

      default:
        break;
    }
  };

  // Handle Progress bar callbacks
  const handleCallBack = (requestForPaymentStatusData) => {
    setStatusData(requestForPaymentStatusData);
  };

  // Handle loading callback
  const hadleLoading = (loading, formId) => {
    setLoading(loading);
    if (formId !== null) {
      setformNumber(formId);
    }
  };

  // State for Callbacks from Parent
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formNumber, setformNumber] = useState("");

  return (
    <div>
      <div
        className="card w-100 sticky-top mb-3 detail-sticky"
        style={{
          maxHeight: "140px",
          zIndex: "1030",
          paddingTop: "1em",
        }}
      >
        <h5
          className="ml-3"
          style={{
            color: "#333333",
            fontWeight: "bold",
            fontFamily: "Montserrat",
          }}
        >
          {renderFormName()}
        </h5>
        {match.params.status === "view" ? (
          !loading ? (
            <div className="horizontal-container mt-1">
              <Status progress={statusData} />
            </div>
          ) : (
            <CardSpinner />
          )
        ) : (
          ""
        )}
      </div>

      <main>
        <div className="container-fluid">
          <div>{renderRequestItem()}</div>
        </div>
      </main>
    </div>
  );
};

export default RequestItem;

import axios from 'axios';

import store from '../store';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import { getAllRequests, getRequests } from './requests';
import { PETTYCASH_DATA_GET_BY_ID } from './types';
import { getAccessLevel } from "../actions/shared/AccessLevelsHashMap";

// Submit pettyCash data
export const submitPettyCash = (formData, history) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(`/api/PettyCash/submit`, formData, config);

        if (res.data.isSuccessStatusCode === true) {
            history.push('/user-dashboard');
            dispatch(setAlert('Respond has been recorded', 'success'));
        }
    } catch (err) {
        console.log(err);

        dispatch(setAlert('Internal Server Error', 'error '));
    }
};

// get PettyCash data
export const fetchPettyCashData = (id, accesslevelId) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/PettyCash/get-request?Id=${id}&accessLevelId=${accesslevelId}`, config);

        dispatch({
            type: PETTYCASH_DATA_GET_BY_ID,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const pettyCashRequestButtonClick =
    (formData, additionalComment, access, userId, history) => async dispatch => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            const stringyfyAdditionalComment = JSON.stringify(additionalComment);

            const body = {
                requestProcessLevelActionId: formData.requestProcessLevelActionId,
                requestId: formData.requestId,
                requestLevelId: formData.requestLevelId,
                actionId: formData.actionId,
                additionalComment: stringyfyAdditionalComment,
                AttachmentDataDTO: formData.Attachments,
            };

          const res =  await axios.post('/api/pettycash/submit-action', body, config);

            if (res.status == 200) {
                history.push(`/user-dashboard`);
                dispatch(
                    setAlert(
                        `Form ${formData.formNumber} Has Successfully Recorded`,
                        "success"
                    )
                );
            }

        } catch (err) {
            console.log(err);
            dispatch(setAlert('Something Went Wrong', 'error'));
        }
    };

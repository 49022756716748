import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";

// File upload icons
import pdf from "../../../img/FileUploadIcons/pdf.svg";
import doc from "../../../img/FileUploadIcons/doc.svg";
import csv from "../../../img/FileUploadIcons/csv.svg";
import jpg from "../../../img/FileUploadIcons/jpg.svg";
import png from "../../../img/FileUploadIcons/png.svg";
import xls from "../../../img/FileUploadIcons/xls.svg";

const CommentAttachment = ({
    type = null,
    name,
    onDelete,
    index,
    show = true,
}) => {
    const fileIconRender = (type) => {
        switch (type) {
            case "pdf":
                return pdf;

            case "docx":
                return doc;

            case "csv":
                return csv;

            case "jpg":
                return jpg;

            case "png":
                return png;

            case "xlsx":
                return xls;

            default:
                return jpg;
        }
    };

    return (
        <div className="col-sm-6 mb-3">
            <div className="comment-attachment-list">
                <img src={fileIconRender(type)} alt="fileuploadicon" id="fileIcon" />
                <h6>{name}</h6>
                <div className="attachment-delete" style={{ display: !show && "none" }}>
                    <ClearIcon onClick={(e) => onDelete(e, index)} />
                </div>
            </div>
        </div>
    );
};

CommentAttachment.propTypes = {
    onDelete: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
};

export default CommentAttachment;

import "./index.css";
import DetailGrid from "./DetailGrid";
import { Multiselect } from "multiselect-react-dropdown";
import Status from "../../Shared/Status";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import Print from "./Print";
import NoAttachment from "../../../img/no_attachment.svg";
import React, { Fragment, useEffect, useState, useRef } from "react";
import { getPosItemRequestDataById } from "../../../actions/posRequest";
import { posItemRequestButtonClick } from "../../../actions/posRequest";
import AdditionalComment from "../../Card/AdditionalComment";
import CommentStaticForms from "../../Card/CommentStaticForms";
import PropTypes from "prop-types";
import UserInfo from "../../user/UserInfo";
import CardSpinner from "../../layout/CardSpinner";
import Sidebar from "../../sidebar/Sidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
var moment = require("moment");

const PosRequestDetail = ({
  match,
  history,
  getPosItemRequestDataById,
  posItemRequestButtonClick,
  pos: { pos_item, loading_item }
}) => {

    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.formAccessLevels);

    useEffect(() => {
        if (formAccessLevels !== null) {
            getPosItemRequestDataById(match.params.id, formAccessLevels["POS Item Request"]);
        }
    }, [match.params.id, formAccessLevels]);

  let selectedOutlets;
  if (pos_item !== null) {
    selectedOutlets = pos_item.posItemRequestOutlet;
  }
  // Get auth state from using use Selector
  const auth = useSelector((state) => state.auth);

  // Comment parse to json
  let jsonParseComment;

  // Check if comment available or not
  if (!loading_item) {
    if (pos_item.comments !== null) {
      jsonParseComment = JSON.parse(pos_item.comments);
    }
  }

  const [form, setForm] = useState(null);

  useEffect(() => {
    if (pos_item !== null) {
      setForm(pos_item);
    }
  }, [pos_item]);

  // On change for plu number
  const onChangePluNumber = (index, e) => {
    let values = form;
    values.posItems[index].pluNumber = e.target.value;
    setForm({ ...form, values });
  };

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
    dateTime: "",
  });

  const { comment } = addtionalComment;

  // Form Data for submit additonal comments
  const [formData, setFormData] = useState({
    formNumber: "",
    requestProcessLevelActionId: "",
    requestId: "",
    requestLevelId: "",
    actionId: "",
    Attachments: [],
  });

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Action button click loading spinner
  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: auth.firstName + " " + auth.lastName,
      [e.target.name]: e.target.value,
      dateTime: moment().format("YYYY-MM-DD" + " " + "HH:mm"),
    });
  };

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      formNumber: pos_item.formNumber,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
  };

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setOpen(!open);
    setActionButtonLoading(true);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await posItemRequestButtonClick(
      form.posItems,
      formData,
      jsonParseComment,
      auth.access,
      auth.id,
      history
    );
  };

  // Additional comment popup prop
  const onAdditionalCommentPopup = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  // Print componet ref
  const componentRef = useRef(null);

  return (
    <div className="pos-detail-container">
      <div
        className="card w-100 sticky-top mb-3 pos-detail-sticky"
        style={{
          maxHeight: "100%",
          zIndex: "1030",
        }}
      >
        {!loading_item && !actionButtonLoading ? (
          <>
            <div className="row p-0 m-0">
              <div className="col-lg-9">
                <div className="form-heading-detail pl-3">
                  POS Item Request {pos_item.formNumber}
                </div>
              </div>
              <div className="col-lg-3 date-detail">
                <div className=" date-detail row mt-2">
                  Requested Date
                  <div
                    className="pl-2"
                    style={{
                      color: "#0394fc",
                    }}
                  >
                    {moment(new Date(pos_item.dateRequested)).format(
                      "YYYY/MM/DD"
                    )}
                  </div>
                </div>
              </div>
            </div>
            {pos_item.timeTakenToComplete !== null &&
              pos_item.timeTakenToComplete !== "" && (
                <div className="row ml-4 mt-1 pl-2 date">
                  <div>Time taken to complete: </div>
                  <div className="ml-2"> {pos_item.timeTakenToComplete}</div>
                </div>
              )}
            <div className="horizontal-container mt-1">
              <Status progress={pos_item.requestProgress} />
            </div>
          </>
        ) : (
          <CardSpinner />
        )}
      </div>
      <main>
        <div className="container-fluid">
          <form onSubmit={(e) => onAdditionalCommentPopup(e)}>
            {!loading_item && !actionButtonLoading && pos_item !== null && (
              <>
                <form onSubmit={(e) => onButtonClick(e)}>
                  <AdditionalComment
                    openState={open}
                    onClick={onAdditionalCommentPopup}
                    button={buttonName}
                    value={comment}
                    name="comment"
                    onChange={onChange}
                    attachmnetDisplay
                    setState={setFormData}
                    state={formData}
                    currentAttachments={{
                    data:
                    pos_item.posItemRequestAttachments !== null &&
                    pos_item.posItemRequestAttachments,
                    property: "fileName",
                    }}



                  />
                </form>
                <UserInfo
                  firstName={pos_item.createdUser.firstName}
                  lastName={pos_item.createdUser.lastName}
                  email={pos_item.createdUser.email}
                  department={
                    pos_item.createdUser.department !== null
                      ? pos_item.createdUser.department
                      : ""
                  }
                />
                <div className="mt-3 mb-4">
                  <div
                    className="card card-body"
                    style={{ borderRadius: "10px" }}
                  >
                    <div className="row px-2">
                      <div className="col-lg-6 px-2 mb-3">
                        <div
                          className="pos-card-header"
                          style={{ width: "70px" }}
                        >
                          General
                        </div>
                        <div className="card card-body pos-card">
                          <div className="row mb-4">
                            <div className="col-md-5 field-name">
                              Outlet Names
                            </div>
                            <div className="col-md-7">
                              <Multiselect
                                className="form-control m-0 mt-1 mb-2"
                                displayValue="outletName"
                                hidePlaceholder={true}
                                disable={true}
                                customCloseIcon=" "
                                selectedValues={
                                  selectedOutlets !== null
                                    ? selectedOutlets
                                    : []
                                }
                              />
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-md-5 field-name">
                              Date Required
                            </div>
                            <div className="col-md-7">
                              <input
                                type="text"
                                className="form-control"
                                value={moment(
                                  new Date(pos_item.dateRequired)
                                ).format("YYYY/MM/DD")}
                                disabled
                              />
                            </div>
                          </div>
                                                  <div className="row mb-1">
                                                      <div className="col-md-5 field-name">
                                                          Title
                                                      </div>
                                                      <div className="col-md-7">
                                                          <input
                                                              type="text"
                                                              className="form-control"
                                                              value={pos_item.title}
                                                              disabled
                                                          />
                                                      </div>
                                                  </div>
                        </div>
                      </div>
                      <div className="col-lg-6 px-2 mb-3">
                        <div
                          className="pos-card-header"
                          style={{ width: "110px" }}
                        >
                          Attachments
                        </div>
                        <div className="card card-body pos-card">
                          <div className="row px-2">
                            <div className="col">
                              <Link
                                className="attachment-link"
                                to="!#"
                                data-toggle="collapse"
                                data-target="#collapseAttachments"
                                aria-expanded="false"
                                aria-controls="collapseAttachments"
                              >
                                {pos_item.posItemRequestAttachments.length >
                                0 ? (
                                  <div
                                    className="col-sm-10"
                                    style={{
                                      color: "#333333",
                                      fontWeight: "bold",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {" "}
                                    {pos_item.posItemRequestAttachments.length +
                                      " Attachment(s)"}
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={NoAttachment}
                                      alt="no_attachment"
                                      style={{
                                        height: "50px",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    />
                                    <div
                                      style={{
                                        textAlign: "center",
                                        color: "#333333",
                                        fontWeight: "bold",
                                        fontFamily: "Montserrat",
                                      }}
                                    >
                                      No Attachments
                                    </div>
                                  </div>
                                )}
                              </Link>
                            </div>
                            {pos_item.posItemRequestAttachments.length > 0 ? (
                              <a
                                href={`/api/POSItemRequest/download-attachments?requestId=${pos_item.id}`}
                                target="_blank"
                                download
                                className="text-right col-sm-2"
                              >
                                <i
                                  className="fas fa-download"
                                  style={{
                                    color: "black",
                                  }}
                                ></i>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="collapse"
                            id="collapseAttachments"
                            aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion"
                          >
                            <div
                              style={{
                                height: 100,
                                overflowY: "scroll",
                              }}
                            >
                              <ul className="list-group list-group-flush">
                                {pos_item.posItemRequestAttachments !== null &&
                                  pos_item.posItemRequestAttachments.map(
                                    (file, index) => (
                                      <li
                                        className="list-group-item"
                                        key={index}
                                      >
                                        <div className="col-sm-11">
                                          <i className="fas fa-paperclip mr-3" />
                                          {file.fileName}
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pos-card-header" style={{ width: "104px" }}>
                      Menu Items
                    </div>
                    <div className="card card-body pos-card mb-3">
                      {!loading_item && form !== null && (
                        <div className="mt-3 mb-3 grid-container">
                          <DetailGrid
                            data={form}
                            onChange={onChangePluNumber}
                          />
                        </div>
                      )}
                    </div>
                    <div className="card card-body pos-card">
                      <div className="p-2">
                        {pos_item.comments != null && (
                          <CommentStaticForms
                            accessLevel={auth.access}
                            comments={pos_item.comments}
                            userId={auth.id}
                            requestId={pos_item.id}
                            userName={auth.firstName + " " + auth.lastName}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "none" }}>
                  <Print
                    ref={componentRef}
                    requestData={pos_item}
                    firstName={auth.firstName}
                    lastName={auth.lastName}
                  />
                </div>
                <div style={{ float: "left" }}>
                  <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <button
                          className="btn btn-outline-info ml-3 mb-3 w-100"
                          type="button"
                        >
                          <PrintIcon /> <b> Print This Out</b>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                  />
                </div>
                <div style={{ textAlign: "right", marginRight: "15px" }}>
                  {pos_item.actions !== null
                    ? pos_item.actions.map((action, index) => (
                        <button
                          key={index}
                          style={{ width: "200px" }}
                          className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                          onClick={() => {
                            setButtonName(action.name);
                            onClick(
                              action.requestProcessLevelActionId,
                              action.requestLevelId,
                              action.requestId,
                              action.actionId
                            );
                          }}
                        >
                          <b>{action.name}</b>
                        </button>
                      ))
                    : ""}
                </div>
              </>
            )}
          </form>
        </div>
      </main>
    </div>
  );
};

PosRequestDetail.propTypes = {
  data: PropTypes.array.isRequired,
  getPosItemRequestDataById: PropTypes.func.isRequired,
  posItemRequestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pos: state.pos,
});

export default connect(mapStateToProps, {
  getPosItemRequestDataById,
  posItemRequestButtonClick,
})(withRouter(PosRequestDetail));

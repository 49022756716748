import 'react-date-range/dist/styles.css';
import React, { useEffect, useState } from 'react';
import 'react-date-range/dist/theme/default.css';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { DateRange } from 'react-date-range';
import { DateRangePicker } from 'react-date-range';
import { DefinedRange } from 'react-date-range';

import DateRangeIcon from '@material-ui/icons/DateRange';
import PropTypes from 'prop-types';
import moment from 'moment';
import { addDays } from 'date-fns';

import './dateRange.css';

const DateRangeCalender = ({ setDate, initialState, range_picker = true }) => {
    const useStyles = makeStyles(theme => ({
        backdrop: {
            zIndex: 1000,
            color: '#fff',
        },
    }));

    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (state[0].startDate !== state[0].endDate) {
            setVisiblePicker(false);
            setRangeVisible(false);
        }
    }, [state[0].endDate]);

    const onDateRangeChange = item => {
        setState([item.selection]);

        // Callback function for set dates to Settlement log
        setDate({
            from: moment([item.selection][0].startDate).format('YYYY-MM-DD'),
            to: moment([item.selection][0].endDate).format('YYYY-MM-DD'),
        });
    };

    const [visiblePicker, setVisiblePicker] = useState(false);
    const [rangeVisible, setRangeVisible] = useState(false);

    const classes = useStyles();
    return (
        <div>
            <div className="row p-0 m-0">
                <div className="date-picker-container" style={{ zIndex: '1030' }}>
                    <button
                        className="btn btn-info btn-date-picker"
                        onClick={() => {
                            setVisiblePicker(!visiblePicker);
                            setRangeVisible(false);
                        }}
                    >
                        {initialState.from} ~ {initialState.to}
                        <DateRangeIcon
                            style={{
                                padding: '1px',
                                marginBottom: '3px',
                                marginLeft: '10px',
                            }}
                        />
                    </button>
                </div>

                {range_picker && (
                    <div className="date-picker-container" style={{ zIndex: '1030' }}>
                        <button
                            className="btn btn-info btn-range"
                            onClick={() => {
                                setRangeVisible(!rangeVisible);
                                setVisiblePicker(false);
                            }}
                        >
                            Define Range
              <DateRangeIcon
                                style={{
                                    padding: '1px',
                                    marginBottom: '3px',
                                    marginLeft: '10px',
                                }}
                            />
                        </button>
                    </div>
                )}
            </div>

            <div>
                <div
                    className="card card-body p-0 mt-1 date-picker"
                    style={{
                        display: !visiblePicker && 'none',
                    }}
                >
                    <DateRange
                        onChange={item => onDateRangeChange(item)}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        editableDateInputs={true}
                        months={1}
                        ranges={state}
                        direction="horizontal"
                    />
                </div>
                {range_picker && (
                    <div
                        className="card card-body p-0 mt-1 date-range"
                        style={{
                            display: !rangeVisible && 'none',
                        }}
                    >
                        <DefinedRange
                            onChange={item => onDateRangeChange(item)}
                            ranges={state}
                        />
                    </div>
                )}
            </div>
            <Backdrop
                className={classes.backdrop}
                open={rangeVisible || visiblePicker}
                onClick={() => { setRangeVisible(false); setVisiblePicker(false) }}
            ></Backdrop>
        </div>
    );
};

DateRangeCalender.propTypes = {
    initialState: PropTypes.object.isRequired,
    setDate: PropTypes.func.isRequired,
    range_picker: PropTypes.bool.isRequired,
};

export default DateRangeCalender;

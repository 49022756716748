import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";

import { GET_REPORTS, SET_REPORT_LOADING } from "./types";

// Get Reports Data
export const getReports = (from, to) => async (dispatch) => {
    //Set Loading component true before load new data
    dispatch(setReportLoading());

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/reports/get-returnable-gatepass-report?fromDate=${from}&toDate=${to}`,
      config
      );


    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);

    dispatch(setAlert("Internal Server Error", "warning"));
  }
};

// Get Reports Data
export const getReportsForNonReturnable = (from, to) => async (dispatch) => {
    //Set Loading component true before load new data
    dispatch(setReportLoading());

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/Reports/get-nonreturnable-gatepass-report?fromDate=${from}&toDate=${to}`,
      config
    );

    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);

    dispatch(setAlert("Pick a date", "warning"));
  }
};

// Get Reports Data
export const getReportsForHouseUse = (from, to) => async (dispatch) => {
    //Set Loading component true before load new data
    dispatch(setReportLoading());

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/Reports/get-house-use-request-report?fromDate=${from}&toDate=${to}`,
      config
      );
     

    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Pick a date", "warning"));
  }
};

// Get Reports Data
export const getReportsForRoomRequest = (from, to) => async (dispatch) => {
    //Set Loading component true before load new data
    dispatch(setReportLoading());

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/Reports/get-complement-room-request-report?fromDate=${from}&toDate=${to}`,
      config
    );
    
    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Pick a date", "warning"));
  }
};

// Get Reports Data
export const getPosItemRequestReport = (from, to) => async (dispatch) => {
    //Set Loading component true before load new data
    dispatch(setReportLoading());

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.get(
      `/api/Reports/get-complement-room-request-report?fromDate=${from}&toDate=${to}`,
      config
    );

    dispatch({
      type: GET_REPORTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Pick a date", "warning"));
  }
};

export const setReportLoading = () => async (dispatch)=> {
    dispatch({ type: SET_REPORT_LOADING });
}
import { GET_CASHBOX_DATA, GET_CASHBOX_HISTORY_DATA, REMAIN_CASH_LOADING, REQUEST_HISTORY_LOADING } from '../actions/types';

const initialState = {
    cashbox: null,
    loading: true,
    cashbox_history: [],
    history_loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_CASHBOX_DATA:
            return {
                ...state,
                loading: false,
                cashbox: action.payload,
            };
        case GET_CASHBOX_HISTORY_DATA:
            return {
                ...state,
                history_loading: false,
                cashbox_history: action.payload,
            };
        case REMAIN_CASH_LOADING:
            return {
                ...state,
                loading: true
            };
        case REQUEST_HISTORY_LOADING:
            return {
                ...state,
                history_loading: true
            };

        default:
            return state;
    }
};

export default reducer;
// Hashmaps to store duplicates
const fileNameHashMap = new Map();
const fileDeleteHashMap = {};

export const DuplicateFileUpload = (fileToModified, fileListArray) => {
    const fileName = fileToModified.substring(0, fileToModified.lastIndexOf("."));
    const extension = fileToModified.substring(
        fileToModified.lastIndexOf(".") + 1
    );

    //   Get the lowest filename key
    function getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    function FileExist(fileName) {
        return fileListArray.findIndex((i) => i.name == fileName);
    }

    // Check if file is exists
    const fileIndex = FileExist(fileToModified);

    if (fileIndex !== -1) {
        // Check if file has previously delted
        if (Object.keys(fileDeleteHashMap).length > 0) {
            const ordered = Object.keys(fileDeleteHashMap)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = fileDeleteHashMap[key];
                    return obj;
                }, {});

            //   Get first removed lowest FileName
            const fileName_lowest = getKeyByValue(ordered, fileName);

            if (fileDeleteHashMap[fileName_lowest]) {
                delete fileDeleteHashMap[fileName_lowest];
                return fileName_lowest;
            } else {
                fileNameHashMap.set(
                    fileToModified,
                    fileNameHashMap.get(fileToModified) + 1
                );
                return (
                    fileName + `(${fileNameHashMap.get(fileToModified)}).` + extension
                );
            }
        } else {
            fileNameHashMap.set(
                fileToModified,
                fileNameHashMap.get(fileToModified) + 1
            );
            return fileName + `(${fileNameHashMap.get(fileToModified)}).` + extension;
        }
    } else {
        if (!fileNameHashMap.get(fileToModified)) {
            fileNameHashMap.set(fileToModified, 0);
        }
        return fileToModified;
    }
};

export const DuplicateFileDelete = (fileToDelete) => {
    const fileName = fileToDelete.substring(0, fileToDelete.lastIndexOf("."));

    if (fileName.substring(fileName.length - 1) == ")") {
        const fileWithoutDuplicate = fileName.substring(
            0,
            fileName.lastIndexOf("(")
        );

        fileDeleteHashMap[fileToDelete] = fileWithoutDuplicate;
    }
};

import React, { useEffect, useState } from "react";
import "./userManagement.css";
import Axios from "axios";
import {
    getAllUsers,
    activeStatusChange,
} from "../../../actions/userManagement";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../../layout/Spinner";
import AddIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Link, withRouter } from "react-router-dom";
import store from "../../../store";
import { setAlert } from "../../../actions/alert";

const UserManagement = ({
    getAllUsers,
    activeStatusChange,
    history,
    user: { users, loading },
}) => {
    useEffect(() => {
        getAllUsers();
    }, []);

    // Get App User Previlages
    const app = useSelector((state) => state.app.nav);

    // Validate privilages
    const isUserGrantCreateAccess =
        app.length > 0 && app[2].items.length > 0 && app[2].items[3].create;

    const isUserGrantReadAccess =
        app.length > 0 && app[2].items.length > 0 && app[2].items[3].read;

    const isUserGrantDeleteAccess =
        app.length > 0 &&
        app[2].items.length > 0 &&
        app[2].items[3].delateOrDeactivate;

    // Add user button click
    const onAddUserButtonClick = () => {
        history.push("/user-dashboard/user-management/new-user/register");
    };

    const [userData, setUserData] = useState([]);

    useEffect(() => {
        if (users !== null) {
            setUserData(users);
        }
    }, [users]);

    const [searchInput, setSearchInput] = useState("");


    useEffect(() => {
        if (searchInput !== null && searchInput !== "") {
            const getSuggestUser = async (value) => {
                const userData = await Axios.get(
                    `/api/User/search-users?searchkey=${value}`
                );
                setUserData(userData.data);
            };
            getSuggestUser(searchInput);
            setPage(0);
        } else {
           
            getAllUsers();
        }
    }, [searchInput]);

    // Defualt Material UI Package functions
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#dadee7",
            color: theme.palette.common.black,

            paddingTop: "13px",
            paddingBottom: "13px",
        },
        body: {
            fontSize: 15,
        },
    }))(TableCell);

    const useStyles1 = makeStyles((theme) => ({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }));

    // Defualt Material UI Package functions
    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    // @Style General
    const useStyles = makeStyles((theme) => ({
        table: {
            minWidth: "100%",
        },

        tr: {
            background: "#fff",
            "&:hover": {
                background: "rgba(51,51,51,0.2)",
            },
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: "30%",
            maxWidth: "100%",
        },
        select: { fontSize: "20px" },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: "white",
            "&:hover": {
                backgroundColor: "white",
            },
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto",
                shadows: "2",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            marginTop: "4px",
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "30ch",
                "&:focus": {
                    width: "30ch",
                },
            },
        },
    }));

    // @Function for hadle pagination
    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            !loading && (
                <div className={classes.root}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="previous page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            )
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    // @Method onClick for redirect to form id
    const onClick = (id) => {
        if (isUserGrantReadAccess) {
            history.push(`/user-dashboard/edit-user/${id}`);
        } else {
            store.dispatch(
                setAlert("Not Authorized to Access User Details", "warning")
            );
        }
    };

    // @Method for change active status
    const changeActiveStatus = (id, isActive) => {
        activeStatusChange(id, !isActive);
    };

    //@State for pagination
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

    // @Method HandleChangePage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // @Method Hadle RowsperPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="p-3">
            <div>
                <main>
                    <div className="container-fluid">
                        {!loading ? (
                            <div>
                                <div className="row mt-3 mb-2 mr-1">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div
                                                className=" ml-3 mt-2 mb-2"
                                                style={{ fontSize: "22px", fontWeight: "bold" }}
                                            >
                                                Users
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="row mt-2 pull-right">
                                            <div className="ml-2 mt-1" style={{ width: "11em" }}>
                                                <button
                                                    style={{
                                                        //float: "right",
                                                        color: "#fff",
                                                        backgroundColor: "#0069d9",
                                                        borderColor: "#0062cc",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        // boxShadow: "3px 3px #c0c6cf",
                                                    }}
                                                    className="btn btn-primary w-100 shadow rounded "
                                                    disabled={!isUserGrantCreateAccess}
                                                    onClick={onAddUserButtonClick}
                                                >
                                                    <div className="row">
                                                        <AddIcon /> New User
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 pull-right">
                                    <div className="mt-1 ml-2 mr-3 mb-3 shadow bg-white rounded">
                                        <div className={classes.search}>
                                            <div className={classes.searchIcon}>
                                                <SearchIcon />
                                            </div>
                                            <InputBase
                                                placeholder="Search..."
                                                classes={{
                                                    root: classes.inputRoot,
                                                    input: classes.inputInput,
                                                }}
                                                inputProps={{ "aria-label": "search" }}
                                                
                                                value={searchInput}
                                                onChange={(e) => setSearchInput(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid-container-user">
                                    <TableContainer component={Paper}>
                                        <Table
                                            className={classes.table}
                                            aria-label="customized pagination table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell align="left">Name</StyledTableCell>
                                                    <StyledTableCell align="left">Email</StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        Department
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        User Name
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        Active Status
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                    <StyledTableCell align="center"></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {userData.length > 0
                                                    ? (rowsPerPage > 0
                                                        ? userData.slice(
                                                            page * rowsPerPage,
                                                            page * rowsPerPage + rowsPerPage
                                                        )
                                                        : userData
                                                    ).map((user, id) => (
                                                        <StyledTableRow
                                                            key={id}
                                                            tabIndex={-1}
                                                            className={classes.tr}
                                                        >
                                                            <StyledTableCell component="th" scope="row">
                                                                {user.firstName !== null &&
                                                                    user.firstName + " " + user.lastName}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {user.email}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {user.departmentName}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {user.username}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {user.isActive ? (
                                                                    <CheckCircleOutlineIcon
                                                                        style={{ color: "green" }}
                                                                    />
                                                                ) : (
                                                                    <HighlightOffIcon
                                                                        style={{ color: "red" }}
                                                                    />
                                                                )}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <svg
                                                                    onClick={() => onClick(user.id)}
                                                                    xmlns="http://w  ww.w3.org/2000/svg"
                                                                    width="19"
                                                                    height="19"
                                                                    fill="blue"
                                                                    class="bi bi-pencil-square"
                                                                    viewBox="0 0 16 16"
                                                                    cursor="pointer"
                                                                >
                                                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                                                    />
                                                                </svg>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <button
                                                                    style={{
                                                                        border: "none",
                                                                        color: "white",
                                                                        padding: "3px 6px",
                                                                        textAlign: "center",
                                                                        textDecoration: "none",
                                                                        display: "inline-block",
                                                                        fontSize: "13px",
                                                                        cursor: "pointer",
                                                                        borderRadius: "6px",
                                                                        backgroundColor: "#0069d9",
                                                                    }}
                                                                    className="btn"
                                                                    disabled={!isUserGrantDeleteAccess}
                                                                    onClick={() =>
                                                                        changeActiveStatus(user.id, user.isActive)
                                                                    }
                                                                >
                                                                    {user.isActive ? "Deactivate" : "Activate"}
                                                                </button>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                    : ""}
                                                {emptyRows > 0 && (
                                                    <TableRow style={{ height: 53 * emptyRows }}>
                                                        <TableCell colSpan={6} />
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                            <TableFooter>
                                                <TableRow>
                                                    <TablePagination
                                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                                        colSpan={5}
                                                        count={users.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        SelectProps={{
                                                            inputProps: { "aria-label": "rows per page" },
                                                            native: true,
                                                        }}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                        ActionsComponent={TablePaginationActions}
                                                    />
                                                </TableRow>
                                            </TableFooter>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </main>
            </div>
        </div>
    );
};

UserManagement.propTypes = {
    user: PropTypes.object.isRequired,
    getAllUsers: PropTypes.func.isRequired,
    activeStatusChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, { getAllUsers, activeStatusChange })(
    withRouter(UserManagement)
);

import React from "react";
const RoleManage = React.lazy(() => import("../admin/Role/RoleManage"));
const Dashboard = React.lazy(() => import("../dashboard/UserDashboard"));
const FormContainer = React.lazy(() => import("../forms/FormContainer"));
const SystemAccessRequestDetail = React.lazy(() =>
  import("../SystemAccessRequest/SystemAccessRequestDetail")
);
const Report = React.lazy(() => import("../reports/Report"));

const UserAccount = React.lazy(() => import("../user/UserAccount"));
const RequestItem = React.lazy(() => import("../forms/RequestItem"));

const AdditionalComment = React.lazy(() => import("../Card/AdditionalComment"));

const IOUDetailView = React.lazy(() =>
  import("../PettyCash/Requests/IOU/IOUDetailView")
);
const PettyCashDetailView = React.lazy(() =>
  import("../PettyCash/Requests/PettyCash/PettyCashDetaillView")
);
const Logs = React.lazy(() => import("../PettyCash/CashierView/Logs"));
const Cashbox = React.lazy(() => import("../PettyCash/Cashbox"));
const Reimburse = React.lazy(() => import("../PettyCash/Reimburse"));
const CashierView = React.lazy(() =>
  import("../PettyCash/CashierView/CashierView")
);
const StaticRequestItem = React.lazy(() =>
  import("../forms/Static_Request/RequestItem")
);
const ReimburseDetail = React.lazy(() =>
  import("../PettyCash/ReimburseDetail")
);
const PosRequestDetail = React.lazy(() =>
  import("../PosRequest/PosRequestDetail")
);
const PettyCashContainer = React.lazy(() =>
  import("../PettyCash/PettyCashContainer")
);
const UserFormContainer = React.lazy(() =>
  import("../admin/UserManagement/UserFormContainer")
);
const Register = React.lazy(() => import("../admin/UserManagement/Register"));
const UserAccountManage = React.lazy(() =>
    import("../admin/UserManagement/UserAccountManage")
);
const NewRoleRegister = React.lazy(() =>
    import("../admin/Role/NewRoleRegister")
);

const DepartmentDetails = React.lazy(() =>
    import("../admin/DepartmentManagement/DepartmentDetails")
);
const RegisterDepartment = React.lazy(() =>
    import("../admin/DepartmentManagement/RegisterDepartment")
);
const DivisionDetails = React.lazy(() =>
    import("../admin/DivisionManagement/DivisionDetails")
);
const RegisterDivision = React.lazy(() =>
    import("../admin/DivisionManagement/RegisterDivision")
);
const DivisionManagement = React.lazy(() =>
    import("../admin/DivisionManagement")
);

const TemporaryCreditRequestDeatils = React.lazy(() =>
    import("../TemporaryCreditRequest/TemporaryCreditRequestDetail")
);

const CreditEvaluation = React.lazy(() => import("../CreditEvaluation"));
const CreditEvaluationDetail = React.lazy(() =>
    import("../CreditEvaluation/CreditEvaluationDetail")
);




const RequestForRefund = React.lazy(() => import("../RequestForRefund"));

const RequestForRefundDetail = React.lazy(() =>
  import("../RequestForRefund/RequestForRefundDetail")
);

const SupplierAdvancePaymentRequest = React.lazy(() =>
    import("../SupplierAdvancePaymentRequest")
);

const SupplierAdvancePaymentDetails = React.lazy(() =>
    import("../SupplierAdvancePaymentRequest/SupplierAdvancePaymentDetailed")
);

const SystemAccessRequestReport = React.lazy(() =>
    import("../reports/SystemAccessrequest")
);
const GiftVoucherFormsContainer = React.lazy(() =>
    import("../GiftVoucher/GiftVoucherFormsContainer")
);
const GiftVoucherDetail = React.lazy(() =>
    import("../GiftVoucher/GiftVoucherDetail")
);

const GiftVoucherRquestDetail = React.lazy(() =>
    import("../GiftVoucher/GiftVoucherRequestDetail")
);

const GiftVoucherExtend = React.lazy(() =>
    import("../GiftVoucher/GiftVoucherExtend")
);

const routes = [
  {
    path: "/user-dashboard",
    name: "Dashboard",
    exact: true,
    component: Dashboard,
  },
  {
    path: "/user-dashboard/forms/:id",
    name: "FormContainer",
    component: FormContainer,
    exact: true,
  },
  {
    path: "/user-dashboard/system-access-request-detail/:id",
    name: "SystemAccessRequestDetail",
    component: SystemAccessRequestDetail,
    exact: true,
  },
  {
    path: "/user-dashboard/reports/:id",
    name: "Report",
    component: Report,
    exact: true,
  },
  {
    path: "/user-dashboard/my-account",
    name: "UserAccount",
    component: UserAccount,
    exact: true,
  },
  {
    path: "/user-dashboard/request/:id",
    name: "RequestItem",
    component: RequestItem,
    exact: true,
  },

  {
    path: "/user-dashboard/additional",
    name: "AdditionalComment",
    component: AdditionalComment,
    exact: true,
  },

  {
    path: "/user-dashboard/request/pettycash/request-iou/:id",
    name: "IOUDetailView",
    component: IOUDetailView,
    exact: true,
  },
  {
    path: "/user-dashboard/request/pettycash/request-pettycash/:id",
    name: "PettyCashDetailView",
    component: PettyCashDetailView,
    exact: true,
  },
  {
    path: "/user-dashboard/pettycash/logs/:name",
    name: "Logs",
    component: Logs,
    exact: true,
  },
  {
    path: "/user-dashboard/pettycash/cashbox",
    name: "Cashbox",
    component: Cashbox,
    exact: true,
  },
  {
    path: "/user-dashboard/pettycash/reimburse",
    name: "Reimburse",
    component: Reimburse,
    exact: true,
  },
  {
    path: "/user-dashboard/pettycash/cashier/:name",
    name: "CashierView",
    component: CashierView,
    exact: true,
  },

  {
    path: "/user-dashboard/request/static/:name/:status/:id",
    name: "StaticRequestItem",
    component: StaticRequestItem,
    exact: true,
  },
  {
    path: "/user-dashboard/petty-cash/reimburse/:id",
    name: "ReimburseDetail",
    component: ReimburseDetail,
    exact: true,
  },
  {
    path: "/user-dashboard/pos-request-detail/:id",
    name: "PosRequestDetail",
    component: PosRequestDetail,
    exact: true,
  },
  {
    path: "/user-dashboard/petty-cash/:id",
    name: "PettyCashContainer",
    component: PettyCashContainer,
    exact: true,
  },
  {
    path: "/user-dashboard/user-management/:id",
    name: "UserFormContainer",
    component: UserFormContainer,
    exact: true,
  },
  {
    path: "/user-dashboard/user-management/new-user/register",
    name: "Register",
    component: Register,
    exact: true,
    },
    {
        path: "/user-dashboard/edit-user/:id",
        name: "UserAccountManage",
        component: UserAccountManage,
        exact: true,
    },
    {
        path: "/user-dashboard/edit-role/:id",
        name: "RoleManage",
        component: RoleManage,
        exact: true,
    },
    {
        path: "/user-dashboard/new-role",
        name: "NewRoleRegister",
        component: NewRoleRegister,
        exact: true,
    },
    {
        path: "/user-dashboard/department-management/register-dept",
        name: "RegisterDepartment",
        component: RegisterDepartment,
        exact: true,
    },
     {
    path: "/user-dashboard/department-management/:userManagementId/details-dept/:id",
    name: "DepartmentDetails",
    component: DepartmentDetails,
    exact: true,
  },
    {
        path: "/user-dashboard/division-management/division-register",
        name: "RegisterDivision",
        component: RegisterDivision,
        exact: true,
    },
    {
        path: "/user-dashboard/division-management/division-details/:id",
        name: "DivisionDetails",
        component: DivisionDetails,
        exact: true,
    },
    {
        path: "/user-dashboard/division-management",
        name: "DivisionManagement",
        component: DivisionManagement,
        exact: true,
    },
    {
        path: "/user-dashboard/temporary-credit-request-detail/:id",
        name: "TemporaryCreditRequestDeatils",
        component: TemporaryCreditRequestDeatils,
        exact: true,
    },
 
    {
        path: "/user-dashboard/credit-evaluation-detail/:id",
        name: "CreditEvaluationDetail",
        component: CreditEvaluationDetail,
        exact: true,
    },
    {
        path: "/user-dashboard/credit-evaluation",
        name: "CreditEvaluation",
        component: CreditEvaluation,
        exact: true,
    },
    {
        path: "/user-dashboard/request-for-refund",
        name: "RequestForRefund",
        component: RequestForRefund,
        exact: true,
    },
    {
        path: "/user-dashboard/request-for-refund-detail/:id",
        name: "RequestForRefundDetail",
        component: RequestForRefundDetail,
        exact: true,
    },

    {
        path: "/user-dashboard/supplier-advance-payment-request",
        name: "SupplierAdvancePaymentRequest",
        component: SupplierAdvancePaymentRequest,
        exact: true,
    },
    {
        path: "/user-dashboard/supplier-advance-payment-detail/:id",
        name: "SupplierAdvancePaymentDetails",
        component: SupplierAdvancePaymentDetails,
        exact: true,
    },
    {
        path: "/user-dashboard/sr",
        name: "SystemAccessRequestReport",
        component: SystemAccessRequestReport,
        exact: true,
    },
    {
        path: "/user-dashboard/gift-voucher/:id",
        name: "GiftVoucherFormsContainer",
        component: GiftVoucherFormsContainer,
        exact: true,
    },
    {
        path: "/user-dashboard/gift-voucher-detail/:id",
        name: "GiftVoucherDetail",
        component: GiftVoucherDetail,
        exact: true,
    },
    {
        path: "/user-dashboard/gift-voucher-request-detail/:id",
        name: "GiftVoucherRquestDetail",
        component: GiftVoucherRquestDetail,
        exact: true,
    },
    {
        path: "/user-dashboard/gift-voucher-extend/:no",
        name: "GiftVoucherExtend",
        component: GiftVoucherExtend,
        exact: true,
    },

  
  //   {
  //     path: '/user-dashboard/admin-portal',
  //     name: 'Admin Portal',
  //     component: Admin,
  //     exact: true,
  //   },
];
export default routes;

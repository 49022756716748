import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import moment from "moment";

import Menu from "./Menu";
import Sidebar from "../sidebar/Sidebar";
import Attachment from "./Attachment";
import Spinner from "../layout/Spinner";

import {
  getAllCategories,
  getAllMenuOptions,
  getAllOutlets,
  getAllPriceUpdates,
  submitPosRequest,
} from "../../actions/posRequest";
import { withRouter } from "react-router";
import "./index.css";

const PosRequest = ({
  getAllOutlets,
  getAllMenuOptions,
  getAllCategories,
  getAllPriceUpdates,
  submitPosRequest,
  pos: { outlets, menuOptions, categories, price_updates, loading },
  history,
}) => {
  useEffect(() => {
    getAllOutlets();
    getAllMenuOptions();
    getAllCategories();
    getAllPriceUpdates();
  }, []);

  const outletsData = outlets;

  // Check all dropdown data recieved or not
  const isDataLoaded =
    outlets.length > 0 &&
    menuOptions.length > 0 &&
    categories.length > 0 &&
    price_updates.length > 0;

  // Menu component call back json
  const [formData, setFormData] = useState({
    posItemRequestOutlet: [],
      dateRequired: "",
    title:"",
    POSItemRequestAttachments: [],
    POSItems: [],
  });

    const { dateRequired, title } = formData;

  // Error Handle Outlets, Printers and required date
  const [error, setError] = useState({
    outletError: false,
    attachmentError: false,
    dateRequiredError: false,
    titleRequiredError: false,
  });

    const { outletError, attachmentError, dateRequiredError, titleRequiredError } = error;

  const [outletIdList, setOutletIdList] = useState([]);

  // Submit Loading
  const [submitLoading, setSubmitLoading] = useState(false);

  function onSelect(selectedList, selectedItem) {
    setOutletIdList(selectedList);
    if (outletIdList.length) setError({ ...error, outletError: false });
  }

  function onRemove(selectedList, removedItem) {
    setOutletIdList(selectedList);
  }

  const onChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Menu Callback funtion
  const menuFormDataCallback = (formDataResponse) =>
    setFormData({ ...formData, POSItems: [...formDataResponse] });

  // onSubmit new pos request
  const submitNewPosRequest = (e) => {
    e.preventDefault();

    if (
      outletIdList.length &&
      formData.POSItemRequestAttachments.length >= 2 &&
        dateRequired.length &&
        title.length
    ) {
      setSubmitLoading(true);
      outletIdList.forEach((i) => {
        formData.posItemRequestOutlet.push({ OutletId: i.id });
      });
      submitPosRequest(formData, history);
    } else if (!outletIdList.length) {
      setError({ ...error, outletError: true });
      document.getElementById("outletSelect").scrollIntoView();
      //setOutletIdList([])
    } else if (formData.POSItemRequestAttachments.length < 2) {
      setError({ ...error, attachmentError: true });
      document.getElementById("outletSelect").scrollIntoView();
    } else if (!dateRequired.length) {
      setError({ ...error, dateRequiredError: true });
      document.getElementById("outletSelect").scrollIntoView();
    } else if (!title.length) {
        setError({ ...error, titleRequiredError: true });
        document.getElementById("outletSelect").scrollIntoView();
    }
  };

  //set Error to its default
  useEffect(() => {
    if (dateRequired.length) setError({ ...error, dateRequiredError: false });
  }, [dateRequired]);

    useEffect(() => {
        if (title.length) setError({ ...error, titleRequiredError: false });
    }, [title]);

  useEffect(() => {
    if (formData.POSItemRequestAttachments.length >= 2)
      setError({ ...error, attachmentError: false });
  }, [formData.POSItemRequestAttachments]);

  // Disable required date upto today
  const today = new Date();
  const disableDate = today.setUTCDate(today.getUTCDate() + 1);

  return (
    <main>
      <div className="container-fluid">
        {!loading && isDataLoaded && !submitLoading ? (
          <>
            <div className="mt-3">
              <div className="card card-body" style={{ borderRadius: "10px" }}>
                <div className="row header-container" id="outletSelect">
                  <h5 className="col-md-6 mb-3">POS Item Request</h5>
                  <div className="col-md-6 pos-date">
                    <header>Request Date: </header>{" "}
                    {moment(new Date()).format("YYYY/MM/DD")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 px-2 mb-3">
                    <div className="pos-card-header" style={{ width: "70px" }}>
                      General
                    </div>
                    <div className="card card-body pos-card">
                      <div className="row mb-4">
                        <div className="col-md-5 field-name">
                          Outlet Name <span>*</span>
                        </div>
                        <div className="col-md-7">
                          <>
                            <Multiselect
                              className="form-control m-0 mt-1 mb-2"
                              options={outletsData !== null ? outletsData : []}
                              displayValue="name"
                              onSelect={onSelect}
                              onRemove={onRemove}
                            />
                          </>
                          {outletError && (
                            <small>
                              <b>This Field is Required</b>
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-5">
                          <div className="field-name">
                            Date Required <span>*</span>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <input
                            type="date"
                            name="dateRequired"
                            min={
                              new Date(disableDate).toISOString().split("T")[0]
                            }
                            value={dateRequired}
                            onChange={(e) => onChange(e)}
                            required
                            className="form-control"
                          />

                          {dateRequiredError && (
                            <small>
                              <b>This Field is Required</b>
                            </small>
                          )}
                        </div>
                      </div>
                                          <div className="row mb-1">
                                              <div className="col-md-5">
                                                  <div className="field-name">
                                                      Title <span>*</span>
                                                  </div>
                                              </div>
                                              <div className="col-md-7">
                                                  <input
                                                      type="text"
                                                      name="title"
                                                     
                                                      value={title}
                                                      onChange={(e) => onChange(e)}
                                                      required
                                                      className="form-control"
                                                  />

                                                  {titleRequiredError && (
                                                      <small>
                                                          <b>This Field is Required</b>
                                                      </small>
                                                  )}
                                              </div>
                                          </div>
                     </div>
                  </div>

                  <div className="col-md-5 px-2">
                    <div
                      className="pos-card-header"
                      style={{ width: "120px", display: "flex" }}
                    >
                      Attachments <span>*</span>
                    </div>
                    <form>
                      <Attachment
                        initialState={formData}
                        setState={setFormData}
                        field="POSItemRequestAttachments"
                        error={attachmentError}
                      />
                    </form>
                  </div>
                </div>
              </div>

              <Menu
                menuOptions={menuOptions}
                categories={categories}
                price_updates={price_updates}
                onSubmitFormData={menuFormDataCallback}
              />
            </div>
            <button
              className="btn btn-primary mb-3 px-5"
              style={{ float: "right" }}
              disabled={!formData.POSItems.length > 0}
              onClick={submitNewPosRequest}
            >
              Submit
            </button>
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </main>
  );
};

PosRequest.propTypes = {
  getAllOutlets: PropTypes.func.isRequired,
  getAllMenuOptions: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  getAllPriceUpdates: PropTypes.func.isRequired,
  submitPosRequest: PropTypes.func.isRequired,
  pos: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pos: state.pos,
});

export default connect(mapStateToProps, {
  getAllOutlets,
  getAllMenuOptions,
  getAllCategories,
  getAllPriceUpdates,
  submitPosRequest,
})(withRouter(PosRequest));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { iouWithdraw } from '../../../actions/IOU';
import setAuthToken from '../../../utils/setAuthToken';
import GridCheckbox from '../CashierView/Shared/GridCheckbox';
import Balance from './Shared/Balance';
import { getRemainingCashboxBalance } from '../../../actions/settlement';
import Spinner from '../../layout/Spinner';

const IOUWithdrawal = ({
    iouWithdraw,
    history,
    getRemainingCashboxBalance,
    settlement: { remainingCashBox, loading_cashbox },
}) => {
    const headCells = [
        {
            id: 'Form Number',
            numeric: false,
            disablePadding: true,
            label: 'Form Number',
            propertyValue: 'formNumber',
            type: 'text',
        },
        {
            id: 'Payment Title',
            numeric: false,
            disablePadding: false,
            label: 'Payment Title',
            propertyValue: 'paymentTitle',
            type: 'text',
        },
        {
            id: 'CreatedDate',
            numeric: false,
            disablePadding: false,
            label: 'Created Date',
            propertyValue: 'createdOn',
            type: 'date',
        },
        {
            id: 'Requested Date',
            numeric: false,
            disablePadding: false,
            label: 'Requested Date',
            propertyValue: 'requestingDate',
            type: 'date',
        },
        {
            id: 'Amount',
            numeric: true,
            disablePadding: false,
            label: 'Amount',
            propertyValue: 'amount',
            type: 'text',
        },
    ];

    useEffect(() => {
        // Cashbox value to update balance with IOU
        getRemainingCashboxBalance();

        // Set Authroize token headers
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        // Fetch pending iou withdrawals
        (async () => {
            const withdrawals = await Axios.get(
                `/api/IOU/all-pendingWithdrawal-ious`
            );
            // Create defualt state for value
            if (withdrawals.data.length > 0) {
                // Set withdrwal state data
                setWithdrawData(withdrawals.data);
            }
        })();
    }, []);

    // Submit form id list state
    // NOTE: send as parameter for withdrawal
    const [iouWithdrawIds, setIouWithdrawIds] = useState({
        idList: [],
        totalAmount: 0,
    });

    // IOU data from the API
    const [withdrawData, setWithdrawData] = useState([]);

    // On Withdraw loading spinner
    const [withdrawLoading, setWithdrawLoading] = useState(false);

    const onWithdraw = e => {
        e.preventDefault();
        setWithdrawLoading(true);
        iouWithdraw(iouWithdrawIds.idList, history);
    };

    // Callback of withdrawan Ids from Checkbox Grid
    const GridCheckboxCallback = callbackIds => setIouWithdrawIds(callbackIds);

    return (
        <div>
            {!loading_cashbox && remainingCashBox != null && !withdrawLoading ? (
                <div>
                    <div className="card card-body mb-3">
                        <div className="p-3">
                            <Balance
                                header="Remaining Cash Box Balance"
                                value={remainingCashBox.remainingCash}
                                div_css="small-heading"
                                h2_css="amount-heading"
                            />
                            <div className="row mb-4">
                                <div className="col-sm-6 pr-5">
                                    <Balance
                                        header="Total IOU Amount"
                                        value={iouWithdrawIds.totalAmount}
                                        div_css="small-heading"
                                        h2_css="amount-heading-normal"
                                    />
                                </div>
                                <div className="col-sm-6 pr-5">
                                    <Balance
                                        header="Total Remaining Balance"
                                        value={
                                            remainingCashBox.remainingCash -
                                            iouWithdrawIds.totalAmount
                                        }
                                        div_css="small-heading"
                                        h2_css="amount-heading"
                                    />
                                </div>
                            </div>
                            <div className="divider"></div>
                            <div className="mt-4 mb-3">
                                <GridCheckbox
                                    headers={headCells}
                                    data={withdrawData}
                                    initialState={iouWithdrawIds}
                                    setState={GridCheckboxCallback}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                        disabled={!withdrawData.length > 0}
                        onClick={e => onWithdraw(e)}
                    >
                        Withdraw
          </button>
                </div>
            ) : (
                    <Spinner />
                )}
        </div>
    );
};

IOUWithdrawal.propTypes = {
    iouWithdraw: PropTypes.func.isRequired,
    getRemainingCashboxBalance: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    settlement: state.settlement,
});

export default connect(mapStateToProps, {
    iouWithdraw,
    getRemainingCashboxBalance,
})(withRouter(IOUWithdrawal));

import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";

import Spinner from "../../layout/Spinner";
import routes from "../routes";

const loading = (
  <div className="pt-3 text-center">
    <Spinner />
  </div>
);

const Content = () => {

    // Get auth state from store
    const auth = useSelector((state) => state.auth);

    (() => {
        if (!auth.isAuthenticated) {
            localStorage.setItem("destinationPath", window.location.pathname);
        } else {
            localStorage.removeItem("destinationPath");
        }
    })();

  return (
    <>
      <Suspense fallback={loading}>
        <Switch>
          {routes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                        render={(props) =>
                            auth.isAuthenticated && !auth.loading ? (
                                <>
                                    <route.component {...props} />
                                </>
                            ) : (
                                <Redirect to="/" />
                            )
                        }
                />
              )
            );
          })}
          <Redirect from="/" to="/user-dashboard" />
        </Switch>
      </Suspense>
    </>
  );
};

export default Content;

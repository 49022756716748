import React from "react";
import { Link } from "react-router-dom";

export default class RequestItemComponent extends React.PureComponent {
    renderFormItem(field) {
        switch (field.type) {
            case "TextArea":
                return (
                    <textarea
                        style={{ width: "100%", padding: "10px" }}
                        rows="5"
                        disabled={true}
                        defaultValue={field.inputText}
                    />
                );
            case "checkbox":
                return (
                    <div>
                        {field.isTextAvailable ? (
                            <div>
                                <div
                                    style={{
                                        color: "#333333",
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                    }}
                                >
                                    {field.displayName}
                                </div>
                                <div
                                    style={{
                                        marginBottom: "10px",
                                    }}
                                >
                                    {field.textValue.inputText}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <input
                                    type="checkbox"
                                    className="mr-3"
                                    defaultChecked={field.isChecked}
                                    disabled={true}
                                />
                                <b>{field.displayName}</b>
                            </div>
                        )}
                    </div>
                );
            case "dropdown":
                return (
                    <div>
                        <select className="form-control" disabled={true}>
                            <option defaultChecked>{field.inputText}</option>
                        </select>
                    </div>
                );
            case "Attachments":
                // NOTE: This is only for show existing attachment list
                this.props.setFileNameList(field.fileList != null && field.fileList);
                return (
                    <div>
                        <div className="row">
                            <div className="col-lg-6">
                                <Link
                                    className="attachment-link"
                                    to="!#"
                                    data-toggle="collapse"
                                    data-target="#collapseAttachments"
                                    aria-expanded="false"
                                    aria-controls="collapseAttachments"
                                >
                                    <div className="row">
                                        <div
                                            className="col-sm-10"
                                            style={{
                                                color: "#333333",
                                                fontWeight: "bold",

                                                fontFamily: "Montserrat",
                                            }}
                                        >
                                            {field.fileList !== null ? field.fileList.length : 0}{" "}
                                            Attachments
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {field.fileList !== null ? (
                                <a
                                    href={`/api/Request/download-attachments?requestId=${this.props.request.id}`}
                                    target="_blank"
                                    download
                                    className="text-center col-lg-6"
                                >
                                    <i
                                        className="fas fa-download"
                                        style={{
                                            color: "black",
                                        }}
                                    ></i>
                                </a>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className="collapse"
                            id="collapseAttachments"
                            aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion"
                        >
                            <ul className="list-group list-group-flush">
                                {field.fileList !== null &&
                                    field.fileList.map((file, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className="col-sm-11">
                                                <i className="fas fa-paperclip mr-3" />
                                                {file.fileName}
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                );
            default:
                return (
                    <div
                        style={{
                            color: "black",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                        }}
                    >
                        {field.inputText}
                    </div>
                );
        }
    }

    render() {
        return (
            <div>
                {this.props.request !== null && this.props.request.tabs.length > 0
                    ? this.props.request.tabs.map((tab, tabIndex) => (
                        <div
                            key={tabIndex}
                            className="card card-3"
                            style={{
                                marginBottom: "30px",
                            }}
                        >
                            <div className="card-body-form">
                                {tab.title !== "" ? (
                                    <h6
                                        style={{
                                            color: "#333333",
                                            fontWeight: "bold",
                                            marginTop: "20px",
                                            fontFamily: "Montserrat",
                                            textTransform: "uppercase",
                                        }}
                                    >
                                        {tab.title}
                                    </h6>
                                ) : (
                                    ""
                                )}
                                {tab.sections.length > 0 ? (
                                    <div>
                                        {tab.sections.map((section, id) => (
                                            <div
                                                key={id}
                                                className="card"
                                                style={{
                                                    marginBottom: "20px",
                                                    marginTop: "20px",
                                                    borderRadius: 0,
                                                    border: "2px solid rgba(233, 236, 239, 1)",
                                                    borderTop: 0,
                                                }}
                                            >
                                                <div
                                                    className="card-img-top"
                                                    style={{
                                                        backgroundColor: "#ffd100",
                                                        height: "3px",
                                                    }}
                                                />
                                                <div className="card-body">
                                                    <div
                                                        className="attachment-link"
                                                        data-toggle="collapse"
                                                        data-target={`#${tabIndex}${id}`}
                                                        aria-expanded="false"
                                                        aria-controls="collapseComments"
                                                    >
                                                        {section.title !== "" ? (
                                                            <b>{section.title} </b>
                                                        ) : (
                                                            <b>Section {id + 1}</b>
                                                        )}
                                                        <i
                                                            className="fas fa-angle-down"
                                                            style={{
                                                                float: "right",
                                                                marginTop: "2px",
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        className="collapse"
                                                        id={`${tabIndex}${id}`}
                                                        aria-labelledby="headingOne"
                                                        data-parent="#sidenavAccordion"
                                                    >
                                                        {/* Coloumn Divider for Sections */}
                                                        {section.columns === 1 ? (
                                                            section.formFields.map((field, id) => (
                                                                <div
                                                                    key={id}
                                                                    style={{
                                                                        padding: "10px",
                                                                    }}
                                                                >
                                                                    {/* Madtory Validation Field Name display */}

                                                                    <div
                                                                        style={{
                                                                            color: "#333333",
                                                                            fontWeight: "bold",
                                                                            marginBottom: "10px",
                                                                            fontFamily: "Montserrat",
                                                                        }}
                                                                    >
                                                                        {field.type === "checkbox" ||
                                                                            field.type === "Attachments"
                                                                            ? ""
                                                                            : field.modatory === true
                                                                                ? field.displayName + "*"
                                                                                : field.displayName}
                                                                    </div>

                                                                    <div>{this.renderFormItem(field)}</div>
                                                                </div>
                                                            ))
                                                        ) : // SECTION COLUMN = 2
                                                            section.columns === 2 ? (
                                                                <div className="row">
                                                                    {section.formFields.map((field, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="col-sm-6"
                                                                            style={{
                                                                                padding: "20px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    color: "#333333",
                                                                                    fontWeight: "bold",
                                                                                    marginBottom: "10px",
                                                                                    fontFamily: "Montserrat",
                                                                                }}
                                                                            >
                                                                                {field.type === "checkbox" ||
                                                                                    field.type === "Attachments"
                                                                                    ? ""
                                                                                    : field.modatory === true
                                                                                        ? field.displayName + "*"
                                                                                        : field.displayName}
                                                                            </div>

                                                                            <div>{this.renderFormItem(field)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                // SECTION COLUMN = 3
                                                                <div className="row">
                                                                    {section.formFields.map((field, index) => (
                                                                        <div
                                                                            key={index}
                                                                            className="col-sm-4"
                                                                            style={{
                                                                                padding: "20px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    color: "#333333",
                                                                                    fontWeight: "bold",
                                                                                    marginBottom: "10px",
                                                                                    fontFamily: "Montserrat",
                                                                                }}
                                                                            >
                                                                                {field.type === "checkbox" ||
                                                                                    field.type === "Attachments"
                                                                                    ? ""
                                                                                    : field.modatory === true
                                                                                        ? field.displayName + "*"
                                                                                        : field.displayName}
                                                                            </div>

                                                                            <div>{this.renderFormItem(field)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    "NO SECTIONS PRESENT"
                                )}
                            </div>
                        </div>
                    ))
                    : "No Tabs"}
            </div>
        );
    }
}

import React from 'react';
var moment = require('moment');

var pervious_prograss_status = "";
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export default class FormPrint extends React.PureComponent {



    render() {
        const { request, name } = this.props;

        return (
            <div style={{ margin: 40 }}>
                <div className="row">
                    <div style={{ marginTop: 36, marginLeft: 20 }}>
                        <img
                            style={{ width: 300 }}
                            src={require("../../img/navLogo.png")}
                        />
                    </div>
                    <div
                        className="box-header"
                        style={{
                            display: "grid",
                        }}
                    >
                        <h2
                            style={{ marginLeft: "260px", marginTop: "150px" }}
                            className="box-title "
                        >
                            Shangri-La Hotels Lanka Pvt Ltd
                        </h2>
                    </div>
                </div>
                <div className="box">
                  
                    <div className="box-body">
                        <h4 style={{ marginBottom: 25, marginTop: 45 }}> <b>Request For Payment  -   Form Number</b> : {request.formNumber !== null && request.formNumber} </h4>

                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Request By</th>
                                    <th>Department Name</th>
                                    <th>Location</th>
                                    <th>Created Date</th>
                                </tr>
                                <tr>
                                    <td>{request.createdUser.firstName + " " + request.createdUser.lastName}</td>
                                    <td>{request.createdUser.department !== null && request.createdUser.department.name} </td>
                                    <td>Shangri-La Hotels Lanka Pvt Ltd</td>
                                    <td> {request.createdOn.split("T")[0]}  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="box"  >
                    <div
                        className="box-header"
                        style={{ marginTop: 40, marginBottom: 20 }}
                    >
                    </div>
                    <h5 style={{ marginTop: 30 }}>Details</h5>
                    <div className="box-body" style={{ marginTop: 30 }}>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: 340 }}>Attribute Name</th>
                                    <th> Values</th>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Supplier Name</td>
                                    <td> {request.supplierName}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Document Description</td>
                                    <td> {request.documentDescription}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Currency</td>
                                    <td> {request['currency']['name']}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Amount</td>
                                    <td> {request.amount}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Invoice No</td>
                                    <td> {request.invoiceNo}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Cost Center Division</td>
                                    <td> {request.costCenterDivision.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Cost Center Department</td>
                                    <td> {request.costCenterDepartment.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>P&L Expense Category (Main)</td>
                                    <td> {request.mainPLExpenseCategory.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>P&L Expense Category (Sub)</td>
                                    <td> {request.subPLExpenseCategory.name}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Type of Expense</td>
                                    <td> {request.typeOfExpense.name} </td>
                                </tr>


                                <tr>
                                    <td style={{ width: 340 }}>GA Code</td>
                                    <td> {request.gaCode.name} </td>
                                </tr>


                                {request.expenseLine !== null ?

                                    <tr>
                                        <td style={{ width: 340 }}>Expense Line</td>
                                        <td>
                                            {request.costCenterDivisionId === 6 &&
                                                request.costCenterDepartmentId === 49 &&
                                                request.typeOfExpenseId === 6
                                                ? request.itExpenseLine
                                                : request.expenseLine.name}
                                        </td>
                                    </tr>
                                    : request.costCenterDivisionId === 6 &&
                                    request.costCenterDepartmentId === 49 &&
                                    request.typeOfExpenseId === 6
                                    && 
                                    <tr>
                                        <td style={{ width: 340 }}>IT Expense Line</td>
                                        <td>
                                            {request.itExpenseLine
                                            }
                                        </td>
                                    </tr>}
                                <tr>
                                    <td style={{ width: 340 }}>Remarks</td>
                                    <td> {request.remarks} </td>
                                </tr>
                            </tbody>
                        </table>

                        {request.requestProgress.length > 0 &&
                            <div>
                            <div className="box">
                                <div
                                    className="box-header"
                                    style={{ marginTop: 40, marginBottom: 20 }}
                                >
                                    <h6>Document Approval History :</h6>
                                </div>
                                <div className="box-body">
                                    <table className="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th>Date</th>
                                                <th>User</th>
                                                <th>Stage</th>
                                                <th>Action</th>
                                            </tr>
                                            {request.requestProgress.length > 0 &&
                                                request.requestProgress.map((progress, index) => {
                                                    if (index == 0) {
                                                        pervious_prograss_status = "";
                                                    }

                                                    if (pervious_prograss_status != "Pending") {
                                                        pervious_prograss_status = progress.tookAction;
                                                        return <tr key={index} >
                                                            <td>{progress.date}</td>
                                                            <td>{progress.userName}</td>
                                                            <td>{progress.levelName}</td>
                                                            <td>{progress.tookAction}</td>
                                                        </tr>
                                                    }

                                                }

                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        }

                        {JSON.parse(request.comments).length > 0 && <div >
                            <h5 style={{ marginTop: 50 }}>Comments</h5>

                            <table className="table table-bordered" style={{ marginTop: 30 }}>
                                <tbody>
                                    <tr>
                                        <th style={{ width: 340 }}>Level Name</th>
                                        <th> Comment </th>
                                    </tr>
                                    {JSON.parse(request.comments).length > 0 && JSON.parse(request.comments).map(comment => {
                                        return <tr>
                                            <td style={{ width: 340 }}>{comment.name}</td>
                                            <td> {comment.comment}</td>
                                        </tr>
                                    })


                                    }


                                </tbody>
                            </table>
                        </div>}


                    </div>
                </div>



                <br />
                <h3 style={{ color: '#e2e2e2' }}>
                    {' '}
          ---------------------------------------------------------------------------------{' '}
                </h3>
                <span className="">
                    Printed On : {moment(new Date()).format('YYYY-MM-DD')}
                    <span className=" pull-right">Generated By : {name}</span>
                </span>
            </div>
        );
    }
}

import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DescriptionIcon from "@material-ui/icons/Description";

import NoAttachment from "../../../img/no_attachment.svg";
import "./index.css";
import {
    DuplicateFileUpload,
    DuplicateFileDelete,
} from "../../Shared/Attachment/UploadFunction";

const Attachment = ({ initialState, setState, field, error = false }) => {
    // File Upload States
    const [uploadFile, setUploadFile] = useState("");
    const [fileName, setFileName] = useState("Choose a File");
    const [fileListArray, setFileListArray] = useState([]);
    const [base64List, setBase64List] = useState([]);

    // Attachment delete handle
    const deleteAttachment = (e, index) => {
        DuplicateFileDelete(fileListArray[index].name);
        fileListArray.splice(index, 1);
        base64List.splice(index, 1);
        setBase64List([...base64List]);
        setFileListArray([...fileListArray]);
    };
    const [fileDeleteNameHash, setFileDeleteNameHash] = useState([]);

    // File Upload function
    const base64arraylist = [];

    // Handle File upload change
    const onFileUploadChange = async (e) => {
        if (e.target.files.length > 0) {
            e.persist();
            setUploadFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            const fileNameOfchoosenUpload = e.target.files[0].name;

            //Check if file duplicate and return file name
            const fileNameCallBack = DuplicateFileUpload(
                fileNameOfchoosenUpload,
                fileListArray
            );

            setFileListArray([...fileListArray, { name: fileNameCallBack }]);

            //Convert file to base 64
            const base64 = await convertBase64(e.target.files[0]);
            const splitBase64 = base64.split(",")[1];

            const formatedList = {
                FileName: fileNameCallBack,
                value: splitBase64,
            };

            base64arraylist.push(formatedList);

            setBase64List([...base64List, formatedList]);
        }

        e.target.value = "";
    };

    useEffect(() => {
        setState({
            ...initialState,
            [field]: base64List,
        });
    }, [base64List]);

    const convertBase64 = (file) => {
        return new Promise((resolve, rejects) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    return (
        <div className="px-1">
            <div className="attachment-card card-body">
                <div className="list-wrapper">
                    {fileListArray.length > 0 ? (
                        <>
                            {fileListArray.map((file, index) => (
                                <div className="attachment-list" key={index}>
                                    <DescriptionIcon className="img" />
                                    <h5 className="pos-h5">{file.name} </h5>

                                    <div
                                        className="delete-icon"
                                        onClick={(e) => deleteAttachment(e, index)}
                                    >
                                        <i
                                            className="fa fa-trash"
                                            style={{ cursor: "pointer" }}
                                        ></i>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div style={{ textAlign: "center", width: "100%" }}>
                            <img
                                src={NoAttachment}
                                alt="no_attachment"
                                style={{ height: "40px", textAlign: "center", width: "100%" }}
                            />
                            <small className="w-100">Upload Approved Menu </small>
                            <small className="w-100">Upload Approved Cost Sheet</small>
                            {/* <small className="w-100">Click Upload Attachments</small> */}
                        </div>
                    )}
                </div>
            </div>
            {error && (
                <small>
                    <b>Please Upload Minimum 2 Attachments</b>
                </small>
            )}
            <div class="attachment-wrapper">
                <input
                    type="file"
                    id="file"
                    onChange={(e) => onFileUploadChange(e)}
                    required
                />
                <label for="file" class="btn-3">
                    <span>Upload</span>
                </label>
            </div>
        </div>
    );
};

Attachment.prototype = {
    initialState: PropTypes.object.isRequired,
    setState: PropTypes.object.isRequired,
    field: PropTypes.string.isRequired,
};

export default Attachment;

import { GET_NAV_LINKS, SIDEBAR_SET, ACCESS_LEVEL_SET, ACCESS_PEETYCASH_LEVEL_SET, ACCESS_PEETYCASH_CLEAR } from "../actions/types";

const initialState = {
  nav: [],
  sidebarShow: true,
    loading_nav: true,
    formAccessLevels: null,
    pettyCashFormAccessLevels: null
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_NAV_LINKS:
      return {
        ...state,
        loading_nav: false,
        nav: payload,
      };

    case SIDEBAR_SET:
      return {
        ...state,
        sidebarShow: payload,
      };
      case ACCESS_LEVEL_SET:
      return {
        ...state,
          formAccessLevels: payload,
          };
      case ACCESS_PEETYCASH_LEVEL_SET:
      return {
        ...state,
          pettyCashFormAccessLevels: payload,
          };
      case ACCESS_PEETYCASH_CLEAR:
      return {
        ...state,
          pettyCashFormAccessLevels: null,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// Routing
import PrivateRoute from "./routing/PrivateRoute";
import AdminRoute from "./routing/AdminRoute";

// component
import Landing from "../src/components/layout/Landing";
import Navbar from "./components/layout/Navbar";
import Signin from "./components/auth/Signin";
import Register from "./components/admin/UserManagement/Register";
import UserDashboard from "./components/dashboard/UserDashboard";
import Requests from "./components/forms/Requests";
import Group from "./components/admin/Group";
import RequestItem from "./components/forms/RequestItem";
import FormItem from "./components/forms/FormItem";
import AdminDashboard from "./components/dashboard/AdminDashboard";
import Department from "./components/admin/Department";
import AdditionalComment from "./components/Card/AdditionalComment";
import UserAccount from "./components/user/UserAccount";
import Page404 from "./components/page/Page404";
import Alert from "./components/layout/Alert";
import Report from "./components/reports/Report";
import IdleTimerContainer from "./components/auth/IdleTimerContainer";
import AdvancedSearch from "./components/AdvancedSearch";


// Static Forms
import StaticRequestItem from "./components/forms/Static_Request/RequestItem";
import IOUDetailView from "./components/PettyCash/Requests/IOU/IOUDetailView";
import PettyCashDetailView from "./components/PettyCash/Requests/PettyCash/PettyCashDetaillView";

import CashierView from "./components/PettyCash/CashierView/CashierView";
import Logs from "./components/PettyCash/CashierView/Logs";
import Cashbox from "./components/PettyCash/Cashbox";
import Reimburse from "./components/PettyCash/Reimburse";
import ReimburseDetail from "./components/PettyCash/ReimburseDetail";
import SystemAccessRequest from "./components/SystemAccessRequest";
import PosRequest from "./components/PosRequest";

// Functions
import { loadUser } from "./actions/auth";

// Test Components
import TestFormItem from "./components/forms/FormTest";
import TestDashboard from "./components/test/Dashboard3";
import test from "./components/test/test";
import SystemAccessRequestDetail from "./components/SystemAccessRequest/SystemAccessRequestDetail";
import PosRequestDetail from "./components/PosRequest/PosRequestDetail";

import Layout from "./components/Container/Layout";

import SidebarMUi from "./components/test/SidebarMUi";
import UserManagement from "./components/admin/UserManagement";



const App = () => {
    useEffect(() => {
        if (localStorage.token) {
    store.dispatch(loadUser());
        }

  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <IdleTimerContainer />
        <Fragment>
          <Switch>
          <Route exact path="/" name="Landing" component={Landing} />
                      <Route
                          exact
                          path="/advanced-search"
                          name="AdvancedSearch"
                          component={AdvancedSearch}
                      />
       
            <PrivateRoute path="/user-dashboard" name="Dashboard" />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;

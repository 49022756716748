import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';

import { IOU_DATA_GET_BY_ID } from './types';

// Submit IOU data
export const submitIOU = (formData, history) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(`/api/IOU/submit`, formData, config);

        if (res.data.isSuccessStatusCode === true) {
            history.push('/user-dashboard');
            dispatch(setAlert('Respond has been recorded', 'success'));
        }
    } catch (err) {
        console.log(err);
    }
};

// get IOU data
export const fetchIOUData = (id, accesslevelId)=> async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/IOU/get-request?Id=${id}&accessLevelId=${accesslevelId}`, config);
        dispatch({
            type: IOU_DATA_GET_BY_ID,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

export const iouRequestButtonClick =
    (formData, additionalComment, access, userId, history) => async dispatch => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            const stringyfyAdditionalComment = JSON.stringify(additionalComment);

            const body = {
                requestProcessLevelActionId: formData.requestProcessLevelActionId,
                requestId: formData.requestId,
                requestLevelId: formData.requestLevelId,
                actionId: formData.actionId,
                additionalComment: stringyfyAdditionalComment,
                AttachmentDataDTO: formData.Attachments,

            };

            const res = await axios.post('/api/iou/submit-action', body, config);

            if (res.status == 200) {
                history.push(`/user-dashboard`);
                dispatch(
                    setAlert(
                        `Form ${formData.formNumber} Has Successfully Recorded`,
                        "success"
                    )
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(setAlert('Something Went Wrong', 'error'));
        }
    };

export const iouWithdraw = (formData, history) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
       
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post('/api/IOU/withdraw', formData, config);
        history.push('/user-dashboard');
        dispatch(setAlert('IOU has been withdrawn', 'success'));

       
    } catch (err) {
        console.log(err);
        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

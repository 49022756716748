import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "./Grid";
import PrinterGrid from "./PrinterGrid";
import "../index.css";
import { useRef } from "react";
import Axios from "axios";

const Menu = ({ onSubmitFormData, menuOptions, categories, price_updates }) => {
    // price Update constant ratio value
    const [priceUpdateRatio, setPriceUpdateRatio] = useState(null);

    // Get Price update ratio
    useEffect(() => {
        (async () => {
            await Axios.get("/api/POSItemRequest/get-net-rate").then((res, error) => {
                setPriceUpdateRatio(res.data);
            });
        })();
    }, []);

    // Ref Values
    const editTableRow = useRef(null);
    const printerInput = useRef(null);

  // Printer required validation error
  const [isPrinterEmpty, setIsPrinterEmpty] = useState(false);

    // Menu table one row submit form
    const [tableRow, setTableRow] = useState({
        menuOptionId: null,
        description: "",
        pluNumber: "",
        categoryId: null,
        newPrice: "",
        oldPrice: "",
        unitCost: "",
        cost: "",
        priceUpdateId: null,
        POSPrinters: [],
    });

    const {
        description,
        pluNumber,
        newPrice,
        oldPrice,
        unitCost,
        cost,
        menuOptionId,
        priceUpdateId,
    } = tableRow;

  //   Table Grid Data state
  const [gridData, setGridData] = useState([]);

  // Menu button Add/Update State controll
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateRowIndex, setUpdateRowIndex] = useState(null);

    // Printers for Printer Grid
    // NOTE: This state only uses for printer grid onChange
    const [printersList, setPrintersList] = useState([
        { Id: 1, Name: "", visible: true },
        { Id: 2, Name: "", visible: false },
        { Id: 3, Name: "", visible: false },
        { Id: 4, Name: "", visible: false },
        { Id: 5, Name: "", visible: false },
    ]);

    // Check for 1st printer Name
    const printerValidation = printersList[0].Name.length > 0;

    //   Onchange handler for add a row into grid
    const onChange = (e) => {
        setTableRow({
            ...tableRow,
            [e.target.name]: e.target.value,
        });
    };

  // Handle remove table Grid row
  const onRemoveDataFromGrid = (e, callBackIndex) => {
    gridData.splice(callBackIndex, 1);
    setGridData([...gridData]);
    onSubmitFormData(gridData.concat(gridData.slice(callBackIndex, 1)));
  };

  // Handle Edit row table Gird
  const onEditDataRow = (e, callBackIndex) => {
    // Focus input fields
    editTableRow.current.focus();

    // Set Menu button to Update
    // NOTE: ONLY AFTER EDIT BUTTON CLICK ON GRID
    setIsUpdate(true);
    setUpdateRowIndex(callBackIndex);

    setTableRow({
      menuOptionId: gridData[callBackIndex].menuOptionId,
      description: gridData[callBackIndex].description,
      pluNumber: gridData[callBackIndex].pluNumber,
      categoryId: gridData[callBackIndex].categoryId,
      newPrice: gridData[callBackIndex].newPrice,
      oldPrice: gridData[callBackIndex].oldPrice,
      unitCost: gridData[callBackIndex].unitCost,
      cost: gridData[callBackIndex].cost,
      priceUpdateId: gridData[callBackIndex].priceUpdateId,
      POSPrinters: [],
    });

    // Set POS printers to edit view
    gridData[callBackIndex].POSPrinters.forEach((i, index) => {
      printersList[index].Name = i.Name;
      printersList[index].visible = true;
    });

        // Set Dropdown values to its selected row values
        selectElement("menuOptions", gridData[callBackIndex].menuOptionId);
        selectElement("category", gridData[callBackIndex].categoryId);
        selectElement("priceUpdate", gridData[callBackIndex].priceUpdateId);
    };

    // Replace with the updated values in grid
    const UpdateGridRow = (index) => {
        printersList.forEach((i) => {
            i.Name.length > 0 && tableRow.POSPrinters.push({ Name: i.Name });
        });

    setGridData([
      ...gridData.slice(0, index),
      tableRow,
      ...gridData.slice(index + 1),
    ]);
    setIsUpdate(false);
    setIsPrinterEmpty(false);
    clearForm();
  };

  // Submit Form Data after added to Grid
  useEffect(() => {
    onSubmitFormData(gridData);
  }, [gridData]);

    // On add button click
    const onSubmit = (e) => {
        e.preventDefault();

    //NOTE: ADD OR SUBMIT
    if (!isUpdate) {
      if (printerValidation) {
        setIsPrinterEmpty(false);
        setGridData([...gridData, tableRow]);

                // Set Original printer state with original structure of form
                printersList.forEach((i) => {
                    console.log(i.Name);
                    if (typeof i !== "undefined" && typeof i.Name !== "undefined") {
                        i.Name.length > 0 && tableRow.POSPrinters.push({ Name: i.Name });
                    }
                });
                clearForm();
            } else setIsPrinterEmpty(true);
        } else {
            printerValidation
                ? UpdateGridRow(updateRowIndex)
                : setIsPrinterEmpty(true);
        }
    };

  // Clear dropdown by element Id
  function selectElement(id, valueToSelect) {
    let element = document.getElementById(id);
    element.value = valueToSelect;
  }

    // Clear form Data after submit
    const clearForm = () => {
        setTableRow({
            menuOptionId: "",
            description: "",
            pluNumber: "",
            categoryId: "",
            newPrice: "",
            oldPrice: "",
            unitCost: "",
            cost: "",
            priceUpdateId: "",
            POSPrinters: [],
        });

        setPrintersList([
            { Id: 1, Name: "", visible: true },
            { Id: 2, Name: "", visible: false },
            { Id: 3, Name: "", visible: false },
            { Id: 4, Name: "", visible: false },
            { Id: 5, Name: "", visible: false },
        ]);

        // Clear Dropdowns by element id
        selectElement("menuOptions", "");
        selectElement("category", "");
        selectElement("priceUpdate", "");
    };

    // Calculate the cost
    useEffect(() => {
        if (unitCost.length > 0 && newPrice.length > 0 && newPrice !== "0") {
            let cost;
            if (priceUpdateId == 1) cost = Math.round((unitCost / newPrice) * 100);
            else cost = Math.round((unitCost / (newPrice / priceUpdateRatio)) * 100);

            setTableRow({
                ...tableRow,
                cost: cost,
            });
        }
    }, [unitCost, newPrice, priceUpdateId]);

    return (
        <>
            <div className="card card-body p-3 my-3" style={{ borderRadius: "10px" }}>
                <div className="pos-card-header" style={{ width: "55px" }}>
                    Menu
                </div>
                <form onSubmit={(e) => onSubmit(e)} className="pos-card p-3">
                    <div className="row px-3 pt-3">
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                Menu Options <span>*</span>
                            </div>
                            <>
                                <select
                                    ref={editTableRow}
                                    className="form-control"
                                    name="menuOptionId"
                                    required
                                    onChange={(e) => onChange(e)}
                                    id="menuOptions"
                                >
                                    <option value="">Select</option>
                                    {menuOptions !== null &&
                                        menuOptions.map((menu, index) => (
                                            <option key={index} value={menu.id}>
                                                {menu.name}
                                            </option>
                                        ))}
                                    )
                                </select>
                            </>
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                Description <span>*</span>
                            </div>

                            <input
                                name="description"
                                value={description}
                                required
                                maxLength="20"
                                onChange={(e) =>
                                    setTableRow({
                                        ...tableRow,
                                        description: e.target.value.toUpperCase(),
                                    })
                                }
                                type="text"
                                className="form-control"
                                style={{ textTransform: "uppercase" }}
                            />
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                PLU Number <span>*</span>
                            </div>

                            <input
                                value={pluNumber}
                                name="pluNumber"
                                required
                                onChange={(e) => onChange(e)}
                                type="text"
                                disabled
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                Category <span>*</span>
                            </div>
                            <>
                                <select
                                    className="form-control"
                                    name="categoryId"
                                    onChange={(e) => onChange(e)}
                                    required
                                    id="category"
                                >
                                    <option value="">Select</option>
                                    {categories !== null &&
                                        categories.map((category, index) => (
                                            <option key={index} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    )
                                </select>
                            </>
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                Price Updates <span>*</span>
                            </div>
                            <>
                                <select
                                    className="form-control"
                                    name="priceUpdateId"
                                    onChange={(e) => onChange(e)}
                                    required
                                    id="priceUpdate"
                                >
                                    <option value="">Select</option>
                                    {price_updates !== null &&
                                        price_updates.map((price, index) => (
                                            <option key={index} value={price.id}>
                                                {price.name}
                                            </option>
                                        ))}
                                    )
                                </select>
                            </>
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                New Price <span>*</span>
                            </div>

                            <input
                                name="newPrice"
                                value={newPrice}
                                required
                                onChange={(e) => onChange(e)}
                                type="number"
                                className="form-control"
                            />
                        </div>
                        {menuOptionId == 2 && (
                            <div className="col-md-4 mb-3 px-4 py-2">
                                <div className="field-name mb-2">
                                    Old Price <span>*</span>
                                </div>

                <input
                  name="oldPrice"
                  value={oldPrice}
                  required
                  onChange={(e) => onChange(e)}
                  type="number"
                  className="form-control"
                />
              </div>
            )}
            <div className="col-md-4 mb-3 px-4 py-2">
              <div className="field-name mb-2">
                Unit Cost <span>*</span>
              </div>

                            <input
                                name="unitCost"
                                value={unitCost}
                                required
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                maxLength="2"
                                onChange={(e) => onChange(e)}
                                type="number"
                                className="form-control"
                            />
                        </div>
                        <div className="col-md-4 mb-3 px-4 py-2">
                            <div className="field-name mb-2">
                                Cost (%) <span>*</span>
                            </div>

                            <input
                                name="cost"
                                value={cost}
                                required
                                onChange={(e) => onChange(e)}
                                type="number"
                                disabled
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-md-4 px-4 py-2">
                            <div className="field-name mb-2">
                                Printers <span>*</span>
                            </div>

              <PrinterGrid state={printersList} setState={setPrintersList} />

                            {isPrinterEmpty && (
                                <small style={{ color: "red" }}>
                                    <b>This Field Is Required</b>
                                </small>
                            )}
                        </div>
                    </div>
                    <button
                        className="btn btn-info"
                        style={{ float: "right", width: "100px", margin: "10px" }}
                    >
                        {isUpdate ? "Update" : "Add"}
                    </button>
                </form>

        <div className="mt-3 mb-3 grid-container">
          <Grid
            data={gridData}
            menuOptions={menuOptions}
            categories={categories}
            priceUpdates={price_updates}
            onRemove={onRemoveDataFromGrid}
            onEdit={onEditDataRow}
          />
        </div>
      </div>
    </>
  );
};

Menu.propTypes = {
  onSubmitFormData: PropTypes.func.isRequired,
  menuOptions: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  price_updates: PropTypes.array.isRequired,
};

export default Menu;

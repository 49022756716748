import React, { Fragment } from 'react';
import spinner from '../../img/Spinner.gif';

export default () => (
  <Fragment>
    <div className="container">
      <img
        src={spinner}
        style={{
          width: '60px',
          margin: 'auto',
          display: 'block',
          alignContent: 'center',
        }}
        alt="Loading..."
      />
    </div>
  </Fragment>
);

import React, { useEffect, useState } from 'react';
import { login } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from '../../img/logo.webp';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

const Signin = ({ login, alert }) => {
    // Signin Functionallity
    const [formData, setformData] = useState({
        Email: '',
        password: '',
    });

    const { Email, password } = formData;

    // @Function Onchange event handler
    const onChange = e =>
        setformData({ ...formData, [e.target.name]: e.target.value });

    // @Function Onsubmit event handler
    const onSubmit = async e => {
        e.preventDefault();
        login({ Email, password });
        setIsLoading(true);
    };

    // Material UI customization
    const useStyles = makeStyles(theme => ({
        colorPrimary: {
            backgroundColor: '#e5e5e5',
        },
        barColorPrimary: {
            backgroundColor: 'rgba(188, 152, 84, 0.7)',
        },
    }));

    useEffect(() => {
        setIsLoading(false);
    }, [alert]);

    const [isLoading, setIsLoading] = useState(false);

    const classes = useStyles();

    return (
        <div className="login-page">
            <div>
                <div className="authform mt-5">
                    <img
                        className="text-center mb-5 mr-4"
                        width="250px"
                        height="100%"
                        src={logo}
                        alt="logo"
                    />

                    <form className="register-form mb-3" onSubmit={e => onSubmit(e)}>
                        <input
                            name="Email"
                            value={Email}
                            onChange={e => onChange(e)}
                            type="text"
                            className="form-control"
                            placeholder="User Name"
                            required
                            autoFocus
                        />

                        <input
                            name="password"
                            value={password}
                            onChange={e => onChange(e)}
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            required
                        />

                        <button className="mt-4">login</button>
                    </form>
                    {isLoading && (
                        <LinearProgress
                            classes={{
                                colorPrimary: classes.colorPrimary,
                                barColorPrimary: classes.barColorPrimary,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

Signin.propTypes = {
    alert: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    alert: state.alert,
});

export default connect(mapStateToProps, { login })(Signin);

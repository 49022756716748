import React, { useEffect, useState } from "react";
import {
    getApprovedRequests,
    getApprovedRequestsCount,
    getDashboardTileData,
    getRejectedRequests,
    getRejectedRequestsCount,
    getPendingRequests,
    getPendingRequestsCount,
} from "../../actions/dashboard";
import PropTypes from "prop-types";
import Axios from "axios";
import { connect } from "react-redux";
import Spinner from "../layout/CardSpinner";
import { getRequests, setRequestLoading } from "../../actions/requests";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";

const DashboardCard = ({
    setRequestLoading,
    getRejectedRequests,
    getRejectedRequestsCount,
    getApprovedRequests,
    getApprovedRequestsCount,
    getDashboardTileData,
    getPendingRequests,
    getPendingRequestsCount,
    setState,
    dashboard: {
        tiles,
        tiles_loading,
        approved_req_count,
        approved_req_count_loading,
        pending_req_count,
        pending_req_count_loading,
        rejected_req_count,
        rejected_req_count_loading,
    },
    auth: { id, access },
}) => {
    useEffect(() => {
        getDashboardTileData(id);
        getApprovedRequestsCount(id);
        getRejectedRequestsCount(id);
        getPendingRequestsCount(id);
    }, [getDashboardTileData]);
    
    const onClickApproved = async (e) => {
        setRequestLoading();
        getApprovedRequests(id);
        setState("Approved");
    };

    const onClickRejected = async (e) => {
        setRequestLoading();
        getRejectedRequests(id);
        setState("Rejected");
    };

    const onClickPending = async (e) => {
        setRequestLoading();
        getPendingRequests(id);
        setState("Pending");
    };

    return (
        <div>
            <div className="row mb-3">
                <div className="col-md-4">
                    <div
                        className="card-counter success"
                        onClick={(e) => onClickApproved(e)}
                        style={{ cursor: "pointer" }}
                    >
                        {!approved_req_count_loading ? (
                            <div>
                                <i
                                    className="fa fa-tasks"
                                    style={{ fontSize: "5em", opacity: "0.2" }}
                                ></i>
                                <span
                                    className="count-numbers"
                                    style={{ fontFamily: "Segoe UI" }}
                                >
                                    {approved_req_count}{" "}
                                </span>
                                <span className="count-name" style={{ fontFamily: "Segoe UI" }}>
                                    approved
                                </span>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </div>

                <div className="col-md-4">
                    <div
                        className="card-counter primary"
                        onClick={(e) => onClickPending(e)}
                        style={{ cursor: "pointer" }}
                    >
                        {!pending_req_count_loading ? (
                            <div>
                                <i
                                    className="fa fa-exclamation"
                                    style={{ fontSize: "5em", opacity: "0.2" }}
                                ></i>
                                <span
                                    className="count-numbers"
                                    style={{ fontFamily: "Segoe UI" }}
                                >
                                    {" "}
                                    {pending_req_count}{" "}
                                </span>
                                <span className="count-name" style={{ fontFamily: "Segoe UI" }}>
                                    pending
                                </span>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </div>

                <div className="col-md-4">
                    <div
                        className="card-counter danger"
                        onClick={(e) => onClickRejected(e)}
                        style={{ cursor: "pointer" }}
                    >
                        {!rejected_req_count_loading ? (
                            <div>
                                <i
                                    className="fa fa-ban"
                                    style={{ fontSize: "5em", opacity: "0.2" }}
                                ></i>
                                <span
                                    className="count-numbers"
                                    style={{ fontFamily: "Segoe UI" }}
                                >
                                    {rejected_req_count}{" "}
                                </span>
                                <span className="count-name" style={{ fontFamily: "Segoe UI" }}>
                                    rejected
                                </span>
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

DashboardCard.propTypes = {
    getDashboardTileData: PropTypes.func.isRequired,
    getRejectedRequests: PropTypes.func.isRequired,
    getRejectedRequestsCount: PropTypes.func.isRequired,
    getPendingRequests: PropTypes.func.isRequired,
    getPendingRequestsCount: PropTypes.func.isRequired,
    getApprovedRequests: PropTypes.func.isRequired,
    getApprovedRequestsCount: PropTypes.func.isRequired,
    setRequestLoading: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    dashboard: state.dashboard,
    auth: state.auth,
    requests: state.requests,
});

export default connect(mapStateToProps, {
    getDashboardTileData,
    setRequestLoading,
    getApprovedRequests,
    getApprovedRequestsCount,
    getRejectedRequests,
    getRejectedRequestsCount,
    getPendingRequests,
    getPendingRequestsCount,
})(DashboardCard);

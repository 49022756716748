import React, { Component } from "react";
import ReactExport from "react-data-export";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
var moment = require("moment");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const column_data = [
    {
        title: "Requested By",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Employee Number",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Department",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Job Title",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Requested On",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Status",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    { title: "Form Number", width: { wch: 25 }, style: { font: { bold: true } } },

    {
        title: "System/Sub System",
        width: { wch: 40 },
        style: { font: { bold: true } },
    },
    {
        title: "User Name",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },

    {
        title: "Accepted On",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Completed On",
        width: { wch: 25 },
        style: { font: { bold: true } },
    },
    {
        title: "Time taken To Complete",
        width: { wch: 40 },
        style: { font: { bold: true } },
    },
];
var excel_data;
const font_style = { font: { sz: "11" } };
class SystemAccessRequestReportTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiDataSet: [
                {
                    columns: [],
                    data: [],
                },
            ],
        };

        excel_data = this.state.multiDataSet;
    }

    componentDidMount() {
        var data = [];

        excel_data[0].columns = column_data;

        var data_set = [];
        this.props.data.map((item) => {
            data_set.push([
                {
                    value: item.name !== "" && item.name !== null ? item.name : "--",
                    style: font_style,
                },
                {
                    value:
                        item.employeeNumber !== "" && item.employeeNumber !== null
                            ? item.employeeNumber
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.department !== "" && item.department !== null
                            ? item.department
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.jobTitle !== "" && item.jobTitle !== null
                            ? item.jobTitle
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.createdDate !== "" && item.createdDate !== null
                            ? moment(new Date(item.createdDate)).format("YYYY-MM-DD")
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.status !== "" && item.status !== null ? item.status : "--",
                    style: font_style,
                },
                {
                    value:
                        item.formNumber !== "" && item.formNumber !== null
                            ? item.formNumber
                            : "--",
                    style: font_style,
                },

                {
                    value:
                        item.system !== "" && item.system !== null ? item.system : "--",
                    style: font_style,
                },
                {
                    value:
                        item.userName !== "" && item.userName !== null
                            ? item.userName
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.acceptedDate !== "" && item.acceptedDate !== null
                            ? moment(new Date(item.acceptedDate)).format("YYYY-MM-DD")
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.completedDate !== "" && item.completedDate !== null
                            ? moment(new Date(item.completedDate)).format("YYYY-MM-DD")
                            : "--",
                    style: font_style,
                },
                {
                    value:
                        item.timeTakenToComplete !== "" && item.timeTakenToComplete !== null
                            ? item.timeTakenToComplete
                            : "--",
                    style: font_style,
                },
            ]);
        });
        excel_data[0].data = data_set;
    }

    render() {
        return (
            <div>
                <ExcelFile
                    filename="System Access Request Report"
                    element={
                        <button
                            className="btn btn-info mb-3 mt-3"
                            style={{ left: "100px" }}
                        >
                            <DescriptionOutlinedIcon />
                            <a style={{ margin: 10, cursor: "pointer" }}>Export</a>
                        </button>
                    }
                >
                    <ExcelSheet
                        dataSet={this.state.multiDataSet}
                        name="System Access Request Report"
                    />
                </ExcelFile>
            </div>
        );
    }
}

export default SystemAccessRequestReportTemplate;

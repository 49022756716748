import React, { Fragment } from 'react';
import spinner from '../../img/Spinner.gif';

export default () => (
    <Fragment>
        <div className="container">
            <div className="autosuggest-wrapper">
                <div className="autosuggest-spinner">
                    <div className="autosuggest-spinner--bar"></div>
                    <div className="autosuggest-spinner--bar"></div>
                    <div className="autosuggest-spinner--bar"></div>
                </div>
            </div>
        </div>
    </Fragment>
);

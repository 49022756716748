import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { requesterRequestMore } from "../../actions/requests";

const Comment = ({
    accessLevel,
    comments,
    history,
    userId,
    requestId,
    userName,
    statusId,
    requesterRequestMore,
}) => {
    // Comment parse to json
    let jsonParseComment;

    if (comments !== null) {
        jsonParseComment = JSON.parse(comments);
    }

    // State of submitting new comment
    const [formData, setFormData] = useState({ name: "", comment: "" });
    const [attachments, setAttachments] = useState([]);

    // Display comment section after submit
    // NOTE: after submitting a request comment section should disapear
    const [display, setDisplay] = useState(true);

    const { comment } = formData;

    // Onchange event handler for submit comment
    const onChange = (e) => {
        setFormData({
            ...formData,
            name: userName,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmitComment = async (e) => {
        e.preventDefault();
        await jsonParseComment.push(formData);
        await requesterRequestMore(
            jsonParseComment,
            attachments,
            userId,
            requestId,
            history
        );
        setDisplay(false);
    };

    return (
        <div className="mb-4">
            <div>
                <div
                    className="card-img-top"
                    style={{
                        backgroundColor: "#ffd100",
                        height: "3px",
                    }}
                />
                <div className="card card-body">
                    <b className="mb-3">Comments</b>

                    {comments !== null
                        ? jsonParseComment.map((comment, id) => (
                            <div className="Area" key={id}>
                                <div className="Receiver">
                                    <h6
                                        className="p-0 mb-2 L"
                                        style={{
                                            fontWeight: "bolder",
                                            color: "#a4a4a4",
                                        }}
                                    >
                                        {comment.name}
                                    </h6>
                                    <div className="text L textL">{comment.comment} </div>
                                </div>
                            </div>
                        ))
                        : ""}

                    {/* {accessLevel == 0 && display && statusId === 3 && (
            <div>
              <>
                <div className="current-attachment-title">
                  Current Attachments
                </div>
                <div className="row px-3">
                  {data.map((file, index) => (
                    <CommentAttachment
                      key={index}
                      type={file.type}
                      name={file.fileName}
                      index={index}
                      show={false}
                    />
                  ))}
                </div>
              </>
              <>
                {data.length > 0 && fileListArray.length > 0 && (
                  <div className="current-attachment-title">
                    New Attachments
                  </div>
                )}
                <div className="row px-3">
                  {fileListArray.map((file, index) => (
                    <CommentAttachment
                      key={index}
                      type={file.type}
                      name={file.name}
                      index={index}
                      onDelete={deleteAttachment}
                    />
                  ))}
                </div>
                {error.show && (
                  <div className="attachment-error px-3">
                    Selected File <b> "{error.msg}" </b> is Already Exists.
                  </div>
                )}
              </>
              <form onSubmit={(e) => onSubmitComment(e)}>
                <div className="Message">
                  <input
                    type="text"
                    className="form-control w-75"
                    maxLength="100"
                    placeholder="Enter Request..."
                    name="comment"
                    value={comment}
                    autoFocus={true}
                    required
                    onChange={(e) => onChange(e)}
                  />
                  <div className="comment-attachment ml-3">
                    <label for="file-upload" className="custom-file-upload">
                      <AttachFileIcon type="button" />
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={(e) => onFileUploadChange(e)}
                    />
                  </div>
                  <button
                    type="sumbit"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="Tooltip on top"
                    className="btn btn-success ml-3 w-25"
                  >
                    SEND
                  </button>
                </div>
              </form>
            </div>
          )} */}
                </div>
            </div>
        </div>
    );
};

Comment.propTypes = {
    comments: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    statusId: PropTypes.number.isRequired,
    requestId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    requesterRequestMore: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { requesterRequestMore })(
    withRouter(Comment)
);

import React from 'react';
import PropTypes from 'prop-types';

const Status = ({ progress }) => {
    return (
        <div>
            <div
                style={{
                    textAlign: 'center',
                }}
                className="mt-3 ml-3"
            >
                <ol className="progress-tracker icon">
                    <li className="completed document">
                        <span>REQUESTER</span>
                    </li>

                    {progress.map((node, index) => (
                        <li
                            key={index}
                            className={
                                progress.length > 0
                                    ? node.tookAction === 'Approved' ||
                                        node.tookAction === 'Checked In' ||
                                        node.tookAction === 'Checked Out'
                                        ? 'completed'
                                        : node.tookAction === 'Rejected'
                                            ? 'skipped'
                                            : node.tookAction === 'Expired'
                                                ? 'skipped expire'
                                                : 'current'
                                    : ''
                            }
                        >
                            <span>{node.levelName}</span>
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    );
};

Status.propTypes = {
    progress: PropTypes.array.isRequired,
};

export default Status;

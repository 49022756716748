import React from "react";
import PropTypes from "prop-types";

import "./index.css";

const Status = ({ progress = [] }) => {
    return (
        <div className="sticky-top" style={{ top: "56px", zIndex: "1030" }}>
            <section class="multi_step_form">
                <form id="msform">
                    <ul id="progressbar">
                        {progress.length > 0 &&
                            progress.map((node, index) => (
                                <li
                                    key={index}
                                    className={
                                        node.tookAction === "Approved" ||
                                            node.tookAction === "Checked In" ||
                                            node.tookAction === "Checked Out"
                                            ? "active"
                                            : node.tookAction === "Rejected"
                                                ? "reject"
                                                : node.tookAction === "Expired"
                                                    ? "reject"
                                                    : node.tookAction === "Pending"
                                                        ? "pending"
                                                        : ""
                                    }
                                    style={{ width: `calc(100%/${progress.length})` }}
                                >
                                    {node.levelName}
                                </li>
                            ))}
                    </ul>
                </form>
            </section>
        </div>
    );
};

Status.propTypes = {
    progress: PropTypes.array.isRequired,
};

export default Status;

import {
    GET_ALL_CATEGORIES,
    GET_ALL_MENU_OPTIONS,
    GET_ALL_OUTLETS,
    GET_ALL_PRICE_UPDATES,
    GET_POS_ITEM_REQUEST_BY_ID
} from '../actions/types';

const initialState = {
    outlets: [],
    menuOptions: [],
    categories: [],
    price_updates: [],
    loading: true,
    pos_item: null,
    loading_item: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_OUTLETS:
            return {
                ...state,
                loading: false,
                outlets: action.payload,
            };

        case GET_ALL_MENU_OPTIONS:
            return {
                ...state,
                loading: false,
                menuOptions: action.payload,
            };

        case GET_ALL_CATEGORIES:
            return {
                ...state,
                loading: false,
                categories: action.payload,
            };

        case GET_ALL_PRICE_UPDATES:
            return {
                ...state,
                loading: false,
                price_updates: action.payload,
            };
        case GET_POS_ITEM_REQUEST_BY_ID:
            return {
                ...state,
                loading_item: false,
                pos_item: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;

const submitJson = {
    outletId: 2,
    dateRequired: '2020/2/23',
    posItem: [
        {
            menuOptionId: 1,
            description: 'Description',
            PLUNumber: null,
            categoryId: 2,
            newPrice: 400,
            oldPrice: 500,
            unitCost: 4,
            cost: 45,
            priceUpdatesId: 6,
            printers: [
                {
                    name: 'printer1',
                },
            ],
        },
    ],
    Attachments: [
        {
            name: '1.pdf',
            value: 'base64value',
        },
    ],
};

import React, { useEffect, useState } from "react";
import { getSystemAccessReportData } from "../../actions/systemAccess";
import DateRangeCalender from "../PettyCash/Shared/DateRangeCalender";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../layout/Spinner";
import SystemAccessRequestReportTemplate from "./Export/SystemAccessRequestReportTemplate";
var moment = require("moment");

const SystemAccessRequest = ({
    getSystemAccessReportData,
    systemAccess: { report, report_loading },
}) => {
    // Calculate three months prior from today
    var threeMonthsAgo = moment().subtract(3, "months");

    // State for Date submission
    const [formData, setFormData] = useState({
        from: threeMonthsAgo.format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
    });

    const { from, to } = formData;

    useEffect(() => {
        if (from !== "" && to !== "") {
            getSystemAccessReportData(from, to);
        }
    }, [formData]);

    // Defualt Material UI Package functions
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#dadee7",
            color: theme.palette.common.black,
            whiteSpace: "nowrap",
            paddingTop: "13px",
            paddingBottom: "13px",
        },
        body: {
            fontSize: 15,
            whiteSpace: "nowrap",
        },
    }))(TableCell);

    const useStyles1 = makeStyles((theme) => ({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }));

    // Defualt Material UI Package functions
    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    // @Style General
    const useStyles = makeStyles((theme) => ({
        table: {
            minWidth: "100%",
        },

        tr: {
            background: "#fff",
            "&:hover": {
                background: "rgba(51,51,51,0.2)",
            },
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: "30%",
            maxWidth: "100%",
        },
    }));

    // @Function for hadle pagination
    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            !report_loading && (
                <div className={classes.root}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="previous page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            )
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    //@State for pagination
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, report.length - page * rowsPerPage);

    // @Method HandleChangePage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // @Method Hadle RowsperPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="mt-3 mb-3">
            <DateRangeCalender setDate={setFormData} initialState={formData} />
            {!report_loading && (
                <div>                 
                    {report.length ? (
                        <SystemAccessRequestReportTemplate data={report} />
                    ) : (
                        ""
                    )}
                </div>
            )}
            <div className="grid-container">
                <TableContainer component={Paper}>
                    <Table
                        className={classes.table}
                        aria-label="customized pagination table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left">Requested By</StyledTableCell>
                                <StyledTableCell align="left">Employee Number</StyledTableCell>
                                <StyledTableCell align="left">Department</StyledTableCell>
                                <StyledTableCell align="left">Job Title</StyledTableCell>
                                <StyledTableCell align="left">Requested On</StyledTableCell>
                                <StyledTableCell align="left">Status</StyledTableCell>
                                <StyledTableCell align="left">Form Number</StyledTableCell>
                                <StyledTableCell align="left">
                                    System/Sub System
                                </StyledTableCell>
                                <StyledTableCell align="left">User Name</StyledTableCell>
                                <StyledTableCell align="left">Accepted On</StyledTableCell>
                                <StyledTableCell align="left">Completed On</StyledTableCell>
                                <StyledTableCell align="left">
                                    Time Taken To Complete
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {!report_loading ? (
                            <TableBody>
                                {report.length > 0
                                    ? (rowsPerPage > 0
                                        ? report.slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        : report
                                    ).map((request, index) => (
                                        <StyledTableRow
                                            key={index}
                                            tabIndex={-1}
                                            className={classes.tr}
                                        >
                                            <StyledTableCell align="left">
                                                {request.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.employeeNumber}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.department}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.jobTitle}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.createdDate &&
                                                    moment(new Date(request.createdDate)).format(
                                                        "YYYY-MM-DD"
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.status}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.formNumber}
                                            </StyledTableCell>

                                            <StyledTableCell align="left">
                                                {request.system}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.userName}
                                            </StyledTableCell>

                                            <StyledTableCell align="left">
                                                {request.acceptedDate &&
                                                    moment(new Date(request.acceptedDate)).format(
                                                        "YYYY-MM-DD"
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.completedDate &&
                                                    moment(new Date(request.completedDate)).format(
                                                        "YYYY-MM-DD"
                                                    )}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {request.timeTakenToComplete}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                    : ""}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        ) : (
                            <StyledTableRow className={classes.tr}>
                                <StyledTableCell align="center" colSpan={9}>
                                    <Spinner />
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    colSpan={6}
                                    count={report.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { "aria-label": "rows per page" },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

SystemAccessRequest.propTypes = {

    getSystemAccessReportData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    systemAccess: state.systemAccess,
});

export default connect(mapStateToProps, { getSystemAccessReportData })(
    SystemAccessRequest
);

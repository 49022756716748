import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formRequest } from '../../actions/requests';
import { withRouter } from 'react-router';
import { Spinner } from 'reactstrap';

const FormTest = ({
  forms: { form, form_loading },
  getTestJsonForm,
  formRequest,
}) => {
  useEffect(() => {
    // getTestJsonForm();
  }, []);

  // const [formData, setformData] = useState([]);
  const [formData, setformData] = useState([]);
  const [message, setMessage] = useState(false);

  const onChange = (index, tabIndex, sectionIndex, e) => {
    const values = form;
    values.tab[tabIndex].sections[sectionIndex].formFields[index].inputText =
      e.target.value;
    setformData(values);
  };

  const onSubmit = async e => {
    e.preventDefault();
    await formRequest(formData);
  };

  const onPopup = async e => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div class="col-lg-6 mb-4 mt-5 container-auth">
        <div class="card">
          <a href="#">
            <div
              class="card-img-top"
              style={{ backgroundColor: 'black', height: '5px' }}
            />
          </a>
          <div class="card-body">
            <h4 class="card-title">
              <a href="#">Project Two</a>
            </h4>
            <p class="card-text">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit
              aliquam aperiam nulla perferendis dolor nobis numquam, rem
              expedita, aliquid optio, alias illum eaque. Non magni, voluptates
              quae, necessitatibus unde temporibus.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

FormTest.propTypes = {
  forms: PropTypes.object.isRequired,
  formRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  forms: state.forms,
});

export default connect(mapStateToProps, { formRequest })(withRouter(FormTest));

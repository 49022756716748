import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import {
  getRequestById,
  getRequestForPaymentById,
  requestForPaymentRequestButtonClick,
} from "../../../actions/requests";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import UserInfo from "../../user/UserInfo";
import AdditionalComment from "../../Card/AdditionalComment";
import ReactToPrint from "react-to-print";
import FormPrint from "../../print/FormPrintComponent2";
import Spinner from "../../../components/layout/Spinner";
import CommentStaticForms from "../../Card/CommentStaticForms";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";

const RequestForPayment = ({
  getRequestForPaymentById,
  history,
  statusData,
  loading,
  requestForPaymentRequestButtonClick,
  requests: { request, request_loading },
  auth: { access, id, firstName, lastName },
  match,
}) => {


    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.formAccessLevels);

    useEffect(() => {
        if (formAccessLevels !== null) {
            getRequestForPaymentById(match.params.id, formAccessLevels["Request for Payment"]);
            loading(true);
        }
    }, [match.params.id, getRequestById, formAccessLevels]);

  useEffect(() => {
    if (!request_loading && request !== null) {
      statusData(request.requestProgress);
      loading(false, request.formNumber);
    }
  }, [request]);

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // Action Button onclick Loading
  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  // Form Data for submit additonal comments
  const [formData, setFormData] = useState({
    requestProcessLevelActionId: "",
    requestId: "",
    requestLevelId: "",
    actionId: "",
    Attachments: [],
  });

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
  });

  // Comment parse to json
  let jsonParseComment;

  // Check if comment available or not
  if (!request_loading && request !== null) {
    if (request.comments !== null) {
      jsonParseComment = JSON.parse(request.comments);
    }
  }

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
    // await setAccessLevel(access)
  };

  const componentRef = useRef(null);

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setOpen(!open);
    setActionButtonLoading(true);
    loading(true, request.formNumber);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await requestForPaymentRequestButtonClick(
      formData,
      jsonParseComment,
      access,
      id,
      history
    );
  };

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: firstName,
      [e.target.name]: e.target.value,
    });
  };

  const { comment } = addtionalComment;

  /**
   *
   * Additional comment popup prop
   *
   */
  const onAdditionalCommentPopup = () => {
    setOpen(!open);
  };

  return (
    <div>
    {!request_loading && request !== null && !actionButtonLoading ? (
        <Fragment>
          <form onSubmit={(e) => onButtonClick(e)}>
            <AdditionalComment
              openState={open}
              onClick={onAdditionalCommentPopup}
              button={buttonName}
              value={comment}
              name="comment"
              onChange={onChange}
              attachmnetDisplay
              setState={setFormData}
                          state={formData}
                          currentAttachments={{
                              data: request.attachments !== null && request.attachments,
                              property: "fileName",
                          }}
            />
          </form>

          <UserInfo
            firstName={request.createdUser.firstName}
            lastName={request.createdUser.lastName}
            email={request.createdUser.email}
            department={request.createdUser.department}
          />
          <div
            className="card-img-top"
            style={{
              backgroundColor: "#ffd100",
              height: "3px",
            }}
          />

          <div
            className="card card-body"
            style={{
              marginBottom: "30px",
            }}
          >
            <div className="card-body-form">
              <div className="row">
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Supplier Name
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={request.supplierName}
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Document Description
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={request.documentDescription}
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Currency
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={request["currency"]["name"]}
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Amount
                  </div>
                  <input
                    className="form-control"
                    disabled
                    placeholder={request.amount}
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Invoice No
                  </div>
                  <input
                    className="form-control"
                    disabled
                    placeholder={request.invoiceNo}
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Cost Center Division
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.costCenterDivision !== null
                        ? request.costCenterDivision.name
                        : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Cost Center Department
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.costCenterDepartment !== null
                        ? request.costCenterDepartment.name
                        : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    P&L Expense Category (Main)
                  </div>
                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.mainPLExpenseCategory !== null
                        ? request.mainPLExpenseCategory.name
                        : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    P&L Expense Category (Sub)
                  </div>
                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.subPLExpenseCategory !== null
                        ? request.subPLExpenseCategory.name
                        : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Type of Expense
                  </div>

                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.typeOfExpense !== null
                        ? request.typeOfExpense.name
                        : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  <div
                    style={{
                      color: "#333333",
                      fontWeight: "bold",
                      marginBottom: "10px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    GA Code
                  </div>
                  <input
                    className="form-control"
                    disabled
                    placeholder={
                      request.gaCode !== null ? request.gaCode.name : ""
                    }
                  />
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    padding: "20px",
                  }}
                >
                  {request.expenseLine !== null ? (
                    <>
                      <div
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Expense Line
                      </div>

                      {request.costCenterDivisionId === 6 &&
                      request.costCenterDepartmentId === 49 &&
                      request.typeOfExpenseId === 6 ? (
                        <input
                          className="form-control"
                          disabled
                          value={request.itExpenseLine}
                        />
                      ) : (
                        <input
                          className="form-control"
                          disabled
                          value={request.expenseLine.name}
                        />
                      )}
                    </>
                  ) : (
                    <div>
                      {request.costCenterDivisionId === 6 &&
                        request.costCenterDepartmentId === 49 &&
                        request.typeOfExpenseId === 6 && (
                          <>
                            <div
                              style={{
                                color: "#333333",
                                fontWeight: "bold",
                                marginBottom: "10px",
                                fontFamily: "Montserrat",
                              }}
                            >
                              IT Expense Line
                            </div>

                            <input
                              className="form-control"
                              disabled
                              value={request.itExpenseLine}
                            />
                          </>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="card-img-top"
            style={{
              backgroundColor: "#ffd100",
              height: "3px",
            }}
          />
          <div className="card card-body mb-4">
            <div
              className="col-md-12"
              style={{
                padding: "20px",
              }}
            >
              <div
                style={{
                  color: "#333333",
                  fontWeight: "bold",
                  marginBottom: "10px",
                  fontFamily: "Montserrat",
                }}
              >
                Remarks
              </div>

              <textarea
                className="form-control"
                disabled
                placeholder={request.remarks}
              />
            </div>
            <div
              className="row"
              style={{
                padding: "20px",
              }}
            >
              <div className="col-sm-6">
                <Link
                  className="attachment-link"
                  to="!#"
                  data-toggle="collapse"
                  data-target="#collapseAttachments"
                  aria-expanded="false"
                  aria-controls="collapseAttachments"
                >
                  <div className="row">
                    <div
                      className="col-sm-6"
                      style={{
                        color: "#333333",
                        fontWeight: "bold",

                        fontFamily: "Montserrat",
                      }}
                    >
                      {request.attachments !== null
                        ? request.attachments.length
                        : 0}{" "}
                      Attachments
                    </div>
                  </div>
                </Link>
              </div>
              {request.attachments !== null ? (
                <a
                  href={`/api/requestforpayment/download-attachments?requestId=${request.id}`}
                  target="_blank"
                  download
                  className="text-right col-sm-6"
                >
                  <i
                    className="fas fa-download"
                    style={{
                      color: "black",
                    }}
                  ></i>
                </a>
              ) : (
                ""
              )}
            </div>
            <div
              className="collapse"
              id="collapseAttachments"
              aria-labelledby="headingOne"
              data-parent="#sidenavAccordion"
            >
              <div
                style={{
                  height: 100,
                  overflowY: "scroll",
                }}
              >
                <ul className="list-group list-group-flush">
                  {request.attachments !== null &&
                    request.attachments.map((file, index) => (
                      <li className="list-group-item" key={index}>
                        <div className="col-sm-11">
                          <i className="fas fa-paperclip mr-3" />
                          {file.fileName}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <CommentStaticForms
            accessLevel={access}
            comments={request.comments}
            userId={id}
            requestId={request.id}
            userName={firstName + " " + lastName}
          />
          <div>
            <ReactToPrint
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <button className="btn btn-outline-info ml-3 mb-3">
                    <PrintIcon /> <b> Print This Out</b>
                  </button>
                );
              }}
              content={() => componentRef.current}
            />
          </div>
          <div style={{ display: "none" }}>
            <FormPrint
              ref={componentRef}
              request={request}
              name={firstName + " " + lastName}
            />
          </div>

          <div style={{ textAlign: "right" }} className="mr-3">
            {request.actions !== null
              ? request.actions.map((action, index) => (
                  <button
                    key={index}
                    style={{ width: "200px" }}
                    className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                    onClick={() => {
                      onAdditionalCommentPopup();
                      setButtonName(action.name);
                      onClick(
                        action.requestProcessLevelActionId,
                        action.requestLevelId,
                        action.requestId,
                        action.actionId
                      );
                    }}
                  >
                    <b>{action.name}</b>
                  </button>
                ))
              : ""}
          </div>
        </Fragment>
          ) : (
               "" 
          )}
    </div>
  );
};

RequestForPayment.propTypes = {
  auth: PropTypes.object.isRequired,
  requests: PropTypes.object.isRequired,
  statusData: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  getRequestForPaymentById: PropTypes.func.isRequired,
  requestForPaymentRequestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, {
  getRequestForPaymentById,
  requestForPaymentRequestButtonClick,
})(withRouter(RequestForPayment));

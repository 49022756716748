import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getReports, getReportsForNonReturnable } from "../../actions/reports";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import NonReturnableGatePassTemplate from "./Export/NonReturnableGatePassTemplate";
import Grid from "../PettyCash/Shared/Grid";
import DateRangeCalender from "../PettyCash/Shared/DateRangeCalender";
var moment = require("moment");

const NonReturnableGatePass = ({
  getReportsForNonReturnable,
  reports: { reports, reports_loading },
}) => {
  // Calculate three months prior from today
  var threeMonthsAgo = moment().subtract(3, "months");

  // State for Date submission
  const [formData, setFormData] = useState({
    from: threeMonthsAgo.format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  const { from, to } = formData;

  useEffect(() => {
    if (from !== "" && to !== "") {
      getReportsForNonReturnable(from, to);
    }
  }, [formData]);

  const headerCells = [
    {
      id: "formNo",
      numeric: false,
      disablePadding: false,
      name: "Form No",
      propertyValue: "formNo",
      nested: false,
      type: "number",
    },
    {
      id: "formStatus",
      numeric: false,
      disablePadding: false,
      name: "Form Status",
      propertyValue: "formStatus",
      nested: false,
      type: "text",
    },
    {
      id: "docName",
      numeric: false,
      disablePadding: false,
      name: "Docoument Name",
      propertyValue: "documentName",
      nested: false,
      type: "text",
    },
    {
      id: "createdUser",
      numeric: false,
      disablePadding: false,
      name: "Created By",
      propertyValue: "createdBy",
      nested: false,
      type: "text",
    },
    {
      id: "raisedDepartment",
      numeric: false,
      disablePadding: false,
      name: "Raised by Department",
      propertyValue: "raisedbyDepartment",
      nested: false,
      type: "text",
    },
    {
      id: "equipment",
      numeric: false,
      disablePadding: false,
      name: "Equipment",
      propertyValue: "equipment",
      nested: false,
      type: "text",
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      name: "Company",
      propertyValue: "company",
      nested: false,
      type: "text",
    },
    {
      id: "checkOutBy",
      numeric: false,
      disablePadding: false,
      name: "Check Out By",
      propertyValue: "checkOutby",
      nested: false,
      type: "text",
    },
    {
      id: "checkOutDate",
      numeric: false,
      disablePadding: false,
      name: "Check Out Date",
      propertyValue: "checkOutDate",
      nested: false,
      type: "date",
    },
  ];

  return (
    <div className="mt-3 mb-3">
      <div>
        <DateRangeCalender setDate={setFormData} initialState={formData} />
      </div>

      {!reports_loading ? (
        <div>
          {reports.length > 0 && (
            <NonReturnableGatePassTemplate data={reports} />
          )}

          <div>
            <Grid
              headers={headerCells}
              data={reports !== null ? reports : []}
            />
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

NonReturnableGatePass.propTypes = {
  getReportsForNonReturnable: PropTypes.func.isRequired,
  reports: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  reports: state.reports,
});

export default connect(mapStateToProps, { getReportsForNonReturnable })(
  NonReturnableGatePass
);

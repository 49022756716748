import React from "react";
import PropTypes from "prop-types";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import "./reimburseDetail.css";
import Grid from "../Shared/Grid";
import {
  getReimbursementById,
  reimburseRequestButtonClick,
} from "../../../actions/reimburse";
import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Spinner from "../../layout/Spinner";
import Status from "../../status/Status";
import AdditionalComment from "../../Card/AdditionalComment";
import CardSpinner from "../../layout/CardSpinner";
import { useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import UserInfo from "../../user/UserInfo";
import CommentStaticForms from "../../Card/CommentStaticForms";
import Print from "./Print";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import ReimburseTemplate from "../Reimburse/Export/ReimburseTemplate";

const headerCells = [
  {
    id: "formNumber",
    numeric: false,
    disablePadding: false,
    name: "Form Number",
    propertyValue: "formNumber",
    nested: false,
    nestedLevels: 2,
    type: "text",
  },
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: false,
    name: "Transaction Date",
    propertyValue: "settledOn",
    nested: false,
    type: "date",
  },
  {
    id: "paymentTitle",
    numeric: false,
    disablePadding: false,
    name: "Payment Title",
    propertyValue: "pyamentTitle",
    nested: false,
    type: "text",
  },
  {
    id: "requestedBy",
    numeric: false,
    disablePadding: false,
    name: "Requested By",
    propertyValue: "createdUser",
    nestedValue: ["firstName", "lastName"],
    nested: true,
    type: "text",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    name: "Description",
    propertyValue: "description",
    nested: false,
    type: "text",
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    name: "Amount (LKR)",
    propertyValue: "amount",
    nested: false,
    type: "numeric",
  },
  {
    id: "ccd",
    numeric: false,
    disablePadding: false,
    name: "Cost Center Department",
    propertyValue: "costCenterDepartment",
    nested: true,
    nestedValue: ["name"],
    type: "text",
  },
];

const ReimburseDetail = ({
  match,
  history,
  getReimbursementById,
  reimburseRequestButtonClick,
  reimburse: { reimburseItem, reimburse_loading },
}) => {
    
    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.pettyCashFormAccessLevels);

    useEffect(() => {
        if (formAccessLevels !== null) {
            getReimbursementById(match.params.id, formAccessLevels["Reimbursements"]);
        }
    }, [match.params.id, formAccessLevels]);

  // Get auth state from using use Selector
  const auth = useSelector((state) => state.auth);

  // Comment parse to json
  // const [jsonParseComment, setJsonParseComment] = useState([])
  let jsonParseComment;

  // Check if comment available or not
  if (!reimburse_loading) {
    if (reimburseItem.comments !== null) {
      jsonParseComment = JSON.parse(reimburseItem.comments);
    }
  }

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
  });

  const { comment } = addtionalComment;

  // Form Data for submit additonal comments
  const [formData, setFormData] = useState({
    formNumber: "",
    requestProcessLevelActionId: "",
    requestId: "",
    requestLevelId: "",
    actionId: "",
  });

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Action button click loading spinner
  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: auth.firstName,
      [e.target.name]: e.target.value,
    });
  };

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      formNumber: reimburseItem.id,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
    // await setAccessLevel(access)
  };

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setOpen(!open);
    setActionButtonLoading(true);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await reimburseRequestButtonClick(
      formData,
      jsonParseComment,
      auth.access,
      auth.id,
      history
    );
  };

  // Additional comment popup prop
  const onAdditionalCommentPopup = () => {
    setOpen(!open);
  };

  // Print componet ref
  const componentRef = useRef(null);

  return (
    <div>
      <div
        className="card w-100 sticky-top mb-3 detail-sticky"
        style={{
          maxHeight: "138px",
          zIndex: "1030",
        }}
      >
        {!reimburse_loading && !actionButtonLoading ? (
          <>
            <h5
              style={{
                color: "#333333",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              Reimbursement
            </h5>
            <div className="horizontal-container mt-1">
              <Status progress={reimburseItem.requestProgress} />
            </div>
          </>
        ) : (
          <CardSpinner />
        )}
      </div>
      {!reimburse_loading && !actionButtonLoading && (
        <>
          <form onSubmit={(e) => onButtonClick(e)}>
            <AdditionalComment
              openState={open}
              onClick={onAdditionalCommentPopup}
              button={buttonName}
              value={comment}
              name="comment"
              onChange={onChange}
            />
          </form>
          <main>
            <div className="container-fluid">
              <UserInfo
                firstName={reimburseItem.createdUser.firstName}
                lastName={reimburseItem.createdUser.lastName}
                email={reimburseItem.createdUser.email}
                department={
                  reimburseItem.createdUser.department !== null
                    ? reimburseItem.createdUser.department
                    : ""
                }
              />
              <>
                <div
                  className="card-body-form p-4 mb-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="card detail-card">
                    <div className="card-img-top card-img-top-detail" />
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                          <div className="detail-title">
                            <div className="mb-2">From</div>
                            <input
                              type="text"
                              value={moment(
                                new Date(reimburseItem.fromDate)
                              ).format("YYYY-MM-DD")}
                              className="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                          <div className="detail-title">
                            <div className="mb-2">To</div>
                            <input
                              type="text"
                              value={moment(
                                new Date(reimburseItem.toDate)
                              ).format("YYYY-MM-DD")}
                              className="form-control"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                          <div className="detail-title">
                            <div className="mb-2">Petty Cash</div>
                            <NumberFormat
                              style={{ backgroundColor: "#E9ECEF" }}
                              className="form-control"
                              disabled
                              value={reimburseItem.totalPettyCash}
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                          <div className="detail-title">
                            <div className="mb-2">Reimburse</div>
                            <NumberFormat
                              style={{ backgroundColor: "#E9ECEF" }}
                              className="form-control"
                              disabled
                              value={reimburseItem.totalReimburse}
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                        {/* <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                <div className="detail-title">
                                  <div className="mb-2">Accountant</div>
                                  <input
                                    type="text"
                                    value={
                                      reimburseItem.accountant !== null &&
                                      reimburseItem.accountant.firstName +
                                        ' ' +
                                        reimburseItem.accountant.lastName
                                    }
                                    className="form-control"
                                    disabled
                                  />
                                </div>
                              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card-body-form p-4 mb-4"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="row mb-3 px-4" style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flex: "50%",
                        justifyContent: "left",
                        verticalAlign: "middle",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <h4 className="mb-3" style={{ color: "gray" }}>
                        Petty Cashes
                      </h4>
                    </div>
                    <div style={{ textAlign: "right" }}>
                      {reimburseItem.pettyCashes !== null && (
                        <ReimburseTemplate data={reimburseItem.pettyCashes} />
                      )}
                    </div>
                  </div>
                  <div className="card detail-card">
                    <div className="card-img-top card-img-top-detail" />
                    <div className="card-body p-0 m-0">
                      <Grid
                        headers={headerCells}
                        data={
                          reimburseItem !== null
                            ? reimburseItem.pettyCashes
                            : []
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="card-img-top"
                  style={{
                    backgroundColor: "#ffd100",
                    height: "3px",
                  }}
                />
                <div className="card card-body mb-4">
                  <div className="row px-2">
                    <div className="col-sm-6">
                      <Link
                        className="attachment-link"
                        to="!#"
                        data-toggle="collapse"
                        data-target="#collapseAttachments"
                        aria-expanded="false"
                        aria-controls="collapseAttachments"
                      >
                        <div className="row">
                          <div
                            className="col-sm-6"
                            style={{
                              color: "#333333",
                              fontWeight: "bold",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {reimburseItem.reimbursementAttachments !== null
                              ? reimburseItem.reimbursementAttachments.length
                              : 0}{" "}
                            Attachments
                          </div>
                        </div>
                      </Link>
                    </div>
                    {reimburseItem.reimbursementAttachments !== null ? (
                      <a
                        href={`/api/Reimbursement/download-attachments?requestId=${reimburseItem.id}`}
                        target="_blank"
                        download
                        className="text-right col-sm-6"
                      >
                        <i
                          className="fas fa-download"
                          style={{
                            color: "black",
                          }}
                        ></i>
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="collapse"
                    id="collapseAttachments"
                    aria-labelledby="headingOne"
                    data-parent="#sidenavAccordion"
                  >
                    <div
                      style={{
                        height: 100,
                        overflowY: "scroll",
                      }}
                    >
                      <ul className="list-group list-group-flush">
                        {reimburseItem.reimbursementAttachments !== null &&
                          reimburseItem.reimbursementAttachments.map(
                            (file, index) => (
                              <li className="list-group-item" key={index}>
                                <div className="col-sm-11">
                                  <i className="fas fa-paperclip mr-3" />
                                  {file.fileName}
                                </div>
                              </li>
                            )
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
                <CommentStaticForms
                  accessLevel={auth.access}
                  comments={reimburseItem.comments}
                  userId={auth.id}
                  requestId={reimburseItem.id}
                  userName={auth.firstName + " " + auth.lastName}
                />
                <div style={{ display: "none" }}>
                  <Print
                    ref={componentRef}
                    requestData={reimburseItem}
                    firstName={auth.firstName}
                    lastName={auth.lastName}
                  />
                </div>
                <div style={{ float: "left" }}>
                  <ReactToPrint
                    trigger={() => {
                      // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                      // to the root node of the returned component as it will be overwritten.
                      return (
                        <button className="btn btn-outline-info ml-3 mb-3 w-100">
                          <PrintIcon /> <b> Print This Out</b>
                        </button>
                      );
                    }}
                    content={() => componentRef.current}
                  />
                </div>
                <div style={{ textAlign: "right", marginRight: "15px" }}>
                  {reimburseItem.actions !== null
                    ? reimburseItem.actions.map((action, index) => (
                        <button
                          key={index}
                          style={{ width: "200px" }}
                          className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                          onClick={() => {
                            onAdditionalCommentPopup();
                            setButtonName(action.name);
                            onClick(
                              action.requestProcessLevelActionId,
                              action.requestLevelId,
                              action.requestId,
                              action.actionId
                            );
                          }}
                        >
                          <b>{action.name}</b>
                        </button>
                      ))
                    : ""}
                </div>
              </>
            </div>
          </main>
        </>
      )}
    </div>
  );
};

ReimburseDetail.propTypes = {
  getReimbursementById: PropTypes.func.isRequired,
  reimburseRequestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  reimburse: state.reimburse,
});

export default connect(mapStateToProps, {
  getReimbursementById,
  reimburseRequestButtonClick,
})(withRouter(ReimburseDetail));

import React, { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router";

import { Multiselect } from "multiselect-react-dropdown";

import { getRequests } from "../../actions/requests";
import Spinner from "../layout/Spinner";

// Material ui imports
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";

const AdvancedSearch = ({
  getRequests,
  auth: { isAuthenticated, id, loading },
  requests: { requests, requests_loading },
  history,
}) => {
  //const multiselectRef = React.createRef();

  useEffect(() => {
    if (!loading) {
      getRequests(id);
    }
  }, [getRequests]);

  const [requestData, setRequestData] = useState([]);

  useEffect(() => {
    if (requests !== null) {
      setRequestData(requests);
    }
  }, [requests]);

  const [fields, setFields] = useState([
    { id: 1, name: "Form Number" },
    { id: 2, name: "Created User" },
    { id: 3, name: "Date" },
  ]);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(new Array(fields.length).fill(false));
  }, []);

  const [selectedFields, setSelectedFields] = useState([]);

  const [selectValue, setSelectValue] = useState(null);

  const onSelectValue = (e) => {
    if (
      e.target.value !== undefined &&
      e.target.value !== null &&
      e.target.value !== ""
    ) {
      let val = fields.filter((x) => x.id == e.target.value); // find the particular object that has id == e.target.value in fields array

      setSelectedFields([
        ...selectedFields,
        { id: val[0].id, name: val[0].name },
      ]);

      let indexOption = fields.findIndex((obj) => obj.id == e.target.value); // find the index of the particular object that has id == e.target.value in fields array

      const updatedOptionAccess = options.map((item, index) =>
        index === indexOption ? !item : item
      );

      setOptions(updatedOptionAccess);
      setSelectValue("");
    }
  };

  const handleRemoveFilter = (id, name) => {
    let val = selectedFields.filter((x) => x.id != id);

    setSelectedFields(val);

    let indexOption1 = fields.findIndex((obj) => obj.id == id);

    const updatedOptionAccess1 = options.map((item, index) =>
      index === indexOption1 ? item : !item
    );

    setOptions(updatedOptionAccess1);
  };

  const handleClearFilters = () => {
    setSelectedFields([]);
    setSelectValue("");
    //multiselectRef.current.resetSelectedValues();
    //console.log(selectedFields);
    //console.log(options);
  };

  const handleSearch = () => {
    //console.log(selectedFields);
  };

  // function onSelect(selectedList, selectedItem) {
  //   console.log(selectedList);
  //   setSelectedFields([
  //     ...selectedFields,
  //     { id: selectedItem.id, name: selectedItem.name },
  //   ]);
  // }

  // function onRemove(selectedList, removedItem) {
  //   //setSelectedFields(selectedList);

  //   setSelectedFields(
  //     selectedFields.filter((item) => item.id !== removedItem.id)
  //   );
  // }

  // @Method onClick for redirect to form id
  const onClick = (id, status, requestStatus, open) => {
    if (status === "Request For Payment" && requestStatus === "Draft") {
      if (open === "current") {
        history.push(
          `/user-dashboard/request/static/request-for-payment/edit/${id}`
        );
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/request/static/request-for-payment/edit/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "Request For Payment") {
      if (open === "current") {
        history.push(
          `/user-dashboard/request/static/request-for-payment/view/${id}`
        );
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/request/static/request-for-payment/view/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "IOU") {
      if (open === "current") {
        history.push(`/user-dashboard/request/pettycash/request-iou/${id}`);
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/request/pettycash/request-iou/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "Petty Cash") {
      if (open === "current") {
        history.push(
          `/user-dashboard/request/pettycash/request-pettycash/${id}`
        );
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/request/pettycash/request-pettycash/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "Petty Cash Reimbursement") {
      if (open === "current") {
        history.push(`/user-dashboard/petty-cash/reimburse/${id}`);
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/petty-cash/reimburse/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "System Access Request") {
      if (open === "current") {
        history.push(`/user-dashboard/system-access-request-detail/${id}`);
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/system-access-request-detail/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "POS Item Request") {
      if (open === "current") {
        history.push(`/user-dashboard/pos-request-detail/${id}`);
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/pos-request-detail/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else if (status === "Temporary Credit Request") {
      if (open === "current") {
        history.push(`/user-dashboard/temporary-credit-request-detail/${id}`);
      } else if (open === "newTab") {
        var win = window.open(
          `/user-dashboard/temporary-credit-request-detail/${id}`,
          "_blank"
        );
        win.focus();
      }
    } else {
      history.push(`/user-dashboard/request/${id}`);
    }
  };

  // Defualt Material UI Package functions
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#dadee7",
      color: theme.palette.common.black,

      paddingTop: "13px",
      paddingBottom: "13px",
    },
    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  // Defualt Material UI Package functions
  const StyledTableRow = withStyles((theme) => ({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  // @Style General
  const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: "100%",
    },

    tr: {
      background: "#fff",
      "&:hover": {
        background: "rgba(51,51,51,0.2)",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: "30%",
      maxWidth: "100%",
    },
  }));

  // @Function for hadle pagination
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      !loading && (
        <div className={classes.root}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </IconButton>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </IconButton>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
          >
            {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </div>
      )
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  //@State for pagination
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, requestData.length - page * rowsPerPage);

  // @Method HandleChangePage
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // @Method Hadle RowsperPage
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // if (!isAuthenticated) {
  //   return <Redirect to="/" />;
  // }

  return (
    <main className="mt-5 px-4">
      <div className="row">
        <div
          className="col-sm-2 mt-2"
          style={{
            fontSize: "14px",
            fontFamily: "Segoe UI",
            fontWeight: "600",
          }}
        >
          Request Type
        </div>
        <div className="col-sm-10">
          <select className="form-control">
            <option value="">Select</option>
            <option value="">IOU</option>
          </select>
        </div>
      </div>
      <div className="row mt-2">
        <div
          className="col-sm-2 mt-2"
          style={{
            fontSize: "14px",
            fontFamily: "Segoe UI",
            fontWeight: "600",
          }}
        >
          Field
        </div>
        <div className="col-sm-10">
          {/* <Multiselect
            className="form-control m-0 mt-1 mb-2"
            options={fields !== null ? fields : []}
            displayValue="name"
            onSelect={onSelect}
            onRemove={onRemove}
            ref={multiselectRef}
          /> */}
          <select
            className="form-control"
            name="selectValue"
            value={selectValue}
            onChange={(e) => onSelectValue(e)}
            //required
          >
            <option value="">Select</option>
            {fields.length > 0 &&
              fields.map((option, index) => (
                <option value={option.id} disabled={options[index]}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div
        className="dropdown-divider"
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        {" "}
      </div>

      {selectedFields.length > 0 &&
        selectedFields.map((type, index) => (
          <>
            <div
              className="row mt-2"
              key={index}
              style={{
                fontSize: "14px",
                fontFamily: "Segoe UI",
                fontWeight: "500",
              }}
            >
              <div className="col-sm-2 mt-2">{type.name}</div>
              <div className="col-sm-2">
                <select className="form-control mt-1">
                  <option value="">equals</option>
                  <option value="">contains</option>
                </select>
              </div>

              <div className="col-sm-7 mt-1">
                <input type="text" className="form-control" />
              </div>
              <div className="col-sm-1 mt-2 ml-0">
                <svg
                  onClick={() => handleRemoveFilter(type.id, type.name)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  class="bi bi-x-circle"
                  viewBox="0 0 16 16"
                  cursor="pointer"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
            </div>

            <div
              className="row mt-3"
              style={{ fontSize: "14px" }} //, width: "auto", textAlign: "center"
            >
              {index === selectedFields.length - 1 ? (
                ""
              ) : (
                <div className="col-sm-12">
                  <select
                    className="form-control"
                    style={{ width: "74px" }} //margin: "0 auto",
                  >
                    <option value="">and</option>
                    <option value="">or</option>
                  </select>
                </div>
              )}
            </div>
          </>
        ))}

      <div className="mt-2" style={{ textAlign: "right" }}>
        <button
          style={{ width: "200px" }}
          className="btn btn-primary ml-3 mb-4 col-md-2"
          type="button"
          onClick={handleSearch}
        >
          Search
        </button>
        <button
          style={{ width: "200px" }}
          className="btn btn-danger ml-3 mb-4 col-md-2"
          type="button"
          onClick={handleClearFilters}
        >
          Clear Filters
        </button>
      </div>

      {/* {!requests_loading ? (
        <div className="py-3">
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              aria-label="customized pagination table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">Form Number</StyledTableCell>
                  <StyledTableCell align="left">Created Date</StyledTableCell>
                  <StyledTableCell align="left">Created User</StyledTableCell>
                  <StyledTableCell align="left">Request Type</StyledTableCell>
                  <StyledTableCell align="left">Status</StyledTableCell>
                  <StyledTableCell align="left"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestData.length > 0
                  ? (rowsPerPage > 0
                      ? requestData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : requestData
                    ).map((request, id) => (
                      <StyledTableRow
                        key={id}
                        tabIndex={-1}
                        className={classes.tr}
                      >
                        <StyledTableCell
                          component="th"
                          scope="row"
                          onClick={() =>
                            onClick(
                              request.id,
                              request.name,
                              request.status,
                              "current"
                            )
                          }
                        >
                          {request.formNumber}
                        </StyledTableCell>

                        <StyledTableCell
                          align="left"
                          onClick={() =>
                            onClick(
                              request.id,
                              request.name,
                              request.status,
                              "current"
                            )
                          }
                        >
                          {request.createdOn}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={() =>
                            onClick(
                              request.id,
                              request.name,
                              request.status,
                              "current"
                            )
                          }
                        >
                          {request.createdBy}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={() =>
                            onClick(
                              request.id,
                              request.name,
                              request.status,
                              "current"
                            )
                          }
                        >
                          {request.name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="left"
                          onClick={() =>
                            onClick(
                              request.id,
                              request.name,
                              request.status,
                              "current"
                            )
                          }
                        >
                          {request.status}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <svg
                            onClick={() =>
                              onClick(
                                request.id,
                                request.name,
                                request.status,
                                "newTab"
                              )
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="blue"
                            class="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                            cursor="pointer"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                            />
                          </svg>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : ""}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    colSpan={5}
                    count={requestData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <Spinner />
      )} */}
    </main>
  );
};

AdvancedSearch.propTypes = {
  auth: PropTypes.object.isRequired,
  getRequests: PropTypes.func.isRequired,
  setRequestLoading: PropTypes.func.isRequired,
  requests: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,

  requests: state.requests,
});

export default connect(mapStateToProps, {
  getRequests,
})(withRouter(AdvancedSearch));

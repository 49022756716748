const accesslevelList = {};

const accesslevelListPettyCash = {};

export const setAccessLevels = (data) => {
  data.forEach((i) => {
    accesslevelList[i.name] = i.accessLevelId;
  });
};

export const getAccessLevel = (formName) => {
  
  return accesslevelList[formName];
};

export const setAccessLevelsPettyCash = (data) => {
    data.forEach((i) => {
        accesslevelListPettyCash[i.name] = i.accessLevelId;
    });
};

export const getAccessLevelPettyCash = (formName) => {
   
    return accesslevelListPettyCash[formName];
};

import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline } from '@material-ui/core';
import './index.css';

const Balance = ({ header, value, div_css, h2_css }) => {
  return (
    <div className="p-2">
      <div className={div_css}>{header}</div>
      <h2 className={h2_css}>
        <span>LKR </span>
        {value}
      </h2>
    </div>
  );
};

Balance.propTypes = {
  header: PropTypes.string.isRequired,
  div_css: PropTypes.string.isRequired,
  h2_css: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default Balance;

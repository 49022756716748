import axios from 'axios';
import store from '../store';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import { setFormLoading } from './forms';

import {
    GET_REQUEST,
    GET_REQUESTS,
    REQUEST_LOADING,
    CLEAR_REQUESTS,
    GET_ALL_REQUESTS,
    SUBMIT_REQUEST_FOR_PAYMENT_ERROR,
} from './types';
import { getAccessLevel } from "../actions/shared/AccessLevelsHashMap";

// Submit a form request
export const formRequest = (formData, userId, history) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post('/api/Request', formData.values, config);

        if (res.data[0].value[0] === 'true') {
            history.push('/user-dashboard');
            dispatch(getRequests(userId));
            dispatch(setAlert('Respond has been recorded', 'success'));
        }
    } catch (err) {
        console.log(err);

        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

/**
 *
 * @param {FormDataEntryValue} formData
 * @param {UserVerificationRequirement} userId
 * @param {reirect into dashboard} history
 * @returns
 *
 */
// Save form Item
export const saveRequestForPaymentForm =
    (formData, userId, history) => async dispatch => {
        // Set Form loading before save
        store.dispatch(setFormLoading());

        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            await axios.post('/api/requestforpayment/save', formData, config);

            history.push('/user-dashboard');
            dispatch(getRequests(userId));
            dispatch(setAlert('Respond has been Saved', 'success'));
        } catch (err) {
            dispatch(setAlert('Something Went Wrong!', 'error'));
            dispatch({
                type: SUBMIT_REQUEST_FOR_PAYMENT_ERROR,
            });
            console.log(err);
        }
    };

// Submit Form
export const submitRequestForPaymentForm =
    (formData, userId, history) => async dispatch => {
        // Set Form loading before save
        store.dispatch(setFormLoading());

        // Set Auth token as bearer
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            await axios.post('/api/requestforpayment/submit', formData, config);

            history.push('/user-dashboard');
            dispatch(getRequests(userId));
            dispatch(setAlert('Respond has been Recorded', 'success'));
        } catch (err) {
            dispatch(setAlert('Something Went Wrong!', 'error'));
            dispatch({
                type: SUBMIT_REQUEST_FOR_PAYMENT_ERROR,
            });
            console.log(err);
        }
    };

// -------------------------------------------------------------------------

// Get request by user ID
export const getRequests = userId => async dispatch => {
    // Clear previous requests
    dispatch({ type: CLEAR_REQUESTS });

    // Loading Spinner
    dispatch({ type: REQUEST_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

  try {
      const res = await axios.get(
          `/api/dashboard/get-pending?userid=${userId}`
      );

      ///api/dashboard / get - alldata - by - userid ? userid = ${ userId }

    dispatch({
      type: GET_REQUESTS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);

        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

// Get all request for HOD
export const getAllRequests = () => async dispatch => {
    // Clear previous requests
    dispatch({ type: CLEAR_REQUESTS });

    // Loading Spinner
    dispatch({ type: REQUEST_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/api/Request/all-request`);

        dispatch({
            type: GET_ALL_REQUESTS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Get Request detail view for one request
export const getRequestById = id => async dispatch => {
    // Request Loading
    dispatch(setRequestLoading());

    dispatch(clearRequests());

    // If Authenticated set token as default
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {
        const res = await axios.get(`/api/Request/get-by-id?id=${id}`);
       
        dispatch({
            type: GET_REQUEST,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

// Get Request detail view for one request
export const getRequestForPaymentById = (id, accesslevelId) => async dispatch => {

    // Request Loading
    dispatch(setRequestLoading());

    dispatch(clearRequests());

    // If Authenticated set token as default
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    try {

        const res = await axios.get(`/api/requestforpayment/get-request?Id=${id}&accessLevelId=${accesslevelId}`);
    
        dispatch({
            type: GET_REQUEST,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

// Button click action
export const requesterRequestMore =
    (formData, attachments, userId, requestId, history) => async (dispatch) => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                "Content-Type": "application/json",
            };

            const strigifyFormData = JSON.stringify(formData);

            const body = {
                requestId: requestId,
                Comment: strigifyFormData,
                AttachmentDataDTO: attachments,
            };
           
            const res = await axios.post(
                "/api/Request/submit-reqcomment",
                body,
                config
            );
            if (res.status == 200) {
                history.push("/user-dashboard");
                dispatch(setAlert("Respond has been Recorded", "success"));
            }
        } catch (err) {
            console.log(err);
            dispatch(setAlert("Something Went Wrong", "error"));
        }
    };

// ---------------- Static Form -----------------------
// Button click action
export const requestForPaymentRequestButtonClick =
    (formData, additionalComment, access, userId, history) =>
        async (dispatch) => {
            if (localStorage.token) {
                setAuthToken(localStorage.token);
            }

            try {
                const config = {
                    "Content-Type": "application/json",
                };

                const stringyfyAdditionalComment = JSON.stringify(additionalComment);

                const body = {
                    requestProcessLevelActionId: formData.requestProcessLevelActionId,
                    requestId: formData.requestId,
                    requestLevelId: formData.requestLevelId,
                    actionId: formData.actionId,
                    additionalComment: stringyfyAdditionalComment,
                    AttachmentDataDTO: formData.Attachments,
                };

                const res = await axios.post(
                    "/api/requestforpayment/submit-action",
                    body,
                    config
                );

                if (res.status == 200) {
                    history.push("/user-dashboard");
                    dispatch(setAlert("Respond has been Recorded", "success"));
                }
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something Went Wrong", "error"));
            }
        };

// Set loading state
export const setRequestLoading = () => {
    return {
        type: REQUEST_LOADING,
    };
};

// Button click action
export const requestButtonClick =
    (formData, additionalComment, access, userId, history) =>
        async (dispatch) => {
            if (localStorage.token) {
                setAuthToken(localStorage.token);
            }

            try {
                const config = {
                    "Content-Type": "application/json",
                };

                const stringyfyAdditionalComment = JSON.stringify(additionalComment);

                const body = {
                    requestProcessLevelActionId: formData.requestProcessLevelActionId,
                    requestId: formData.requestId,
                    actionId: formData.actionId,
                    requestLevelId: formData.requestLevelId,
                    additionalComment: stringyfyAdditionalComment,
                    AttachmentDataDTO: formData.Attachments,
                };

                
                const res = await axios.post("/api/Request/user-action", body, config);

                if (res.status == 200) {
                    history.push("/user-dashboard");
                    dispatch(setAlert("Respond has been Recorded", "success"));
                }
            } catch (err) {
                console.log(err);
                dispatch(setAlert("Something Went Wrong", "error"));
            }
        };

// Clear requests
export const clearRequests = () => {
    return {
        type: CLEAR_REQUESTS,
    };
};

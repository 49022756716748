import React, { Component } from 'react';
import ReactExport from 'react-data-export';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const column_data = [
  { title: 'Form No', width: { wpx: 80 }, style: { font: { bold: true } } },
  { title: 'Form Status', width: { wch: 25 }, style: { font: { bold: true } } },
  {
    title: 'Dcoument Name',
    width: { wch: 25 },
    style: { font: { bold: true } },
  },
  { title: 'Created By', width: { wch: 25 }, style: { font: { bold: true } } },
  {
    title: 'Raised by Department',
    width: { wch: 25 },
    style: { font: { bold: true } },
  },
  { title: 'Equipment', width: { wch: 25 }, style: { font: { bold: true } } },
  { title: 'Company', width: { wch: 25 }, style: { font: { bold: true } } },
  {
    title: 'Check Out Date',
    width: { wch: 25 },
    style: { font: { bold: true } },
  },
  {
    title: 'Check Out by',
    width: { wch: 25 },
    style: { font: { bold: true } },
  },
];
var excel_data;
const font_style = { font: { sz: '11' } };
class NonReturnablegatePassTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiDataSet: [
        {
          columns: [],
          data: [],
        },
      ],
    };

    excel_data = this.state.multiDataSet;
  }

  componentDidMount() {
    var data = [];

    excel_data[0].columns = column_data;

    var data_set = [];
    this.props.data.map(item => {
      data_set.push([
        {
          value:
            item.formNo !== '' && item.formNo !== null ? item.formNo : '--',
          style: font_style,
        },
        {
          value:
            item.formStatus !== '' && item.formStatus !== null
              ? item.formStatus
              : '--',
          style: {
            font: { sz: '11' },
            fill:
              item.formStatus == 'Completed'
                ? { patternType: 'solid', fgColor: { rgb: 'FFFFFF00' } }
                : null,
          },
        },
        {
          value:
            item.dcoumentName !== '' && item.dcoumentName !== null
              ? item.dcoumentName
              : '--',
          style: font_style,
        },
        {
          value:
            item.createdBy !== '' && item.createdBy !== null
              ? item.createdBy
              : '--',
          style: font_style,
        },
        {
          value:
            item.raisedbyDepartment !== '' && item.raisedbyDepartment !== null
              ? item.raisedbyDepartment
              : '--',
          style: font_style,
        },
        {
          value:
            item.equipment !== '' && item.equipment !== null
              ? item.equipment
              : '--',
          style: font_style,
        },
        {
          value:
            item.company !== '' && item.company !== null ? item.company : '--',
          style: font_style,
        },
        {
          value:
            item.checkOutDate !== '' && item.checkOutDate !== null
              ? item.checkOutDate
              : '--',
          style: font_style,
        },
        {
          value:
            item.checkOutby !== '' && item.checkOutby !== null
              ? item.checkOutby
              : '--',
          style: font_style,
        },
      ]);
    });
    excel_data[0].data = data_set;
  }

  render() {
    return (
      <div>
        <ExcelFile
          filename="Non Returnable Gate Pass"
          element={
            
              <button
                className="btn btn-info mb-3 mt-3"
                style={{ left: '100px' }}
              >
                <DescriptionOutlinedIcon />
                <a style={{ margin: 10, cursor: 'pointer' }}>Export</a>
              </button>
            
          }
        >
          <ExcelSheet
            dataSet={this.state.multiDataSet}
            name="Non Returnable Gate Pass"
          />
        </ExcelFile>
      </div>
    );
  }
}

export default NonReturnablegatePassTemplate;

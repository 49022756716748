import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { fetchIOUData } from "../../../../actions/IOU";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Status from "../../../Shared/Status";
import CardSpinner from "../../../layout/CardSpinner";
import UserInfo from "../../../user/UserInfo";
import AdditionalComment from "../../../Card/AdditionalComment";
import IOUPrint from "./IOUPrint";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { iouRequestButtonClick } from "../../../../actions/IOU";
import CommentStaticForms from "../../../Card/CommentStaticForms";

const IOUDetailView = ({
  fetchIOUData,
  IOU: { IOU, loading },
  match,
  iouRequestButtonClick,
  history,
  auth: { access, id, firstName, lastName },
}) => {
    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.formAccessLevels);

    useEffect(() => {
        if (formAccessLevels !==null) {
            fetchIOUData(match.params.id, formAccessLevels["IOU Request"]);
        }
       
    }, [match.params.id, formAccessLevels]);

  // Comment parse to json
  // const [jsonParseComment, setJsonParseComment] = useState([])
  let jsonParseComment;

  // Check if comment available or not
  if (!loading) {
    if (IOU.comments !== null) {
      jsonParseComment = JSON.parse(IOU.comments);
    }
  }

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
  });

  const { comment } = addtionalComment;

    // Form Data for submit additonal comments
    const [formData, setFormData] = useState({
        formNumber: '',
        requestProcessLevelActionId: '',
        requestId: '',
        requestLevelId: '',
        actionId: '',
        Attachments: [],

    });

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: firstName,
      [e.target.name]: e.target.value,
    });
  };

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      formNumber: IOU.formNumber,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
    // await setAccessLevel(access)
  };

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setOpen(!open);
    setActionButtonLoading(true);

    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await iouRequestButtonClick(
      formData,
      jsonParseComment,
      access,
      id,
      history
    );
  };

  /**
   * Additional comment popup prop
   */
  const onAdditionalCommentPopup = () => {
    setOpen(!open);
  };

  // Print componet ref
  const componentRef = useRef(null);

  // Action Button onclick Loading
  const [actionButtonLoading, setActionButtonLoading] = useState(false);

  return (
    <div>
      <div
        className="card w-100 sticky-top mb-3 detail-sticky"
        style={{
          maxHeight: "140px",
          zIndex: "1030",
        }}
      >
        {!loading && !actionButtonLoading ? (
          <>
            <h5
              style={{
                color: "#333333",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginLeft: "15px",
              }}
            >
              {IOU.formNumber} IOU Request
            </h5>
            <div className="horizontal-container mt-1">
              <Status progress={IOU.requestProgress} />
            </div>
          </>
        ) : (
          <CardSpinner />
        )}
      </div>
      <main>
        {!loading && !actionButtonLoading && (
          <Fragment>
            <div className="container-fluid">
              <form onSubmit={(e) => onButtonClick(e)}>
                              <AdditionalComment
                                  openState={open}
                                  onClick={onAdditionalCommentPopup}
                                  button={buttonName}
                                  value={comment}
                                  name="comment"
                                  onChange={onChange}
                                  attachmnetDisplay
                                  setState={setFormData}
                                  state={formData}
                                  currentAttachments={{
                                      data: IOU.attachments !== null && IOU.attachments,
                                      property: "fileName",
                                  }}
                              />
              </form>
              <UserInfo
                firstName={IOU.createdUser.firstName}
                lastName={IOU.createdUser.lastName}
                email={IOU.createdUser.email}
                department={
                  IOU.createdUser.department !== null &&
                  IOU.createdUser.department
                }
              />
              <div
                className="card-body-form p-4 mb-4"
                style={{ backgroundColor: "white" }}
              >
                <div
                  className="card"
                  style={{
                    borderRadius: 0,
                    border: "2px solid rgba(233, 236, 239, 1)",
                    borderTop: 0,
                  }}
                >
                  <div
                    className="card-img-top"
                    style={{
                      backgroundColor: "#ffd100",
                      height: "3px",
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  />
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                        <div
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <div className="mb-2">Form Number</div>
                          <input
                            type="text"
                            value={IOU.formNumber}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                        <div
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <div className="mb-2">Payment Title</div>
                          <input
                            type="text"
                            value={IOU.paymentTitle}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                        <div
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <div className="mb-2">Requesting Date</div>
                          <input
                            type="text"
                            value={new Date(
                              IOU.requestingDate
                            ).toLocaleDateString()}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                        <div
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <div className="mb-2">Amount (LKR)</div>
                          <input
                            type="text"
                            value={IOU.amount}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                        <div
                          style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "15px",
                            marginBottom: "10px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          <div className="mb-2">Description</div>
                          <textarea
                            rows={6}
                            type="text"
                            value={IOU.description}
                            className="form-control"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-body-form p-4 mb-4"
                style={{ backgroundColor: "white" }}
              >
                <div className="card">
                  <div
                    className="card-img-top"
                    style={{
                      backgroundColor: "#ffd100",
                      height: "3px",
                    }}
                  />
                  <div className="card card-body mb-4">
                    <div
                      className="col-md-12"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        Remarks
                      </div>
                      <textarea
                        className="form-control"
                        rows={6}
                        disabled
                        placeholder={IOU.remarks}
                      />
                    </div>
                    <div
                      className="row"
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div className="col-sm-6">
                        <Link
                          className="attachment-link"
                          to="!#"
                          data-toggle="collapse"
                          data-target="#collapseAttachments"
                          aria-expanded="false"
                          aria-controls="collapseAttachments"
                        >
                          <div className="row">
                            <div
                              className="col-sm-6"
                              style={{
                                color: "#333333",
                                fontWeight: "bold",
                                fontFamily: "Montserrat",
                              }}
                            >
                              {IOU.attachments !== null
                                ? IOU.attachments.length
                                : 0}{" "}
                              Attachments
                            </div>
                          </div>
                        </Link>
                      </div>
                      {IOU.attachments !== null ? (
                        <a
                          href={`/api/IOU/download-attachments?requestId=${IOU.id}`}
                          target="_blank"
                          download
                          className="text-right col-sm-6"
                        >
                          <i
                            className="fas fa-download"
                            style={{
                              color: "black",
                            }}
                          ></i>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className="collapse"
                      id="collapseAttachments"
                      aria-labelledby="headingOne"
                      data-parent="#sidenavAccordion"
                    >
                      <div
                        style={{
                          height: 100,
                          overflowY: "scroll",
                        }}
                      >
                        <ul className="list-group list-group-flush">
                          {IOU.attachments !== null &&
                            IOU.attachments.map((file, index) => (
                              <li className="list-group-item" key={index}>
                                <div className="col-sm-11">
                                  <i className="fas fa-paperclip mr-3" />
                                  {file.fileName}
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CommentStaticForms
                accessLevel={access}
                comments={IOU.comments}
                userId={id}
                requestId={IOU.id}
                userName={firstName + " " + lastName}
              />
            </div>
            <div style={{ display: "none" }}>
              <IOUPrint
                ref={componentRef}
                requestData={IOU}
                firstName={firstName}
                lastName={lastName}
              />
            </div>
            <div>
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return (
                    <button className="btn btn-outline-info ml-3 mb-3">
                      <PrintIcon /> <b> Print This Out</b>
                    </button>
                  );
                }}
                content={() => componentRef.current}
              />
            </div>
            <div style={{ textAlign: "right" }} className="mr-3">
              {IOU.actions !== null
                ? IOU.actions.map((action, index) => (
                    <button
                      key={index}
                      style={{ width: "200px" }}
                      className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                      onClick={() => {
                        onAdditionalCommentPopup();
                        setButtonName(action.name);
                        onClick(
                          action.requestProcessLevelActionId,
                          action.requestLevelId,
                          action.requestId,
                          action.actionId
                        );
                      }}
                    >
                      <b>{action.name}</b>
                    </button>
                  ))
                : ""}
            </div>
          </Fragment>
        )}
      </main>
    </div>
  );
};

IOUDetailView.propTypes = {
  IOU: PropTypes.object.isRequired,
  fetchIOUData: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  iouRequestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  IOU: state.IOU,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  fetchIOUData,
  iouRequestButtonClick,
})(IOUDetailView);

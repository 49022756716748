import {
    GET_SETTLEMENT_LOG,
    GET_PENDING_SETTLEMENT_IOU,
    GET_PENDING_SETTLEMENT_PETTYCASH,
    GET_REMAINING_CASHBOX,
    SETTLEMENT_LOADING,
    SETTLEMENT_IOU_LOADING,
    SETTLEMENT_PETTYCASH_LOADING,
    SETTLEMENT_CASHBOX_LOADING,
    CLEAR_SETTLEMENT_IOU,
    CLEAR_SETTLEMENT_PETTY_CASH,
} from '../actions/types';

const intialState = {
    data: [],
    iou: [],
    pettyCash: [],
    remainingCashBox: null,
    loading_iou: true,
    loading_pettyCash: true,
    loading_cashBox: true,
    loading: true,
};

export default function (state = intialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SETTLEMENT_LOG:
            return {
                ...state,
                data: payload,
                loading: false,
            };

        case GET_PENDING_SETTLEMENT_IOU:
            return {
                ...state,
                loading_iou: false,
                iou: action.payload,
            };

        case GET_PENDING_SETTLEMENT_PETTYCASH:
            return {
                ...state,
                loading_pettyCash: false,
                pettyCash: action.payload,
            };

        case GET_REMAINING_CASHBOX:
            return {
                ...state,
                loading_cashBox: false,
                loading: false,
                remainingCashBox: action.payload,
            };

        case SETTLEMENT_LOADING:
            return {
                ...state,
                loading: true,
            };

        case SETTLEMENT_IOU_LOADING:
            return {
                ...state,
                loading_iou: true,
            };

        case SETTLEMENT_PETTYCASH_LOADING:
            return {
                ...state,
                loading_pettyCash: true,
            };

        case SETTLEMENT_CASHBOX_LOADING:
            return {
                ...state,
                loading_cashBox: true,
            };

        case CLEAR_SETTLEMENT_IOU:
            return {
                ...state,
                iou: [],
                loading_iou: true,
            };

        case CLEAR_SETTLEMENT_PETTY_CASH:
            return {
                ...state,
                pettyCash: [],
                loading_pettyCash: true,
            };

        default:
            return state;
    }
}

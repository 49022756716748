import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from "./alert";
import {
    GET_ALL_OUTLETS,
    GET_ALL_MENU_OPTIONS,
    GET_ALL_CATEGORIES,
    GET_ALL_PRICE_UPDATES,
    GET_POS_ITEM_REQUEST_BY_ID,
} from './types';

// Get Reimburse Data
export const getAllOutlets = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/Outlet/get-outlets`, config);

        dispatch({
            type: GET_ALL_OUTLETS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Get All Menu options
export const getAllMenuOptions = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/MenuOption/get-menu-options`, config);

        dispatch({
            type: GET_ALL_MENU_OPTIONS,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Get All Categories
export const getAllCategories = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/Category/get-all-categories`, config);

        dispatch({
            type: GET_ALL_CATEGORIES,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Get All Price Updates
export const getAllPriceUpdates = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(`/api/PriceUpdate/get-price-updates`, config);

        dispatch({
            type: GET_ALL_PRICE_UPDATES,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);
    }
};

// Submit New POS Request
export const submitPosRequest = (formData, history) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(
            `/api/POSItemRequest/submit`,
            formData,
            config
        );

        if (res.status == 200) {
            history.push(`/user-dashboard`);
            dispatch(setAlert('POS Request has Been Recorded', 'success'));
        }

    
    } catch (err) {
        console.log(err);
    }
};

export const getPosItemRequestDataById = (Id, accesslevelId) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const res = await axios.get(
            `/api/POSItemRequest/get-request?Id=${Id}&accessLevelId=${accesslevelId}`,
            config
        );

       

        dispatch({ type: GET_POS_ITEM_REQUEST_BY_ID, payload: res.data });
    } catch (err) {
        console.log(err);
    }
};

export const posItemRequestButtonClick =
    (posItemsList, formData, additionalComment, access, userId, history) =>
        async (dispatch) => {
            

            

             if (localStorage.token) {
               setAuthToken(localStorage.token);
             }

             try {
               const config = {
                 "Content-Type": "application/json",
               };

               const stringyfyAdditionalComment = JSON.stringify(additionalComment);

               const body = {
                 requestProcessLevelActionId: formData.requestProcessLevelActionId,
                 requestId: formData.requestId,
                 requestLevelId: formData.requestLevelId,
                 actionId: formData.actionId,
                 additionalComment: stringyfyAdditionalComment,
                 POSItemDTO: posItemsList,
                 AttachmentDataDTO: formData.Attachments,

               };

               

               const res = await axios.post(
                 "/api/POSItemRequest/submit-action",
                 body,
                 config
               );
                 if (res.status == 200) {
                     history.push(`/user-dashboard`);
                     dispatch(
                         setAlert(
                             `Form ${formData.formNumber} Has Successfully Recorded`,
                             "success"
                         )
                     );
                 }
             } catch (err) {
               console.log(err);
               dispatch(setAlert("Something Went Wrong", "error"));
             }
        };

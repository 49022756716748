import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "../../sidebar/Sidebar";
import { getReimburseData } from "../../../actions/reimburse";
import { submitReimburse } from "../../../actions/reimburse";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "../Shared/Grid";
import ReimburseTemplate from "./Export/ReimburseTemplate";
import { withRouter } from "react-router";
import Spinner from "../../../components/layout/Spinner";
import NumberFormat from "react-number-format";
var moment = require("moment");

const Reimburse = ({
    submitReimburse,
    getReimburseData,
    reimburse: { reimburse, reimburse_data_loading },
    auth: { id, firstName, lastName },
    history,
}) => {
    useEffect(() => {
        getReimburseData();
    }, [getReimburseData]);

    const headerCells = [
        {
            id: "formNo",
            numeric: false,
            disablePadding: false,
            name: "Form No",
            propertyValue: "formNumber",
            nested: false,
            type: "number",
        },
        {
            id: "settledDate",
            numeric: false,
            disablePadding: false,
            name: "Transaction date",
            propertyValue: "settledOn",
            nested: false,
            type: "date",
        },
        {
            id: "paymentTitle",
            numeric: false,
            disablePadding: false,
            name: "Payment Title",
            propertyValue: "pyamentTitle",
            nested: false,
            type: "text",
        },
        {
            id: "description",
            numeric: false,
            disablePadding: false,
            name: "Description",
            propertyValue: "description",
            nested: false,
            type: "text",
        },
        {
            id: "createdUser",
            numeric: false,
            disablePadding: false,
            name: "Requested By",
            propertyValue: "createdUser",
            nested: true,
            nestedValue: ["firstName", "lastName"],
            type: "text",
        },
        {
            id: "amount",
            numeric: false,
            disablePadding: false,
            name: "Amount (LKR)",
            propertyValue: "amount",
            nested: false,
            type: "numeric",
        },
        {
            id: "costCenterDepartment",
            numeric: false,
            disablePadding: false,
            name: "Cost center Department",
            propertyValue: "costCenterDepartment",
            nested: true,
            nestedValue: ["name"],
            minWidth: 190,
            type: "text",
        },
    ];

    const [submitLoading, setSubmitLoading] = useState(false);

    const [reimburseData, setReimburseData] = useState({
        fromDate: "",
        toDate: "",
        totalPettyCash: 0,
        totalReimburse: 0,
        accountantId: "",
        statusId: "",
        reimbursementAttachments: [],
        reimbursementPettyCashes: [],
    });

    const {
        fromDate,
        toDate,
        totalPettyCash,
        totalReimburse,
        statusId,
        accountantId,
        reimbursementAttachments,
        reimbursementPettyCashes,
    } = reimburseData;

    let sum = 0;
    

    if (
        !reimburse_data_loading &&
        reimburse.pettyCashes !== null &&
        reimburse.pettyCashes !== undefined
    ) {
        reimburse.pettyCashes.forEach((i) => (sum = sum + i.amount));
    }

    //useEffect(() => {
    //    setReimburseData({
    //        ...reimburseData,
    //        totalReimburse: sum
    //    })
    //}, [sum])

    let pettyCashesSum = [];

    if (
        !reimburse_data_loading &&
        reimburse.pettyCashes !== null &&
        reimburse.pettyCashes !== undefined
    ) {
        reimburse.pettyCashes.forEach((i) => {
            pettyCashesSum.push({ PettyCashId: i.id, Amount: i.amount, id: 0 });
        });
    }

    //reimburseData.reimbursementPettyCashes = pettyCashesSum;

    useEffect(() => {
        if (!reimburse_data_loading) {
            setReimburseData({
                ...reimburseData,
                fromDate: reimburse.fromDate,
                toDate: reimburse.toDate,
                totalPettyCash: reimburse.totalPettyCash,
                statusId: reimburse.status !== null && reimburse.status.id,
                accountantId: id,
                totalReimburse: sum,
                reimbursementPettyCashes: pettyCashesSum,
            });

           
        }
    }, [reimburse_data_loading]);

   

    const onSubmit = (e) => {
        setSubmitLoading(true);
        e.preventDefault();
 
        submitReimburse(reimburseData, history);
    };

    // File Upload States
    const [uploadFile, setUploadFile] = useState("");
    const [fileName, setFileName] = useState("Choose a File");
    const [fileListArray, setFileListArray] = useState([]);
    const [base64List, setBase64List] = useState([]);

    // Attachment delete handle
    const deleteAttachment = (e, index) => {
        fileListArray.splice(index, 1);
        base64List.splice(index, 1);
        setBase64List([...base64List]);
        setFileListArray([...fileListArray]);
    };

    // File Upload function
    const base64arraylist = [];
    // Handle File upload change
    const onFileUploadChange = async (e) => {
        if (e.target.files.length > 0) {
            e.persist();
            setUploadFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setFileListArray([...fileListArray, { name: e.target.files[0].name }]);

            const fileNameOfchoosenUpload = e.target.files[0].name;

            //Convert file to base 64
            const base64 = await convertBase64(e.target.files[0]);
            const splitBase64 = base64.split(",")[1];

            const formatedList = {
                FileName: fileNameOfchoosenUpload,
                value: splitBase64,
            };

            base64arraylist.push(formatedList);

            setBase64List([...base64List, formatedList]);

            setFileName("Choose a File");
        }

        e.target.value = "";
    };

    useEffect(() => {
        setReimburseData({
            ...reimburseData,
            reimbursementAttachments: base64List,
        });
    }, [base64List]);

    const convertBase64 = (file) => {
        return new Promise((resolve, rejects) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    return (
        <main>
            <div
                className="card w-100 sticky-top mb-3 p-3 detail-sticky"
                style={{
                    maxHeight: "91px",
                    zIndex: "1030",
                }}
            >
                <div className="col-md-12">
                    <div
                        style={{
                            color: "#333333",
                            fontWeight: "bold",
                            fontSize: "25px",

                            fontFamily: "Montserrat",
                        }}
                    >
                        Reimbursement
                    </div>
                </div>
            </div>

            <form onSubmit={(e) => onSubmit(e)}>
                {!reimburse_data_loading && !submitLoading && (
                    <div
                        className="card-body-form p-4 mb-5 ml-4 mr-4"
                        style={{ backgroundColor: "white" }}
                    >
                        <div className="card detail-card">
                            <div className="card-img-top card-img-top-detail" />
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="mb-2">From</div>
                                            <input
                                                type="text"
                                                value={moment(new Date(reimburse.fromDate)).format(
                                                    "YYYY-MM-DD"
                                                )}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="mb-2">To</div>
                                            <input
                                                type="text"
                                                value={moment(new Date(reimburse.toDate)).format(
                                                    "YYYY-MM-DD"
                                                )}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="mb-2">Petty Cash (LKR)</div>

                                            <NumberFormat
                                                className="form-control"
                                                value={reimburse.totalPettyCash}
                                                displayType={"input"}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="mb-2">Reimburse (LKR)</div>

                                            <NumberFormat
                                                className="form-control"
                                                value={reimburseData.totalReimburse}
                                                displayType={"input"}
                                                thousandSeparator={true}
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    {/*<div className="col-md-6 pl-4 pr-4 pt-3 pb-3">*/}
                                    {/*    <div className="detail-title">*/}
                                    {/*        <div className="mb-2">Accountant</div>*/}
                                    {/*        <input*/}
                                    {/*            type="text"*/}
                                    {/*            value={firstName + ' ' + lastName}*/}
                                    {/*            className="form-control"*/}
                                    {/*            disabled*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-md-6 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="mb-2">Status</div>
                                            <input
                                                type="text"
                                                value={reimburse.status.name}
                                                className="form-control"
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 pl-4 pr-4 pt-3 pb-3">
                                        <div className="detail-title">
                                            <div className="row ml-1 mt-3 mb-2">
                                                Attachments
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <div className="custom-file col-md-12 mb-3">
                                                <input
                                                    type="file"
                                                    className="custom-file-input w-100"
                                                    id="customFile"
                                                    required={fileListArray.length === 0 ? true : false}
                                                    onChange={(e) => onFileUploadChange(e)}
                                                />
                                                <div className="custom-file-label" htmlFor="customFile">
                                                    {fileName}
                                                </div>
                                            </div>

                                            <div className="horizontal-container">
                                                <ul className="list-group list-group-flush">
                                                    {fileListArray &&
                                                        fileListArray.map((file, index) => (
                                                            <li className="list-group-item" key={index}>
                                                                <div style={{ display: "flex" }}>
                                                                    <div className="text-center mr-3">
                                                                        <DeleteIcon
                                                                            key={index}
                                                                            onClick={(e) =>
                                                                                deleteAttachment(e, index)
                                                                            }
                                                                            style={{
                                                                                color: "red",
                                                                                cursor: "handle",
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div>{file.name}</div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    //        <div className="px-4 mb-5"><button

                    //            style={{ float: 'right' }}
                    //            type="submit"
                    //            className="btn w-100 btn-primary col-md-2 mb-4 "
                    //            disabled={reimburse.pettyCashes === null}
                    //        >
                    //            Reimburse
                    //</button></div>
                )}

                {!reimburse_data_loading && !submitLoading ? (
                    <>
                        <div
                            className="card-body-form p-4 mb-4 ml-4 mr-4"
                            style={{ backgroundColor: "white" }}
                        >
                            <div className="card detail-card">
                                <div className="card-img-top card-img-top-detail" />
                                <div className="card-body">
                                    <Fragment>
                                        {reimburse.pettyCashes !== null && (
                                            <ReimburseTemplate data={reimburse.pettyCashes} />
                                        )}

                                        <div>
                                            <Grid
                                                headers={headerCells}
                                                data={
                                                    reimburse.pettyCashes !== null
                                                        ? reimburse.pettyCashes
                                                        : []
                                                }
                                            />
                                        </div>
                                    </Fragment>
                                </div>
                            </div>
                        </div>

                        <div className="px-4 mb-5">
                            <button
                                style={{ float: "right" }}
                                type="submit"
                                className="btn w-100 btn-primary col-md-2 mb-4 "
                                disabled={reimburse.pettyCashes === null}
                            >
                                Reimburse
                            </button>
                        </div>
                    </>
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </form>
        </main>
    );
};

Reimburse.propTypes = {
    submitReimburse: PropTypes.func.isRequired,
    getReimburseData: PropTypes.func.isRequired,
    reimburse: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reimburse: state.reimburse,
});

export default connect(mapStateToProps, { getReimburseData, submitReimburse })(
    withRouter(Reimburse)
);

import {
    GET_ALL_DIVISIONS,
    GET_DIVISION_BY_ID,
    GET_ALL_DIVISION_HEADS,
} from "../actions/types";

const initialState = {
    divisions: [],
    loading: true, //true

    division: null,
    loadingDivision: true,

    divisionHeads: [],
    loadingDivisionHead: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DIVISIONS:
            return {
                ...state,
                loading: false, //false
                divisions: action.payload,
            };
        case GET_DIVISION_BY_ID:
            return {
                ...state,
                loadingDivision: false, //false
                division: action.payload,
            };
        case GET_ALL_DIVISION_HEADS:
            return {
                ...state,
                divisionHeads: action.payload,
                loadingDivisionHead: false, //false
            };

        default:
            return state;
    }
};
export default reducer;

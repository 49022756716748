import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import logo from '../../img/logo.png';
import navLogo from "../../img/navLogo.png";
import logo_S from '../../img/logo_S_Only.png';
import toggle from '../../img/toggle.png';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const Navbar = ({
    auth: { isAuthenticated, loading, firstName, lastName },
    logout,
}) => {
    const authLinks = (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <a className="nav-link" href="!#">
                    <NotificationsActiveIcon />
                </a>
            </li>

            {!loading ? (
                <ul className="navbar-nav ml-auto ml-md-0">
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link dropdown-toggle"
                            id="userDropdown"
                            href="!#"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {/* <i className="fas fa-user fa-fw"></i> */}
                            <b>
                                {firstName} {lastName}
                            </b>
                        </a>
                        <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="userDropdown"
                        >
                            <Link className="dropdown-item" to="/user-dashboard/settings">
                                Settings
              </Link>
                            <Link className="dropdown-item" to="/user-dashboard/my-account">
                                View Account
              </Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" onClick={logout} to="/">
                                Logout
              </Link>
                        </div>
                    </li>
                </ul>
            ) : (
                    ''
                )}
        </ul>
    );

    const guestLinks = (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <a className="nav-link" href="!#">
                    <NotificationsActiveIcon />
                </a>
            </li>
            {/* 
      <li className="nav-item">
        <Link className="nav-link" to="/register">
          Register
        </Link>
      </li> */}
            <li className="nav-item">
                <Link className="nav-link" to="/">
                    <b>SIGNIN</b>
                </Link>
            </li>
        </ul>
    );

    // Calculate the inner width of the screen
    var width = window.innerWidth;

    return (
        <div>
            <div className="sb-nav-fixed">
                <nav className="sb-topnav navbar navbar-expand navbar-light bg-light">
                    <button
                        className="btn btn-link btn-sm order-1 order-lg-0 ml-2"
                        id="sidebarToggle"
                        href="!#"
                    >
                        <img width="20px" src={toggle} alt="toggle" />
                    </button>

                    {width < 500 ? (
                        <Link className="navbar-brand" to="/">
                            <img width="30px" src={logo_S} alt="logo2" />
                        </Link>
                    ) : (
                            <Link className="navbar-brand" to="/">
                                <img width="105px" src={navLogo} alt="logo" />
                            </Link>
                        )}

                    {isAuthenticated ? authLinks : guestLinks}
                </nav>
            </div>
        </div>
    );
};

Navbar.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);

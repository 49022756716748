import {
    CLEAR_TILES,
    DASHBOARD_TILE,
    GET_APPROVED_REQUESTS_COUNT,
    GET_PENDING_REQUESTS_COUNT,
    GET_REJECTED_REQUESTS_COUNT,
} from '../actions/types';

const intialState = {
    tiles: [],
    tiles_loading: true,

    approved_req_count: null,
    approved_req_count_loading: true,

    pending_req_count: null,
    pending_req_count_loading: true,

    rejected_req_count: null,
    rejected_req_count_loading: true,

};

export default function (state = intialState, action) {
    const { type, payload } = action;

    switch (type) {
        case DASHBOARD_TILE:
            return {
                ...state,
                tiles: payload,
                tiles_loading: false,
            };

        case CLEAR_TILES:
            return {
                ...state,
                tiles: [],               
                tiles_loading: true,
                approved_req_count: [],
                approved_req_count_loading: true,
                pending_req_count: [],
                pending_req_count_loading: true,
                rejected_req_count: [],
                rejected_req_count_loading: true,

            };

        case GET_APPROVED_REQUESTS_COUNT:
            return {
                ...state,
                approved_req_count: payload.length > 0 ? payload[payload.length - 1].approvedCount : 0,
                approved_req_count_loading: false,
            }
        case GET_PENDING_REQUESTS_COUNT:
            return {
                ...state,
                pending_req_count: payload.length > 0 ? payload[payload.length - 1].pendingCount : 0,
                pending_req_count_loading: false,
            }
        case GET_REJECTED_REQUESTS_COUNT:
            return {
                ...state,
                rejected_req_count: payload.length > 0 ? payload[payload.length - 1].rejectedCount : 0,
                rejected_req_count_loading: false,
            }

        default:
            return state;
    }
}

import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AttachFileIcon from "@material-ui/icons/AttachFile";

import "./AdditionalComment.css";
import { useState } from "react";
import CommentAttachment from "./CommentAttachment";

const AdditionalComment = ({
  openState,
  onClick,
  button,
  onChange,
  value,
  name,
  attachmnetDisplay = true,
  setState,
  state,
  currentAttachments = {},
}) => {
  const { data = [], property = "name" } = currentAttachments;

  const [fileListArray, setFileListArray] = useState([]);
  const [base64List, setBase64List] = useState([]);
  const [error, setError] = useState({
    show: false,
    msg: "",
  });

  // File Upload function
  const base64arraylist = [];

  // Set File error to false after 5sec
  const setTimeOutError = () => {
    setTimeout(() => {
      setError({
        show: false,
      });
    }, 5000);
  };

  // Handle File upload change
  const onFileUploadChange = async (e) => {
    if (e.target.files.length > 0) {
      e.persist();

      const fileNameOfchoosenUpload = e.target.files[0].name;

      let isFileExists = -1;
      //Check Uploaded file name exists //
      if (data.length) {
        isFileExists = data.findIndex(
          (i) => i[property] == fileNameOfchoosenUpload
        );
      }

      if (isFileExists !== -1) {
        setError({
          show: true,
          msg: fileNameOfchoosenUpload,
        });
        setTimeOutError();
      } else {
        // Set File upload error to false
        setError({
          show: false,
        });

        setFileListArray([
          ...fileListArray,
          {
            name: e.target.files[0].name,
            type: fileNameOfchoosenUpload.substring(
              fileNameOfchoosenUpload.lastIndexOf(".") + 1
            ),
          },
        ]);

        //Convert file to base 64
        const base64 = await convertBase64(e.target.files[0]);
        const splitBase64 = base64.split(",")[1];

        const formatedList = {
          Name: fileNameOfchoosenUpload,
          FileName: fileNameOfchoosenUpload,
          Value: splitBase64,
          giftVoucherRequestId: state.requestId,
          requestId: state.requestId,
        };

        base64arraylist.push(formatedList);

        setBase64List([...base64List, formatedList]);
        //   Set attachment list to submit
        setState({
          ...state,
          Attachments: [
            ...((state.Attachments && state.Attachments) || []),
            formatedList,
          ],
        });
      }
    }

    e.target.value = "";
  };

  // Attachment delete handle
  const deleteAttachment = (e, index) => {
    fileListArray.splice(index, 1);
    base64List.splice(index, 1);
    setState({
      ...state,
      Attachments: [...base64List],
    });
    setFileListArray([...fileListArray]);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, rejects) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
  };

  return (
    <Dialog open={openState} aria-labelledby="form-dialog-title">
      <form className="form">
        <div className="dialog-title-container">
          <DialogTitle id="form-dialog-title"> Comment</DialogTitle>
          {attachmnetDisplay && button === "Submit More infor" && (
            <div className="comment-attachment-container">
              <div className="row">
                <span
                  className="mt-2 mr-2"
                  style={{ color: "red", fontWeight: "500", fontSize: "17px" }}
                >
                  Attachment
                </span>
                <div className="comment-attachment">
                  <label for="file-upload" className="custom-file-upload">
                    <AttachFileIcon type="button" />
                  </label>

                  <input
                    id="file-upload"
                    type="file"
                    onChange={(e) => onFileUploadChange(e)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <DialogContent>
          {data.length > 0 && button === "Submit More infor" && (
            <>
              <div className="current-attachment-title">
                Current Attachments
              </div>
              <div className="row px-2">
                {data.map((file, index) => (
                  <CommentAttachment
                    key={index}
                    type={file.type}
                    name={file[property]}
                    index={index}
                    show={false}
                  />
                ))}
              </div>
            </>
          )}
          <>
            {data.length > 0 && fileListArray.length > 0 && (
              <div className="current-attachment-title">New Attachments</div>
            )}
            <div className="row px-2">
              {fileListArray.map((file, index) => (
                <CommentAttachment
                  key={index}
                  type={file.type}
                  name={file.name}
                  index={index}
                  onDelete={deleteAttachment}
                />
              ))}
            </div>
          </>
          {error.show && (
            <div className="attachment-error">
              Selected File <b> "{error.msg}" </b> is Already Exists.
            </div>
          )}
          <DialogContentText>
            Please add some additional comments before submit this request.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            label="Comment"
            multiline
            variant="outlined"
            rows={4}
            required={
              button === "Approve" ||
              button === "Check In" ||
              button === "Settle" ||
              button === "Withdraw" ||
              button === "Reimburse" ||
              button === "Check Out" ||
              button === "Accept" ||
              button === "Complete"
                ? false
                : true
            }
            value={value}
            name={name}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" onClick={onClick} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            {button}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

AdditionalComment.propTypes = {
  openState: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  button: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  currentAttachments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AdditionalComment);

import {
  GET_ALL_CURRENCY,
    GET_SUPPLIER_ADVANCE_REQ_BY_ID,
    CLEAR_ADVANCE_PAYMENT_REQUEST,
} from "../actions/types";

const initialState = {
  currencies: [],
  loading: true,
  supplierAdvanceReq: null,
  loading_supplierAdvaceReq: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CURRENCY:
      return {
        ...state,
        loading: false,
        currencies: action.payload,
      };
    case GET_SUPPLIER_ADVANCE_REQ_BY_ID:
      return {
        ...state,
        supplierAdvanceReq: action.payload,
        loading_supplierAdvaceReq: false,
          };
      case CLEAR_ADVANCE_PAYMENT_REQUEST:
          return {
              ...state,
              supplierAdvanceReq: null,
              loading_supplierAdvaceReq: true,
          };

    default:
      return state;
  }
};

export default reducer;

import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import moment from "moment";
import purple from "@material-ui/core/colors/purple";

const PettyCashGrid = ({ data, setState, initialState }) => {
  const headCells = [
    {
      id: "FormNumber",
      numeric: false,
      disablePadding: true,
      label: "Form Number",
    },
    {
      id: "InvoiceNumber",
      numeric: false,
      disablePadding: false,
      label: "Invoice Number",
    },
    {
      id: "PaymentTitle",
      numeric: false,
      disablePadding: false,
      label: "Payment Title",
    },
    {
      id: "CreatedDate",
      numeric: false,
      disablePadding: false,
      label: "Created Date",
    },
    { id: "Amount", numeric: true, disablePadding: false, label: "Amount" },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount } = props;

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </StyledTableCell>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
            >
              <b>{headCell.label}</b>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const EnhancedTableToolbar = (props) => {
    return (
      <Toolbar>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Petty Cash
        </Typography>
      </Toolbar>
    );
  };

  // Defualt Material UI Package functions
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#dadee7",
      color: theme.palette.common.black,
    },

    body: {
      fontSize: 15,
    },
  }))(TableCell);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },

    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      backgroundColor: "#F8F9FB",
    },
    table: {
      minWidth: 750,
    },

    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  const [selectedId, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Calculate sum of select All in Grid
  const pettyCashSum = (obj) =>
    Object.values(obj).reduce(
      (accumulator, current) => accumulator + current.amount,
      0
    );

  // Handle Select all event
  const handleSelectAllClick = (event) => {
    if (data != "") {
      if (event.target.checked) {
        const newSelectedIds = data.map((n) => ({ id: n.id }));
        setSelected(newSelectedIds);
        setState({
          idList: newSelectedIds,
          totalAmount: pettyCashSum(
            data.map((i) => ({
              amount: i.amount,
            }))
          ),
        });
        return;
      }
    }
    setSelected([]);
    setState({
      idList: [],
      totalAmount: 0,
    });
  };

  const handleClick = (event, id, index) => {
    const selectedIdIndex = selectedId.findIndex((i) => i.id == id);
    let newSelected = [];

    if (selectedIdIndex === -1) {
      newSelected = newSelected.concat(selectedId, { id: id });
      setState({
        idList: newSelected,
        totalAmount: JSON.parse(initialState.totalAmount) + data[index].amount,
      });
    } else if (selectedIdIndex === 0) {
      newSelected = newSelected.concat(selectedId.slice(1));
      setState({
        idList: newSelected,
        totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
      });
    } else if (selectedIdIndex === selectedId.length - 1) {
      newSelected = newSelected.concat(selectedId.slice(0, -1));
      setState({
        idList: newSelected,
        totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
      });
    } else if (selectedIdIndex > 0) {
      newSelected = newSelected.concat(
        selectedId.slice(0, selectedIdIndex),
        selectedId.slice(selectedIdIndex + 1)
      );
      setState({
        idList: newSelected,
        totalAmount: JSON.parse(initialState.totalAmount) - data[index].amount,
      });
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selectedId.findIndex((i) => i.id == id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selectedId.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {data !== "" &&
                data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id, index)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.formNumber}
                        // selected={isItemSelected}
                        backgroundColor="black"
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            color="primary"
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.formNumber}
                        </TableCell>
                        <TableCell align="left">{row.invoiceNumber}</TableCell>
                        <TableCell align="left">{row.pyamentTitle}</TableCell>
                        <TableCell align="left">
                          {moment(new Date(row.createOn)).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

PettyCashGrid.propTypes = {
  data: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  initialState: PropTypes.object.isRequired,
};

export default PettyCashGrid;

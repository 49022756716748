import axios from 'axios';
import store from '../store';
import setAuthToken from '../utils/setAuthToken';
import { setAlert } from './alert';
import { getAllRequests, getRequests } from './requests';
import {
    GET_REIMBURSE_DATA,
    GET_REIMBURSE_BY_ID,
    SET_REIMBURSE_LOADING,
    SET_ALERT,
} from './types';

// Get Reimburse Data
export const getReimburseData = () => async dispatch => {
    dispatch({ type: SET_REIMBURSE_LOADING });

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.get(
            `/api/Reimbursement/get-by-period?fromDate=&toDate=`,
            config
        );

        dispatch({
            type: GET_REIMBURSE_DATA,
            payload: res.data,
        });
    } catch (err) {
        console.log(err);

        dispatch(setAlert('Pick a date', 'warning'));
    }
};

export const submitReimburse = (reimburseData, history) => async dispatch => {

   

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(
            `/api/Reimbursement/submit-Reimbursement`,
            reimburseData,
            config
        );

        if (res.status == 200) {
            history.push(`/user-dashboard`);
            dispatch(setAlert('Reimburse has been recorded', 'success'));
        }
    } catch (err) {
        console.log(err);
    }
};

export const getReimbursementById = (reimburseId, accesslevelId) => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };


        const res = await axios.get(
            `/api/Reimbursement/get-by-id?Id=${reimburseId}&accessLevelId=${accesslevelId}`,
            config
        );

        dispatch({ type: GET_REIMBURSE_BY_ID, payload: res.data });
    } catch (err) {
        console.log(err);
    }
};

export const reimburseRequestButtonClick =
    (formData, additionalComment, access, userId, history) => async dispatch => {
        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }

        try {
            const config = {
                'Content-Type': 'application/json',
            };

            const stringyfyAdditionalComment = JSON.stringify(additionalComment);

            const body = {
                requestProcessLevelActionId: formData.requestProcessLevelActionId,
                requestId: formData.requestId,
                requestLevelId: formData.requestLevelId,
                actionId: formData.actionId,
                additionalComment: stringyfyAdditionalComment,
            };

            const res = await axios.post('/api/Reimbursement/submit-action', body, config);

            if (res.status == 200) {
                history.push(`/user-dashboard`);
                dispatch(
                    setAlert(
                        `Form ${formData.formNumber} Has Successfully Recorded`,
                        "success"
                    )
                );
            }
        } catch (err) {
            console.log(err);
            dispatch(setAlert('Something Went Wrong', 'error'));
        }
    };

import React, { useEffect, useState } from 'react';
import AutoSuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { getCostCenterDivision } from '../../../../actions/forms';
import { connect } from 'react-redux';
import Axios from 'axios';
import { withRouter } from 'react-router';
import {
    saveRequestForPaymentForm,
    submitRequestForPaymentForm,
} from '../../../../actions/requests';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from '../../../layout/SubmitSpinner';
import { getRequestForPaymentById } from '../../../../actions/requests';

const RequestForPayment = ({
    getCostCenterDivision,
    getRequestForPaymentById,
    history,
    match,
    saveRequestForPaymentForm,
    submitRequestForPaymentForm,
    forms: {
        requestForPayment: { costCenterDivision },
        form_loading,
    },
    requests: { request, request_loading },
    auth: { id, loading },
}) => {
    useEffect(() => {
        getRequestForPaymentById(match.params.id);
        getCostCenterDivision(division);
        getTypeOfExpense();
        getCurrencyValues();
    }, [match.params.id]);

    useEffect(() => {
        if (!request_loading && request !== null) {
            setFormData({
                ...formData,
                SupplierName: request.supplierName,
                ID: request.id,
                DocumentDescription: request.documentDescription,
                CurrencyId: request.currency.shortName,
                Amount: request.amount,
                CostCenterDivisionId: request.costCenterDivisionId,
                CostCenterDepartmentId: request.costCenterDepartmentId,
                MainPLExpenseCategoryId: request.mainPLExpenseCategoryId,
                SubPLExpenseCategoryId: request.subPLExpenseCategoryId,
                TypeOfExpenseId: request.typeOfExpenseId,
                GACodeId: request.gaCodeId,
                ExpenseLineId: request.expenseLineId,
                Remarks: request.remarks,
            });
            setDraftValues({
                ...draftValues,
                SupplierName: request.supplierName,
                DocumentDescription: request.documentDescription,
                Currency: request.currency,
                Amount: request.amount,
                TypeOfExpense: request.typeOfExpense.name,
                ExpenseLine: request.expenseLine.name,
                Remarks: request.remarks,
                Attachments: request.attachments,
            });

            //   Handle Currency Dropdown value
            if (request.currencyId !== null) {
                selectElement('currency', request.currencyId);
            }

            //   Handle cost center division and department
            if (request.costCenterDivisionId !== null) {
                setDivision(request.costCenterDivision.name);
                getCostCenterDepartments(request.costCenterDivisionId);
            }

            //   Handle Department and main P&L
            if (request.costCenterDepartmentId !== null) {
                setDepartment(request.costCenterDepartment.name);
                getMainExpenseCategory(request.costCenterDepartmentId);
            }

            //   Handle Main PL and Sub PL
            if (request.mainPLExpenseCategoryId !== null) {
                setMainExpense(request.mainPLExpenseCategory.name);
                getSubExpenseCategory(request.mainPLExpenseCategoryId);
            }

            //   Handle Sub PL
            if (request.subPLExpenseCategoryId !== null) {
                setSubExpense(request.subPLExpenseCategory.name);
            }

            //   Handle Type of Expense Value
            if (request.typeOfExpenseId !== null) {
                selectElement('typeOfExpense', request.typeOfExpenseId);
            }

            //   Handle GA Code and expense line
            setGaCodes(request.gaCode.name);
            setExpenseLine(request.expenseLine.name);

            //   Handle GaCode Data
            if (request.typeOfExpenseId === 6) {
                setGaCodeDisable(true);
                setGaCodes('DUM');
            } else getGaCodeData(request.typeOfExpenseId);

            //   Append attachment file names to fileListArray
            request.attachments.forEach(item =>
                setFileListArray([...fileListArray, { name: item.fileName }])
            );
        }
    }, [request]);

    // onclick of card click render dropdown
    function selectElement(id, valueToSelect) {
        let element = document.getElementById(id);
        element.value = valueToSelect;
    }

    //   Input Text Fields States
    const [draftValues, setDraftValues] = useState({
        SupplierName: '',
        DocumentDescription: '',
        Currency: null,
        Amount: '',
        TypeOfExpense: null,
        ExpenseLine: null,
        Remarks: '',
        Attachments: [],
    });

    // Currency values state
    const [currencyValues, setCurrencyValues] = useState([]);

    // Get Currency from the API
    const getCurrencyValues = async () => {
        const currency = await Axios.get('/api/Currency/get-currency');
        setCurrencyValues(currency.data);
    };

    const [formData, setFormData] = useState({
        ID: '',
        SupplierName: '',
        DocumentDescription: '',
        CurrencyId: null,
        Amount: '',
        CostCenterDivisionId: null,
        CostCenterDepartmentId: null,
        MainPLExpenseCategoryId: null,
        SubPLExpenseCategoryId: null,
        TypeOfExpenseId: null,
        GACodeId: null,
        ExpenseLineId: null,
        Remarks: '',
        Attachments: [],
    });

    const {
        CostCenterDivisionId,
        CostCenterDepartmentId,
        MainPLExpenseCategoryId,
        SubPLExpenseCategoryId,
    } = formData;

    // Onchange function for hadle change evenet
    const onChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Submit funtion for formdata
    const onSubmit = async (e, userId) => {
        e.preventDefault();
        if (CostCenterDivisionId === null) {
            setIsSelectValue(0);
        } else if (CostCenterDepartmentId === null && !departmentDisable) {
            setIsSelectValue(1);
        } else if (MainPLExpenseCategoryId === null && !mainExpenseDisable) {
            setIsSelectValue(2);
        } else if (SubPLExpenseCategoryId === null && !subExpenseDisable) {
            setIsSelectValue(3);
        } else {
            submitRequestForPaymentForm(formData, userId, history);
        }
    };

    // Create Save form function
    const saveForm = (e, userId) => {
        e.preventDefault();
        saveRequestForPaymentForm(formData, userId, history);
    };

    // Escape from the un necessecry values
    function escapeRegexCharacters(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    // Custom Error Handling for empty autosuggest
    const [isSelectValue, setIsSelectValue] = useState(null);

    // ----------Type of Expense Drop down ----------------------

    // Type of expense state
    const [typeOfExpense, setTypeOfExpense] = useState([]);

    // Get types of expense from API
    const getTypeOfExpense = async () => {
        const typeOfExpenseDropDown = await Axios.get(
            `/api/TypeOfExpense/get-type-of-expense`
        );
        setTypeOfExpense(typeOfExpenseDropDown.data);
    };

    // Set Type of Expense
    const getGaCodeData = async expenseId => {
        const gaCodes = await Axios.get(
            `/api/GACode/get-ga-code?typeOfExpenseId=${expenseId}`
        );

        // Set GA code initial state value
        if (gaCodes.data !== '') {
            setGaCodeData(gaCodes.data);
        }
    };

    // Set Type of Expense
    // Trigger: onChange
    const onChangeDropDown = async e => {
        const id = e.target.value;
        const gaCodes = await Axios.get(
            `/api/GACode/get-ga-code?typeOfExpenseId=${id}`
        );

        const parseIntId = parseInt(id);

        // Set Expense ID
        setFormData({
            ...formData,
            TypeOfExpenseId: parseIntId,
        });

        if (parseIntId === 5) {
            setExpenseLineDisable(true);
            setGaCodeDisable(false);
            setGaCodes('');
            // Set GA code initial state value
            if (gaCodes.data !== '') {
                setGaCodeData(gaCodes.data);
            }
        } else {
            getExpenseLine();
            setExpenseLineDisable(false);
            setExpenseLine('');
            setGaCodeDisable(true);
            setFormData({
                ...formData,
                GACodeId: gaCodes.data.id,
                TypeOfExpenseId: parseIntId,
            });
            setGaCodes('DUM');
        }
    };

    // Set Currency from Dropdown
    // Trigger: OnChange
    const onChangeCurrencyDropDown = async e => {
        const id = e.target.value;

        const parseIntId = parseInt(id);

        // Set Currency ID
        setFormData({
            ...formData,
            CurrencyId: parseIntId,
        });
    };

    // Trigger: OnChange of the dropdown value
    const getExpenseLine = async () => {
        const expenseLine = await Axios.get(`/api/ExpenseLine/get-expense-line`);
        setExpenseLineData(expenseLine.data);
    };

    //  ----------GA Code autosuggest-------------

    const [gaCodeDisable, setGaCodeDisable] = useState(false);
    const [gaCodeData, setGaCodeData] = useState([]);
    const [gaCodes, setGaCodes] = useState('');
    const [gaCodesSuggestions, setGaCodesSuggestions] = useState([]);

    // Match the division suggestions
    function getGaCodeSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return gaCodeData.filter(gaCode => regex.test(gaCode.name));
    }

    //  ----------Expense Line autosuggest-------------

    const [expenseLineDisable, setExpenseLineDisable] = useState(false);
    const [expenseLineData, setExpenseLineData] = useState([]);
    const [expenseLine, setExpenseLine] = useState('');
    const [expenseLineSuggestions, setExpenseLineSuggestions] = useState([]);

    function getExpenseLineSuggetions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return expenseLineData.filter(department => regex.test(department.name));
    }

    //  ----------Cost center division autosuggest-------------

    const [division, setDivision] = useState('');
    const [divisionSuggestions, setDivisionSuggestions] = useState([]);

    // Clear Data According to the sugessted dropdown change
    useEffect(() => {
        if (divisionSuggestions.length > 1) {
            setDepartment('');
            setDepartmentDataArray([]);
            setMainExpense('');
            setMainExpenseCategoryData([]);
            setSubExpense('');
            setSubExpenseCategoryData([]);
        }
    }, [divisionSuggestions]);

    // Match the division suggestions
    function getDivisionSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return costCenterDivision.filter(division => regex.test(division.name));
    }

    // Set const center department according to cost center division
    // Trigger: Onclick of suggestion
    const getCostCenterDepartments = async divisionDataId => {
        const departments = await Axios.get(
            `/api/CostCenterDepartment/get-costcenter-department?divisionId=${divisionDataId}`
        );
        if (department.data !== '') {
            setDepartmentDisable(false);
            setDepartmentDataArray(departments.data);
        } else {
            setDepartmentDisable(true);
        }
    };

    //   ---------- Cost center department autosugges--------------

    const [departmentDisable, setDepartmentDisable] = useState(false);
    const [departmentDataArrya, setDepartmentDataArray] = useState([]);
    const [department, setDepartment] = useState('');
    const [departmentSuggestions, setDepartmentSuggestions] = useState([]);

    // Clear Data According to the sugessted dropdown change
    useEffect(() => {
        if (departmentSuggestions.length > 1) {
            setMainExpense('');
            setMainExpenseCategoryData([]);
            setSubExpense('');
            setSubExpenseCategoryData([]);
        }
    }, [departmentSuggestions]);

    function getDepartmentSuggestions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return departmentDataArrya.filter(department =>
            regex.test(department.name)
        );
    }

    // Set Main expense category according to cost center department
    // Trigger: OnClick of suggestion
    const getMainExpenseCategory = async departmentId => {
        const expenseCategory = await Axios.get(
            `/api/MainPLCategory/get-main-pl-category?departmentId=${departmentId}`
        );

        if (expenseCategory.data !== '') {
            setMainExpenseCategoryData(expenseCategory.data);
            setMainExpenseDisable(false);
        } else {
            setMainExpenseDisable(true);
        }
    };

    // ---------- Main Expense Category ------------------------

    const [mainExpenseDisable, setMainExpenseDisable] = useState(false);
    const [mainExpenseCategoryData, setMainExpenseCategoryData] = useState([]);
    const [mainExpense, setMainExpense] = useState('');
    const [mainExpenseSuggestions, setMainExpenseSuggestions] = useState([]);

    // Clear Data According to the sugessted dropdown change
    useEffect(() => {
        if (mainExpenseSuggestions.length > 1) {
            setSubExpense('');
            setSubExpenseCategoryData([]);
        }
    }, [mainExpenseSuggestions]);

    function getMainExpenseSuggetions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return mainExpenseCategoryData.filter(expenseCategory =>
            regex.test(expenseCategory.name)
        );
    }

    // Set Sub expense category according to cost center department
    // Trigger: OnClick of suggestion
    const getSubExpenseCategory = async mainPLCategoryId => {
        const expenseCategory = await Axios.get(
            `/api/SubPLCategory/get-sub-pl-category?divisionId=2&mainPLCategoryId=${mainPLCategoryId}`
        );

        if (expenseCategory.data !== '') {
            setSubExpenseCategoryData(expenseCategory.data);
            setSubExpenseDisable(false);
        } else {
            setSubExpenseDisable(true);
        }
    };

    // ----------------- Sub Expense Category ----------------------------------

    const [subExpenseDisable, setSubExpenseDisable] = useState(false);
    const [subExpenseCategoryData, setSubExpenseCategoryData] = useState([]);
    const [subExpense, setSubExpense] = useState('');
    const [subExpenseSuggestions, setSubExpenseSuggestions] = useState([]);

    function getSubExpenseSuggetions(value) {
        const escapedValue = escapeRegexCharacters(value.trim());
        const regex = new RegExp('^' + escapedValue, 'i');

        return subExpenseCategoryData.filter(expenseCategory =>
            regex.test(expenseCategory.name)
        );
    }

    // -------------------------------------------------------------

    //@Funcion for add data into Column name
    function createData(formName, type, name, value) {
        return { formName, type, name, value };
    }

    const formFields = [
        createData(
            'Supplier Name',
            'text',
            'SupplierName',
            draftValues.SupplierName
        ),
        createData(
            'Document Description',
            'text',
            'DocumentDescription',
            draftValues.DocumentDescription
        ),
        createData('Currency', 'currency-dropdown', '', draftValues.Currency),
        createData('Amount', 'amount', 'Amount', draftValues.Amount),
        createData('Cost Center Division', 'cost-center-division'),
        createData('Cost Center Department', 'cost-center-department'),
        createData('P&L Expense Category (Main)', 'main-expense-category'),
        createData('P&L Expense Category (Sub)', 'sub-expense-category'),
        createData(
            'Type of Expense',
            'expense-dropdown',
            draftValues.TypeOfExpense
        ),
        createData('GA Code', 'ga-code'),
        createData('Expense Line', 'expense-line', draftValues.ExpenseLine),
    ];

    // File Upload States
    const [uploadFile, setUploadFile] = useState('');
    const [fileName, setFileName] = useState('Choose a File');
    const [fileListArray, setFileListArray] = useState([]);
    const [base64List, setBase64List] = useState([]);

    // Attachment delete handle
    const deleteAttachment = (e, index) => {
        fileListArray.splice(index, 1);
        base64List.splice(index - request.attachments.length, 1);
        setBase64List([...base64List]);
        setFileListArray([...fileListArray]);
    };

    // File Upload function
    const base64arraylist = [];
    // Handle File upload change
    const onFileUploadChange = async e => {
        if (e.target.files.length > 0) {
            e.persist();
            setUploadFile(e.target.files[0]);
            setFileName(e.target.files[0].name);

            setFileListArray([...fileListArray, { name: e.target.files[0].name }]);

            const fileNameOfchoosenUpload = e.target.files[0].name;

            //Convert file to base 64
            const base64 = await convertBase64(e.target.files[0]);
            const splitBase64 = base64.split(',')[1];

            const formatedList = {
                FileName: fileNameOfchoosenUpload,
                value: splitBase64,
            };

            base64arraylist.push(formatedList);

            setBase64List([...base64List, formatedList]);

            setFileName('Choose a File');
        }

        e.target.value = '';
    };

    useEffect(() => {
        setFormData({
            ...formData,
            Attachments: base64List,
        });
    }, [base64List]);

    const convertBase64 = file => {
        return new Promise((resolve, rejects) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    // Switch case for render form fields
    const renderFormField = (type, name, value) => {
        switch (type) {
            case 'text':
                return (
                    <input
                        name="inputText"
                        className="form-control"
                        name={name}
                        required={true}
                        defaultValue={value}
                        onChange={e => onChange(e)}
                    />
                );

            case 'amount':
                return (
                    <input
                        name="inputText"
                        type="number"
                        pattern="(d{3})([.])(d{2})"
                        min="0"
                        className="form-control"
                        name={name}
                        required={true}
                        defaultValue={value}
                        onChange={e => onChange(e)}
                    />
                );

            case 'expense-dropdown':
                return (
                    <div>
                        <select
                            id="typeOfExpense"
                            className="form-control"
                            name="inputText"
                            required
                            onChange={e => onChangeDropDown(e)}
                        >
                            <option value="">Select</option>
                            {typeOfExpense.map((expense, id) => (
                                <option key={id} value={expense.id}>
                                    {expense.name}
                                </option>
                            ))}
              )
            </select>
                    </div>
                );

            case 'currency-dropdown':
                return (
                    <div>
                        <select
                            id="currency"
                            className="form-control"
                            name="inputText"
                            required
                            onChange={e => onChangeCurrencyDropDown(e)}
                        >
                            <option value=""> Select </option>
                            {currencyValues.map((currency, id) => (
                                <option key={id} value={currency.id}>
                                    {currency.shortName}
                                </option>
                            ))}
              )
            </select>
                    </div>
                );

            case 'cost-center-division':
                return (
                    <div>
                        <AutoSuggest
                            suggestions={divisionSuggestions}
                            onSuggestionsClearRequested={() => setDivisionSuggestions([])}
                            onSuggestionsFetchRequested={({ value }) => {
                                // setDivision(value);
                                setDivisionSuggestions(getDivisionSuggestions(value));
                            }}
                            onSuggestionSelected={(
                                _,
                                { suggestionValue, suggestionIndex }
                            ) => {
                                getCostCenterDepartments(
                                    divisionSuggestions[suggestionIndex].id
                                );
                                setFormData({
                                    ...formData,
                                    CostCenterDivisionId: divisionSuggestions[suggestionIndex].id,
                                });
                                setIsSelectValue(null);
                            }}
                            getSuggestionValue={suggestion => suggestion.name}
                            shouldRenderSuggestions={() => true}
                            renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                            inputProps={{
                                className: 'form-control',
                                value: division,
                                required: true,
                                onChange: (_, { newValue, method }) => {
                                    setDivision(newValue);
                                    setFormData({
                                        ...formData,
                                        CostCenterDivisionId: null,
                                        CostCenterDepartmentId: null,
                                        MainPLExpenseCategoryId: null,
                                        SubPLExpenseCategoryId: null,
                                    });
                                },
                            }}
                            highlightFirstSuggestion={true}
                        />
                        {isSelectValue === 0 && (
                            <small style={{ color: 'red' }}>
                                <b>Select a Valid Cost Center Division </b>
                            </small>
                        )}
                    </div>
                );

            case 'cost-center-department':
                return (
                    <div>
                        <AutoSuggest
                            suggestions={departmentSuggestions}
                            onSuggestionsClearRequested={() => setDepartmentSuggestions([])}
                            onSuggestionsFetchRequested={({ value }) => {
                                // setDepartment(value);
                                setDepartmentSuggestions(getDepartmentSuggestions(value));
                            }}
                            onSuggestionSelected={(
                                _,
                                { suggestionValue, suggestionIndex }
                            ) => {
                                getMainExpenseCategory(
                                    departmentSuggestions[suggestionIndex].id
                                );
                                setFormData({
                                    ...formData,
                                    CostCenterDepartmentId:
                                        departmentSuggestions[suggestionIndex].id,
                                });
                                setIsSelectValue(null);
                            }}
                            getSuggestionValue={suggestion => suggestion.name}
                            shouldRenderSuggestions={() => true}
                            renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                            inputProps={{
                                className: 'form-control',
                                value: department,
                                disabled: departmentDisable,
                                required: true,
                                onChange: (_, { newValue, method }) => {
                                    setDepartment(newValue);
                                    setFormData({
                                        ...formData,
                                        CostCenterDepartmentId: null,
                                        MainPLExpenseCategoryId: null,
                                        SubPLExpenseCategoryId: null,
                                    });
                                },
                            }}
                            required={true}
                            highlightFirstSuggestion={true}
                        />
                        {isSelectValue === 1 && (
                            <small style={{ color: 'red' }}>
                                <b>Select a Valid Cost Center Department </b>
                            </small>
                        )}
                    </div>
                );
            case 'main-expense-category':
                return (
                    <div>
                        <AutoSuggest
                            suggestions={mainExpenseSuggestions}
                            onSuggestionsClearRequested={() => setMainExpenseSuggestions([])}
                            onSuggestionsFetchRequested={({ value }) => {
                                // setMainExpense(value);
                                setMainExpenseSuggestions(getMainExpenseSuggetions(value));
                            }}
                            onSuggestionSelected={(
                                _,
                                { suggestionValue, suggestionIndex }
                            ) => {
                                getSubExpenseCategory(
                                    mainExpenseSuggestions[suggestionIndex].id
                                );
                                setFormData({
                                    ...formData,
                                    MainPLExpenseCategoryId:
                                        mainExpenseSuggestions[suggestionIndex].id,
                                });
                                setIsSelectValue(null);
                            }}
                            getSuggestionValue={suggestion => suggestion.name}
                            shouldRenderSuggestions={() => true}
                            renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                            inputProps={{
                                className: 'form-control',
                                value: mainExpense,
                                disabled: mainExpenseDisable,
                                required: true,
                                onChange: (_, { newValue, method }) => {
                                    setMainExpense(newValue);
                                    setFormData({
                                        ...formData,
                                        MainPLExpenseCategoryId: null,
                                        SubPLExpenseCategoryId: null,
                                    });
                                },
                            }}
                            highlightFirstSuggestion={true}
                        />
                        {isSelectValue === 2 && (
                            <small style={{ color: 'red' }}>
                                <b>Select a Valid Main P&L Category</b>
                            </small>
                        )}
                    </div>
                );
            case 'sub-expense-category':
                return (
                    <div>
                        <AutoSuggest
                            suggestions={subExpenseSuggestions}
                            onSuggestionsClearRequested={() => setSubExpenseSuggestions([])}
                            onSuggestionsFetchRequested={({ value }) => {
                                // setSubExpense(value);
                                setSubExpenseSuggestions(getSubExpenseSuggetions(value));
                            }}
                            onSuggestionSelected={(
                                _,
                                { suggestionValue, suggestionIndex }
                            ) => {
                                getMainExpenseCategory(
                                    subExpenseSuggestions[suggestionIndex].id
                                );
                                setFormData({
                                    ...formData,
                                    SubPLExpenseCategoryId:
                                        subExpenseSuggestions[suggestionIndex].id,
                                });
                                setIsSelectValue(null);
                            }}
                            getSuggestionValue={suggestion => suggestion.name}
                            shouldRenderSuggestions={() => true}
                            renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                            inputProps={{
                                className: 'form-control',
                                value: subExpense,
                                disabled: subExpenseDisable,
                                required: true,
                                onChange: (_, { newValue, method }) => {
                                    setSubExpense(newValue);
                                    setFormData({
                                        ...formData,
                                        SubPLExpenseCategoryId: null,
                                    });
                                },
                            }}
                            highlightFirstSuggestion={true}
                        />
                        {isSelectValue === 3 && (
                            <small style={{ color: 'red' }}>
                                <b>Select a Valid Sub P&L Category</b>
                            </small>
                        )}
                    </div>
                );
            case 'ga-code':
                return (
                    <AutoSuggest
                        suggestions={gaCodesSuggestions}
                        onSuggestionsClearRequested={() => setGaCodesSuggestions([])}
                        onSuggestionsFetchRequested={({ value }) => {
                            // setGaCodes(value);
                            setGaCodesSuggestions(getGaCodeSuggestions(value));
                        }}
                        onSuggestionSelected={(_, { suggestionValue, suggestionIndex }) => {
                            setExpenseLine(
                                gaCodesSuggestions[suggestionIndex].expenseLine.name
                            );
                            setFormData({
                                ...formData,
                                GACodeId: gaCodesSuggestions[suggestionIndex].id,
                                ExpenseLineId:
                                    gaCodesSuggestions[suggestionIndex].expenseLine.id,
                            });
                        }}
                        getSuggestionValue={suggestion => suggestion.name}
                        shouldRenderSuggestions={() => true}
                        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                        inputProps={{
                            className: 'form-control',
                            required: true,
                            value: gaCodes,
                            disabled: gaCodeDisable,
                            onChange: (_, { newValue, method }) => {
                                setGaCodes(newValue);
                            },
                        }}
                        highlightFirstSuggestion={true}
                    />
                );
            case 'expense-line':
                return (
                    <AutoSuggest
                        suggestions={expenseLineSuggestions}
                        onSuggestionsClearRequested={() => setExpenseLineSuggestions([])}
                        onSuggestionsFetchRequested={({ value }) => {
                            // setExpenseLine(value);
                            setExpenseLineSuggestions(getExpenseLineSuggetions(value));
                        }}
                        onSuggestionSelected={(_, { suggestionValue, suggestionIndex }) =>
                            setFormData({
                                ...formData,
                                ExpenseLineId: expenseLineSuggestions[suggestionIndex].id,
                            })
                        }
                        getSuggestionValue={suggestion => suggestion.name}
                        shouldRenderSuggestions={() => true}
                        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
                        inputProps={{
                            className: 'form-control',
                            value: expenseLine,
                            required: true,
                            disabled: expenseLineDisable,
                            onChange: (_, { newValue, method }) => {
                                setExpenseLine(newValue);
                            },
                        }}
                        highlightFirstSuggestion={true}
                    />
                );
            default:
                break;
        }
    };

    return (
        <div>
            {!loading && !form_loading && !request_loading ? (
                <form onSubmit={e => onSubmit(e, id)}>
                    <div
                        className="card-body-form p-4 mt-4 mb-4"
                        style={{ backgroundColor: 'white' }}
                    >
                        <div
                            className="card"
                            style={{
                                borderRadius: 0,
                                border: '2px solid rgba(233, 236, 239, 1)',
                                borderTop: 0,
                            }}
                        >
                            <div
                                className="card-img-top"
                                style={{
                                    backgroundColor: '#ffd100',
                                    height: '3px',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                            />
                            <div className="card-body">
                                <div className="row">
                                    {formFields.map((field, id) => (
                                        <div className="col-md-6 p-4" key={id}>
                                            <div
                                                style={{
                                                    color: '#333333',
                                                    fontWeight: 'bold',
                                                    fontSize: '15px',
                                                    marginBottom: '10px',
                                                    fontFamily: 'Montserrat',
                                                }}
                                            >
                                                <div className="row ml-1">
                                                    {field.formName}
                                                    <div
                                                        className="ml-2"
                                                        style={{
                                                            color: '#ef233c',
                                                        }}
                                                    >
                                                        *
                          </div>
                                                </div>
                                            </div>

                                            {renderFormField(field.type, field.name, field.value)}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div
                            className="card mt-4"
                            style={{
                                borderRadius: 0,
                                border: '2px solid rgba(233, 236, 239, 1)',
                                borderTop: 0,
                            }}
                        >
                            <div
                                className="card-img-top"
                                style={{
                                    backgroundColor: '#ffd100',
                                    height: '3px',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                            />

                            <div className="card-body">
                                <div
                                    style={{
                                        color: '#333333',
                                        fontWeight: 'bold',
                                        fontSize: '15px',
                                        marginBottom: '10px',
                                        fontFamily: 'Montserrat',
                                    }}
                                >
                                    <div className="row ml-1 mt-3">
                                        Attachments
                    <div
                                            className="ml-2"
                                            style={{
                                                color: '#ef233c',
                                            }}
                                        >
                                            *
                    </div>
                                    </div>
                                </div>
                                <div className="card card-body mb-4">
                                    <div className="row">
                                        <div className="custom-file col-md-12 mb-3">
                                            <input
                                                type="file"
                                                className="custom-file-input w-100"
                                                id="customFile"
                                                required={fileListArray.length > 0 ? false : true}
                                                onChange={e => onFileUploadChange(e)}
                                            />
                                            <label className="custom-file-label" htmlFor="customFile">
                                                {fileName}
                                            </label>
                                        </div>

                                        <div className="horizontal-container">
                                            <ul className="list-group list-group-flush">
                                                {fileListArray &&
                                                    fileListArray.map((file, index) => (
                                                        <li className="list-group-item" key={index}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="text-center mr-3">
                                                                    <DeleteIcon
                                                                        key={index}
                                                                        onClick={e => deleteAttachment(e, index)}
                                                                        style={{ color: 'red', cursor: 'handle' }}
                                                                    />
                                                                </div>
                                                                <div>{file.name}</div>
                                                            </div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-2">
                                    <div
                                        style={{
                                            color: '#333333',
                                            fontWeight: 'bold',
                                            fontSize: '15px',
                                            marginBottom: '10px',
                                            fontFamily: 'Montserrat',
                                        }}
                                    >
                                        <div className="row ml-1">
                                            Remarks
                      <div
                                                className="ml-2"
                                                style={{
                                                    color: '#ef233c',
                                                }}
                                            >
                                                *
                      </div>
                                        </div>
                                    </div>
                                    <textarea
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                        }}
                                        rows="5"
                                        className="form-control"
                                        required={true}
                                        name="Remarks"
                                        onChange={e => onChange(e)}
                                        defaultValue={draftValues.Remarks}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mr-3 text-right">
                        <button
                            type="button"
                            onClick={e => saveForm(e, id)}
                            className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                        >
                            Save
            </button>
                        <button
                            type="submit"
                            className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                        >
                            Submit
            </button>
                        <button
                            type="button"
                            onClick={e => history.push('/user-dashboard')}
                            className="btn w-100 btn-danger ml-3 mb-4 col-md-2"
                        >
                            Cancel
            </button>
                    </div>
                </form>
            ) : (
                    <Spinner />
                )}
        </div>
    );
};

RequestForPayment.propTypes = {
    getCostCenterDivision: PropTypes.func.isRequired,
    getRequestForPaymentById: PropTypes.func.isRequired,
    submitRequestForPaymentForm: PropTypes.func.isRequired,
    saveRequestForPaymentForm: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    forms: PropTypes.object.isRequired,
    requests: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    forms: state.forms,
    requests: state.requests,
    auth: state.auth,
});
export default connect(mapStateToProps, {
    getCostCenterDivision,
    saveRequestForPaymentForm,
    getRequestForPaymentById,
    submitRequestForPaymentForm,
})(withRouter(RequestForPayment));

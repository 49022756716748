import "./index.css";
import NumberFormat from "react-number-format";
import Axios from "axios";
import { getSystemAccessData } from "../../actions/systemAccess";
import { getEmpLevelData } from "../../actions/systemAccess";
import { getEmpDepartmentData } from "../../actions/systemAccess";
import { submitAccessRequest } from "../../actions/systemAccess";
import { getUserById } from "../../actions/userManagement";
import React, { useEffect, useState, createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import { setAlert } from "../../actions/alert";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Spinner from "../../components/layout/Spinner";
import Attachment from "../Shared/Attachment";
import store from "../../store";
var moment = require("moment");

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
    formControl: {
        margin: theme.spacing.unit * 3,
    },
    label: {
        color: "#333333",
        fontWeight: "10px",
    },
}));

const SystemAccessRequest = ({
    getSystemAccessData,
    getEmpLevelData,
    getEmpDepartmentData,
    submitAccessRequest,
    systemAccess: {
        system_access,
        loading,
        emp_level,
        empLevel_loading,
        emp_departments,
        departments_loading,
    },
    user: { user, user_loading },
    getUserById,
    auth,
    history,
}) => {
    //set maximum value for employee number
    const MAX_VAL = 99999;
    const withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_VAL) return true;
        return false;
    };

    const scrollDiv = createRef();

    const scrollRequestType = createRef();

    const classes = useStyles();

    useEffect(() => {
        getSystemAccessData();
        getEmpLevelData();
        getEmpDepartmentData();
    }, []);

    useEffect(() => {
        if (!loading) {
            setCheckedState(new Array(system_access.length).fill(false));
        }
    }, [system_access]);

    const [requestType, setRequestType] = useState(null);

    const [disableEmployeeDetails, setDisableEmployeeDetails] = useState(true);

    const handleRequestType = (e) => {
        setRequestType(e.target.value);
        if (e.target.value == 1) {
            getUserById(auth.id);
            setDisableEmployeeDetails(true);
            setIsAlreadyExistsEmployeeNo(false);
            setFormData({
                ...formData,
                self: true,
                onbehalfof: false,
            });
        } else if (e.target.value == 2) {
            setFormData({
                ...formData,
                employeeNumber: "",
                firstName: "",
                lastName: "",
                employmentLevelId: 0,
                jobTitle: "",
                departmentId: 0,
                self: false,
                onbehalfof: true,
            });
            setDisableEmployeeDetails(false);
            
        }
    };

    useEffect(() => {
        if (user !== [] && requestType == "1") {
            setFormData({
                ...formData,
                employeeNumber: user.emplyoeeNumber,
                firstName: user.firstName,
                lastName: user.lastName,
                employmentLevelId: user.employmentLevelId,
                jobTitle: user.designation,
                departmentId: user.departmentId,
            });
        }
    }, [user_loading]);

    useEffect(() => {
        if (requestType !== null && requestType !== "") {
            setRequestTypeSelected(true);
        }
    }, [requestType]);

    const [submitLoading, setSubmitLoading] = useState(false);

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        employmentLevelId: 0,
        jobTitle: "",
        employeeNumber: "",
        departmentId: 0,
        SystemAccessRequestSystems: [],
        SystemAccessRequesAttachments: [],
        comments: "",
        self: null,
        onbehalfof: null,
    });

    const {
        firstName,
        lastName,
        employmentLevelId,
        jobTitle,
        departmentId,
        employeeNumber,
    } = formData;

    const [isAlreadyExistsEmployeeNo, setIsAlreadyExistsEmployeeNo] =
        useState(false);

    //Verify Employee Number
    useEffect(() => {
        if (requestType == "2") {
            (async () => {
                if (formData.employeeNumber != "") {
                    const res = await Axios.get(
                        `/api/verification/verify-employee-no?employeeNo=${formData.employeeNumber}`
                    );

                    // Set Already exists state to response
                    setIsAlreadyExistsEmployeeNo(res.data);

                    if (res.data) {
                        store.dispatch(
                            setAlert(
                                "User already exists in the system. Please raise request from requestor's user account.",
                                "error"
                            )
                        );
                    }
                }
            })();
        }
    }, [formData.employeeNumber]);

    const [checkedState, setCheckedState] = useState([]);

    //state for comment
    const [comentData, setcomentData] = useState({ coment: "" });

    const { coment } = comentData;

    let commentArr = [];

    let jsonarr;

    const onChangeComment = (e) => {
        setcomentData({
            [e.target.name]: e.target.value,
        });

        if (e.target.value !== "") {
            commentArr.push({
                name: auth.firstName + " " + auth.lastName,
                comment: e.target.value,
                dateTime: moment().format("YYYY-MM-DD" + " " + "HH:mm"),
            });

            jsonarr = JSON.stringify(commentArr);
            formData.comments = jsonarr;
        }
    };

    // Selected Checkbox array state
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        if (updatedCheckedState[position]) {
            setSelectedCheckbox([
                ...selectedCheckbox,
                { systemAccessId: system_access[position].id },
            ]);
        } else {
            setSelectedCheckbox(
                selectedCheckbox.filter(
                    (item) => item.systemAccessId !== system_access[position].id
                )
            );
        }
    };

    useEffect(() => {
        formData.SystemAccessRequestSystems = selectedCheckbox;
    }, [selectedCheckbox]);

    const onChange = (e) => {
        setFormData({
            ...formData,

            [e.target.name]: e.target.value,
        });
    };

    // systems not selecting error
    const [isNotSelect, setIsNotSelect] = useState(true);

    // request type error
    const [requestTypeSelected, setRequestTypeSelected] = useState(true);

    const onSubmit = (e) => {
        if (requestType !== null && requestType !== "") {
            if (selectedCheckbox.length > 0) {
                setSubmitLoading(true);
                e.preventDefault();
                setIsNotSelect(true);
                submitAccessRequest(formData, history);
            } else {
                e.preventDefault();
                setIsNotSelect(false);
                scrollDiv.current.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            e.preventDefault();
            setRequestTypeSelected(false);
            scrollRequestType.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <main className="form-container">
            <form onSubmit={(e) => onSubmit(e)}>
                {!loading &&
                    !empLevel_loading &&
                    !departments_loading &&
                    !submitLoading ? (
                    <>
                        <div
                            className="card-body-form px-4 py-4 m-4"
                            style={{ backgroundColor: "white", borderRadius: "10px" }}
                            ref={scrollRequestType}
                        >
                            <div className="row mb-3">
                                <div className="col-lg-9">
                                    <div className="form-heading">System Access Request</div>
                                </div>
                                <div className="col-lg-3 date">
                                    <div className="row">
                                        Requested Date:
                                        <div
                                            className="ml-2"
                                            style={{
                                                color: "#0394fc",
                                            }}
                                        >
                                            {moment().format("YYYY/MM/DD")}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="row" style={{ marginBottom: "-20px" }}>
                                    <div className="col-lg-2">
                                        <div className="row">
                                            {" "}
                                            <div className="form-title ml-3 mt-0 mb-4">
                                                Employee Details
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <RadioGroup
                                            value={requestType}
                                            onChange={(e) => handleRequestType(e)}
                                        >
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <FormControlLabel
                                                        value="1"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                icon={
                                                                    <RadioButtonUncheckedIcon fontSize="small" />
                                                                }
                                                                checkedIcon={
                                                                    <RadioButtonCheckedIcon fontSize="small" />
                                                                }
                                                            />
                                                        }
                                                        label="For Self"
                                                        labelPlacement="start"
                                                        className={classes.label}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <FormControlLabel
                                                        value="2"
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                icon={
                                                                    <RadioButtonUncheckedIcon fontSize="small" />
                                                                }
                                                                checkedIcon={
                                                                    <RadioButtonCheckedIcon fontSize="small" />
                                                                }
                                                            />
                                                        }
                                                        label="On Behalf Of"
                                                        labelPlacement="start"
                                                    />
                                                </div>
                                                {!requestTypeSelected ? (
                                                    <div style={{ marginTop: "-5px" }}>
                                                        <Alert severity="error">
                                                            Please select one option!
                                                        </Alert>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </RadioGroup>
                                    </div>
                                </div>

                                <div className="card-form card-body">
                                    <div className="row px-2 py-2">
                                        <div className="col-lg-4">
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                New Colleague's Emp. No.
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <NumberFormat
                                                className="form-control"
                                                name="employeeNumber"
                                                value={employeeNumber}
                                                isAllowed={withValueCap}
                                                onChange={(e) => onChange(e)}
                                                required
                                                disabled={disableEmployeeDetails}
                                            />
                                            
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                First Name
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="firstName"
                                                value={firstName}
                                                required
                                                disabled={disableEmployeeDetails}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                Last Name
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="lastName"
                                                value={lastName}
                                                required
                                                disabled={disableEmployeeDetails}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                Employment Level
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <select
                                                className="form-control"
                                                name="employmentLevelId"
                                                value={employmentLevelId}
                                                onChange={(e) => onChange(e)}
                                                required
                                                disabled={disableEmployeeDetails}
                                            >
                                                <option value="">Select</option>
                                                {emp_level.length > 0 &&
                                                    emp_level.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                    ))}
                                            </select>
                                        </div>

                                        <div className="col-lg-4">
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                Job Title
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="jobTitle"
                                                value={jobTitle}
                                                required
                                                disabled={disableEmployeeDetails}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>

                                        <div className="col-lg-4" ref={scrollDiv}>
                                            <div className="row ml-1 mt-3 mb-2 card-title">
                                                Department
                                                <div
                                                    className="ml-2"
                                                    style={{
                                                        color: "#ef233c",
                                                    }}
                                                >
                                                    *
                                                </div>
                                            </div>
                                            <select
                                                className="form-control"
                                                name="departmentId"
                                                value={departmentId}
                                                onChange={(e) => onChange(e)}
                                                required
                                                disabled={disableEmployeeDetails}
                                            >
                                                <option value="">Select</option>
                                                {emp_departments.length > 0 &&
                                                    emp_departments.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    {" "}
                                    <div className="row ml-3 mt-4 mb-3">
                                        <div className="form-title"> System Access Required </div>
                                        <div
                                            className="ml-2"
                                            style={{
                                                color: "#ef233c",
                                            }}
                                        >
                                            *
                                        </div>
                                    </div>
                                </div>

                                <div className="card-form card-body">
                                    <div className="row">
                                        {!isNotSelect ? (
                                            <div className={classes.root}>
                                                <Alert severity="error">
                                                    Please select at least one system!
                                                </Alert>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {system_access.length > 0 &&
                                            system_access.map((data, index) => (
                                                <div key={index} className="py-2 col-lg-4 form-check">
                                                    <div className="mb-3 px-4">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value={data.name}
                                                            id={`custom-checkbox-${index}`}
                                                            name={data.name}
                                                            checked={checkedState[index]}
                                                            onChange={() => handleOnChange(index)}
                                                        />

                                                        <div
                                                            className="form-check-label form-heading-checkbox"
                                                            for="flexCheckDefault"
                                                        >
                                                            {data.name}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="form-title ml-3 mt-4 mb-4">Comments</div>
                                        </div>
                                        <div className="card-form card-body">
                                            <div className="p-2">
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    rows="4"
                                                    name="coment"
                                                    value={coment}
                                                    onChange={(e) => onChangeComment(e)}
                                                //required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="form-title ml-3 mt-4 mb-4">
                                                Attachments
                                            </div>
                                        </div>

                                        <Attachment
                                            initialState={formData}
                                            setState={setFormData}
                                            field="SystemAccessRequesAttachments"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 mb-5">
                            <button
                                style={{ float: "right" }}
                                type="submit"
                                className="btn w-100 btn-primary col-md-2 mb-4 "
                                disabled={isAlreadyExistsEmployeeNo}
                            >
                                Submit
                            </button>
                        </div>
                    </>
                ) : (
                    <div>
                        <Spinner />
                    </div>
                )}
            </form>
        </main>
    );
};

SystemAccessRequest.propTypes = {
    systemAccess: PropTypes.object.isRequired,
    getSystemAccessData: PropTypes.func.isRequired,
    getEmpLevelData: PropTypes.func.isRequired,
    getEmpDepartmentData: PropTypes.func.isRequired,
    submitAccessRequest: PropTypes.func.isRequired,
    getUserById: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    systemAccess: state.systemAccess,
    auth: state.auth,
    user: state.user,
});

export default connect(mapStateToProps, {
    getSystemAccessData,
    getEmpLevelData,
    getEmpDepartmentData,
    submitAccessRequest,
    getUserById,
})(withRouter(SystemAccessRequest));

import {
  FORM_ERROR,
  GET_REQUESTS,
  GET_REQUEST,
  CLEAR_REQUEST,
  CLEAR_REQUESTS,
  REQUEST_LOADING,
  GET_ALL_REQUESTS,
} from '../actions/types';

const intialState = {
  requests: [],
  request: null,
  requests_loading: true,
  request_loading: true,
};

export default function (state = intialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_REQUESTS:
      return {
        ...state,
        requests: payload,
        requests_loading: false,
      };

    case GET_ALL_REQUESTS:
      return {
        ...state,
        requests: payload,
        requests_loading: false,
      };

    case GET_REQUEST:
      return {
        ...state,
        request: payload,
        request_loading: false,
      };

    case REQUEST_LOADING:
      return {
        ...state,
        request_loading: true,
        requests_loading: true,
      };

    case CLEAR_REQUEST:
      return {
        ...state,
        request: null,
        request_loading: false,
      };

    case CLEAR_REQUESTS:
      return {
        ...state,
        requests: [],
        requests_loading: false,
      };

    default:
      return state;
  }
}

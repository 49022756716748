import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getReports, getReportsForRoomRequest } from "../../actions/reports";
import { connect } from "react-redux";
import {  makeStyles } from "@material-ui/core/styles";
import RoomRequestTemplate from "./Export/RoomRequestTemplate";
import Spinner from "../layout/Spinner";
import Grid from "../PettyCash/Shared/Grid";
import DateRangeCalender from "../PettyCash/Shared/DateRangeCalender";
var moment = require("moment");

const RoomRquest = ({
    getReportsForRoomRequest,
    reports: { reports, reports_loading },
}) => {
    const dateToday = new Date();
    const week_erlier = moment(dateToday).add(-7, "d").format("YYYY-MM-DD");

    // State for Date submission
    const [formData, setFormData] = useState({
        from: week_erlier,
        to: moment(dateToday).format("YYYY-MM-DD"),
    });

    const { from, to } = formData;

    useEffect(() => {
        if (from !== "" && to !== "") {
            getReportsForRoomRequest(from, to);
        }
    }, [formData]);

    const headerCells = [
        {
            id: "formNo",
            numeric: false,
            disablePadding: false,
            name: "Form No",
            propertyValue: "formNo",
            nested: false,
            minWidth: 120,
            type: "number",
        },
        {
            id: "formStatus",
            numeric: false,
            disablePadding: false,
            name: "Form Status",
            propertyValue: "formStatus",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "dcoumentName",
            numeric: false,
            disablePadding: false,
            name: "Docoument Name",
            propertyValue: "dcoumentName",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "createdUser",
            numeric: false,
            disablePadding: false,
            name: "Created By",
            propertyValue: "createdBy",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "raisedDepartment",
            numeric: false,
            disablePadding: false,
            name: "Raised by Department",
            propertyValue: "raisedbyDepartment",
            minWidth: 190,
            nested: false,
            type: "text",
        },
        {
            id: "company",
            numeric: false,
            disablePadding: false,
            name: "Company",
            propertyValue: "company",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "guestName",
            numeric: false,
            disablePadding: false,
            name: "Guest Name",
            propertyValue: "guestName",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "arrivalDate",
            numeric: false,
            disablePadding: false,
            name: "Arrival Date",
            propertyValue: "arrivalDate",
            nested: false,
            minWidth: 190,
            type: "date",
        },
        {
            id: "departureDate",
            numeric: false,
            disablePadding: false,
            name: "Depature Date",
            propertyValue: "departureDate",
            nested: false,
            minWidth: 190,
            type: "date",
        },
        {
            id: "noOfRooms",
            numeric: false,
            disablePadding: false,
            name: "No of Rooms",
            propertyValue: "noOfRooms",
            nested: false,
            minWidth: 170,
            type: "text",
        },
        {
            id: "remarks",
            numeric: false,
            disablePadding: false,
            name: "Remarks",
            propertyValue: "remarks",
            nested: false,
            minWidth: 200,
            type: "text",
        },
    ];





    

    

    return (
        <div className="mt-3 mb-3">
            <div>
                <DateRangeCalender setDate={setFormData} initialState={formData} />
            </div>

            {!reports_loading ? (
                <div>
                    <div>
                        {reports.length > 0 && (
                            <RoomRequestTemplate data={reports} />
                        )}
                    </div>

                    <div>
                        <Grid
                            headers={headerCells}
                            data={reports !== null ? reports : []}
                        />
                    </div>
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    );
};

RoomRquest.propTypes = {
    getReportsForRoomRequest: PropTypes.func.isRequired,
    reports: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    reports: state.reports,
});

export default connect(mapStateToProps, { getReportsForRoomRequest })(
    RoomRquest
);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import Spinner from "../layout/Spinner";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { changePassword } from "../../actions/userManagement";
import { usePasswordValidation } from "../../hooks/usePassword";
import Axios from "axios";

const UserAccount = ({
    auth: { loading, firstName, lastName, id },
    user: { change_password },
    changePassword,
}) => {
    useEffect(() => {
        (async () => {
            const res = await Axios.get(`/api/User/me?id=${id}`);
            setMyProfile(res.data);
        }
        )();


        if (change_password) {
            setFormData({
                ...formData,
                oldPassword: "",
                newPassword: "",
                rePassword: "",
            });
        }
    }, [change_password, id]);

    // State for capture form data
    const [formData, setFormData] = useState({
        oldPassword: "",
        newPassword: "",
        rePassword: "",
    });

    // Custom validation Hook
    const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
        usePasswordValidation({
            password: formData.newPassword,
        });

    const [myProfile, setMyProfile] = useState(null);

    const { oldPassword, newPassword, rePassword } = formData;

    // Password validation
    const [isValid, setIsValid] = useState(true);

    // pasword does not match error
    const [isMatch, setIsMatch] = useState(true);

    // Onchange evenet for change password form
    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    // Onsubmit for change password
    const onSubmit = (e) => {
        if (validLength && hasNumber && upperCase && lowerCase && specialChar) {
            if (newPassword === rePassword) {
                setIsMatch(true);
                setIsValid(true);
                e.preventDefault();
                changePassword(id, oldPassword, newPassword);
            } else {
                e.preventDefault();
                setIsMatch(false);
            }
        } else {
            setIsValid(false);
            e.preventDefault();
        }
    };

    // Check if all validations are correct for render error message box
    const isMatchValidation =
        lowerCase && upperCase && hasNumber && validLength && specialChar;

    const passwordValidationDiv = (
        <div
            className="card card-body mt-2 w-100"
            style={{
                borderColor: !isMatchValidation ? "red" : "green",
            }}
        >
            <div className="horizontal-container">
                <div style={{ display: "flex" }}>
                    <div>
                        {!lowerCase || !upperCase ? (
                            <HighlightOffIcon style={{ fill: "red" }} />
                        ) : (
                            <CheckCircleOutlineIcon style={{ fill: "green" }} />
                        )}
                    </div>
                    <div className="ml-3">
                        <small
                            style={{
                                color: !lowerCase || !upperCase ? "red" : "green",
                                textAlign: "left",
                            }}
                            className="form-text"
                        >
                            <b>Include both lower and uppercase character.</b>
                        </small>
                    </div>
                </div>
                <div className="mt-1" style={{ display: "flex" }}>
                    <div>
                        {!hasNumber || !specialChar ? (
                            <HighlightOffIcon style={{ fill: "red" }} />
                        ) : (
                            <CheckCircleOutlineIcon style={{ fill: "green" }} />
                        )}
                    </div>
                    <div className="ml-3">
                        <small
                            style={{
                                color: !hasNumber || !specialChar ? "red" : "green",
                                textAlign: "left",
                            }}
                            className="form-text"
                        >
                            <b>Include at least one number and symbol.</b>
                        </small>
                    </div>
                </div>
                <div className="mt-1" style={{ display: "flex" }}>
                    <div>
                        {!validLength ? (
                            <HighlightOffIcon style={{ fill: "red" }} />
                        ) : (
                            <CheckCircleOutlineIcon style={{ fill: "green" }} />
                        )}
                    </div>
                    <div className="ml-3">
                        <small
                            style={{
                                color: !validLength ? "red" : "green",
                                textAlign: "left",
                            }}
                            className="form-text"
                        >
                            <b>Be at least 8 characters long.</b>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <main>
            <div className="px-4 py-3 m-4">
                <h6 className="mb-0">{firstName} {lastName}</h6>
                <div className="row mb-3 mt-0">
                    <div className="form-heading ml-3">Account Summary</div>
                </div>
                <div className="card-form card-body">
                    {!loading && myProfile !== null ? (
                        <div className="row px-2 py-2">
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">User Name</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={myProfile.username}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">First Name</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={firstName}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">Last Name</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={lastName}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">Email</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={myProfile.email}
                                    disabled
                                />
                            </div>

                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">Employee No.</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={myProfile.emplyoeeNumber}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">Department</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={
                                        myProfile.department !== null && myProfile.department.name
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">Designation</div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={myProfile.designation}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">
                                    Employement Level
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={
                                        myProfile.employmentLevel !== null
                                            ? myProfile.employmentLevel.name +
                                            " - " +
                                            myProfile.employmentLevel.description
                                            : ""
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">
                                    Reporting Manager
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={myProfile.reportingManagerName}
                                    disabled
                                />
                            </div>
                            {myProfile.roles !== null &&
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">User Roles</div>
                                <Multiselect
                                    className="form-control m-0 mt-1 mb-2"
                                    displayValue="name"
                                    selectedValues={[]}
                                    disable={true}
                                />
                            </div>
                            }
                        </div>
                    ) : (
                        <div className="mt-2">
                            <Spinner />
                        </div>
                    )}
                </div>
                <form onSubmit={(e) => onSubmit(e)}>
                    <div className="card-form card-body mt-4">
                        <div className="row mb-2">
                            <div className="form-heading ml-3" style={{ fontSize: "20px" }}>
                                CHANGE PASSWORD
                            </div>
                        </div>
                        <div className="row px-2 py-2">
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">
                                    Current Password
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="oldPassword"
                                    value={oldPassword}
                                    onChange={(e) => onChange(e)}
                                    required
                                />
                                {!change_password ? (
                                    <small
                                        style={{ color: "red", textAlign: "left" }}
                                        className="form-text"
                                    >
                                        <b>Incorrect Current Password</b>
                                    </small>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">New Password</div>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="newPassword"
                                    value={newPassword}
                                    onChange={(e) => onChange(e)}
                                    required
                                />
                                {!isMatch ? (
                                    <small
                                        style={{ color: "red", textAlign: "left" }}
                                        className="form-text"
                                    >
                                        <b>Password does not match</b>
                                    </small>
                                ) : (
                                    ""
                                )}
                                {!isValid ? passwordValidationDiv : ""}
                            </div>
                            <div className="col-lg-4">
                                <div className="ml-1 mt-3 mb-2 card-title">
                                    Confirm Password
                                </div>
                                <input
                                    className="form-control"
                                    type="password"
                                    name="rePassword"
                                    value={rePassword}
                                    onChange={(e) => onChange(e)}
                                    required
                                />
                                {!isMatch ? (
                                    <small
                                        style={{ color: "red", textAlign: "left" }}
                                        className="form-text"
                                    >
                                        <b>Password does not match</b>
                                    </small>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div className="mb-5 mt-2">
                            <button
                                style={{ float: "right" }}
                                type="submit"
                                className="btn w-100 btn-primary col-lg-2"
                            >
                                Change Password
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </main>
    );
};

UserAccount.propTypes = {
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    user: state.user,
});

export default connect(mapStateToProps, { changePassword })(UserAccount);

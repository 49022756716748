import { setAlert } from "./alert";
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {
  GET_ALL_USERS,
  GET_ALL_USER_ROLES,
  GET_ALL_EMP_LEVELS,
  GET_BRANCH_DATA,
  USER_MANAGEMENT_GET_USER_BY_ID,
  USER_PROFILE_LOADING,
  GET_ALL_ROLES,
  GET_ROLE_BY_ID,
  CHANGE_MY_PASSWORD
} from "./types";

export const getAllUsers = () => async (dispatch) => {
  // If Authenticated set token as default
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };

  try {
    const res = await axios.get(`/api/User/get-all-users`, config);

    dispatch({
      type: GET_ALL_USERS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);

    dispatch(setAlert("Something Went Wrong", "error"));
  }
};

export const getAllUserRoles = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`/api/roles/get-all`, config);

    dispatch({
      type: GET_ALL_USER_ROLES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEmployeeLevelData = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`/api/EmployementLevel/get-all`, config);

    dispatch({
      type: GET_ALL_EMP_LEVELS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};

export const getAllBanches = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`/api/branches/get-all`, config);

    dispatch({
      type: GET_BRANCH_DATA,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};

// Submit New User
export const submitNewUser = (formData, history) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(`/api/user/create-user`, formData, config);

    if (res.status == 200) {
      history.push(`/user-dashboard/user-management/4`);
      dispatch(setAlert("New User Created Successfully", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Get user detail view for one user
export const getUserById = (Id) => async (dispatch) => {
  dispatch({ type: USER_PROFILE_LOADING });

  // If Authenticated set token as default
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/api/user/get-user-by-id?userid=${Id}`);

    dispatch({
      type: USER_MANAGEMENT_GET_USER_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};

// edit user submit
export const submitEditUser = (profileData, history) => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const config = {
      "Content-Type": "application/json",
    };

    const res = await axios.post(`/api/user/edit-user`, profileData, config);

    if (res.status == 200) {
      history.push(`/user-dashboard/user-management/4`);
      dispatch(setAlert("User Updated Successfully", "success"));
    }
  } catch (err) {
    console.log(err);
  }
};

// Set loading state
export const setUserProfileLoading = () => {
  return {
    type: USER_PROFILE_LOADING,
  };
};

//get all roles
export const getAllRoles = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`/api/roles/get-all`, config);

    dispatch({
      type: GET_ALL_ROLES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};

// Get one role details by id
export const getRoleById = (Id) => async (dispatch) => {
  // If Authenticated set token as default
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`/api/roles/get-privilages-by-roleid?Id=${Id}`);

    dispatch({
      type: GET_ROLE_BY_ID,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};


// change active status
export const activeStatusChange = (id, isActive) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        };

        const body = {
            UserId: id,
            IsActive: isActive,
        };

        const res = await axios.post(
            `/api/user/active-inactive-user`,
            body,
            config
        );

        if (res.status == 200) {
            dispatch(setAlert("Acive status change successfully", "success"));
            dispatch(getAllUsers());
        }
    } catch (err) {
        console.log(err);
    }
};

// Change password of a user
export const changePassword =
    (id, oldPassword, newPassword) => async (dispatch) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = {
            UserId: id,
            OldPassword: oldPassword,
            NewPassword: newPassword,
        };

        try {
            const res = await axios.post("/api/User/change-password", body, config);

            if (res.data === "Password changed successfully") {
                dispatch({
                    type: CHANGE_MY_PASSWORD,
                    payload: true,
                });
                dispatch(setAlert("Password has been Changed successfully", "success"));
            } else if (res.data === "Incorrect old password") {
                dispatch({
                    type: CHANGE_MY_PASSWORD,
                    payload: false,
                });
            }
        } catch (err) {
            console.log(err.error);
        }
    };
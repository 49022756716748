import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { getSettlementLog } from "../../../../actions/settlement";
import Spinner from "../../../layout/Spinner";
import Grid from "../../Shared/Grid";
import DateRangeCalender from "../../Shared/DateRangeCalender";

const SettlementLog = ({ getSettlementLog, settlement: { data, loading } }) => {
  const headerCells = [
    {
      id: "settledBy",
      numeric: false,
      disablePadding: false,
      name: "Settled By",
      propertyValue: "settledUser",
      nested: true,
      nestedValue: ["firstName", "lastName"],
      type: "text",
    },
    {
      id: "settledDate",
      numeric: false,
      disablePadding: false,
      name: "Settled Date",
      propertyValue: "settledOn",
      nested: false,
      type: "date",
    },
    {
      id: "iou",
      numeric: false,
      disablePadding: false,
      name: "IOU",
      propertyValue: "iou",
      nested: false,
      type: "text",
    },
    {
      id: "pettyCash",
      numeric: false,
      disablePadding: false,
      name: "Petty Cash",
      propertyValue: "pettyCash",
      nested: false,
      type: "numeric",
    },
    {
      id: "balance",
      numeric: false,
      disablePadding: false,
      name: "Balance",
      propertyValue: "balance",
      nested: false,
      type: "numeric",
    },
    {
      id: "requestor",
      numeric: false,
      disablePadding: false,
      name: "Requestor",
      propertyValue: "createdUser",
      nestedValue: ["firstName", "lastName"],
      nested: true,
      type: "text",
    },
  ];

  // Calculate three months prior from today
  var threeMonthsAgo = moment().subtract(3, "months");

  // Set Dates for filter settlements
  const [formData, setFormData] = useState({
    from: threeMonthsAgo.format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getSettlementLog(formData);
  }, [formData]);

  return (
    <div>
      <div className="card-body mt-3" style={{ backgroundColor: "white" }}>
        <div className="row p-0 m-0">
          <div className="col-lg-12">
            <DateRangeCalender setDate={setFormData} initialState={formData} />
            <div className="mt-4">
              {!loading ? (
                <Grid headers={headerCells} data={data} />
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SettlementLog.propTypes = {
  settlement: PropTypes.object.isRequired,
  getSettlementLog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  settlement: state.settlement,
});

export default connect(mapStateToProps, { getSettlementLog })(SettlementLog);

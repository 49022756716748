import {
    GET_FORMS,
    GET_FORM,
    CLEAR_FORM,
    FORM_LOADING,
    GET_COST_DIVISION,
    SUBMIT_REQUEST_FOR_PAYMENT_ERROR,
} from '../actions/types';

const intialState = {
    forms: [],
    form: null,
    requestForPayment: {
        costCenterDivision: [],
    },
    forms_loading: true,
    form_loading: true,
};

export default function (state = intialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_FORM:
            return {
                ...state,
                form: payload,
                form_loading: false,
            };
        case GET_FORMS:
            return {
                ...state,
                forms: payload,
                forms_loading: false,
            };

        case GET_COST_DIVISION:
            return {
                ...state,
                requestForPayment: {
                    costCenterDivision: payload,
                },
                form_loading: false,
            };

        case SUBMIT_REQUEST_FOR_PAYMENT_ERROR:
            return {
                ...state,
                form_loading: false,
            };

        case CLEAR_FORM:
            return {
                ...state,
                form: null,
                form_loading: false,
            };

        case FORM_LOADING:
            return {
                ...state,
                form_loading: true,
            };

        default:
            return state;
    }
}

import {
    GET_CREDIT_EVALUATION_REQUEST_BY_ID, GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT, CLEAR_CREDIT_REQUEST, GET_RELATIONSHIP_MANAGER_FOR_USER } from "../actions/types";

const initialState = {
  request_item: null,
    request_item_loading: true,
    relationship_managers: null,
    relationship_managers_loading: true,
    isRelationship_manager:true
    
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CREDIT_EVALUATION_REQUEST_BY_ID:
      return {
        ...state,
        request_item_loading: false,
        request_item: action.payload,
          };
      
      case CLEAR_CREDIT_REQUEST:
          return {
              ...state,
              request_item_loading: true,
              request_item: null,
          };
      case GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT:
          return {
              ...state,
              relationship_managers_loading: false,
              relationship_managers: action.payload,
          };
      case GET_RELATIONSHIP_MANAGER_FOR_USER:
          return {
              ...state,
              isRelationship_manager: action.payload,
            
          };
    default:
      return state;
  }
};

export default reducer;

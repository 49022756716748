import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Content from "../Content";
import { Link } from "react-router-dom";
import logo from "../../../img/navLogo.png";
import { getNavLinks } from "../../../actions/app";
import { logout } from "../../../actions/auth";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import CardGiftcardOutlinedIcon from "@material-ui/icons/CardGiftcardOutlined";

const drawerWidth = "264px";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    rightToolbar: {
        marginLeft: "auto",
        marginRight: 20,
    },
    selected: {},
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            marginBottom: 0,
            paddingBottom: 0,
        },
        backgroundColor: "#F8F9FA",
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,

    listItem: {
        color: "white",
        fontFamily: "Segoe UI",
        fontSize: "16px",
    },
    listItemIcon: { color: "white", marginRight: "-18px" },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#12343B",
        borderColor: "#F8F9FA",
    },
    content: {
        flexGrow: 1,
    },
}));

const Layout = ({
    getNavLinks,
    logout,
    app: { nav, loading_nav },
    auth: { loading, firstName, lastName },
}) => {
    useEffect(() => {
        getNavLinks();
    }, [getNavLinks]);

    //const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [selectedIndex, setSelectedIndex] = React.useState(null);
    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [open, setOpen] = React.useState(false);
    const [openReports, setOpenReports] = React.useState(false);
    const [openUser, setOpenUser] = React.useState(false);
    const [openPettyCash, setOpenPettyCash] = React.useState(false);
    const [openVouchers, setOpenVouchers] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleClickR = () => {
        setOpenReports(!openReports);
    };

    const handleClickUser = () => {
        setOpenUser(!openUser);
    };

    const handleClickPettyCash = () => {
        setOpenPettyCash(!openPettyCash);
    };

    const handleClickVouchers = () => {
        setOpenVouchers(!openVouchers);
    };

    const drawer = (
        <div>
            <div
                className="sticky-top"
                style={{
                    width: "263.4",
                    display: "flex",
                    justifyContent: "center",
                    height: "64px",
                    backgroundColor: "#F8F9FA",
                }}
            >
                <img
                    width="100px"
                    src={logo}
                    alt="navLogo"
                    style={{ marginTop: "3px", marginBottom: "3px" }}
                />
            </div>
            {/* <div className={classes.toolbar} /> */}
            <div>
                <Divider />
                <List component="nav" aria-label="main mailbox folders">
                    <Link
                        to={`/user-dashboard`}
                        style={{
                            display: "block",
                            color: "white",
                            marginTop: "5px",
                            // marginBottom: "5px",
                            textDecoration: "none",
                        }}
                    >
                        <ListItem
                            style={{
                                backgroundColor: selectedIndex === 0 && "rgb(255,215,0,0.7)",
                            }}
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0)}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <HomeOutlinedIcon />
                            </ListItemIcon>
                            <div
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontFamily: "Segoe UI",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                }}
                            >
                                Home
                            </div>
                            {/* <ListItemText primary="Home" /> */}
                        </ListItem>
                    </Link>
                </List>
                <List component="nav" aria-labelledby="nested-list-subheader">
                    <ListItem className={classes.listItem} button onClick={handleClick}>
                        <ListItemIcon className={classes.listItemIcon}>
                            <DescriptionOutlinedIcon />
                        </ListItemIcon>
                        <div
                            style={{
                                color: "white",
                                textDecoration: "none",
                                fontSize: "16px",
                                fontFamily: "Segoe UI",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                                width: "18em",
                            }}
                        >
                            Request Forms
                        </div>
                        {/* <ListItemText primary="Forms" className={classes.listItemText} /> */}
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {!loading_nav ? (
                                nav.length > 0 &&
                                nav[0].items.map((form) => (
                                    <Link
                                        to={`/user-dashboard/forms/${form.id}`}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={form.name}
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                            width: "263.4",
                                            color: "white",
                                            paddingTop: "11px",
                                            paddingBottom: "11px",
                                            textDecoration: "none",
                                            fontSize: "16px",
                                            fontFamily: "Segoe UI",
                                            paddingLeft: "66px",
                                            backgroundColor:
                                                selectedIndex === form.id && "rgb(255,215,0,0.7)",
                                        }}
                                        type="button"
                                        key={form.id}
                                        selected={selectedIndex === form.id}
                                        onClick={(event) => handleListItemClick(event, form.id)}
                                        className={classes.nested}
                                    >
                                        {form.name}
                                    </Link>
                                ))
                            ) : (
                                <div className="ml-3">Loading...</div>
                            )}
                        </List>
                    </Collapse>
                </List>
                <List component="nav" aria-labelledby="nested-list-subheader">
                    {nav.length > 0 && nav[1].items.length > 0 && (
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={handleClickR}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <div
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontFamily: "Segoe UI",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    width: "18em",
                                }}
                            >
                                Reports
                            </div>

                            {/* <ListItemText primary="Reports" /> */}
                            {openReports ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                    )}
                    <Collapse in={openReports} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {!loading_nav ? (
                                nav.length > 0 &&
                                nav[1].items.map((form) => (
                                    <Link
                                        to={`/user-dashboard/reports/${form.id}`}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        title={form.name}
                                        style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                            width: "263.4",
                                            color: "white",
                                            paddingTop: "11px",
                                            paddingBottom: "11px",
                                            textDecoration: "none",
                                            fontSize: "16px",
                                            fontFamily: "Segoe UI",
                                            paddingLeft: "66px",
                                            backgroundColor:
                                                selectedIndex === form.id + 1000 &&
                                                "rgb(255,215,0,0.7)",
                                        }}
                                        key={form.id}
                                        selected={selectedIndex === form.id + 1000}
                                        onClick={(event) =>
                                            handleListItemClick(event, form.id + 1000)
                                        }
                                        className={classes.nested}
                                    >
                                        {form.name}
                                    </Link>
                                ))
                            ) : (
                                <div className="ml-3">Loading...</div>
                            )}
                        </List>
                    </Collapse>
                </List>
                {nav.length > 0 && nav[2].items.length > 0 && (
                    <List component="nav" aria-labelledby="nested-list-subheader">
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={handleClickUser}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <AssignmentIndOutlinedIcon />
                            </ListItemIcon>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "block",
                                    width: "18em",
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontFamily: "Segoe UI",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="User Management"
                            >
                                User Management
                            </div>

                            {/* <ListItemText primary="Reports" /> */}
                            {openUser ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openUser} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {!loading_nav ? (
                                    nav.length > 0 &&
                                    nav[2].items.map((form) => (
                                        <Link
                                            to={`/user-dashboard/user-management/${form.id}`}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={form.name}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "block",
                                                width: "263.4",
                                                color: "white",
                                                paddingTop: "11px",
                                                paddingBottom: "11px",
                                                textDecoration: "none",
                                                fontSize: "16px",
                                                fontFamily: "Segoe UI",
                                                paddingLeft: "66px",
                                                backgroundColor:
                                                    selectedIndex === form.id + 2000 &&
                                                    "rgb(255,215,0,0.7)",
                                            }}
                                            key={form.id}
                                            selected={selectedIndex === form.id + 2000}
                                            onClick={(event) =>
                                                handleListItemClick(event, form.id + 2000)
                                            }
                                            className={classes.nested}
                                        >
                                            {form.name}
                                        </Link>
                                    ))
                                ) : (
                                    <div className="ml-3">Loading...</div>
                                )}
                            </List>
                        </Collapse>
                    </List>
                )}

                {nav.length > 0 && nav[3].items.length > 0 && (
                    <List component="nav" aria-labelledby="nested-list-subheader">
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={handleClickPettyCash}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <AssignmentTurnedInOutlinedIcon />
                            </ListItemIcon>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",

                                    display: "block",
                                    width: "18em",
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontFamily: "Segoe UI",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    //marginRight: "59px",
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Petty Cash Management"
                            >
                                Petty Cash Management
                            </div>

                            {/* <ListItemText primary="Reports" /> */}
                            {openPettyCash ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPettyCash} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {!loading_nav ? (
                                    nav.length > 0 &&
                                    nav[3].items.map((form) => (
                                        <Link
                                            to={`/user-dashboard/petty-cash/${form.id}`}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={form.name}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "block",
                                                width: "263.4",
                                                color: "white",
                                                paddingTop: "11px",
                                                paddingBottom: "11px",
                                                textDecoration: "none",
                                                fontSize: "16px",
                                                fontFamily: "Segoe UI",
                                                paddingLeft: "66px",
                                                backgroundColor:
                                                    selectedIndex === form.id + 3000 &&
                                                    "rgb(255,215,0,0.7)",
                                            }}
                                            key={form.id}
                                            selected={selectedIndex === form.id + 3000}
                                            onClick={(event) =>
                                                handleListItemClick(event, form.id + 3000)
                                            }
                                            className={classes.nested}
                                        >
                                            {form.name}
                                        </Link>
                                    ))
                                ) : (
                                    <div className="ml-3">Loading...</div>
                                )}
                            </List>
                        </Collapse>
                    </List>
                )}
                {nav.length > 0 && nav[4].items.length > 0 && (
                    <List component="nav" aria-labelledby="nested-list-subheader">
                        <ListItem
                            className={classes.listItem}
                            button
                            onClick={handleClickVouchers}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <CardGiftcardOutlinedIcon />
                            </ListItemIcon>
                            <div
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",

                                    display: "block",
                                    width: "18em",
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "16px",
                                    fontFamily: "Segoe UI",
                                    paddingTop: "6px",
                                    paddingBottom: "6px",
                                    //marginRight: "59px",
                                }}
                                data-toggle="tooltip"
                                data-placement="right"
                                title="Gift Voucher Management"
                            >
                                Gift Voucher Management
                            </div>

                            {/* <ListItemText primary="Reports" /> */}
                            {openVouchers ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openVouchers} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {!loading_nav ? (
                                    nav.length > 0 &&
                                    nav[4].items.map((form) => (
                                        <Link
                                            to={`/user-dashboard/gift-voucher/${form.id}`}
                                            data-toggle="tooltip"
                                            data-placement="right"
                                            title={form.name}
                                            style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "block",
                                                width: "263.4",
                                                color: "white",
                                                paddingTop: "11px",
                                                paddingBottom: "11px",
                                                textDecoration: "none",
                                                fontSize: "16px",
                                                fontFamily: "Segoe UI",
                                                paddingLeft: "66px",
                                                backgroundColor:
                                                    selectedIndex === form.id + 4000 &&
                                                    "rgb(255,215,0,0.7)",
                                            }}
                                            key={form.id}
                                            selected={selectedIndex === form.id + 4000}
                                            onClick={(event) =>
                                                handleListItemClick(event, form.id + 4000)
                                            }
                                            className={classes.nested}
                                        >
                                            {form.name}
                                        </Link>
                                    ))
                                ) : (
                                    <div className="ml-3">Loading...</div>
                                )}
                            </List>
                        </Collapse>
                    </List>
                )}
            </div>
        </div>
    );

    //const container =
    // window !== undefined ? () => window().document.body : undefined;
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon style={{ color: "black" }} />
                    </IconButton>
                    <div className={classes.rightToolbar}>
                        {!loading ? (
                            <div className="row">
                                <a href="!#" className="mr-2">
                                    <NotificationsActiveIcon style={{ color: "#575757" }} />
                                </a>
                                <div className="dropdown">
                                    <div
                                        className="dropdown-toggle"
                                        id="dropdownMenuLink"
                                        data-bs-toggle="dropdown"
                                        style={{
                                            color: "#575757",
                                            fontSize: "18px",
                                            fontFamily: "Segoe UI",
                                            fontWeight: "500",
                                        }}
                                        type="button"
                                    >
                                        {firstName} {lastName}
                                    </div>
                                    <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuLink"
                                        style={{
                                            color: "#575757",
                                            fontSize: "18px",
                                            fontFamily: "Segoe UI",
                                        }}
                                    >
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                href="/user-dashboard/settings"
                                            >
                                                Settings
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                href="/user-dashboard/my-account"
                                            >
                                                View Account
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={logout}
                                                type="button"
                                            >
                                                Sign Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        //container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Content />
            </main>
        </div>
    );
};

Layout.propTypes = {
    auth: PropTypes.object.isRequired,
    app: PropTypes.object.isRequired,
    getNavLinks: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    window: PropTypes.func,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    app: state.app,
});

export default connect(mapStateToProps, { getNavLinks, logout })(Layout);

import {
  GET_SUGGEST_DEPARTMENT,
  GET_ALL_DEPARTMENT_HEADS,
    GET_DEPARTMENT_BY_ID,
    DEPARTMENT_LOADING,
    GET_ALL_DEPARTMENT_RELATIONSIP_MANAGERS,
} from "../actions/types";

const initialState = {
  departments: null,
  loading: true, //true
  departmentHeads: [],

  department: null,
    department_loading: true,

    departmentRelationshipManagers: null,
    managers_loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGEST_DEPARTMENT:
      return {
        ...state,
        loading: false, //false
        departments: action.payload,
      };
    case GET_ALL_DEPARTMENT_HEADS:
      return {
        ...state,
        loading: false, //false
        departmentHeads: action.payload,
      };
    case GET_DEPARTMENT_BY_ID:
      return {
        ...state,
        department_loading: false,
        department: action.payload,
          };
      case DEPARTMENT_LOADING:
          return {
              ...state,
              department_loading: true,
              
          };
      case GET_ALL_DEPARTMENT_RELATIONSIP_MANAGERS:
          return {
              ...state,
              managers_loading: false,
              departmentRelationshipManagers: action.payload,
          };

    default:
      return state;
  }
};

export default reducer;
